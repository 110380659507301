import { cloneDeep, sum } from 'lodash';

/**
 * 
 * @param {Object[]} treeData 
 * @param {Object[]} treeData[].children 
 * @param {?number} treeData[].branch_length
 * @param {string} [treeData.name]
 */
export function serializeBinaryTree(treeData) {
    const result = [];
    const serialize = (node, level, path) => {
        const branchLength = (node.branch_length && node.branch_length > 0) ? node.branch_length : 0;
        if (node.children && node.children.length === 2) {
            result.push({
                id: node.id,
                position: node.position,
                level, branchLength,
                path: [...path, node.id],
                type: 'middle',
            });
            serialize(node.children[0], level + 1, [...path, node.id]);
            serialize(node.children[1], level + 1, [...path, node.id]);
        } else {
            result.push({
                id: node.id, level, branchLength,
                position: node.position,
                type: 'leaf',
                path: [...path, node.id],
                name: node.name,
                hash_name: node.hash_name
            });
        }
    };
    serialize(treeData, 0, []);
    return result;
}


export function transformToEdges(treeData) {
    const treeWithPos = allocatePosition(treeData);
    const serializedTree = serializeBinaryTree(treeWithPos);
    const result = [];
    const recursion = (node) => {
        if (node.children && node.children.length === 2) {
            const parent = serializedTree.find(item => item.id === node.id);
            const child1 = serializedTree.find(item => item.id === node.children[0].id);
            const child2 = serializedTree.find(item => item.id === node.children[1].id);
            result.push({
                position: [parent.position, child1.position],
                distance: [
                    sum(parent.path.map(id => serializedTree[id].branchLength)),
                    sum(child1.path.map(id => serializedTree[id].branchLength)),
                ]
            });
            result.push({
                position: [parent.position, child2.position],
                distance: [
                    sum(parent.path.map(id => serializedTree[id].branchLength)),
                    sum(child2.path.map(id => serializedTree[id].branchLength)),
                ]
            });
            recursion(node.children[0]);
            recursion(node.children[1]);
        }
    };
    recursion(treeWithPos);
    return result;
}

export function allocatePosition(treeData) {
    const result = cloneDeep(treeData);
    let id = 0;
    let leafCount = 0;
    const allocate = (node) => {
        node.id = id;
        id++;
        if (node.children && node.children.length === 2) {
            const position = 0.5 * (allocate(node.children[0]) + allocate(node.children[1]));
            node.position = position;
            return position;
        } else {
            leafCount++;
            node.position = leafCount;
            return node.position;
        }
    };
    allocate(result);
    return result;
}


/**
 * Transform a filtered tree (with nodes only have one child) to an normal binary tree.
 * branch_length will be summed.
 * @param {Object[]} treeData 
 * @param {Object[]} treeData[].children 
 * @param {?number} treeData[].branch_length
 * @param {string} [treeData.name]
 */
export function transformTreeToBinary(treeData) {
    const result = cloneDeep(treeData);
    const recursion = (node) => {
        if (node.children) {
            if (node.children.length === 1) {
                return recursion(node.children[0]);
            } else {
                node.children = [
                    recursion(node.children[0]),
                    recursion(node.children[1])
                ];
                return node;
            }
        } else {
            return node;
        }
    };
    recursion(result);
    return result;
}