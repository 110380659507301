import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Atoms, Charts } from 'components';
import { getSwissModelInfo } from 'api/fetch_data';


class Prot3D extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            info: [],
            QE: [],
            seq: {},
            prot_id: '',
            is_empty: true
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    toFixed = (dight, bits = 3) => {
        return Math.round(dight * Math.pow(10, bits)) / Math.pow(10, bits);
    };

    fetchData = () => {
        const { speciesAbbr, genomeAbbr, id, recordType } = this.props;

        getSwissModelInfo(genomeAbbr, id, recordType).then(data => {
            if (!this.__ignoreLastFetch) {
                const info = [
                    { title: 'Template', description: data['template'] },
                    { title: 'Oligo-state', description: data['oligo_state'] },
                    { title: 'Coverage', description: this.toFixed(data['coverage']) },
                    { title: 'Identity', description: this.toFixed(data['identity']) },
                    { title: 'PDB file', description: <a href={`${process.env.REACT_APP_PROTEIN_MODEL_PATH}${speciesAbbr}/${genomeAbbr}/${data['prot_id']}.pdb`}>Download</a> },
                ];
                const QE = [
                    { title: 'QMEAN', description: this.toFixed(data['QMEAN']) },
                    { title: 'Cβ', description: this.toFixed(data['cbeta']) },
                    { title: 'All atom', description: this.toFixed(data['all_atom']) },
                    { title: 'solvation', description: this.toFixed(data['solvation']) },
                    { title: 'torsion', description: this.toFixed(data['torsion']) },
                ];
                const seq = {
                    query_seq: data['query_seq'],
                    template_seq: data['template_seq'],
                };
                this.setState({
                    info: info,
                    QE: QE,
                    seq: seq,
                    prot_id: data['prot_id'],
                    is_empty: Object.keys(data).length === 0,
                });
            }
        });
    };

    render() {
        const { info, QE, seq, is_empty, prot_id } = this.state;
        const { speciesAbbr, genomeAbbr } = this.props;
        if (is_empty) {
            return null;
        }

        return (
            <Atoms.InfoGroup title="Protein 3D structure">
                <Grid container direction="row" alignItems="center" style={{ padding: '0 20px' }}>
                    <Grid container item spacing={4} xs={12} justify="space-evenly">
                        <Grid item sm={3}>
                            <h4>Protein model information</h4>
                            <Atoms.InfoList dataSource={info} titleWidth={3} />
                            <br />
                            <h4>Global Quality Estimate</h4>
                            <Atoms.InfoList dataSource={QE} titleWidth={3} />
                        </Grid>
                        <Grid item sm={7}>
                            <Charts.ProteinModel
                                width="100%"
                                height="100%"
                                modelFileName={prot_id}
                                modelSource={`${process.env.REACT_APP_PROTEIN_MODEL_PATH}${speciesAbbr}/${genomeAbbr}/`}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <div id="seq" style={{ padding: '0 auto', border: '1px solid white'}}>
                            <Atoms.SequenceCompare
                                maxWidth="calc(100% - 1px)"
                                type="protein"
                                sourceSequence={{
                                    description: 'Protein',
                                    sequence: seq.query_seq,
                                }}
                                targetSequence={{
                                    description: 'Template',
                                    sequence: seq.template_seq,
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
            </Atoms.InfoGroup>
        );
    }
}

Prot3D.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default Prot3D;

