import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Paper, Collapse, Typography } from '@material-ui/core';
import { Atoms, Layouts } from 'components';
import { getMapViewerInfo } from 'api/fetch_data';
import { GeneListForm } from '../components';
import Chromosome from 'components/charts/Chromosome.jsx';

class MapViewerView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chromSizes: {},
            data: [],
            notFoundId: [],
            tableData: [],
        };
        this.header = [
            { key: 'name', content: 'Name' },
            { key: 'chrom', content: 'Chrom' },
            { key: 'start', content: 'Start' },
            { key: 'end', content: 'End' },
            { key: 'strand', content: 'Strand' },
        ];
        this.formRef = React.createRef();
        this.tabsRef = React.createRef();
    }

    handleSubmit = async (species, genome, geneList) => {
        const response = await getMapViewerInfo(genome, geneList);
        const { chrom_size: chromSizes, data, not_found_id: notFoundId } = response;
        console.log(data);
        this.setState({
            chromSizes,
            data,
            notFoundId,
            tableData: data.map(item => {
                const { name, chrom, start, end, strand, locus_hash_name } = item;
                return {
                    name: <Link to={`/wiki/gene/${species}/${genome}/${locus_hash_name}`}>{name}</Link>,
                    chrom, start, end, strand
                };
            })
        });
    };

    applyExample = ({ species, genome, geneInput }) => (event) => {
        this.tabsRef.current.handleChange(event, 0);
        this.formRef.current.setValues({ species, genome, geneInput });
    }

    render() {
        const { tableData, chromSizes, data, notFoundId } = this.state;
        return (
            <Layouts.MainLayoutContent headerText="Gene map viewer">
                <Atoms.ExampleCard
                    ref={this.tabsRef}
                    title="Type Gene IDs to View:"
                    formContent={<GeneListForm
                        ref={this.formRef}
                        style={{ marginBottom: '20px' }}
                        handleSubmit={this.handleSubmit}
                    />}
                    exampleContent={<div>
                        <Button onClick={this.applyExample({
                            species: 'gosArb',
                            genome: 'CGP',
                            geneInput: 'Ga01g00064.F1  Ga01g01054.F1  Ga05g01394.F2  Ga05g01727.F1  Ga06g01859.F4'
                        })}>Use Example</Button>
                    </div>}
                />
                <Collapse in={data.length > 0 || notFoundId.length > 0}>
                    <Paper style={{ padding: '16px' }}>
                        {notFoundId.length > 0 && <Typography variant="body1" style={{ marginBottom: '16px' }}>{`Can't found these genes: ${notFoundId.join(', ')}`}</Typography>}
                        {data.length > 0 && <Chromosome
                            chromosomeSize={chromSizes}
                            geneList={data}
                            height={400}
                        />}
                        {tableData.length > 0 && <Atoms.CustomTable columns={this.header} dataSource={tableData} />}
                    </Paper>
                </Collapse>
            </Layouts.MainLayoutContent>
        );
    }
}

export default MapViewerView;
