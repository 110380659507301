import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import MagenColinearInfo from './MagenColinearInfo';
import PlazaColinearInfo from './PlazaColinearInfo';

function ColinearInfo(props){
    return (
        <Atoms.InfoGroup title="Gene synteny">
            <Atoms.InfoTabs
                dataSource={[{
                    title: 'MaGenDB genomes',
                    content: <MagenColinearInfo {...props} source="MaGen" />
                }, {
                    title: 'Other plant genomes',
                    content: <PlazaColinearInfo {...props} source="Plaza" />
                }]}
            />
        </Atoms.InfoGroup>
    );
}

ColinearInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default ColinearInfo;

