import React from 'react';
// import PropTypes from 'prop-types';
import { Atoms } from 'components';
import GoInfo from './GoInfo';
import KoInfo from './KoInfo';
import EcInfo from './EcInfo';

function Ontology (props) {
    return(
        <Atoms.InfoGroup title="Ontology annotation">
            <Atoms.InfoTabs
                dataSource={[{
                    title: 'Gene Ontology',
                    content: <GoInfo {...props} />
                }, {
                    title: 'KEGG Orthology',
                    content: <KoInfo {...props} />
                }, {
                    title: 'Enzyme Commission',
                    content: <EcInfo {...props} />
                }]}
            />
        </Atoms.InfoGroup>
        
    );
}


export default Ontology;