import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import { getSummary, getGeneSeq } from 'api/fetch_data';

const { InfoGroup, InfoList, CustomLink, ChipDownloadLink } = Atoms;

class GeneSummaryInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = async () => {
        const { speciesAbbr, genomeAbbr, id } = this.props;
        const data = await getSummary(genomeAbbr, id, 'Locus');

        if (!this.__ignoreLastFetch && data) {
            const { gene_name, species_name, gene_type, ms_support, taxon_id, chrom, start, end, strand, tair_symbol } = data;
            const dataSource = [
                { title: 'Name', description: gene_name },
                { title: 'TAIR gene symbol', description: tair_symbol },
                {
                    title: 'Taxonomy',
                    description: <CustomLink
                        externalTarget="NCBI"
                        href={`https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=${taxon_id}`}
                    >{`${species_name} (${taxon_id})`}</CustomLink>
                },
                {
                    title: 'Position',
                    description: <div>
                        {`${chrom}: ${start} - ${end}`}
                        <CustomLink
                            to={`/jbrowse?species_abbr=${speciesAbbr}&genome_abbr=${genomeAbbr}&chrom=${chrom}&start=${start}&end=${end}`}
                            style={{ marginLeft: '5px' }}
                        >View this gene</CustomLink>
                        <ChipDownloadLink
                            downloadParams={[genomeAbbr, id]}
                            downloadPromise={getGeneSeq}
                            fileName="download.fasta"
                            style={{ marginLeft: '10px' }}
                        >Get Sequence</ChipDownloadLink>
                    </div>
                },
                { title: 'Gene type', description: gene_type },
                { title: 'MS support', description: ms_support.toString() },
            ];
            this.setState({ dataSource });
            this.props.setLocus({ chrom, start, end, strand });
        }

    };

    render() {
        const { dataSource } = this.state;
        return (
            <InfoGroup title="Summary" defaultExpanded>
                <InfoList dataSource={dataSource} />
            </InfoGroup>
        );
    }
}

GeneSummaryInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    setLocus: PropTypes.func,
};

export default GeneSummaryInfo;

