import React from 'react';
import { SvgIcon, withStyles } from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        height: '100%'
    }
};

function ToolsIcon({ scale, classes }) {
    let svgScale = scale;
    if (!scale) {
        svgScale = 24;
    }
    return (
        <SvgIcon viewBox={`0 0 ${svgScale} ${svgScale}`} className={scale ? classes.root : undefined}>
            <g transform={`scale(${svgScale / 24})`} >
                <g transform="scale(0.04) translate(50,50)">
                    <path d="M13.361,409.063c-17.814,17.814-17.814,46.663,0,64.477c8.907,8.907,20.58,13.345,32.254,13.345
                        s23.347-4.438,32.254-13.345l149.717-149.748l-64.508-64.447L13.361,409.063z"/>
                    <polygon points="387.548,131.577 452.755,97.863 486.893,31.866 455.065,0.038 389.098,34.207 
                        355.324,99.353 276.012,178.756 308.175,210.98"/>
                    <path d="M401.835,304.641l-6.141-0.608c-11.126,0-21.675,2.28-31.524,5.928l-187.17-187.2
                        c3.648-9.849,5.928-20.398,5.928-31.524l-0.608-6.141c-3.222-47.454-42.316-85.058-90.59-85.058
                        c-14.045,0-27.208,3.435-39.094,9.12l60.586,60.586c3.192,3.192,5.381,6.961,6.84,10.944c3.982,10.761,1.824,23.347-6.84,32.041
                        c-5.928,5.928-13.71,8.907-21.492,8.907c-3.587,0-7.144-0.79-10.549-2.067c-3.982-1.52-7.752-3.648-10.944-6.87L9.652,52.143
                        c-5.685,11.886-9.12,25.049-9.12,39.094c0,48.274,37.604,87.368,85.058,90.59l6.141,0.608c11.126,0,21.675-2.28,31.524-5.897
                        l187.139,187.17c-3.587,9.849-5.867,20.398-5.867,31.524l0.638,6.141c3.162,47.484,42.286,85.058,90.56,85.058
                        c14.045,0,27.238-3.374,39.094-9.12l-60.586-60.586c-3.162-3.162-5.381-6.961-6.901-10.913
                        c-3.982-10.761-1.824-23.377,6.901-32.071c5.867-5.928,13.71-8.907,21.492-8.907c3.587,0,7.113,0.76,10.579,2.067
                        c3.982,1.49,7.782,3.618,10.913,6.84l60.586,60.586c5.715-11.917,9.12-25.049,9.12-39.094
                        C486.893,346.957,449.289,307.863,401.835,304.641z"/>
                </g>
            </g>
        </SvgIcon>
    );
}

export default withStyles(styles)(ToolsIcon);