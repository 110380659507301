import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { createMuiTheme, colors } from '@material-ui/core';
import Jbrowse from 'pages/jbrowse/JbrowseView';
import { NoMatch, GeneWiki, TransWiki, ProtWiki } from 'pages/wiki/views';
import { SearchView } from 'pages/search';
import { HomeView, DocView, HelpView, Genome } from 'pages/base';
import {
    CompareView, BlastView, DownloadView, PrimerDesignView,
    BatchFetchDataView, MapViewerView, LiftOverView, TopGoView, GenomeSyntenyViewerView,
    GeneSyntenyViewerView, PhyloTreeViewer, FetchMAFView
} from 'pages/tools/views';
import RouteWrapper from 'components/RouteWrapper';
import { MainLayout } from 'components/layouts';

import getMenu from 'api/menu';
import { storage } from 'api/storage';
import { primaryColor } from 'assets/global';

export const AppContext = React.createContext();


const theme = createMuiTheme({
    palette: {
        primary: {
            main: primaryColor[0]
        },
        secondary: colors.red,
    },
});

class App extends React.Component {
    state = {
        menu: [],
    };
    customHistory = createBrowserHistory();

    async componentDidMount() {
        const menu = await getMenu();
        this.setState({ menu });
        storage.init();
    }

    render() {
        const { menu } = this.state;
        return (
            <BrowserRouter basename="/magendb">
                <AppContext.Provider value={{
                    history: this.customHistory,
                }}>
                    <Switch>
                        <RouteWrapper exact path="/" component={HomeView} theme={theme} />
                        {/*<RouteWrapper exact path="/demo" component={Demo} theme={theme} />**/}
                        <MainLayout menu={menu} theme={theme} >
                            <Switch>
                                <RouteWrapper exact path="/genome/:speciesAbbr" component={Genome} forceUpdateWithLocation />
                                <RouteWrapper exact path="/jbrowse" component={Jbrowse} forceUpdateWithLocation />
                                <RouteWrapper
                                    exact
                                    path="/search"
                                    component={SearchView}
                                    withImplicitFilter
                                    forceUpdateWithLocation
                                />
                                <RouteWrapper
                                    exact
                                    path="/wiki/gene/:speciesAbbr/:genomeAbbr/:id"
                                    component={GeneWiki}
                                    forceUpdateWithLocation
                                />
                                <RouteWrapper
                                    exact
                                    path="/wiki/transcript/:speciesAbbr/:genomeAbbr/:id"
                                    component={TransWiki}
                                    forceUpdateWithLocation
                                />
                                <RouteWrapper
                                    exact
                                    path="/wiki/protein/:speciesAbbr/:genomeAbbr/:id"
                                    component={ProtWiki}
                                    forceUpdateWithLocation
                                />
                                <RouteWrapper exact path="/compare" component={CompareView} />
                                <RouteWrapper exact path="/blast" component={BlastView} />
                                <RouteWrapper exact path="/downloads" component={DownloadView} />
                                <RouteWrapper exact path="/document" component={DocView} />
                                <RouteWrapper exact path="/primerdesign" component={PrimerDesignView} />
                                <RouteWrapper exact path="/fetchdata" component={BatchFetchDataView} />
                                <RouteWrapper exact path="/mapviewer" component={MapViewerView} />
                                <RouteWrapper exact path="/help/:page" component={HelpView} forceUpdateWithLocation />
                                <RouteWrapper exact path="/liftover" component={LiftOverView} />
                                <RouteWrapper exact path="/goenrichment" component={TopGoView} />
                                <RouteWrapper exact path="/genomesyntenyviewer" component={GenomeSyntenyViewerView} />
                                <RouteWrapper exact path="/genesyntenyviewer" component={GeneSyntenyViewerView} />
                                <RouteWrapper exact path="/phylotree" component={PhyloTreeViewer} />
                                <RouteWrapper exact path="/fetchmaf" component={FetchMAFView} />
                                <Route component={NoMatch} />

                            </Switch>
                        </MainLayout>
                    </Switch>

                </AppContext.Provider>
            </BrowserRouter>
        );
    }
}


export default App;