import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Axis, Coord, Guide } from 'bizcharts';
import { chartColors as colors } from 'assets/global';

/* const arrow = 0.01;
Shape.registerShape('interval', 'geneblock', {
    getPoints(cfg) {
        const x = cfg.x;
        const y = cfg.y[1];
        const y0 = cfg.y[0];
        const width = cfg.size;
        return [
            { x: x - width / 2, y: y0 },
            { x: x - width / 2, y: y - arrow },
            { x: x, y: y },
            { x: x + width / 2, y: y - arrow },
            { x: x + width / 2, y: y0 },
            { x: x, y: y0 + arrow },
        ];
    },
    draw(cfg, container) {
        const points = this.parsePoints(cfg.points);
        const polygon = container.addShape('polygon', {
            attrs: {
                points: [
                    [points[0].x, points[0].y],
                    [points[1].x, points[1].y],
                    [points[2].x, points[2].y],
                    [points[3].x, points[3].y],
                    [points[4].x, points[4].y],
                    [points[5].x, points[5].y]
                ],
                fill: cfg.color
            }
        });
        return polygon;
    }
});

Shape.registerShape('interval', 'geneblockreverse', {
    getPoints(cfg) {
        const x = cfg.x;
        const y = cfg.y[1];
        const y0 = cfg.y[0];
        const width = cfg.size;
        return [
            { x: x - width / 2, y: y0 + arrow },
            { x: x - width / 2, y: y },
            { x: x, y: y - arrow },
            { x: x + width / 2, y: y },
            { x: x + width / 2, y: y0 + arrow },
            { x: x, y: y0 },
        ];
    },
    draw(cfg, container) {
        const points = this.parsePoints(cfg.points);
        const polygon = container.addShape('polygon', {
            attrs: {
                points: [
                    [points[0].x, points[0].y],
                    [points[1].x, points[1].y],
                    [points[2].x, points[2].y],
                    [points[3].x, points[3].y],
                    [points[4].x, points[4].y],
                    [points[5].x, points[5].y]
                ],
                fill: cfg.color
            }
        });
        return polygon;
    }
}); */

function GeneStructure({ dataSource, bpStart, bpEnd, heightPerDataGroup, width, padding, ToolTipComponent }) {
    const data = [];
    dataSource.forEach((geneGroup, index) => {
        geneGroup.blocks.forEach(gene => {
            data.push({
                index: index,
                range: [gene[0], gene[1]],
                group: geneGroup.group,
                name: geneGroup.name,
                strand: geneGroup.strand
            });
        });
    });

    const colorDict = {};
    let iter = 0;
    dataSource.forEach(geneGroup => {
        if (!(geneGroup.group in colorDict)) {
            colorDict[geneGroup.group] = colors[iter];
            iter++;
        }
    });

    const scale = {
        index: {
            type: 'cat',
            alias: 'name',
            ticks: Array.from(new Array(dataSource.length).keys())
        },
        range: {
            min: bpStart,
            max: bpEnd
        }
    };


    return (
        <Chart
            height={heightPerDataGroup * (dataSource.length) + padding[0] + padding[2]}
            width={width}
            data={data}
            scale={scale}
            padding={padding}
            onIntervalClick={(ev) => {
                console.log(ev);
            }}
            forceFit
        >
            <Coord transpose />
            {ToolTipComponent}
            <Axis
                name="index"
                label={{
                    textStyle: {
                        fontSize: '12',
                        fill: '#000',
                        fontWeight: 'bold',
                    },
                    position: 'center',
                    formatter: (text, item, index) => {
                        const axisLabel = data.find(set => set.index === index);
                        if (axisLabel) {
                            return axisLabel.name;
                        }
                    }
                }} />
            <Axis
                name="range"
                label={{
                    offset: 20,
                    textStyle: {
                        fontSize: '12',
                        fill: '#000',
                        fontWeight: 'bold',
                    },
                }}
            />

            <Geom
                type="interval"
                position="index*range"
                color={['group', colors]}
                /**
                 *shape={['strand', (strand) => {
                  *  if (strand === '-') {
                        return 'geneblockreverse';
                    } else if (strand === '+') {
                        return 'geneblock';
                    } else {
                        return 'interval';
                    }
                }]}
                */
                size={6}
            />
            <Guide>
                {dataSource.map((geneGroup, index) => (
                    <Guide.Line
                        key={index}
                        start={{
                            index: index,
                            range: geneGroup.start
                        }}
                        end={{
                            index: index,
                            range: geneGroup.end
                        }}
                        lineStyle={{
                            stroke: colorDict[geneGroup.group],
                            lineWidth: 1,
                            lineDash: [0, 0, 0]
                        }}
                    />
                ))}

            </Guide>
        </Chart>
    );
}


GeneStructure.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({
        group: PropTypes.string,
        name: PropTypes.string,
        start: PropTypes.number,
        end: PropTypes.number,
        strand: PropTypes.oneOf(['-', '+']),

        blocks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    })),
    bpStart: PropTypes.number,
    bpEnd: PropTypes.number,
    heightPerDataGroup: PropTypes.number,
    width: PropTypes.number,
    padding: PropTypes.arrayOf(PropTypes.number)
};

export default GeneStructure;