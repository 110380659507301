import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Atoms } from 'components';
import { getGeneTransInfo } from 'api/fetch_data';

class GeneTransInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = { dataSource: [] };
        this.header = [
            { key: 'name', content: 'Name' },
            { key: 'alias_name', content: 'Alias' },
            { key: 'position', content: 'Position' },
            { key: 'exonNum', content: 'Exon num' },
            { key: 'type', content: 'Type' },
            { key: 'codingScore', content: 'Coding potential score' },
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        getGeneTransInfo(this.props.genomeAbbr, this.props.id).then(data => {
            if (!this.__ignoreLastFetch) {
                const dataSource = data.map(this.trans_info);
                this.setState({ dataSource });
            }
        });
    };

    trans_info = (record) => {
        const { alias_name, exon_num, chrom, start, end, strand, trans_type, coding_score, uniq_trans_id, trans_name } = record;
        const { speciesAbbr, genomeAbbr } = this.props;
        const element = {
            name: <Link
                to={`/wiki/transcript/${speciesAbbr}/${genomeAbbr}/${uniq_trans_id}`}>{trans_name}</Link>,
            alias_name: alias_name.length > 0 ? <ul> {alias_name.map((names, index) => <li key={index}>{names}</li>)} </ul> : '-',
            exonNum: exon_num,
            position: <div>{`${chrom}: ${start} - ${end} (${strand})`}</div>,
            type: trans_type,
            codingScore: coding_score,
        };
        return (element);
    };

    render() {
        const { dataSource } = this.state;
        if (dataSource.length === 0) {
            return null;
        }
        return (
            <Atoms.InfoGroup title="Transcripts">
                <Atoms.CustomTable columns={this.header} dataSource={dataSource} />
            </Atoms.InfoGroup>
        );
    }
}

GeneTransInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default GeneTransInfo;

