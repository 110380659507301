import React from 'react';
import PropTypes from 'prop-types';
import { Atoms, Charts } from 'components';
import { getTransRg4Region } from 'api/fetch_data';

class TransRg4Region extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            plotData: [],
        };
        this.header = [
            { key: 'start', content: 'Start' },
            { key: 'end', content: 'End' },
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { genomeAbbr, id } = this.props;
        if (!this.__ignoreLastFetch) {
            getTransRg4Region(genomeAbbr, id).then(data => {
                const plotData = data.map(item => ({
                    group: 'RG4',
                    start: item['start'],
                    end: item['end'],
                    name: 'RG4',
                    blocks: [[item['start'], item['end']]],
                }));
                this.setState({ data, plotData });
            });
        }
    };



    render() {
        const { data, plotData } = this.state;

        if (data.length === 0){
            return null;
        }
        
        return (
            <Atoms.ExpansionInfoCard
                title="RG4 region"
                mainContent={
                    <Charts.GeneStructure
                        bpStart={0}
                        bpEnd={this.props.ival.exon_len}
                        heightPerDataGroup={20}
                        width={1000}
                        padding={[10, 50, 30, 150]}
                        dataSource={plotData}
                    />
                }
                expansionContent={
                    <Atoms.CustomTable 
                        columns={this.header} 
                        dataSource={data} />
                }
            />
        );
    }
}

TransRg4Region.propTypes = {
    ival: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default TransRg4Region;

