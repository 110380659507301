import React from 'react';
import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { grayColor } from 'assets/global';

const useStyles = makeStyles(theme => ({
    progress: {
        margin: theme.spacing(2),
    },
    container: {
        color: grayColor[6]
    },
    text: {
        fontWeight: 'bold'
    }
}));
export default function Loading(props) {
    const classes = useStyles();
    return(
        <div className={classes.container}>
            <CircularProgress {...props} className={classes.progress} size={50} thickness={5} color="inherit"/>
            <Typography variant="h5" className={classes.text}>Loading...</Typography>
        </div> 
    );

}