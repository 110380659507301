import React from 'react';
import PropTypes from 'prop-types';
import { Layouts } from 'components';
import { withStyles } from '@material-ui/core';
import { getCertainSpeciesInfo } from 'api/fetch_data';

const styles = {
    iframe: {
        width: '100%',
        flexGrow: 1,
        frameBorder: 0
    }
};

class Jbrowse extends React.Component {
    state = { name: '' };
    componentDidMount() {
        const { location } = this.props;
        const search = {};
        const searchList = location.search.replace('?', '').split('&');
        searchList.forEach(searchText => {
            const arr = searchText.split('=');
            search[arr[0]] = arr[1];
        });
        getCertainSpeciesInfo(search['species_abbr']).then(res => {
            if(!this.__ignoreLastFetch)
                this.setState({ name: res.name });
        });
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    render() {
        const baseURL = process.env.REACT_APP_JBROWSE_PATH;
        const { classes, location } = this.props;
        const { name } = this.state;

        const search = {};
        const searchList = location.search.replace('?', '').split('&');
        searchList.forEach(searchText => {
            const arr = searchText.split('=');
            search[arr[0]] = arr[1];
        });

        let loc_str = '';
        if (search['chrom'] && search['start'] && search['end']) {
            loc_str = loc_str + `&loc=${search['chrom']}:${search['start']}..${search['end']}`;
        }


        return (
            <Layouts.MainLayoutContent headerText={`Genome browser (${name} - ${search['genome_abbr']})`} contentNoPadding>
                <iframe
                    title="jbrowse frame"
                    src={`${baseURL}?data=data/${search['species_abbr']}__${search['genome_abbr']}/conf${loc_str}`}
                    className={classes.iframe} />
            </Layouts.MainLayoutContent>
        );
    }

}

Jbrowse.propTypes = {
    location: PropTypes.object,
    classes: PropTypes.object
};

export default withStyles(styles)(Jbrowse);