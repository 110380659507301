import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, Toolbar, Typography, IconButton } from '@material-ui/core';

import CompareIcon from '@material-ui/icons/Compare';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';

const useStyles = makeStyles(theme => ({
    root: {
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.primary.main,
                backgroundColor: lighten(theme.palette.primary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.primary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.primary,
    },
    title: {
        flex: '0 0 auto',
    },
    tableTitle: {
        color: 'rgba(0,0,0, 0.7)'
    }
}));

function TableToolbar({ numSelected, text }) {
    const classes = useStyles();
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        {numSelected} selected
                    </Typography>
                ) : (<Typography className={classes.tableTitle} variant="subtitle1" id="tableTitle">{text}</Typography>)}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {numSelected > 0 ? (<IconButton aria-label="Compare">
                    <CompareIcon />
                </IconButton>) : (<IconButton aria-label="Filter list">
                    <FilterListIcon />
                </IconButton>)}
            </div>
        </Toolbar>
    );
}

TableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    text: PropTypes.string
};

export default TableToolbar;
