import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';

// 去除props中不必要项与重复序列
function buildRawData(dataList, owner) {
    const rawDataList = [];
    dataList.forEach(data => {
        if (rawDataList.findIndex(item => item.group === data.group && item.name === data.name) === -1) {
            rawDataList.push({
                group: data.group,
                name: data.name,
                owner
            });
        }
    });
    return rawDataList;
}

// 对表格内容排序
function sortData(data1, data2) {
    if (data1.group > data2.group) return 1;
    else if (data1.group < data2.group) return -1;
    else {
        if (data1.name > data2.name) return 1;
        else if (data2.name > data1.name) return -1;
        else {
            if (data1.owner === 'Both' && data2.owner !== 'Both') return -1;
            else if (data2.owner === 'Both' && data1.owner !== 'Both') return 1;
            else {
                if (data1.owner > data2.owner) return 1;
                else if (data2.owner > data1.owner) return -1;
                return 0;
            }
        }
    }
}
function sortDataWithoutGroup(data1, data2) {
    if (data1.name > data2.name) return 1;
    else if (data2.name > data1.name) return -1;
    else {
        if (data1.owner === 'Both' && data2.owner !== 'Both') return -1;
        else if (data2.owner === 'Both' && data1.owner !== 'Both') return 1;
        else {
            if (data1.owner > data2.owner) return 1;
            else if (data2.owner > data1.owner) return -1;
            return 0;
        }
    }
}

function CompareDomainTable({ sourceData, targetData, sourceName, targetName }) {
    const sourceRawData = buildRawData(sourceData, sourceName);
    const targetRawData = buildRawData(targetData, targetName);
    const concatData = sourceRawData;
    for (const targetRecord of targetRawData) {
        const index = concatData.findIndex(concatRecord => concatRecord.name === targetRecord.name && concatRecord.group === targetRecord.group);
        if (index === -1) {
            concatData.push(targetRecord);
        } else {
            const newData = { ...targetRecord, owner: 'Both' };
            concatData[index] = newData;
        }
    }
    let sortedTableData = [];
    const mergeData = [];
    let header = [];
    if (concatData[0].group === undefined) {
        header = [{
            key: 'owner', content: 'Presence in'
        }, {
            key: 'name', content: 'Name' 
        }];
        sortedTableData = concatData.sort(sortDataWithoutGroup);
        // 合并表格第一项（owner）
        let currentOwner = '';
        for (let index = 0; index < sortedTableData.length; index++) {
            if (sortedTableData[index].owner !== currentOwner) {
                currentOwner = sortedTableData[index].owner;
                mergeData.push(index);
            } else {
                const newItem = { ...sortedTableData[index], owner: undefined };
                sortedTableData[index] = newItem;
            }
        }
    } else {
        header = [{
            key: 'group', content: 'Group'
        },{
            key: 'name', content: 'Name' 
        }, {
            key: 'owner', content: 'Presence in'
        }];
        sortedTableData = concatData.sort(sortData);
        // 合并表格第一项（group）
        let currentGroup = '';
        for (let index = 0; index < sortedTableData.length; index++) {
            if (sortedTableData[index].group !== currentGroup) {
                currentGroup = sortedTableData[index].group;
                mergeData.push(index);
            } else {
                const newItem = { ...sortedTableData[index], group: undefined };
                sortedTableData[index] = newItem;
            }
        }
    }
    const mergeList = [];
    for (let i = 0; i < mergeData.length; i++) {
        mergeList.push({
            y: 0,
            x: mergeData[i],
            row: i === mergeData.length - 1 ? sortedTableData.length - mergeData[i] : mergeData[i + 1] - mergeData[i]
        });
    }

    return (
        <Atoms.CustomTable
            columns={header}
            dataSource={sortedTableData}
            mergeList={mergeList}
        />
    );

}

CompareDomainTable.propTypes = {
    sourceData: PropTypes.array,
    targetData: PropTypes.array,
    sourceName: PropTypes.string,
    targetName: PropTypes.string
};

export default CompareDomainTable;

