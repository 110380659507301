import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Collapse, Grid, TextField, Button } from '@material-ui/core';
import { Atoms, Layouts } from 'components';
import { getTransInRegion, getChromSizeInfo } from 'api/fetch_data';

import { PrimerDesignForm } from '../components';

export default class PrimerDesignView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderSelectData: [],
            // form of species, genome, chromosome, bpRange
            currentGenomeList: [],
            currentChromosomeList: [],
            currentValues: {
                species: '',
                genome: ''
            },
            chromosome: '',
            bpRange: [0, 0],
            formSubmitted: false,


            // form to design primer
            transList: [],
        };

        this.tabsRef = React.createRef();
        this.example = {
            species: 'gosArb',
            genome: 'CGP',
            chromosome: 'Chr01',
            bpRange: [10272517, 10276920],
        };
    }

    componentDidMount() {
        this.renderSelect();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    renderSelect = async () => {
        const response = await getChromSizeInfo();
        if (response && !this.__ignoreLastFetch) {
            this.setState({ renderSelectData: response });
        }
    }

    handleInputChange = (event) => {
        const { currentChromosomeList, bpRange } = this.state;
        if (currentChromosomeList[event.target.value]) {
            if (bpRange[1] > currentChromosomeList[event.target.value]) {
                this.setState({ bpRange: [0, currentChromosomeList[event.target.value]] });
            }
        }
        this.setState({ chromosome: event.target.value });
    }
    handleSpeciesChange = (event) => {
        const newValues = { species: event.target.value, genome: '' };
        const currentGenomeList = this.state.renderSelectData.find(item => item['abbr_name'] === event.target.value)['genome_li'];
        this.setState({ currentValues: newValues, currentGenomeList });
    };
    handleGenomeChange = (event) => {
        const newValues = { ...this.state.currentValues, genome: event.target.value };
        const currentChromosomeList = this.state.currentGenomeList.find(item => item['abbr_name'] === event.target.value)['chromSizes'];
        this.setState({ currentValues: newValues, currentChromosomeList });
    };

    // position: 'start' or 'end'
    handleRangeChange = (position) => (event) => {
        const { currentChromosomeList, chromosome, bpRange } = this.state;
        const bpMax = currentChromosomeList[chromosome];
        let value = Number(event.target.value);
        if (!isNaN(value)) {
            if (value < 0) {
                value = 0;
            } else if (value > bpMax) {
                value = bpMax;
            }
            const newRange = position === 'start' ? [value, bpRange[1]] : [bpRange[0], value];
            this.setState({ bpRange: newRange });
        }
    }

    handleSubmit = async () => {
        const { currentValues: { genome }, chromosome, currentChromosomeList, bpRange } = this.state;
        if (currentChromosomeList[chromosome] !== undefined) {
            const response = await getTransInRegion(genome, chromosome, bpRange[0], bpRange[1]);
            if (response && !this.__ignoreLastFetch) {
                const transList = response.map(item => ({
                    blocks: item.blocks,
                    chrom: item.chrom,
                    start: item.start,
                    end: item.end,
                    strand: item.strand,
                    name: item.name,
                    hash_name: item.hash_name,
                    group: 'translate'
                }));

                // Not Good:
                // Add Empty Rows to make chart show padding
                const emptyData = { group: 'empty', name: 'empty', start: 0, end: 0, blocks: [[0, 0]] };

                this.setState({
                    formSubmitted: true,
                    transList: [emptyData, ...transList, emptyData],
                });
            }
        } else {
            console.log('Invalid Chromosome Name!');
        }
    }

    handleClear = () => this.setState({
        formSubmitted: false,
        transList: [],
    });

    applyExample = ({ species, genome, chromosome, bpRange }) => (event) => {
        const { renderSelectData } = this.state;
        if (renderSelectData.length > 0) {
            const currentGenomeList = renderSelectData.find(item => item['abbr_name'] === species)['genome_li'];
            const currentChromosomeList = currentGenomeList.find(item => item['abbr_name'] === genome)['chromSizes'];
            this.setState({
                currentGenomeList, currentChromosomeList, currentValues: { species, genome }, chromosome, bpRange,
                formSubmitted: false, transList: [],
            });
            this.tabsRef.current.handleChange(event, 0);
        }
    }


    render() {
        const { renderSelectData, currentValues: { species, genome }, currentGenomeList, currentChromosomeList, formSubmitted,
            transList, chromosome, bpRange
        } = this.state;
        const speciesOptions = renderSelectData.map(item => ({
            value: item.abbr_name,
            text: item.name
        }));
        const genomeOptions = currentGenomeList.map(item => ({
            value: item.abbr_name,
            text: item.abbr_name
        }));

        const bpMax = currentChromosomeList[chromosome] || 0;

        return (
            <Layouts.MainLayoutContent headerText="Primer design">
                <Atoms.ExampleCard
                    ref={this.tabsRef}
                    title="Select Region:"
                    formContent={<Grid container spacing={4}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Atoms.SelectControl
                                value={species}
                                handleChange={this.handleSpeciesChange}
                                placeholder="Species"
                                options={speciesOptions}
                                disabled={formSubmitted}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Atoms.SelectControl
                                value={genome}
                                placeholder="Genome"
                                options={genomeOptions}
                                handleChange={this.handleGenomeChange}
                                disabled={formSubmitted}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Chromosome"
                                value={chromosome}
                                onChange={this.handleInputChange}
                                placeholder="Input Chromosome Name..."
                                disabled={species === '' || genome === '' || formSubmitted}
                            />
                        </Grid>
                        <Grid container item xs={12} spacing={4}>
                            <Grid item xs={bpMax === 0 ? 12 : 6} md={bpMax === 0 ? 12 : 3}>
                                <Collapse in={bpMax > 0}>
                                    <TextField
                                        fullWidth
                                        label="Start"
                                        value={bpRange[0]}
                                        onChange={this.handleRangeChange('start')}
                                        inputProps={{
                                            type: 'text',
                                            inputMode: 'numeric',
                                            pattern: '[1-9]/d*'
                                        }}
                                        disabled={formSubmitted}
                                    />
                                </Collapse>
                                <Collapse in={bpMax === 0}>
                                    <Typography>Invalid Chromosome Name!</Typography>
                                </Collapse>
                            </Grid>
                            <Grid item xs={bpMax === 0 ? 12 : 6} md={bpMax === 0 ? 12 : 3}>
                                <Collapse in={bpMax > 0}>
                                    <TextField
                                        fullWidth
                                        label="End"
                                        value={bpRange[1]}
                                        onChange={this.handleRangeChange('end')}
                                        inputProps={{
                                            type: 'text',
                                            inputMode: 'numeric',
                                            pattern: '[1-9]/d*',
                                        }}
                                        disabled={formSubmitted}
                                    />
                                </Collapse>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2} justify="flex-end">
                            <Grid item xs={3} style={{ textAlign: 'right' }}>
                                <Button onClick={this.handleClear} color="secondary" disabled={!formSubmitted}>Clear</Button>
                                <Button
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={species === '' || genome === '' || bpMax === 0 || bpRange[0] >= bpRange[1] || formSubmitted}
                                >Submit</Button>
                            </Grid>
                        </Grid>
                    </Grid>}
                    exampleContent={<div>
                        <Button onClick={this.applyExample(this.example)}>Use Example</Button>
                    </div>}
                />
                {transList.length > 0 && <PrimerDesignForm
                    species={species}
                    genome={genome}
                    chromosome={chromosome}
                    bpRange={bpRange}
                    transList={transList}
                />}
            </Layouts.MainLayoutContent>
        );
    }
}

PrimerDesignView.propTypes = {
    menu: PropTypes.arrayOf(PropTypes.object),
};