import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Slider, ClickAwayListener } from '@material-ui/core';

export default function RangeSliderWithInput({ range, setRange, min, max, sliderColor }) {
    const [start, setStart] = React.useState(range[0]);
    const [end, setEnd] = React.useState(range[1]);

    function formatValue(value) {
        let newValue = min;
        if (!isNaN(value)) {
            if (value < min) {
                newValue = min;
            } else if (value > max) {
                newValue = max;
            } else {
                newValue= value;
            }
        }
        return newValue;
    }

    const handleClickAway = () => {
        const startValue = Number(start);
        const endValue = Number(end);
        const newRange = [formatValue(startValue), formatValue(endValue)];
        setStart(newRange[0]);
        setEnd(newRange[1]);
        setRange(newRange);
    };

    const handleRangeChange = (event, newValue) => {
        setStart(newValue[0]);
        setEnd(newValue[1]);
        setRange(newValue);
    };

    return(
        <div>
            <ClickAwayListener onClickAway={handleClickAway}>
                <TextField
                    style={{ marginRight: '20px' }}
                    value={start}
                    onChange={(event) => setStart(event.target.value)}
                    label="Start"
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                        type: 'text',
                        inputMode: 'numeric',
                        pattern: '[1-9]/d*'
                    }}
                />
            </ClickAwayListener>
            <ClickAwayListener onClickAway={handleClickAway}>
                <TextField
                    label="End"
                    value={end}
                    onChange={(event) => setEnd(event.target.value)}
                    margin="dense"
                    variant="outlined"
                    inputProps={{
                        type: 'text',
                        inputMode: 'numeric',
                        pattern: '[1-9]/d*'
                    }}
                />
            </ClickAwayListener>
            <Slider
                style={{ color: sliderColor }}
                min={min}
                max={max}
                value={range}
                onChange={handleRangeChange}
            />
        </div>
    );
}

RangeSliderWithInput.propTypes = {
    min: PropTypes.number,
    max: PropTypes.number,
    range: PropTypes.arrayOf(PropTypes.number),
    setRange: PropTypes.func,
    sliderColor: PropTypes.string,
};