import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Atoms, Charts } from 'components';
import { getCddDomain } from 'api/fetch_data';

class GeneCddDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            plotData: []
        };
        this.header = [
            { key: 'CddId', content: 'CDD ID' },
            { key: 'Desc', content: 'Description' },
            { key: 'chrom', content: 'Chrom' },
            { key: 'start', content: 'Start' },
            { key: 'end', content: 'End' },
            { key: 'strand', content: 'Strand' },
            { key: 'search', content: 'Search same domain gene' }
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { genomeAbbr, id } = this.props;
        getCddDomain(genomeAbbr, id, "Locus").then(data => {
            if (!this.__ignoreLastFetch) {
                const dataSource = data.map(item => ({
                    CddId: <a href={`https://www.ncbi.nlm.nih.gov/Structure/cdd/cddsrv.cgi?uid=${item['cdd__ncbi_cdd_id']}`}>{item['cdd__ncbi_cdd_id']}</a>,
                    Desc: item['cdd__desc'],
                    chrom: item['chrom'],
                    start: item['start'],
                    end: item['end'],
                    strand: item['strand'],
                    search: <Link to={{
                        pathname: '/search/cdd_domain',
                        state: {'searchText': item['cdd__ncbi_cdd_id'].toString(), "species": "All", "genome": "All", "assemble": "All"}
                    }}>Search</Link>
                }));
                const plotData = data.map(item => ({
                    group: 'CDD',
                    start: item['start'],
                    end: item['end'],
                    strand: item['strand'],
                    name: item['cdd__ncbi_cdd_id'],
                    blocks: item['blocks'],
                }));
                this.setState({dataSource, plotData});
            }
        });
        
    };

    render() {
        const { locus } = this.props;
        const { dataSource, plotData } = this.state;

        if (dataSource.length === 0){
            return null;
        }
        
        return (
            <Atoms.ExpansionInfoCard
                title="CDD domain"
                mainContent={
                    <Charts.GeneStructure
                        bpStart={locus.start}
                        bpEnd={locus.end}
                        heightPerDataGroup={20}
                        width={1000}
                        padding={[10, 50, 30, 150]}
                        dataSource={plotData}
                    />
                }
                expansionContent={
                    <Atoms.CustomTable columns={this.header} dataSource={dataSource} />
                }
            />
        );
    }
}

GeneCddDomain.propTypes = {
    locus: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default GeneCddDomain;

