import React from 'react';
import Card from './Card';
import CardHeader from './CardHeader';
import CardBody from './CardBody';


import { withStyles } from '@material-ui/core';

import {
    whiteColor,
    grayColor,
    hexToRgb
} from 'assets/global';

const styles = {
    cardCategoryWhite: {
        color: 'rgba(' + hexToRgb(whiteColor) + ',.62)',
        margin: '0',
        fontSize: '14px',
        marginTop: '0',
        marginBottom: '0'
    },
    cardTitleWhite: {
        color: whiteColor,
        marginTop: '0px',
        minHeight: 'auto',
        fontWeight: '300',
        fontFamily: '\'Roboto\', \'Helvetica\', \'Arial\', sans-serif',
        marginBottom: '3px',
        textDecoration: 'none',
        '& small': {
            color: grayColor[1],
            fontWeight: '400',
            lineHeight: '1'
        }
    }
};



function CustomCard({ classes, type, children, title, subTitle }) {
    return (
        <Card>
            <CardHeader color={type}>
                <h4 className={classes.cardTitleWhite}>{title}</h4>
                <p className={classes.cardCategoryWhite}>
                    {subTitle}
                </p>
            </CardHeader>
            <CardBody>
                {children}
            </CardBody>
        </Card>
    );
}


export default withStyles(styles)(CustomCard);