import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Table, TableBody, TableHead, TablePagination, TableRow, withStyles, Collapse } from '@material-ui/core';
import CustomTableCell from './CustomTableCell';
import Empty from '../Empty';
import Loading from '../Loading';
import TableCellWithPopover from './TableCellWithPopover';

const styles = theme => ({
    head: {
        height: 32,
    },
    row: {
        '&:nth-of-type(even)': {
            // backgroundColor: colors.grey[100],
            height: 28,
        },
        tableLayout: 'fixed',
        height: 28,
    },
    withScroll: {
        width: '100%',
        height: '500px',
        overflowY: 'scroll'
    },
    withScrollHeader: {
        position: 'sticky',
        top: 0
    },
    loading: {
        margin: theme.spacing(2),
    }
});

class CustomTable extends React.Component {
    /** 接受两个参数:
     *  1. columns数组。形如[ { key:'column1', content: XXX }, ... ]
     *  2. dataSource数组。形如[{ column1: xxx, column2: xxx, column3: xxx, ...}, ...]
     */
    render() {
        const { classes, dataSource, columns, mergeList, withPagination, pagination, forceNoScroll, loading, ...otherProps } = this.props;
        return (
            <div className={clsx({ [classes.withScroll]: dataSource.length > 10 && !withPagination && !forceNoScroll })}>
                <Table
                    //aria-describedby="table_loading"
                    {...otherProps}
                >
                    <TableHead style={{ width: '100%' }}>
                        <TableRow className={classes.head}>
                            {columns.map((title, index) =>
                                <TableCellWithPopover
                                    className={clsx({ [classes.withScrollHeader]: dataSource.length > 10 && !withPagination && !forceNoScroll })}
                                    key={index}
                                    tableText={title.content}
                                    description={title.description}
                                    {...otherProps}
                                />
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataSource.length > 0 && dataSource.map((row, rowIndex) => (
                            <TableRow style={{ width: '100%' }} className={classes.row} key={rowIndex}>
                                {columns.map((title, colIndex) => {
                                    if (row[title.key] !== undefined) {
                                        let merge = undefined;
                                        if (mergeList !== undefined) {
                                            merge = mergeList.find(item => item.x === rowIndex && item.y === colIndex);
                                        }
                                        if (merge !== undefined) {
                                            return (<CustomTableCell key={colIndex} rowSpan={merge.row} colSpan={merge.col} {...otherProps}>
                                                {row[title.key]}
                                            </CustomTableCell>);
                                        }
                                        return (<CustomTableCell key={colIndex} {...otherProps}>
                                            {row[title.key]}
                                        </CustomTableCell>);
                                    } else {
                                        return undefined;
                                    }
                                })}
                            </TableRow>
                        ))}

                        {dataSource.length === 0 && <TableRow className={classes.row}>
                            <CustomTableCell colSpan={columns.length} align="center">
                                <Collapse in={!loading}>
                                    <Empty />
                                </Collapse>
                                <Collapse in={loading}>
                                    <Loading />
                                </Collapse>
                            </CustomTableCell>
                        </TableRow>}
                    </TableBody>
                </Table>
                {(withPagination && dataSource.length > 0) &&
                    <TablePagination
                        component="div"
                        count={pagination.count}
                        rowsPerPage={pagination.rowsPerPage}
                        page={pagination.page - 1}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        onChangePage={pagination.onChangePage}
                        rowsPerPageOptions={[30]}
                    />}
            </div>

        );
    }
}

CustomTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        content: PropTypes.any
    })).isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    mergeList: PropTypes.arrayOf(PropTypes.shape({
        x: PropTypes.any,
        y: PropTypes.any,
        col: PropTypes.any,
        row: PropTypes.any,
    })),
    withPagination: PropTypes.bool,
    forceNoScroll: PropTypes.bool,
    pagination: PropTypes.shape({
        count: PropTypes.number,
        rowsPerPage: PropTypes.number,
        page: PropTypes.number,
        onChangePage: PropTypes.func
    })
};

export default withStyles(styles)(CustomTable);