import React from 'react';
import { Atoms, Layouts } from 'components';
import { batchGetGeneInfo } from 'api/fetch_data';
import { GeneListForm } from '../components';
import { Button } from '@material-ui/core';

import download from 'tools/download';


class BatchFetchDataView extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.tabsRef = React.createRef();
        this.example = {
            species: 'gosArb',
            genome: 'CGP',
            geneList: [
                'Ga01g00009.F1', 'Ga01g00019.F1', 'Ga01g00036.F1', 'Ga01g00041.F1', 'Ga01g00056.F1'
            ],
        };
    }
    
    handleSubmit = async (species, genome, geneList) => {
        const res = await batchGetGeneInfo(genome, geneList);
        const blob = await res.blob();
        if (res.status === 200) {
            const filename = 'gene_info.tsv';
            // const filename = res.headers.get('Content-Disposition').split('=')[1];
            download(blob, filename);
        }
    }

    applyExample = ({species, genome, geneList}) => (event) => {
        this.formRef.current.setValues({
            species, genome, 
            geneInput: geneList.join(',')
        });
        this.tabsRef.current.handleChange(event, 0);
    }

    render() {
        return (
            <Layouts.MainLayoutContent headerText="Batch Fetch Data">
                <Atoms.ExampleCard
                    ref={this.tabsRef}
                    title="Batch fetch gene information from MaGenDB"
                    formContent={<GeneListForm
                        handleSubmit={this.handleSubmit}
                        ref={this.formRef}
                    />}
                    exampleContent={<div>
                        <Button onClick={this.applyExample(this.example)}>Example</Button>
                    </div>}
                /> 
            </Layouts.MainLayoutContent>
        );
    }
}

BatchFetchDataView.propTypes = {

};

export default BatchFetchDataView;
