import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import CompareGoTable from './CompareGoTable';
import CompareKoTable from './CompareKoTable';
import CompareEcTable from './CompareEcTable';
import CompareDomain from './CompareDomain';
import SeqAlign from './CompareAlign';

function CompareResults({ align, sequence, source, target, compareType, sourceCoor, targetCoor }) {
    if (Object.keys(align).length === 0)
        return null;
    if (!align['pass'])
        return (<Paper style={{ padding: '20px' }}>
            <Typography variant="h4">The difference between the two sequences is too large, please re-select.</Typography>
        </Paper>);

    const ontologyData = { compareType, source, target };
    const seq1Name = source.name;
    const seq2Name = sequence ? 'Sequence': target.name;
    const domainData = { compareType, align, source, target, sequence, sourceCoor, targetCoor, seq1Name, seq2Name };

    return (
        <div>
            {sequence ? <SeqAlign
                seq1Name={source.name}
                seq2Name="Sequence"
                seq1Info={sourceCoor}
                seq2Info={targetCoor}
                align={align}/>: <SeqAlign
                seq1Name={source.name}
                seq2Name={target.name}
                seq1Info={sourceCoor}
                seq2Info={sequence.length}
                align={align}/>}
            {!sequence ? <Atoms.InfoGroup title="Compare Ontology">
                <Atoms.InfoTabs
                    dataSource={[{
                        title: 'Gene Ontology',
                        content: <CompareGoTable {...ontologyData} />
                    }, {
                        title: 'KEGG Orthology',
                        content: <CompareKoTable {...ontologyData} />
                    }, {
                        title: 'Enzyme Commission',
                        content: <CompareEcTable {...ontologyData} />
                    }]}
                />
            </Atoms.InfoGroup>: null}
            <Atoms.InfoGroup title="Functional domain">
                <Atoms.InfoTabs
                    dataSource={['Interpro', 'CDD', 'Pfam', 'SignalP', 'TMHMM', 'RG4'].map((item) => ({
                        title: item, 
                        content: <CompareDomain {...domainData} type={item} key={item} />
                    }))}
                />
            </Atoms.InfoGroup>
        </div>
    );

}

CompareResults.propTypes = {
    align: PropTypes.object,
    sequence: PropTypes.string,
    source: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    target: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    sourceCoor: PropTypes.any,
    targetCoor: PropTypes.any,
    compareType: PropTypes.oneOf(['Locus', 'Trans', 'Prot', ''])
};

export default CompareResults;