import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

function SearchIcon({ fill, style: styleProp, ...otherProps }) {
    return (
        <SvgIcon
            {...otherProps}
            style={fill ? {
                width: '100%', height: '100%', ...styleProp
            } : { ...styleProp }}
        >
            <g transform="scale(0.042) translate(90,70)">
                <path d="
                    M118.534 0.304 
                    C 31.893 9.811,-22.022 97.308,9.314 177.554 
                    C 38.814 253.098,128.648 283.077,199.672 241.079 
                    L 201.715 239.871 214.327 252.385 
                    C 221.264 259.267,253.319 291.181,285.560 323.305 
                    C 339.200 376.749,358.809 395.957,361.583 397.774 
                    C 363.037 398.727,368.991 398.635,371.480 397.621 
                    C 373.780 396.684,384.210 387.041,391.674 378.949 
                    C 398.982 371.027,400.487 367.762,399.112 362.811 
                    C 398.041 358.955,394.179 354.957,311.583 272.198 
                    L 240.172 200.647 241.659 198.491 
                    C 248.748 188.220,257.461 164.889,259.319 151.205 
                    C 260.206 144.674,260.639 118.896,259.952 113.554 
                    C 253.030 59.730,213.774 16.222,160.600 3.439
                    C 149.600 0.795,128.434 -0.783,118.534 0.304 
                    M143.966 33.214
                    C 193.547 37.888,233.349 87.609,228.483 138.793
                    C 221.908 207.943,148.286 248.916,86.493 217.816
                    C 18.254 183.472,14.331 86.473,79.562 46.451
                    C 99.029 34.507,117.473 30.716,143.966 33.214 
                    M52.669 91.155
                    C 45.364 93.435,42.624 99.540,44.197 110.024
                    C 46.970 128.499,55.227 144.293,70.020 159.417
                    C 80.821 170.459,83.987 172.558,114.009 188.571
                    C 138.849 201.820,143.012 204.197,152.195 210.372
                    L 155.965 212.907 160.633 210.952
                    C 169.473 207.250,181.897 200.212,181.897 198.906
                    C 181.897 195.509,158.144 180.475,124.569 162.622
                    C 101.170 150.179,96.128 146.609,87.592 136.435
                    C 78.954 126.140,74.337 117.243,71.711 105.834
                    C 68.693 92.721,62.628 88.046,52.669 91.155
                    M196.567 91.023
                    C 191.804 92.390,188.130 96.949,186.837 103.096
                    C 186.478 104.804,186.078 106.307,185.949 106.436
                    C 185.820 106.565,165.498 106.799,140.790 106.956
                    C 97.915 107.227,90.086 107.421,90.086 108.208
                    C 90.086 108.400,91.265 111.061,92.706 114.122
                    L 95.325 119.687 118.639 119.973
                    C 131.461 120.130,147.885 120.270,155.136 120.284
                    C 168.174 120.309,179.942 120.747,180.420 121.225
                    C 180.805 121.609,179.508 123.907,175.704 129.583
                    L 172.198 134.813 152.155 135.083
                    C 141.131 135.231,127.673 135.472,122.246 135.617
                    L 112.381 135.881 116.212 138.341
                    C 118.319 139.695,122.760 142.453,126.080 144.472
                    L 132.117 148.141 143.686 148.453
                    C 158.764 148.861,158.558 148.724,151.038 153.313
                    L 147.056 155.743 158.334 162.176
                    C 164.537 165.714,169.806 168.752,170.043 168.928
                    C 170.280 169.104,171.384 169.768,172.496 170.403
                    L 174.517 171.558 178.961 168.033
                    C 199.688 151.593,214.220 125.373,214.224 104.413
                    C 214.225 94.543,206.011 88.314,196.567 91.023 
                    M80.468 194.455 
                    C 76.540 197.180,76.600 197.426,82.071 201.056 
                    C 86.757 204.165,93.641 207.744,99.075 209.898 
                    L 103.538 211.667 105.325 210.613 
                    C 108.526 208.726,110.882 206.831,110.501 206.450 
                    C 109.402 205.354,84.515 192.657,83.540 192.694 
                    C 83.229 192.706,81.846 193.499,80.468 194.455 
                    M44.100 247.306 
                    C 41.159 264.525,47.407 284.739,60.129 299.166 
                    C 75.007 316.036,86.860 324.037,125.862 343.535 
                    C 142.854 352.029,149.469 355.586,156.250 359.875 
                    L 161.422 363.147 146.121 363.406 
                    C 126.228 363.744,127.185 363.482,114.341 372.093 
                    L 107.112 376.940 131.466 377.261 
                    C 144.860 377.438,160.500 377.701,166.221 377.847 
                    L 176.622 378.111 179.510 384.083 
                    C 181.099 387.367,182.288 390.232,182.154 390.449 
                    C 181.650 391.264,172.413 391.513,133.233 391.766 
                    L 92.974 392.026 92.372 393.750 
                    C 91.496 396.259,90.191 401.654,89.948 403.770 
                    L 89.738 405.600 137.616 405.709 
                    L 185.494 405.819 186.248 409.328 
                    C 188.969 421.972,200.543 426.021,210.050 417.653 
                    C 213.526 414.592,214.432 407.698,212.734 397.217 
                    C 209.640 378.116,202.437 364.150,188.527 350.277
                    C 177.186 338.967,171.884 335.650,134.645 316.575 
                    C 106.650 302.235,96.795 296.546,93.395 292.763 
                    C 92.213 291.447,92.071 291.457,115.895 291.126 
                    L 137.608 290.824 143.481 287.255
                    C 152.067 282.038,155.765 279.592,155.936 279.016 
                    C 156.407 277.427,155.210 277.374,115.838 277.247 
                    L 77.580 277.124 75.844 273.671 
                    C 74.889 271.772,73.511 268.433,72.782 266.251 
                    C 70.774 260.247,70.968 260.464,64.373 256.803 
                    C 61.091 254.981,55.496 251.640,51.940 249.377 
                    C 44.431 244.601,44.556 244.634,44.100 247.306 
                    M192.605 258.893 
                    L 186.288 262.173 184.681 266.754 
                    C 181.533 275.727,178.381 280.472,171.303 286.896 
                    C 164.335 293.219,154.575 300.586,150.754 302.407 
                    C 149.865 302.830,149.138 303.312,149.138 303.477
                    C 149.138 304.004,154.257 307.084,163.793 312.294 
                    C 168.890 315.079,173.443 317.623,173.911 317.946 
                    C 175.942 319.352,179.005 317.655,186.239 311.113 
                    C 200.922 297.835,208.431 286.916,212.273 273.253 
                    L 213.432 269.135 206.693 262.477 
                    C 202.987 258.815,199.722 255.773,199.439 255.716 
                    C 199.155 255.660,196.080 257.089,192.605 258.893 
                    M76.712 343.412 
                    C 55.893 360.852,44.397 383.643,44.397 407.473 
                    C 44.397 418.873,54.988 425.515,64.684 420.194 
                    C 69.458 417.575,70.768 414.870,72.607 403.841 
                    C 76.036 383.271,84.683 370.896,104.634 358.007 
                    C 107.538 356.130,109.914 354.471,109.914 354.320 
                    C 109.914 354.169,106.695 352.516,102.761 350.647 
                    C 98.827 348.778,92.396 345.540,88.470 343.452 
                    C 84.543 341.364,81.301 339.655,81.264 339.655 
                    C 81.227 339.655,79.179 341.346,76.712 343.412  
                    Z">
                </path>
            </g>
        </SvgIcon>
    );
}

SearchIcon.propTypes = {
    fill: PropTypes.bool,
    style: PropTypes.object
};


export default SearchIcon;