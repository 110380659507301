import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, colors } from '@material-ui/core';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import { whiteColor } from 'assets/global';

const spacingUnit = (value) => `${value * 9}px`;
const sequenceHeight = '20px';
const sequenceMarginBottom = '5px';

const useStyles = makeStyles({
    unit: {
        width: spacingUnit(1),
        fontSize: spacingUnit(1.5),
        flexShrink: 0,
        textTransform: 'uppercase',
        backgroundColor: colors.grey[300]
    },
    decoratorBaseG: {
        backgroundColor: `${colors.orange[500]} !important`,
    },
    decoratorBaseT: {
        backgroundColor: `${colors.red[500]} !important`,
    },
    decoratorBaseA: {
        backgroundColor: `${colors.green[500]} !important`,
    },
    decoratorBaseC: {
        backgroundColor: `${colors.blue[500]} !important`,
    },
    // polar amino acids (G,S,T,Y,C,Q,N) are green
    decoratorAminoAcidG: { backgroundColor: `${colors.teal[600]} !important`, color: whiteColor, },
    decoratorAminoAcidS: { backgroundColor: `${colors.teal['A400']} !important`, },
    decoratorAminoAcidT: { backgroundColor: `${colors.green[600]} !important`, color: whiteColor, },
    decoratorAminoAcidY: { backgroundColor: `${colors.green['A400']} !important`, },
    decoratorAminoAcidC: { backgroundColor: `${colors.lightGreen[600]} !important`, },
    decoratorAminoAcidQ: { backgroundColor: `${colors.lightGreen['A400']} !important`, },
    decoratorAminoAcidN: { backgroundColor: `${colors.lightGreen['A700']} !important`, },
    // basic (K,R,H) blue
    decoratorAminoAcidK: { backgroundColor: `${colors.blue[600]} !important`, color: whiteColor, },
    decoratorAminoAcidR: { backgroundColor: `${colors.blue['A400']} !important`, color: whiteColor, },
    decoratorAminoAcidH: { backgroundColor: `${colors.lightBlue[600]} !important`, color: whiteColor, },
    // acidic (D,E) red
    decoratorAminoAcidD: { backgroundColor: `${colors.red[600]} !important`, color: whiteColor, },
    decoratorAminoAcidE: { backgroundColor: `${colors.pink[600]} !important`, color: whiteColor, },
    // hydrophobic (A,V,L,I,P,W,F,M) amino acids are black
    decoratorAminoAcidA: { backgroundColor: `${colors.brown[900]} !important`, color: whiteColor, },
    decoratorAminoAcidV: { backgroundColor: `${colors.grey[900]} !important`, color: whiteColor, },
    decoratorAminoAcidL: { backgroundColor: `${colors.blueGrey[900]} !important`, color: whiteColor, },
    decoratorAminoAcidI: { backgroundColor: '#342c1a !important', color: whiteColor, },
    decoratorAminoAcidP: { backgroundColor: '#23311f !important', color: whiteColor, },
    decoratorAminoAcidW: { backgroundColor: '#14322e !important', color: whiteColor, },
    decoratorAminoAcidF: { backgroundColor: '#2f2f3a !important', color: whiteColor, },
    decoratorAminoAcidM: { backgroundColor: '#382d34 !important', color: whiteColor, },
    sequenceContainer: {
        fontFamily: 'Consolas, Monaco, monospace',
        display: 'flex',
        marginLeft: spacingUnit(4),
        marginBottom: sequenceMarginBottom,
        height: sequenceHeight,
    },
    ruler: {
        height: spacingUnit(8),
        marginTop: '5px',
        marginLeft: spacingUnit(3.5),
        fontFamily: 'Consolas, Monaco, monospace',
        display: 'flex'
    },
    'rulerBackground': {
        width: spacingUnit(10),
        textAlign: 'right',
        background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWYAAABsCAYAAABZyhjEAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAABTHAAAUxwHqjcpQAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAACF0RVh0Q3JlYXRpb24gVGltZQAyMDE5OjA2OjI1IDIzOjAwOjM4o2UUlgAABNhJREFUeF7t2j9sTW8YwPH3to0/dUmUoUPTQQwkRhGRhsViMIiabWIQu1gsRpGYxGYyqHQziYSIiJjVICRNh8ZgIVWC677ymnrudSpvf33yy+eTvHhO3POennvuV3Kjk1Lq9RcAQYyU3wEIQpgBghFmgGCEGSAYYQYIRpgBghFmgGCG/j/mu3fvpunp6TLV8e3bt3TmzJm0srJSjrSzffv29PDhwzLV8/jx43Tjxo11X8+xY8fS9evXy1TP1atX0/Pnz8vUzvj4eLpy5UqamZkpR+o5depUWl1dLVM7+Xrm5+fTli1bypE6FhcX0/nz58vU3okTJ9K1a9fKVM+tW7d+/5zrMTo6mi5evJhmZ2fLkXrOnj2bPn78WKZ2du7cme7cuZMmJyfLkTqWl5fThQsX0qdPn8qRdiYmJtKDBw/KVM/c3Fy6fft2+vHjRznSTm7V5cuXy1RPfh6fPHlSpmY5zI1rYWGhV9uXL196/Yehcb9hq9vtljPUde/evX+6ntOnT5cz1JXP27TfsJWvvx+Icoa6duzY0bjnsJWvJ7/PteXnsWm/v61+BMsZ6rp06VLjfsPW2NhY7+bNm+UMdfXj2rjnsLV79+7e+/fvyxnqyefM527ac9jKP8NGyPc83/umPYet/B5vhPxMNu33Z/kqAyAYYQYIRpgBghFmgGCEGSAYYQYIRpgBghFmgGCEGSAYYQYIRpgBghFmgGCEGSAYYQYIRpgBghFmgGCEGSAYYQYIRpgBghFmgGCEGSAYYQYIRpgBghFmgGCEGSCYTQnz6upq2rNnz7pWr9crr64vn7tpz0Fr165daWxsrLy6rnzefP6mfQetjbw3WdOew9bXr1/LK//fRkdHU7fbbbwHg9bWrVvLq+vLz87ExETjvoNW/ixulPV+zvO1b9TnKsv3vmnfQSu/t/k93gyd/hr4qV5YWEgHDhwoUx0/f/5MT58+LVN7+Q2bmZkpUz0fPnxIr1+/LlN7k5OT1e9N9ubNm7S8vFym9g4dOpT27t1bpnqePXuWvn//Xqb2jh8/nkZG6v67n+/NwYMHy9Te7Oxsun//fpnqeffuXVpcXCxTe/v3709TU1Nlqufly5dpZWWlTO0dPXo0bdu2rUx15Ci/ePGiTO2Nj4+nI0eOlKmepaWl9Pbt2zK1Nz09nfbt21emes6dO5fm5ubKtNZ/Hmb4V9HCDP/qb2H2HTNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQz0pd/WbMgqqbnddDqdDq/F0Ty57lsembz6szPz/fK313j5MmTqdvtlgk21+fPn9OjR4/K1N7U1FQ6fPhwmWDzvXr1Ki0tLZVprU6vr/wZgAB8ZwEQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AoKf0CQd3Bk+OsdK0AAAAASUVORK5CYII=") top left no-repeat',
        backgroundSize: 'contain'
    },
    'rulerScale': {
        position: 'relative',
        top: spacingUnit(4),
        right: '-0.3em'
    },
});


function SequenceContainer({ sequenceList, type }) {
    const classes = useStyles();
    const length = sequenceList[0].sequence.length;
    const rulerScale = [];
    for (let i = 0; i < length; i += 10) {
        rulerScale.push(i + 10);
    }

    return (
        <div>
            {sequenceList.map((sequenceInfo, containerIndex) => {
                const sequenceData = sequenceInfo.sequence;
                return (
                    <div
                        key={containerIndex}
                        className={classes.sequenceContainer}
                    >
                        {sequenceData.split('').map((unit, index) => {
                            let decorator = '';
                            if (type === 'dna') {
                                decorator = 'decoratorBase';
                            } else if (type === 'protein') {
                                decorator = 'decoratorAminoAcid';
                            }
                            return <div
                                key={index}
                                className={clsx({ [classes[decorator + unit.toUpperCase()]]: classes[decorator + unit.toUpperCase()] !== undefined }, classes.unit)}
                            >{unit}</div>;
                        })}
                    </div>
                );
            })}
            <div
                className={classes.ruler}
                style={{
                    width: spacingUnit(Math.ceil(length / 10) * 10)
                }}
            >
                {rulerScale.map((item, index) =>
                    <div key={index} className={classes.rulerBackground}>
                        <span className={classes.rulerScale}>{item}</span>
                    </div>
                )}
            </div>
        </div>
    );
}

SequenceContainer.propTypes = {
    type: PropTypes.oneOf(['dna', 'protein']),
    sequenceList: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        sequence: PropTypes.string
    })),
};

export { spacingUnit, sequenceHeight, sequenceMarginBottom };
export default React.memo(SequenceContainer, isEqual);
