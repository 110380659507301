import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import { Grid, Button } from '@material-ui/core';

class SimpleSearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: ''
        };
    }

    componentDidMount() {
        this.setState({ searchText: 'ACX3' });
    }

    getSuggestions = (text) => {
        return this.props.getSuggestions('name', 'All', 'All', 'All', text);
    }

    handleInputChange = (event, { newValue }) => {
        this.setState({ searchText: newValue });
    };

    performSearch = (event, page, pageSize) => {
        const { searchText } = this.state;
        if (searchText !== '') {
            this.props.handleSearch({
                searchField: 'name', searchText,
                species: 'All', genome: 'All', assemble: 'All',
            }, page, pageSize);
        }
    }

    render() {
        const { searchText } = this.state;
        return (
            <Grid container>
                <Grid item xs={12}>
                    <Atoms.AutoComplete
                        getSuggestions={this.getSuggestions}
                        inputValue={searchText}
                        handleInputChange={this.handleInputChange}
                    />
                </Grid>
                <Grid style={{ marginTop: '8px' }} container item justify="flex-end" xs={12}>
                    <Button
                        size="large"
                        onClick={this.performSearch}
                        color="primary"
                        disabled={searchText === ''}
                    >Search</Button>
                </Grid>
            </Grid>
        );
    }
}

SimpleSearchForm.propTypes = {
    handleSearch: PropTypes.func,
    getSuggestions: PropTypes.func
};

export default SimpleSearchForm;

