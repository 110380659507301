import React from 'react';
import PropTypes from 'prop-types';
import { CssBaseline, Drawer, Hidden, withStyles, MuiThemeProvider } from '@material-ui/core';
import Sider from './Sider';

import { drawerWidth } from 'assets/global';

const styles = theme => ({
    root: {
        display: 'flex',
        height: '100%'
    },
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        minHeight: '100vh'
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    drawerPaper: {
        width: drawerWidth,
    },
});

export const LayoutContext = React.createContext();

class ResponsiveSiderLayout extends React.Component {
    state = {
        mobileOpen: false,
    };

    handleDrawerToggle = () => {
        this.setState(state => ({ mobileOpen: !state.mobileOpen }));
    };

    render() {
        const { classes, theme, menu, children, ...restProps } = this.props;

        return (
            <LayoutContext.Provider
                value={{
                    mobileOpen: this.state.mobileOpen,
                    toggle: this.handleDrawerToggle
                }}
            >
                <MuiThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <nav className={classes.drawer}>
                            <Hidden smUp implementation="css">
                                <Drawer
                                    variant="temporary"
                                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                    open={this.state.mobileOpen}
                                    onClose={this.handleDrawerToggle}
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                >
                                    <Sider menu={menu} {...restProps} />
                                </Drawer>
                            </Hidden>
                            <Hidden xsDown implementation="css">
                                <Drawer
                                    classes={{
                                        paper: classes.drawerPaper,
                                    }}
                                    variant="permanent"
                                    open
                                >
                                    <Sider menu={menu} {...restProps} />
                                </Drawer>
                            </Hidden>
                        </nav>
                        <div className={classes.mainContent}>
                            <CssBaseline />
                            {children}
                        </div>
                    </div>
                </MuiThemeProvider>
            </LayoutContext.Provider>

        );
    }
}

ResponsiveSiderLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    menu: PropTypes.array.isRequired,
    headerText: PropTypes.string,
    children: PropTypes.any,
};

export default withStyles(styles, { withTheme: true })(ResponsiveSiderLayout);
