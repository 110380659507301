import React from 'react';
import PropTypes from 'prop-types';
import CustomSlider from '../CustomSlider';
import CustomTooltip from '../CustomTooltip';
import { withStyles, colors, Grid } from '@material-ui/core';
import SequenceContainer, { spacingUnit, sequenceHeight, sequenceMarginBottom } from './SequenceContainer';
import GeneStructure from 'components/charts/GeneStructure/GeneSequenceMultipleCompare';
import TreePlot from 'components/charts/TreePlotWithoutDistance';

import { truncate } from 'lodash';
import { serializeBinaryTree, allocatePosition } from 'tools/EvolutionTree';
import { whiteColor } from 'assets/global';

const styles = ({
    root: {
        display: 'flex',
        maxWidth: spacingUnit(119),
        margin: 'auto',
        width: 'auto',
        border: `2px solid ${colors.blue[600]}`,
        borderRadius: '3px',
        boxShadow: `0 0 5px ${colors.blue[300]} inset`,
    },
    labelBox: {
        display: 'flex',
        flexDirection: 'column',
        '&:first-child': {
            marginTop: spacingUnit(2),
        },
    },
    label: {
        fontFamily: 'Consolas, Monaco, monospace',
        whiteSpace: 'nowrap',
        fontSize: spacingUnit(1.5),
        marginLeft: spacingUnit(2),
        maxWidth: spacingUnit(13),
        height: sequenceHeight,
        paddingLeft: spacingUnit(1),
        paddingRight: spacingUnit(2),
        marginBottom: sequenceMarginBottom,
        '-webkitClipPath': 'polygon(0 100%, 8px 50%, 0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%)',
        clipPath: 'polygon(0 100%, 8px 50%, 0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%)',
        color: whiteColor
    },
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden'
    },
    anchor: {
        width: 0,
        height: 0,
        margin: 0,
        zIndex: 199,
        borderTop: `${spacingUnit(2)} solid ${colors.blue[800]}`,
        borderLeft: `${spacingUnit(2)} solid transparent`,
        borderRight: `${spacingUnit(2)} solid transparent`,
        marginLeft: spacingUnit(2.5),
    },
});

function SequenceMultipleCompare({ classes, sequenceList, geneInfo, phyloTree, type, maxWidth }) {
    if (!sequenceList || !sequenceList[0]) {
        return null;
    }

    const [sliderValue, setSliderValue] = React.useState(1);
    const handleChange = (event, value) => setSliderValue(value);

    // build tree to sort geneInfos, make dataset aligns with each other
    const serializedTree = serializeBinaryTree(allocatePosition(phyloTree));
    const leafPoints = serializedTree
        .filter(node => node.type === 'leaf')
        .map((node) => ({
            position: node.position,
            name: node.hash_name,
        }));
    const geneStructureData = geneInfo.map((geneGroup, index) => {
        const point = leafPoints.find(item => item.name === geneGroup.group);
        return {
            ...geneGroup,
            index: point ? point.position : index
        };
    });
    const reArrangedSequenceList = [];
    leafPoints.forEach(point => {
        reArrangedSequenceList.push(sequenceList.find(item => item.geneHashName === point.name));
    });

    // sequence compare
    const length = sequenceList[0].sequence.length;
    const rulerScale = [];
    for (let i = 0; i < length; i += 10) {
        rulerScale.push(i + 10);
    }

    return (
        <div>
            <Grid container>
                <Grid item xs={2}>
                    <TreePlot
                        dataSource={phyloTree}
                        heightPerDataGroup={20}
                        padding={[60, 10, 30, 20]}
                    />
                </Grid>
                <Grid item xs={10}>
                    <GeneStructure
                        dataSource={geneStructureData}
                        sequenceData={reArrangedSequenceList}
                        sliderValue={sliderValue}
                        heightPerDataGroup={20}
                        width={1000}
                        padding={[60, 50, 30, 180]}
                    />
                </Grid>
            </Grid>
            <div className={classes.root} style={{ maxWidth: `${maxWidth} !important` }}>
                <div className={classes.labelBox}>
                    {reArrangedSequenceList.map((sequenceInfo, index) => (
                        <CustomTooltip key={index} title={sequenceInfo.description} placement="top">
                            <div
                                className={classes.label}
                                style={{
                                    backgroundColor: colors.orange[800],
                                    boxShadow: `0 0 2px ${colors.orange[900]} inset`,
                                }}
                            >{truncate(sequenceInfo.description, { length: 12 })}</div>
                        </CustomTooltip>
                    ))}
                </div>
                <div className={classes.container}>
                    <div className={classes.anchor}></div>
                    <div style={{
                        transform: `translateX(-${spacingUnit(sliderValue - 1)})`
                    }}>
                        <SequenceContainer
                            sequenceList={reArrangedSequenceList}
                            type={type}
                        />
                    </div>
                    {length > 50 && <CustomSlider
                        style={{ width: '90%', alignSelf: 'center' }}
                        max={length}
                        min={1}
                        valueLabelDisplay="auto"
                        aria-label="Sequence slider"
                        value={sliderValue}
                        onChange={handleChange}
                    />}
                </div>
            </div>
        </div>
    );
}

SequenceMultipleCompare.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.oneOf(['dna', 'protein']),
    sequenceList: PropTypes.arrayOf(PropTypes.shape({
        description: PropTypes.string,
        sequence: PropTypes.string
    })),
    geneInfo: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string, 
        name: PropTypes.string,
        speciesName: PropTypes.string,
        genome: PropTypes.string,
        group: PropTypes.string,
        blocks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    })),
    phyloTree: PropTypes.arrayOf(PropTypes.object),
    maxWidth: PropTypes.number
};

export default withStyles(styles)(SequenceMultipleCompare);
