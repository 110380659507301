import React from 'react';
import PropTypes from 'prop-types';
import { Paper, Tabs, Tab, AppBar, useTheme } from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';

function InfoTabs({ dataSource }) {
    const [value, setValue] = React.useState(0);
    const theme = useTheme();

    function handleChange(event, newValue) {
        setValue(newValue);
    }

    function handleChangeIndex(index) {
        setValue(index);
    }

    return (
        <Paper>
            <AppBar position="static" color="default">
                <Tabs value={value} onChange={handleChange}>
                    {dataSource.map((tab, index) =>
                        <Tab key={index} label={tab.title} />
                    )}
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                {dataSource.map((tab, index) => {
                    if (value === index) {
                        return <Paper style={{ padding: '10px', marginTop: '5px' }} key={index}>{tab.content}</Paper>;
                    }
                    return <div style={{ display: 'none'}} key={index}>{tab.content}</div>;
                })}
            </SwipeableViews>
        </Paper>
    );
}

InfoTabs.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.any,
        content: PropTypes.node
    }))
};

export default InfoTabs;