import React from 'react';
import PropTypes from 'prop-types';
import { Atoms, Charts } from 'components';
import { getSignalpDomain } from 'api/fetch_data';

class GeneSignalpDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            plotData: []
        };
        this.header = [
            { key: 'domain_name', content: 'Cleavage site' },
            { key: 'chrom', content: 'Chrom' },
            { key: 'start', content: 'Start' },
            { key: 'end', content: 'End' },
            { key: 'strand', content: 'Strand' },
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { genomeAbbr, id } = this.props;
        getSignalpDomain(genomeAbbr, id, "Locus").then(data => {
            if (!this.__ignoreLastFetch) {
                const plotData = data.map(item => ({
                    group: 'SignalP',
                    start: item['start'],
                    end: item['end'],
                    strand: item['strand'],
                    name: item['domain_name'],
                    blocks: item['blocks'],
                }));
                this.setState({ dataSource: data, plotData });
            }
        });
    };

    render() {
        const { locus } = this.props;
        const { dataSource, plotData } = this.state; 

        if (dataSource.length === 0){
            return null;
        }   
        
        return (
            <Atoms.ExpansionInfoCard
                title="SignalP"
                mainContent={
                    <Charts.GeneStructure
                        bpStart={locus.start}
                        bpEnd={locus.end}
                        heightPerDataGroup={20}
                        width={1000}
                        padding={[10, 50, 30, 150]}
                        dataSource={plotData}
                    />
                }
                expansionContent={
                    <Atoms.CustomTable columns={this.header} dataSource={dataSource} />
                }
            />
        );
    }
}

GeneSignalpDomain.propTypes = {
    locus: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default GeneSignalpDomain;

