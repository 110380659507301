import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';

/** recieve an array like:
 *  [ 
 *      { title: xxx, description: xxx }, 
 *      { title: xxx, description: xxx }, 
 *  ...]
 *  return a component show key as title and value as description
*/

const styles = ({
    root: {
        flexGrow: 1,
    },
    container: {
        display: 'table',
        border: 'none',
        textAlign: 'left',
        paddingLeft: '10px'
    },
    containerRow: {
        display: 'table-row'
    },
    containerCell: {
        display: 'table-cell',
        lineHeight: '26px',
        verticalAlign: 'middle'
    },
    titleCell: {
        
    },
    descriptionCell: {
        paddingLeft: '24px',
    },
    title: {
        fontSize: '13px',
        fontWeight: 'bold',
        textAlign: 'right',
        lineHeight: 'inherit',
    },
    description: {
        fontSize: '13px',
        lineHeight: 'inherit',
    },
    


});

function InfoList({ dataSource, classes }) {
    return (
        <div className={classes.root}>
            <div className={classes.container}>
                {dataSource.map((data, index) =>
                    <div className={classes.containerRow} key={index}>
                        <div className={clsx(classes.titleCell, classes.containerCell)}>
                            <Typography className={classes.title} component="div">
                                {data.title}
                            </Typography>
                        </div>
                        <div className={clsx(classes.descriptionCell, classes.containerCell)}>
                            <Typography key={index} className={classes.description} component="div">
                                {data.description}
                            </Typography>
                        </div>
                    </div>
                )}
            </div>
        </div>

    );
}

InfoList.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.any,
        content: PropTypes.any
    })),
    classes: PropTypes.object,
    titleWidth: PropTypes.number
};

export default withStyles(styles)(InfoList);