import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import { getKoInfo } from 'api/fetch_data';

class CompareKoTable extends React.Component {
    constructor(props) {
        super(props);
        const { source, target } = props;
        this.state = {
            tableData: [],
            mergeList: [],
        };
        this.header = [
            { key: 'type', content: 'Type' },
            { key: 'owner', content: 'Presence in' },
            { key: 'url', content: 'Accession' },
            { key: 'name', content: 'Name' },
        ];
        this.PostData = [
            { genomeAbbr: source.genomeAbbr, recordType: source.compareType, id: source.id },
            { genomeAbbr: target.genomeAbbr, recordType: target.compareType, id: target.id },
        ];
        this.linkUrl = {
            KO: 'https://www.genome.jp/dbget-bin/www_bget?ko:',
            Pathway: 'https://www.genome.jp/kegg-bin/show_pathway?',
            Model: 'https://www.genome.jp/kegg-bin/show_module?',
            Disease: 'https://www.genome.jp/dbget-bin/www_bget?ds:'
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { source, target, compareType } = this.props;
        Promise.all(
            this.PostData.map(data => getKoInfo(data.genomeAbbr, data.id, compareType))
        ).then(dataList => {
            if (!this.__ignoreLastFetch) {
                const sourceData = this.buildRawDatafromResponse(dataList[0], source.name);
                const targetData = this.buildRawDatafromResponse(dataList[1], target.name);
                const concatData = sourceData;
                for (const targetKoData of targetData) {
                    const index = concatData.findIndex(concatKoData => concatKoData.id === targetKoData.id);
                    if (index === -1) {
                        concatData.push(targetKoData);
                    } else {
                        const newData = { ...targetKoData, owner: 'Both' };
                        concatData[index] = newData;
                    }
                }
                const sortedTableData = concatData.sort(this.sortData);
                // 合并表格第一项（type）
                const mergeData = [];
                let currentType = '';
                for (let index = 0; index < sortedTableData.length; index++) {
                    if (sortedTableData[index].type !== currentType) {
                        currentType = sortedTableData[index].type;
                        mergeData.push(index);
                    } else {
                        const newItem = { ...sortedTableData[index], type: undefined };
                        sortedTableData[index] = newItem;
                    }
                }
                const mergeList = [];
                for (let i = 0; i < mergeData.length; i++) {
                    mergeList.push({
                        y: 0,
                        x: mergeData[i],
                        row: i === mergeData.length - 1 ? sortedTableData.length - mergeData[i] : mergeData[i + 1] - mergeData[i]
                    });
                }


                this.setState({ tableData: sortedTableData, mergeList: mergeList });
            }


        });
    };

    // 将返回值转换为表格可处理的格式
    buildRawDatafromResponse(data, owner) {
        const dataSource = [];
        dataSource.push({
            type: 'KO',
            id: data['koann']['ko'],
            name: data['koann']['name'],
            url: <Atoms.CustomLink
                href={`${this.linkUrl['KO']}${data['koann']['ko']}`}
                externalTarget="DBGET"
            >{data['koann']['ko']}</Atoms.CustomLink>,
            owner,
        });
        ['Pathway', 'Model', 'Disease'].forEach(tag =>
            data[tag].forEach((item) => dataSource.push({
                type: tag,
                id: item.id,
                name: item.name,
                url: <Atoms.CustomLink
                    href={`${this.linkUrl[tag]}${item.id}`}
                    externalTarget={tag === 'Disease' ? 'DBGET' : 'KEGG'}
                >{item.id}</Atoms.CustomLink>,
                owner
            }))
        );
        return dataSource;
    }

    // 将已经扁平化的数据按要求排序
    sortData(data1, data2) {
        if (data1.type === 'KO' && data2.type !== 'KO') return -1;
        else if (data2.type === 'KO' && data1.type !== 'KO') return 1;
        else if (data1.type > data2.type) return 1;
        else if (data1.type < data2.type) return -1;
        else {
            if (data1.owner === 'Both' && data2.owner !== 'Both') return -1;
            else if (data2.owner === 'Both' && data1.owner !== 'Both') return 1;
            else {
                if (data1.owner > data2.owner) return 1;
                else if (data2.owner > data1.owner) return -1;
                else {
                    if (data1.id > data2.id) return 1;
                    else if (data2.id > data1.id) return -1;
                    return 0;
                }
            }
        }
    }

    render() {
        const { tableData, mergeList } = this.state;

        if (tableData.length === 0) {
            return null;
        }

        return (
            <Atoms.CustomTable
                columns={this.header}
                dataSource={tableData}
                mergeList={mergeList}
            />
        );
    }
}

CompareKoTable.propTypes = {
    source: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    target: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    compareType: PropTypes.oneOf(['Locus', 'Trans', 'Prot', ''])
};

export default CompareKoTable;

