import React from 'react';
import { Atoms, Layouts } from 'components';
import { getStaticMarkdownText } from 'api/staticFile';

function HelpView({ match }) {
    const fileMapping = {
        construction: {
            title: 'Construction',
            doc: 'Construction.md'
        },
        term_to_use: {
            title: 'Term to use',
            doc: 'TermToUse.md'
        },
        contact: {
            title: 'Contact us',
            doc: 'ContactUs.md'
        },
        external_links: {
            title: 'External links',
            doc: 'ExternalLinks.md'
        }
    };

    const data = fileMapping[match.params.page];
    return (
        <Layouts.MainLayoutContent headerText={data['title']}>
            <Atoms.MarkdownPaper
                getFile={getStaticMarkdownText}
                fileName={data['doc']}
            />
        </Layouts.MainLayoutContent>
    );
}


export default HelpView;
