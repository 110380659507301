import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Tooltip, Coord, Label, View, Axis, Shape } from 'bizcharts';
import { sum } from 'lodash';

import { serializeBinaryTree, allocatePosition, transformToEdges } from 'tools/EvolutionTree';

Shape.registerShape('edge', 'phyloTreeEdge', {
    drawShape(cfg, container) {
        const drawPoints = this.parsePoints(cfg.points);
        container.addShape('line', {
            attrs: {
                x1: drawPoints[0].x,
                y1: drawPoints[0].y,
                x2: drawPoints[0].x,
                y2: drawPoints[1].y,
                stroke: cfg.color,
                lineWidth: 2,
            }
        });
        return container.addShape('line', {
            attrs: {
                x1: drawPoints[0].x,
                y1: drawPoints[1].y,
                x2: drawPoints[1].x,
                y2: drawPoints[1].y,
                stroke: cfg.color,
                lineWidth: 2,
            }
        });
    }
});

function TreePlot({ dataSource, width, height }) {
    const serializedTree = serializeBinaryTree(allocatePosition(dataSource));

    const pointData = serializedTree
        .filter(node => node.type === 'leaf')
        .map((node) => ({
            position: node.position,
            name: node.name,
            distance: sum(node.path.map(id => serializedTree[id].branchLength))
        }));
    const edgeData = transformToEdges(dataSource);

    return (
        <Chart
            forceFit={width === undefined}
            width={width}
            height={height}
            padding={[10, '10%']}
            scale={{
                distance: {
                    min: 0
                }
            }}
        >
            <Coord transpose reflect="y"/>
            <Axis name="distance"/>
            <Tooltip showTitle={false} />
            <View data={edgeData}>
                <Geom
                    type="edge"
                    position="position*distance"
                    shape="phyloTreeEdge"
                    color="grey"
                    opacity={0.5}
                    tooltip={['distance', (distance) => ({
                        name: 'Branch Length',
                        value: distance[1]-distance[0]
                    })]}
                />
            </View>
            <View data={pointData}>
                <Geom
                    type="point"
                    position="position*distance"
                    size={3}
                    tooltip={false}
                >
                    <Label
                        content="name"
                        textStyle={{
                            fill: 'grey',
                            fontSize: 14,
                            textBaseline: 'middle',
                            textAlign: 'left'
                        }}
                        offset={5}
                    />
                </Geom>
            </View>
        </Chart>
    );
}

TreePlot.propTypes = {
    dataSource: PropTypes.shape({
        branchLength: PropTypes.number,
        children: PropTypes.arrayOf(PropTypes.object),
    }),
    width: PropTypes.number,
    height: PropTypes.number
};

export default TreePlot;