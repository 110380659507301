import React from 'react';
import { Atoms, Layouts } from 'components';
import { Grid, Button } from '@material-ui/core';
import { getMagenPlazaMultiAlign } from 'api/fetch_data';
import { getCompareListData } from 'api/storage';
import { compact, fromPairs, cloneDeep, min, flatten, size, max } from 'lodash';


export default class GeneSyntenyViewerView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formSubmitted: false,
            locusList: [],
            example: {},
            selectedLocus: {},

            // raw data:
            geneInfoData: [],
            alignData: [],
            treeData: [],

            // render table
            checkBoxTableData: [],
            selectedGeneIdList: [],

            // render sequence compare chart
            displayChartData: [],
            displayAlignData: [],
            displayTreeData: [],
        };
        this.tabsRef = React.createRef();
        this.columns = [
            { key: 'id', content: '' },
            { key: 'type', content: 'Type' },
            { key: 'speciesName', content: 'Species' },
            { key: 'genome', content: 'Genome' },
            { key: 'geneName', content: 'Gene' },
            { key: 'chrom', content: 'Chrom' },
            { key: 'start', content: 'Start' },
            { key: 'end', content: 'End' },
            { key: 'strand', content: 'Strand' },
        ];
    }

    componentDidMount() {
        const { geneList } = getCompareListData();
        this.setState({ locusList: geneList });
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    // Select Form
    handleSourceGeneChange = (event) => {
        this.setState({ selectedLocus: event.target.value });
    }
    handleSelectFormSubmit = () => {
        const { selectedLocus } = this.state;
        if (size(selectedLocus)) {
            const { id, genomeAbbr } = selectedLocus;
            this.fetchSyntenyGenes(id, genomeAbbr);
        }
    }
    handleSelectFormClear = () => this.setState({ formSubmitted: false, example: {}, selectedLocus: {} });

    fetchSyntenyGenes = async(id, genomeAbbr) => {
        const res = await getMagenPlazaMultiAlign(genomeAbbr,id);
        if (res && !this.__ignoreLastFetch) {
            const { gene_info: geneInfo, tree: treeData, align_seq: alignment } = res;

            // target gene is always the first one in response data.
            const checkBoxTableData = geneInfo.map((item, index) => {
                const { type, chrom, start, end, strand, gene_name: geneName, species_name: speciesName, genome_abbr: genome } = item;
                return { id: index === 0 ? '---' : index, type, chrom, start, end, strand, geneName, speciesName, genome, alwaysSelected: index === 0 };
            });
            const geneInfoData = geneInfo.map((item) => {
                const { type, gene_name: geneName, species_name: speciesName, genome_abbr: genome, hash_name: geneHashName, blocks, strand } = item;
                let start = 0;
                let relativeBlocks = [];
                if (strand === '+') {
                    start = min(flatten(blocks));
                    relativeBlocks = blocks.map(block => [block[0] - start, block[1] - start]);
                } else {
                    start = max(flatten(blocks));
                    relativeBlocks = blocks.map(block => [start - block[1], start - block[0]]).reverse();
                }
                return { type, name: geneName, speciesName, genome, group: geneHashName, blocks: relativeBlocks };
            });
            const alignData = alignment.map(item => ({ geneHashName: item.hash_name, sequence: item.sequence }));

            this.setState({ geneInfoData, checkBoxTableData, alignData, treeData: [treeData], formSubmitted: true });
        }
    };

    useExample = ({ locusId, genomeAbbr, name }) => (event) => {
        this.setState({
            example: {
                id: locusId,
                genomeAbbr,
                name
            },
        }, () => this.fetchSyntenyGenes(locusId, genomeAbbr));
        this.tabsRef.current.handleChange(event, 0);
    }

    // Draw charts form
    handleGeneIdListChange = (newGeneIdList) => {
        if (newGeneIdList.length <= 5) {
            this.setState({ selectedGeneIdList: newGeneIdList });
        }
    }

    handleSubmit = () => {
        const { alignData, selectedGeneIdList, treeData, geneInfoData } = this.state;
        const hashNameToId = fromPairs(geneInfoData.map((item, index) => [item.group, index]));
        const displayGene = [0, ...selectedGeneIdList];

        // filter align, then delete '-' if all sequence is same with each other at certain position
        const filteredAlignData = alignData.filter(gene => displayGene.indexOf(hashNameToId[gene.geneHashName]) !== -1);
        const sequenceLength = filteredAlignData[0].sequence.length;
        const removeBpIndexList = [];
        for (let i = 0; i < sequenceLength; i++) {
            let allSeqIsGap = true;
            for (let j = 0; j < filteredAlignData.length; j++) {
                allSeqIsGap = allSeqIsGap && (filteredAlignData[j].sequence)[i] === '-';
            }
            if (allSeqIsGap) {
                removeBpIndexList.push(i);
            }
        }

        const displayAlignData = [];
        filteredAlignData.forEach(gene => {
            const { geneHashName, sequence } = gene;
            const sequenceArray = sequence.split('');
            removeBpIndexList.forEach(index => sequenceArray[index] = undefined);
            displayAlignData.push({
                geneHashName: geneHashName,
                description: geneInfoData[hashNameToId[geneHashName]].name,
                sequence: compact(sequenceArray).join('')
            });
        });

        // filter gene_info to draw compare chart.
        // add empty data to make padding in chart.
        const emptyData = { group: 'empty', name: 'empty', blocks: [[0, 0]] };
        const displayChartData = [
            emptyData,
            ...geneInfoData.filter(gene => displayGene.indexOf(hashNameToId[gene.group]) !== -1),
            emptyData
        ];
        // delete unselected datas in tree
        const filterTree = (treeData, idList) => {
            return treeData.filter(node => {
                if (node.hash_name) {
                    return idList.indexOf(hashNameToId[node.hash_name]) !== -1;
                }
                if (node.children) {
                    node.children = filterTree(node.children, idList);
                    return node.children.length > 0;
                }
                return false;
            });
        };
        const filteredTreeData = filterTree(cloneDeep(treeData), displayGene);

        const recursion = (node) => {
            if (node.children) {
                if (node.children.length === 1) {
                    return recursion(node.children[0]);
                } else {
                    node.children = [
                        recursion(node.children[0]),
                        recursion(node.children[1])
                    ];
                    return node;
                }
            } else {
                return node;
            }
        };
        const displayTreeData = recursion(filteredTreeData[0]);
        this.setState({ displayAlignData, displayChartData, displayTreeData });
    }

    render() {
        const { locusList, example, selectedLocus, formSubmitted, checkBoxTableData, selectedGeneIdList, displayAlignData, displayChartData, displayTreeData } = this.state;

        const renderSource = size(example) ? [example, ...locusList] : locusList;
        const sourceGeneOptions = renderSource.map(gene => ({
            text: gene.name,
            value: gene
        }));

        return (
            <Layouts.MainLayoutContent headerText="Gene Synteny Viewer">
                <Atoms.ExampleCard
                    ref={this.tabsRef}
                    title="Select Gene:"
                    formContent={<Grid container>
                        <Grid item xs={12}>
                            <Atoms.SelectControl
                                placeholder="Please Choose Gene to find Colinear"
                                value={size(example) ? example : selectedLocus}
                                options={sourceGeneOptions}
                                variant="outlined"
                                handleChange={this.handleSourceGeneChange}
                                disabled={formSubmitted}
                            />
                        </Grid>
                        <Grid container item xs={12} spacing={2} justify="flex-end" style={{ marginTop: 10 }}>
                            <Grid item xs={12} md={6} style={{ textAlign: 'right' }}>
                                <Button
                                    color="secondary"
                                    onClick={this.handleSelectFormClear}
                                    disabled={!formSubmitted}
                                >Clear</Button>
                                <Button
                                    color="primary"
                                    onClick={this.handleSelectFormSubmit}
                                    disabled={formSubmitted || (size(selectedLocus) === 0 && size(example) === 0)}
                                >Search Synteny Genes</Button>
                            </Grid>
                        </Grid>
                    </Grid>}
                    exampleContent={<div>
                        <Button onClick={this.useExample({
                            genomeAbbr: 'JGI_1.1',
                            locusId: 'gosHir_D03_7685268_7688414_rev',
                            name: 'Gohir.D03G049100.1.v1.1'
                        })}>Use Example</Button>
                    </div>}
                />

                {formSubmitted && <Atoms.CustomCard
                    type="primary"
                    title="Select up to 5 genes you are interested in."
                >
                    <Grid container>
                        <Grid item xs={12} style={{ paddingTop: 10 }}>
                            <Atoms.CheckBoxTable
                                dataSource={checkBoxTableData}
                                columns={this.columns}
                                selectedKeys={selectedGeneIdList}
                                changeSelectedKeys={this.handleGeneIdListChange}
                                headerText="Please Select Up to 5 Genes to View"
                            />
                        </Grid>
                        <Grid container item xs={12} spacing={2} justify="flex-end" style={{ marginTop: 10 }}>
                            <Grid item xs={3} style={{ textAlign: 'right' }}>
                                <Button
                                    color="primary"
                                    onClick={this.handleSubmit}
                                    disabled={selectedGeneIdList.length === 0}
                                >View Colinear Genes</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Atoms.CustomCard>}

                {formSubmitted && <Atoms.CustomCard
                    type="primary"
                    title="Result"
                >
                    <Atoms.SequenceMultipleCompare
                        type="dna"
                        sequenceList={displayAlignData}
                        geneInfo={displayChartData}
                        phyloTree={displayTreeData}
                    />
                </Atoms.CustomCard>}

            </Layouts.MainLayoutContent>
        );
    }
}

