import React from 'react';
import PropTypes from 'prop-types';
import { Atoms, Charts } from 'components/index';
import { getMsInfo } from 'api/fetch_data';

class GeneMsInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            plotData: []
        };
        this.header = [
            { key: 'accession', content: 'Accession' },
            { key: 'file_name', content: 'File' },
            { key: 'peptide', content: 'Peptide' },
            { key: 'start', content: 'Start' },
            { key: 'end', content: 'End' },
            { key: 'strand', content: 'Strand' },
            { key: 'PSM', content: 'PSM' }
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { genomeAbbr, id } = this.props;
        getMsInfo(genomeAbbr, id, 'Locus').then(data => {
            if (!this.__ignoreLastFetch) {
                const dataSource = data.map(item => ({...item}));
                const plotData = data.map(item => ({
                    group: item['file_name'],
                    start: item['start'],
                    end: item['end'],
                    strand: item['strand'],
                    name: item['peptide'],
                    blocks: item['blocks'],
                }));
        
                this.setState({dataSource, plotData});
            }
        });
        
    };

    render() {
        const { locus } = this.props;
        const { dataSource, plotData } = this.state;

        if (dataSource.length === 0){
            return null;
        }
        return (
            <Atoms.InfoGroup title="Mass spectra evidence">
                <Charts.GeneStructure
                    bpStart={locus.start}
                    bpEnd={locus.end}
                    heightPerDataGroup={20}
                    width={1000}
                    padding={[10, 50, 30, 300]}
                    dataSource={plotData}
                />
                <Atoms.CustomTable columns={this.header} dataSource={dataSource} />
            </Atoms.InfoGroup>
        );
    }
}

GeneMsInfo.propTypes = {
    locus: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default GeneMsInfo;
