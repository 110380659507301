import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import { getNtInfo } from 'api/fetch_data';

class NT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        };
        this.header = [
            { key: 'hit_name', content: 'Accession number' },
            { key: 'align_len', content: 'Alignment length' },
            { key: 'evalue', content: 'E-value' },
            { key: 'score', content: 'Score' },
            { key: 'hit_desc', content: 'Description' }
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { genomeAbbr, id, recordType } = this.props;
        getNtInfo(genomeAbbr, id, recordType).then(data => {
            if (!this.__ignoreLastFetch) {
                const dataSource = data.map(item => ({
                    hit_name: <a href={`https://www.ncbi.nlm.nih.gov/nuccore/${item['hit_name']}`}>{item['hit_name']}</a>,
                    align_len: item['align_len'],
                    evalue: item['evalue'],
                    score: item['score'],
                    hit_desc: item['hit_desc'],
                }));
                this.setState({ dataSource });
            }
        });
    };

    render() {
        const { dataSource } = this.state;

        if (dataSource.length === 0){
            return null;
        }
        return (
            <Atoms.InfoGroup title="BLAST to NCBI Nucleotide database">
                <Atoms.CustomTable columns={this.header} dataSource={dataSource}/>
            </Atoms.InfoGroup>
        );
    }
}

NT.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default NT;

