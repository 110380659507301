import React from 'react';
import PropTypes from 'prop-types';
import { Button, FormControl } from '@material-ui/core';

export default class UploadButton extends React.Component {
    handleCapture = ({ target }) => {
        const { fileList, setFileList } = this.props;
        setFileList([...fileList, ...target.files]);
    }

    render() {
        const { accept, multiple } = this.props;
        return (
            <FormControl>
                <input
                    accept={accept}
                    multiple={multiple}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    onChange={this.handleCapture}
                    type="file"
                />
                <label htmlFor="raised-button-file">
                    <Button variant="contained" color="primary" component="span">
                        Upload
                    </Button>
                </label>
            </FormControl>
        );
    }
}

UploadButton.propTypes = {
    accept: PropTypes.string,
    fileList: PropTypes.array.isRequired,
    setFileList: PropTypes.func.isRequired,
};