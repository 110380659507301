import React from 'react';
import PropTypes from 'prop-types';
import { Stage, DatasourceRegistry, StaticDatasource } from 'ngl';


export default class ProteinModel extends React.Component {
    componentDidMount() {
        const { modelSource } = this.props;
        let source = modelSource;
        if (modelSource === undefined) {
            source = process.env.REACT_APP_DEFAULT_PROTEIN_MODEL;
        }
        DatasourceRegistry.add(
            'data', new StaticDatasource(source)
        );

        // Create NGL Stage object
        this.stage = new Stage('viewport');

        // Handle window resizing
        /* window.addEventListener('resize', function (event) {
            this.stage.handleResize();
        }, false); */
    }

    render() {
        const { width, height, modelFileName } = this.props;
        // Code for example: color/bfactor 
        if (this.stage && modelFileName) {
            this.stage.loadFile(`data://${modelFileName}.pdb`).then(function (o) {
                o.addRepresentation('cartoon', { color: 'bfactor' });
                o.autoView();
            });
        }

        return (
            <div id="viewport" style={{ width: width, height: height }}></div>
        );
    }
}

ProteinModel.propTypes = {
    width: PropTypes.string,
    height: PropTypes.string, //like '100%', '400px', or '200em', ...etc.
    modelFileName: PropTypes.any,
    modelSource: PropTypes.any
};