import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Axis, Coord, Guide } from 'bizcharts';
import { chartColors as colors } from 'assets/global';

function GeneStructureWithCover({ dataSource, bpStart, bpEnd, heightPerDataGroup, width, padding, coverRegion, withBpLabel }) {
    const data = [];
    dataSource.forEach((geneGroup, index) => {
        geneGroup.blocks.forEach(gene => {
            data.push({
                index: index,
                range: [gene[0], gene[1]],
                group: geneGroup.group,
                name: geneGroup.name,
                strand: geneGroup.strand
            });
        });
    });

    const colorDict = {};
    let iter = 0;
    dataSource.forEach(geneGroup => {
        if (!(geneGroup.group in colorDict)) {
            colorDict[geneGroup.group] = colors[iter];
            iter++;
        }
    });

    const scale = {
        index: {
            type: 'cat',
            alias: 'name',
            ticks: Array.from(new Array(dataSource.length).keys()),
            range: [0, 1]
        },
        range: {
            minLimit: -1,
            maxLimit: bpEnd,
            min: bpStart,
            max: bpEnd
        }
    };


    return (
        <Chart
            height={heightPerDataGroup * (dataSource.length) + padding[0] + padding[2]}
            width={width}
            data={data}
            scale={scale}
            padding={padding}
            onIntervalClick={(ev) => {
                console.log(ev);
            }}
        >
            <Coord transpose />
            <Axis
                name="index"
                tickLine={null}
                label={{
                    textStyle: {
                        fontSize: '12',
                        fill: '#000',
                        fontWeight: 'bold',
                    },
                    position: 'center',
                    formatter: (text, item, index) => {
                        const axisLabel = data.find(set => set.index === index);
                        if (axisLabel) {
                            if (axisLabel.group === 'empty' && axisLabel.name === 'empty') {
                                return '';
                            }
                            return axisLabel.name;
                        }
                    }
                }} />
            <Axis
                name="range"
                label={withBpLabel ? {
                    offset: 20,
                    textStyle: {
                        fontSize: '12',
                        fill: '#000',
                        fontWeight: 'bold',
                    },
                } : null}
                visible={false}
            />

            <Geom
                type="interval"
                position="index*range"
                color={['group', colors]}
                size={6}
            />

            <Guide>
                {dataSource.map((geneGroup, index) =>
                    (geneGroup.group === 'empty' && geneGroup.name === 'empty') ? null : (
                        <Guide.Line
                            key={index}
                            start={{
                                index: index,
                                range: geneGroup.start
                            }}
                            end={{
                                index: index,
                                range: geneGroup.end
                            }}
                            lineStyle={{
                                stroke: colorDict[geneGroup.group],
                                lineWidth: 1,
                                lineDash: [0, 0, 0]
                            }}
                        />
                    ))}
                {coverRegion.map((gap, index) =>
                    <Guide.Region
                        key={index}
                        top
                        start={['min', gap[0]]}
                        end={['max', gap[1]]}
                        style={{
                            lineWidth: 0,
                            fill: '#e0e0e0',
                            fillOpacity: 0.99,
                            stroke: '#ccc'
                        }}
                    />
                )}
            </Guide>
        </Chart>
    );
}



GeneStructureWithCover.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({
        group: PropTypes.string,
        name: PropTypes.string,
        start: PropTypes.number,
        end: PropTypes.number,
        strand: PropTypes.oneOf(['-', '+']),
        blocks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    })),
    bpStart: PropTypes.number,
    bpEnd: PropTypes.number,
    heightPerDataGroup: PropTypes.number,
    width: PropTypes.number,
    padding: PropTypes.arrayOf(PropTypes.number),
    coverRegion: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
};

export default GeneStructureWithCover;