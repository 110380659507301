import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles, Table, TableBody, TableCell, TableRow, Checkbox, TableHead } from '@material-ui/core';

import TableToolbar from './TableToolbar';
import { whiteColor, hexToRgb } from 'assets/global';

import { pull } from 'lodash';

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    checkAll: {
        backgroundColor: 'inherit'
    },
    withScroll: {
        width: '100%',
        height: '500px',
        overflowY: 'scroll'
    },
    withScrollHeader: {
        position: 'sticky',
        top: 0,
        backgroundColor: `rgba(${hexToRgb(whiteColor)}, 0.85)`,
        zIndex: 1
    },
});

class EnhancedTable extends React.Component {
    handleSelectAllClick = event => {
        if (event.target.checked) {
            const allKeys = this.props.dataSource.map(n => n.id);
            this.props.changeSelectedKeys(allKeys);
            return;
        }
        this.props.changeSelectedKeys([]);
    };

    handleClick = (row) => () => {
        if (!row.alwaysSelected) {
            const id = row.id;
            const { selectedKeys } = this.props;
            const newSelected = selectedKeys.indexOf(id) === -1 ? [...selectedKeys, id] : pull(selectedKeys, id);
            this.props.changeSelectedKeys(newSelected);
        }
    };

    isSelected = id => this.props.selectedKeys.indexOf(id) !== -1;

    render() {
        const { classes, dataSource, selectedKeys, columns, headerText, forceNoScroll } = this.props;

        return (
            <div>
                <TableToolbar numSelected={selectedKeys.length} text={headerText} />
                <div className={clsx({ [classes.withScroll]: dataSource.length > 10 && !forceNoScroll })}>
                    <Table aria-labelledby="tableTitle">
                        <TableHead>
                            <TableRow>
                                <TableCell
                                    padding="checkbox"
                                    className={clsx({ [classes.withScrollHeader]: dataSource.length > 10 && !forceNoScroll })}
                                >
                                    <Checkbox
                                        className={classes.checkAll}
                                        indeterminate={selectedKeys.length > 0 && selectedKeys.length < dataSource.length}
                                        disabled={dataSource.length === 0}
                                        checked={selectedKeys.length === dataSource.length}
                                        onChange={this.handleSelectAllClick}
                                        color="primary"
                                    />
                                </TableCell>
                                {columns.map((title, index) => (
                                    <TableCell
                                        key={index}
                                        className={clsx({ [classes.withScrollHeader]: dataSource.length > 10 && !forceNoScroll })}
                                    >
                                        {title.content}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataSource.map((row) => {
                                const isSelected = this.isSelected(row.id);
                                return (
                                    <TableRow
                                        hover
                                        onClick={this.handleClick(row)}
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            {row.alwaysSelected ? <Checkbox checked={true} disabled={true} color="primary" /> : <Checkbox
                                                color="primary"
                                                checked={isSelected}
                                            />}
                                        </TableCell>
                                        {columns.map((title, index) =>
                                            <TableCell key={index}>
                                                {row[title.key]}
                                            </TableCell>)}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </div>
        );
    }
}

EnhancedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        content: PropTypes.any
    })).isRequired,
    selectedKeys: PropTypes.arrayOf(PropTypes.number),
    changeSelectedKeys: PropTypes.func,
    headerText: PropTypes.string
};

export default withStyles(styles)(EnhancedTable);