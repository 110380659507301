import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, OutlinedInput, Chip, InputLabel, makeStyles, useTheme } from '@material-ui/core';

const useStyles = makeStyles({
    formControl: {
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};

function getStyles(optionValue, optionList, theme) {
    return {
        fontWeight:
            optionList.findIndex(item => item.value === optionValue) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

function MultipleSelectControl({ value, options, handleChange, placeholder, ...other }) {
    const classes = useStyles();
    const theme = useTheme();

    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);
    
    return (
        <FormControl className={classes.formControl} fullWidth variant="outlined" {...other}>
            <InputLabel ref={inputLabel} htmlFor={`outlined-${placeholder}`}>
                {placeholder}
            </InputLabel>
            <Select
                fullWidth
                multiple
                value={value}
                onChange={handleChange}
                input={<OutlinedInput fullWidth labelWidth={labelWidth} name={placeholder} id={`outlined-${placeholder}`} /> }
                renderValue={selected => (
                    <div className={classes.chips}>
                        {selected.map(value => (
                            <Chip 
                                key={value}
                                label={options.find(item => item.value === value).text}
                                className={classes.chip}
                            />
                        ))}
                    </div>
                )}
                MenuProps={MenuProps}
            >
                {options.map((option, index) => 
                    <MenuItem 
                        key={index} 
                        value={option.value}
                        style={getStyles(option.value, options, theme)}
                    >{option.text}</MenuItem>
                )}
            </Select>
        </FormControl>
    );
}

MultipleSelectControl.propTypes = {
    options: PropTypes.shape({
        value: PropTypes.string,
        text: PropTypes.string
    }),
    value: PropTypes.string,
    handleChange: PropTypes.func,
    placeholder: PropTypes.func
};

export default MultipleSelectControl;