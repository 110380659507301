import React from 'react';
import PropTypes from 'prop-types';
import { Atoms, Charts } from 'components';
import { getPlazaColinearInfo, getPlazaColinearGroupInfo } from 'api/fetch_data';

class PlazaColinearInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            selectedKey: undefined,
            colinerData: [],
            this_chrom: '',
            paired_chrom: '',
        };
        this.columns = [
            { key: 'plaza_genome_name', content: 'Synteny species' },
            { key: 'plaza_chrom', content: 'Synteny chrom' },
            { key: 'plaza_protein_name', content: 'Synteny gene' },
            { key: 'score', content: 'Score' },
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    changeSelectedKeys = (id) => {
        this.setState({
            selectedKey: id,
            this_chrom: this.state.data[id].this_chrom,
            paired_chrom: this.state.data[id].plaza_chrom,
        }, () => this.drawSankey());
    };

    fetchData = async () => {
        if (!this.__ignoreLastFetch) {
            const { genomeAbbr, recordType, id } = this.props;
            const colinerData = await getPlazaColinearInfo(genomeAbbr, id, recordType);
            this.setState({
                data: colinerData
            });
        }
    }

    drawSankey = async () => {
        const { assemble_abbr, plaza_genome_abbr, coliner_group_id } = this.state.data[this.state.selectedKey];
        const data = await getPlazaColinearGroupInfo(assemble_abbr, plaza_genome_abbr, coliner_group_id);
        this.setState({ colinerData: data });
    };

    is_with_rev = (colinear_data_li) => {
        const first_data = colinear_data_li[0];
        const last_data = colinear_data_li[colinear_data_li.length - 1];
        const first_this_sidx = first_data['this_assemble']['start'];
        const first_paired_sidx = first_data['paired_assemble']['start'];
        const last_this_sidx = last_data['this_assemble']['start'];
        const last_paired_sidx = last_data['paired_assemble']['start'];
        return (first_this_sidx > last_this_sidx) ^ (first_paired_sidx > last_paired_sidx);
    };

    render() {
        const { id } = this.props;
        const { data, colinerData, selectedKey, this_chrom, paired_chrom } = this.state;
        const dataSource = data.map((item, index) => {
            const {
                assemble_abbr, plaza_genome_abbr, plaza_genome_name, plaza_protein_name, plaza_chrom, score
            } = item;
            return {
                id: index,
                withRadio: true,
                paired_locus: <a href="#">{plaza_protein_name}</a>,
                assemble_abbr, plaza_genome_abbr, plaza_genome_name,
                score, this_chrom, plaza_chrom, plaza_protein_name
            };
        });
        if (dataSource.length === 0) {
            return <div>No co-linear gene found.</div>;
        }
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {colinerData.length > 0 && <div>
                        <h3>Gene synteny cluster</h3>
                        <Charts.ColinearGeneDiagram
                            highLightDataList={[id, data[selectedKey].locus_id]}
                            pairList={colinerData}
                            chromosome={this_chrom}
                            pairedChromosome={paired_chrom}
                            withReverse={this.is_with_rev(colinerData)}
                        />
                    </div>}
                </div>
                <Atoms.RadioTable
                    selectedKey={selectedKey}
                    changeSelectedKey={this.changeSelectedKeys}
                    headerText="Select a gene"
                    columns={this.columns}
                    dataSource={dataSource}
                    onOptionButtonClick={this.drawSankey}
                />
            </div>
        );
    }
}


PlazaColinearInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default PlazaColinearInfo;

