import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Axis, Geom, Tooltip, Legend } from 'bizcharts';


function LinePlot({ dataSource, xAxis, yAxis, width, height, padding }) {
    const data = [];
    for (const key in dataSource) {
        dataSource[key].x.forEach((xValue, index) => {
            data.push({ x: xValue, y: (dataSource[key].y)[index], group: key });
        });
    }
    const scale = {
        x: {
            alias: xAxis
        },
        y: {
            alias: yAxis
        }
    };

    const axisTitle = {
        autoRotate: true,
        offset: 40,
        textStyle: {
            fontSize: '14',
            textAlign: 'center',
            fill: '#000',
            fontWeight: 'bold',
        },
        position: 'center',
    };

    const axisLabel = {
        textStyle: {
            fontSize: '12',
            textBaseline: 'middle',
            fill: '#000',
        },
    };

    return (
        <Chart
            data={data}
            scale={scale}
            width={width}
            height={height}
            padding={padding}>
            <Axis name="x" title={axisTitle} label={axisLabel} />
            <Axis name="y" title={axisTitle} label={axisLabel} />
            <Tooltip
                crosshairs={{
                    type: 'y',
                }}
            />
            <Legend
                name="group"
                position="right-center"
                textStyle={{
                    textAlign: 'start',
                    fill: '#000',
                    fontSize: '14',
                    fontWeight: 'bold',
                }}
            />
            <Geom type="line" position="x*y" color="group" />

        </Chart>
    );
}

LinePlot.propTypes = {
    dataSource: PropTypes.object.isRequired,
    xAxis: PropTypes.string,
    yAxis: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    padding: PropTypes.arrayOf(PropTypes.number)
};


export default LinePlot;