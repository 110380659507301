import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, Card, Typography, IconButton, CardHeader, CardContent, Collapse } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
    card: {
        maxWidth: '100%',
    },
    heading: {
        fontSize: 18,
        fontWeight: 'bold',
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
}));

function ExpansionInfoCard({ title, mainContent, expansionContent }) {
    const [expanded, setExpanded] = React.useState(false);
    const classes = useStyles();

    function handleExpandClick() {
        setExpanded(!expanded);
    }

    return (
        <Card className={classes.card}>
            <CardHeader
                action={
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="Show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>
                }
                title={
                    <Typography className={classes.heading}>
                        {title}
                    </Typography>
                }
            />
            <CardContent>
                {mainContent}
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    {expansionContent}
                </CardContent>
            </Collapse>
        </Card>
    );

}

ExpansionInfoCard.propTypes = {
    mainContent: PropTypes.any,
    expansionContent: PropTypes.any,
    title: PropTypes.any
};

export default ExpansionInfoCard;