import { asyncFetchPost, FetchFile } from './global';

export function getMataInfo(add_all = false) {
    return asyncFetchPost('wiki/get_mata_info', { add_all });
}

export function getChromSizeInfo() {
    return asyncFetchPost('wiki/get_chrom_size_info');
}

export function getSpeciesDesc(species_abbr) {
    return asyncFetchPost('wiki/get_species_desc', { species_abbr });
}

export function getGenomeDesc(species_abbr) {
    return asyncFetchPost('wiki/get_genome_desc', { species_abbr });
}

export function getSearchInfo(search_field, data, species_abbr, genome_abbr, assemble_abbr, page = 1, page_size = 30) {
    return asyncFetchPost('search', {
        species_abbr, genome_abbr, assemble_abbr, search_field, data, page, page_size
    });
}

export function getSearchSuggestInfo(search_field, data, species_abbr = 'All', genome_abbr = 'All', assemble_abbr = 'All', page = 1, page_size = 10) {
    return asyncFetchPost('search_suggest', {
        species_abbr, genome_abbr, assemble_abbr, search_field, data, page, page_size
    });
}

export function getCertainSpeciesInfo(species_abbr) {
    return asyncFetchPost('wiki/get_certain_species_info', {
        species_abbr
    });
}


export function getName(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_name', {
        id, id_type, genome_abbr
    });
}

export function getSummary(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_summary_info', {
        genome_abbr, id, id_type
    });
}

export function getGeneSeq(genome_abbr, locus_id) {
    return FetchFile('wiki/get_gene_seq', {
        'locus_id': locus_id, 'genome_abbr': genome_abbr
    });
}

export function getTransSeq(genome_abbr, trans_id) {
    return FetchFile('wiki/get_trans_seq', {
        'uniq_trans_id': trans_id, 'genome_abbr': genome_abbr
    });
}

export function getProtSeq(genome_abbr, prot_id) {
    return FetchFile('wiki/get_prot_seq', {
        'uniq_prot_id': prot_id, 'genome_abbr': genome_abbr
    });
}

export function getGeneTransInfo(genome_abbr, locus_id) {
    return asyncFetchPost('wiki/get_gene_trans_info', {
        'locus_id': locus_id, 'genome_abbr': genome_abbr
    });
}

export function getGeneOrfInfo(genome_abbr, locus_id) {
    return asyncFetchPost('wiki/get_gene_orf_info', {
        'locus_id': locus_id, 'genome_abbr': genome_abbr
    });
}

export function getPlant2AtInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_plant2at_info', {
        genome_abbr, id, id_type
    });
}

export function getNtInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_nt_info', {
        genome_abbr, id, id_type
    });
}

export function getNrInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_nr_info', {
        genome_abbr, id, id_type
    });
}

export function getTfInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_tf_info', {
        genome_abbr, id, id_type
    });
}

export function getGoInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_go_info', {
        genome_abbr, id, id_type
    });
}

export function getKoInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_ko_info', {
        genome_abbr, id, id_type
    });
}

export function getEcInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_ec_info', {
        genome_abbr, id, id_type
    });
}

export function getMsInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_ms_info', {
        genome_abbr, id, id_type
    });
}


export function getDomainCoorInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_domain_coor_info', {
        genome_abbr, id, id_type
    });
}


export function getInterproDomain(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_interpro_info', {
        genome_abbr, id, id_type
    });
}

export function getCddDomain(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_cdd_info', {
        genome_abbr, id, id_type
    });
}

export function getPfamDomain(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_pfam_info', {
        genome_abbr, id, id_type
    });
}

export function getSignalpDomain(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_signalp_info', {
        genome_abbr, id, id_type
    });
}

export function getTmhmmDomain(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_tmhmm_info', {
        genome_abbr, id, id_type
    });
}

export function getProtIpured2aScore(genome_abbr, prot_id) {
    return asyncFetchPost('wiki/get_prot_ipured2a_score', {
        'uniq_prot_id': prot_id, 'genome_abbr': genome_abbr
    });
}

export function getTransRg4Region(genome_abbr, trans_id) {
    return asyncFetchPost('wiki/get_trans_rg4_info', {
        'uniq_trans_id': trans_id, 'genome_abbr': genome_abbr
    });
}

export function getColinerInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_coliner_info', {
        genome_abbr, id, id_type
    });
}

export function getColinerGroupInfo(assemble_abbr, paired_assemble_abbr, coliner_group_id, first_pos) {
    return asyncFetchPost('wiki/get_coliner_group_info', {
        assemble_abbr, paired_assemble_abbr, coliner_group_id, first_pos
    });
}

export function getPlazaColinearInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_plaza_colinear_info', {
        genome_abbr, id, id_type
    });
}

export async function getPlazaColinearGroupInfo(assemble_abbr, plaza_genome_abbr, coliner_group_id){
    const response = await asyncFetchPost('wiki/get_plaza_colinear_group_info', {
        assemble_abbr, plaza_genome_abbr, coliner_group_id
    });
    const formatData = [];
    response.forEach(item => {
        const tmp = {
            pair_id: item.pair_id,
            this_assemble: {
                locus_id: item.this_genome.hash_name, name: item.this_genome.name,
                start: item.this_genome.start, end: item.this_genome.end
            },
            paired_assemble: {
                locus_id: item.plaza_genome.name, name: item.plaza_genome.name,
                start: item.plaza_genome.start, end: item.plaza_genome.end
            }
        };
        formatData.push(tmp);
    });
    return formatData;
}

export function getPPIInfo(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_ppi_info', {
        genome_abbr, id, id_type
    });
}


export function getGeneExpr(genome_abbr, locus_id) {
    return asyncFetchPost('wiki/get_locus_expr', {
        locus_id, genome_abbr
    });
}

export function getGeneExprList(locus_info_list) {
    return asyncFetchPost('wiki/get_locus_expr_list', {
        locus_info_list
    });
}

export function getEggFamily(genome_abbr, id, id_type) {
    return asyncFetchPost('wiki/get_egg_gene_family', {
        genome_abbr, id, id_type
    });
}

export async function getSwissModelInfo(genome_abbr, query_id, id_type) {
    const response = await asyncFetchPost('wiki/get_swiss_model_info', {
        genome_abbr, query_id, id_type
    });
    if (response === null) {
        return {};
    }
    return response;
}

export function getPlazaConsGenome() {
    return asyncFetchPost('wiki/get_plaza_cons_genome', {});
}


export function getMultiAlign(id_type, id1, genome_abbr1, id2 = '', genome_abbr2 = '', sequence = '') {
    // id_type: ["Locus", "Trans", "Prot"]
    // (id2 + genome_abbr2) or (sequence)
    return asyncFetchPost('tools/get_multi_align', {
        id_type, id1, genome_abbr1, id2, genome_abbr2, sequence
    });
}

export function getTransInRegion(genome_abbr, chrom, start, end) {
    return asyncFetchPost('tools/get_trans_in_region', {
        genome_abbr, chrom, start, end
    });
}

export function getDnaPcrPrimer(species_abbr, genome_abbr, chrom, start, end,
    primerParams = {
        number_of_return: 5,
        primer_min_size: 18, primer_opt_size: 20, primer_max_size: 22,
        primer_min_gc: 40, primer_opt_gc: 50, primer_max_gc: 60,
        primer_min_tm: 50, primer_opt_tm: 55, primer_max_tm: 60, primer_max_diff_tm: 5,
        min_insert_len: 100, max_insert_len: 300
    }, target_start = null, target_end = null) {
    return asyncFetchPost('tools/design_dna_pcr_primer', {
        species_abbr, genome_abbr, chrom, start, end,
        ...primerParams,
        target_start, target_end
    });
}

export function getRnaPcrPrimer(species_abbr, genome_abbr, uniq_trans_li, chrom, start, end,
    primerParams = {
        number_of_return: 5,
        primer_min_size: 18, primer_opt_size: 20, primer_max_size: 22,
        primer_min_gc: 40, primer_opt_gc: 50, primer_max_gc: 60,
        primer_min_tm: 50, primer_opt_tm: 55, primer_max_tm: 60, primer_max_diff_tm: 5
    },
    target_start = null, target_end = null) {
    return asyncFetchPost('tools/design_rna_pcr_primer', {
        species_abbr, genome_abbr, uniq_trans_li, chrom, start, end, ...primerParams,
        target_start, target_end
    });
}

export function batchGetGeneInfo(genome_abbr, gene_list) {
    return FetchFile('tools/bulk_get_rec_info', {
        genome_abbr, gene_list
    });
}

export function getMapViewerInfo(genome_abbr, gene_list) {
    return asyncFetchPost('tools/map_viewer', {
        genome_abbr, gene_list
    });
}

export function getTopGo(assemble_abbr, gene_list, bg_li=[], top_nodes=10) {
    return FetchFile('tools/topgo', {
        assemble_abbr, gene_list, bg_li, top_nodes
    });
}

export function getMagenPlazaMultiAlign(genome_abbr, hash_name) {
    return asyncFetchPost('tools/get_magen_plaza_multi_align', {
        genome_abbr, hash_name
    });
}

export function getGenomeColinearInfo(query_genome_abbr, target_genome_abbr, target_source) {
    return asyncFetchPost('tools/get_genome_colinear_info', {
        query_genome_abbr, target_genome_abbr, target_source
    });
}
