import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import IvalPfamDomain from './IvalPfamDomain';
import IvalInterproDomain from './IvalInterproDomain';
import IvalCddDomain from './IvalCddDomain';
import IvalSignalpDomain from './IvalSignalpDomain';
import IvalTmhmmDomain from './IvalTmhmmDomain';
import ProtDisorder from './ProtDisorder';


function ProtDomainInfo({prot, ...otherProps}) {
    return (
        <Atoms.InfoGroup title="Functional elements">
            <IvalInterproDomain ival={prot} {...otherProps} recordType="Prot"/>
            <IvalCddDomain ival={prot} {...otherProps} recordType="Prot"/>
            <IvalPfamDomain ival={prot} {...otherProps} recordType="Prot"/>
            <IvalSignalpDomain ival={prot} {...otherProps} recordType="Prot"/>
            <IvalTmhmmDomain ival={prot} {...otherProps} recordType="Prot"/>
            <ProtDisorder ival={prot} {...otherProps}/>
        </Atoms.InfoGroup>
    );
}

ProtDomainInfo.propTypes = {
    prot: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default ProtDomainInfo;

