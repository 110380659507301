import React from 'react';
import PropTypes from 'prop-types';
import { Fab, withStyles, colors } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import MenuIcon from '@material-ui/icons/Menu';
import Draggable from 'react-draggable';

const styles = theme => ({
    float: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        padding: '4px',
        border: '2px solid #eee',
        borderRadius: '8px'
    },
    extendedIcon: {
        marginRight: theme.spacing(1)
    },
    fabGreen: {
        color: theme.palette.common.white,
        backgroundColor: colors.green[500],
        '&:hover': {
            backgroundColor: colors.green[600],
        },
        marginBottom: theme.spacing(1)
    }
});

function FloatButtonGroup({ classes, addButton, infoButton }) {
    return (
        <Draggable>
            <div className={classes.float}>
                <Fab variant="extended" aria-label="Add" onClick={infoButton.onClick} className={classes.fabGreen}>
                    <MenuIcon className={classes.extendedIcon} />
                    {infoButton.text}
                </Fab>
                <Fab variant="extended" color="primary" aria-label="Add" onClick={addButton.onClick}>
                    <AddIcon className={classes.extendedIcon} />
                    {addButton.text}
                </Fab>
            </div>
        </Draggable>
    );
}

FloatButtonGroup.propTypes = {
    classes: PropTypes.object,
    addButton: PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func
    }).isRequired,
    infoButton: PropTypes.shape({
        text: PropTypes.string,
        onClick: PropTypes.func
    }).isRequired,
};


export default withStyles(styles)(FloatButtonGroup);