import React from 'react';
import PropTypes from 'prop-types';
import { Layouts, Atoms } from 'components';
import {
    GeneSummaryInfo, GeneTransInfo, GeneORFInfo, Ontology,
    GeneDomainInfo, GeneExpressionInfo, TfInfo, Annotation,
    PPINetworkInfo, GeneFamilyInfo, ColinearInfo, Prot3D, GeneMsInfo
} from 'pages/wiki/components';
import { getName } from 'api/fetch_data';
import { getCompareListData, storage } from 'api/storage';


class GeneWiki extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            geneName: '',
            speciesName: '',
            locus: {
                chrom: '',
                start: '',
                end: '',
                strand: ''
            },
            compareListData: {
                geneList: [],
                transcriptionList: [],
                proteinList: []
            }

        };
    }

    setLocus = (locus) => this.setState({ locus: locus });

    componentDidMount() {
        this.fetchData();
        this.setState({ compareListData: getCompareListData() });
    }

    addGeneToCompare = () => {
        const { match: { params } } = this.props;
        storage.appendRecord('geneList', {
            name: this.state.geneName,
            ...params
        });
        this.setState({ compareListData: getCompareListData() });
    };

    removeGeneFromCompare = (category, gene) => {
        storage.removeRecord(category, gene);
        this.setState({ compareListData: getCompareListData() });
    };

    fetchData = () => {
        const { genomeAbbr, id } = this.props.match.params;
        getName(genomeAbbr, id, 'Locus')
            .then(data => ({
                geneName: data.name,
                speciesName: data.species_name,
            })).then(res => this.setState(res));
    };

    render() {
        const { match: { params } } = this.props;
        const { geneName, speciesName, compareListData } = this.state;
        return (
            <Layouts.MainLayoutContent headerText={`${geneName} (${speciesName})`} textLoading={geneName===''}>
                <GeneSummaryInfo setLocus={this.setLocus} {...params} />
                <GeneTransInfo {...params} />
                <GeneORFInfo {...params} />
                <GeneMsInfo {...params} recordType="Locus"  locus={this.state.locus}/>
                <Annotation {...params} recordType="Locus" />
                <GeneFamilyInfo {...params} recordType="Locus" />
                <TfInfo {...params} recordType="Locus" />
                <Ontology {...params} recordType="Locus" />
                <GeneExpressionInfo {...params} />
                <GeneDomainInfo locus={this.state.locus} {...params} />
                <ColinearInfo {...params} recordType="Locus" />
                <PPINetworkInfo {...params} recordType="Locus" />
                <Prot3D {...params} recordType="Locus" />
                <Atoms.CompareList
                    dataSource={compareListData}
                    onClickAdd={this.addGeneToCompare}
                    onDelete={this.removeGeneFromCompare}
                />
            </Layouts.MainLayoutContent>
        );
    }
}

GeneWiki.propTypes = {
    match: PropTypes.object
};

export default GeneWiki;
