import React from 'react';
import { TextField } from '@material-ui/core';

export default function InputComponent(inputProps) {
    const { inputRef = () => { }, classes, ref, ...other } = inputProps;
    return (
        <TextField
            fullWidth
            inputRef={node => {
                ref(node);
                inputRef(node);
            }}
            className={classes.input}
            {...other}
        />
    );
}