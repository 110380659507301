const example = [{
    title: 'Example 1',
    description: '',
    querySpecies: 'gosArb', queryGenome: 'CGP', targetSpecies: 'gosHir', targetGenome: 'JGI_1.1', source: 'MaGen'
},
{
    title: 'Example 2',
    description: '',
    querySpecies: 'theCac', queryGenome: 'cocoa_v2', targetGenome: 'atr', source: 'PLAZA'
}];

export default example;
