import React from 'react';
import PropTypes from 'prop-types';
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = ({
    heading: {
        fontSize: '18px',
        fontWeight: 'bold',
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    details: {
        flexDirection: 'column'
    }
});

function InfoGroup({ title, classes, defaultExpanded, children }) {
    return (
        <ExpansionPanel defaultExpanded={defaultExpanded}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                <Typography className={classes.heading}>{title}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.details}>
                {children}
            </ExpansionPanelDetails>
        </ExpansionPanel>
    );
}


InfoGroup.propTypes = {
    title: PropTypes.any,
    classes: PropTypes.object,
    defaultExpanded: PropTypes.bool,
    children: PropTypes.any
};

export default withStyles(styles)(InfoGroup);