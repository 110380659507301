import React from 'react';
import { Atoms, Layouts } from 'components';
import { Grid, Button, Typography, withStyles } from '@material-ui/core';
import { getPlazaConsGenome, getMataInfo } from 'api/fetch_data';

import download from 'tools/download';

const styles = {
    formText: {
        fontSize: '16px',
        marginLeft: '24px',
        fontWeight: 'bold',
        lineHeight: '56px'
    }
};

const initialState = {
    queryGenomeList: [], targetGenomeList: [],
    // form
    fileList: []
};

class LiftOverView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // render select control
            magenGenomeData: [],
            plazaGenomeData: [],
            ...initialState
        };
    }

    componentDidMount() {
        this.renderSelect();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    renderSelect = async () => {
        const magenGenomeData = await getMataInfo();
        const plazaGenomeData = await getPlazaConsGenome();
        if (magenGenomeData && plazaGenomeData && !this.__ignoreLastFetch) {
            this.setState({ magenGenomeData, plazaGenomeData });
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('query_species', 'gosArb');
        formData.append('query_genome', 'CGP');
        formData.append('query_db', 'MaGen');
        formData.append('target_species', 'gosRai');
        formData.append('target_genome', 'JGI_v2.0');
        formData.append('target_db', 'MaGen');
        formData.append('fmt', 'bed6');
        formData.append('file', this.state.fileList[0]);
        const res = await fetch('http://magen.whu.edu.cn/api/tools/liftover/', {
            method: 'POST',
            body: formData
        });
        const blob = await res.blob();

        const filename = 'Result.zip';
        // const filename = res.headers.get('Content-Disposition').split('=')[1];
        download(blob, filename);
    };

    setFileList = (fileList) => this.setState({ fileList });

    render() {
        const { classes } = this.props;
        const {
            magenGenomeData, queryGenomeList, targetGenomeList,
            querySpecies, queryGenome, targetSpecies, targetGenome
        } = this.state;
        const speciesOptions = magenGenomeData.map(item => ({
            value: item.abbr_name,
            text: item.name
        }));
        const queryGenomeOptions = queryGenomeList.map(item => ({
            value: item.abbr_name,
            text: item.abbr_name
        }));
        const targetGenomeOptions = targetGenomeList.map(item => ({
            value: item.abbr_name,
            text: item.abbr_name
        })).filter(item => item.value !== queryGenome);

        return (
            <Layouts.MainLayoutContent headerText="LiftOver">
                <Atoms.CustomCard
                    title="LiftOver"
                >
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={2}>
                            <Typography className={classes.formText} variant="body2">Query:</Typography>
                        </Grid>
                        <Grid container item xs={12} md={10} spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Atoms.SelectControl
                                    value={querySpecies}
                                    handleChange={this.handleQuerySpeciesChange}
                                    placeholder="Query Species"
                                    options={speciesOptions}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Atoms.SelectControl
                                    value={queryGenome}
                                    handleChange={this.handleQueryGenomeChange}
                                    placeholder="Query Genome"
                                    options={queryGenomeOptions}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Typography className={classes.formText} variant="body2">Target:</Typography>
                        </Grid>
                        <Grid container item xs={12} md={10} spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Atoms.SelectControl
                                    value={targetSpecies}
                                    handleChange={this.handleTargetSpeciesChange}
                                    placeholder="Target Species"
                                    options={speciesOptions}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Atoms.SelectControl
                                    value={targetGenome}
                                    handleChange={this.handleTargetGenomeChange}
                                    placeholder="Target Genome"
                                    options={targetGenomeOptions}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                </Atoms.CustomCard>
                <Atoms.UploadButton
                    fileList={this.state.fileList}
                    setFileList={this.setFileList}
                />
                <Button onClick={this.handleSubmit}>submit</Button>

                <br />
                1. Select query genome
                <br />
                2. Select target genome
                <br />
                3. Select BED format bed4/bed6/bed12
                <br />
                4. Upload BED file
                <br />
                NOTE: At least one of those genome is from MaGenDB
            </Layouts.MainLayoutContent>
        );
    }
}

export default withStyles(styles)(LiftOverView);