import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import Plant2At from './Plant2At';
import NT from './NT';
import NR from './NR';

function Annotation(props) {
    return (
        <Atoms.InfoGroup title="Gene functional annotation">
            <NT {...props} />
            <NR {...props} />
            <Plant2At {...props} />
        </Atoms.InfoGroup>
    );

}

Annotation.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default Annotation;

