import React from 'react';
import PropTypes from 'prop-types';
import { Chip, CircularProgress } from '@material-ui/core';
import { whiteColor } from 'assets/global';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import download from 'tools/download';

export default function ChipDownloadLink({ downloadParams, downloadPromise, fileName, style: styleProp, children, ...otherProps }) {
    const [loading, setLoading] = React.useState(false);

    const downloadFile = async () => {
        setLoading(true);
        if (downloadPromise) {
            const res = await downloadPromise(...downloadParams);
            const blob = await res.blob();
            if (res.status === 200) {
                let filename = fileName;
                const disposition = res.headers.get('Content-Disposition');
                if (disposition) {
                    filename = disposition.split('=')[1];
                }
                download(blob, filename);
            }
        }
        setLoading(false);
    };

    const emptyOnDelete = () => { };

    return (
        <Chip
            style={{ color: whiteColor, ...styleProp }}
            icon={<DownloadIcon />}
            size="small"
            label={children}
            onClick={downloadFile}
            deleteIcon={loading ? <CircularProgress size={16} style={{ color: 'white', margin: '4px' }} /> : undefined}
            onDelete={loading ? emptyOnDelete : undefined}
            clickable
            color="primary"
            {...otherProps}
        />
    );
}

ChipDownloadLink.propTypes = {
    downloadPromise: PropTypes.func,
    downloadParams: PropTypes.array,
    fileName: PropTypes.string,
    style: PropTypes.object,
    children: PropTypes.any
};