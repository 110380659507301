import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Atoms} from 'components';
import {getKoInfo} from 'api/fetch_data';

class KoInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            mergeList: []
        };
        this.header = [
            {key: 'type', content: 'Type'},
            {key: 'id', content: 'Accession'},
            {key: 'name', content: 'Name'},
            {key: 'search', content: 'Search same ontology gene'}
        ];
    }

    search_name = {
        KO: 'ko',
        Pathway: 'pathway',
        Model: 'model',
        Disease: 'disease'
    };

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const {genomeAbbr, recordType, id} = this.props;
        getKoInfo(genomeAbbr, id, recordType).then(data => {
            if (!this.__ignoreLastFetch) {
                const dataSource = [];
                const mergeList = [];
                let begin_indx = 0;
                let newData = {};
                if (!data) {
                    return undefined;
                }
                newData = {
                    type: 'KO',
                    id: <a
                        href={`https://www.genome.jp/dbget-bin/www_bget?ko:${data['koann']['ko']}`}>{data['koann']['ko']}</a>,
                    name: data['koann']['name'],
                    search: <Link to={{
                        pathname: `/search/${this.search_name.KO}`,
                        state: {'searchText': data['koann']['ko'], 'species': 'All', 'genome': 'All', 'assemble': 'All'}
                    }}>Search</Link>
                };
                dataSource.push(newData);
                begin_indx = begin_indx + 1;

                const tags = {
                    Pathway: 'https://www.genome.jp/kegg-bin/show_pathway?',
                    Model: 'https://www.genome.jp/kegg-bin/show_module?',
                    Disease: 'https://www.genome.jp/dbget-bin/www_bget?ds:'
                };
                for (const tag in tags) {
                    const search_url = tags[tag];
                    if (data[tag]) {
                        const row_len = data[tag].length;
                        mergeList.push(
                            {y: 0, x: begin_indx, row: row_len}
                        );
                        begin_indx = begin_indx + row_len;
                        data[tag].forEach((item, index) => {
                            let newData = {};
                            if (index === 0) {
                                newData = {
                                    type: tag,
                                    id: <a href={`${search_url}${item.id}`}>{item.id}</a>,
                                    name: item['name'],
                                    search: <Link to={{
                                        pathname: `/search/${this.search_name[tag]}`,
                                        state: {
                                            'searchText': item.id,
                                            'species': 'All',
                                            'genome': 'All',
                                            'assemble': 'All'
                                        }
                                    }}>Search</Link>
                                };
                            } else {
                                newData = {
                                    id: <a href={`${search_url}${item.id}`}>{item.id}</a>,
                                    name: item['name'],
                                    search: <Link to={{
                                        pathname: `/search/${this.search_name[tag]}`,
                                        state: {
                                            'searchText': item.id,
                                            'species': 'All',
                                            'genome': 'All',
                                            'assemble': 'All'
                                        }
                                    }}>Search</Link>
                                };
                            }
                            dataSource.push(newData);
                        });
                    }
                }
                this.setState({dataSource, mergeList});
            }
        });
    };

    render() {
        const {dataSource, mergeList} = this.state;
        if (dataSource.length === 0) {
            return <div></div>;
        }

        return (
            <Atoms.CustomTable columns={this.header} dataSource={dataSource} mergeList={mergeList}/>
        );
    }
}

KoInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};


export default KoInfo;

