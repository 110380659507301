import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ClickAwayListener, TextField, Button, withStyles } from '@material-ui/core';
import { Atoms } from 'components';

import { getMataInfo } from 'api/fetch_data';
import { infoColor, whiteColor, hexToRgb } from 'assets/global';
import { compact, trim } from 'lodash';

const styles = {
    textField: {
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: infoColor[0],
            borderRadius: '2px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': `inset 0 0 6px rgba(${hexToRgb(whiteColor)}, 0.25)`,
            background: 'transparent'
        },
    },
    button: {
        textAlign: 'right'
    }
};


class GeneListForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            renderSelectData: [],
            currentGenomeList: [],
            geneInput: '',
            currentValues: {
                species: '',
                genome: ''
            },
        };
    }

    // called by ref
    setValues({ species, genome, geneInput }) {
        const currentGenomeList = this.state.renderSelectData.find(item => item['abbr_name'] === species)['genome_li'];
        this.setState({
            currentGenomeList,
            currentValues: {
                species,
                genome
            },
            geneInput
        });
    }

    componentDidMount() {
        this.renderSelect();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    renderSelect = () => {
        getMataInfo().then(response => {
            if (response && !this.__ignoreLastFetch) {
                this.setState({ renderSelectData: response });
            }
        });
    }

    handleSpeciesChange = (event) => {
        const newValues = { species: event.target.value, genome: '' };
        const currentGenomeList = this.state.renderSelectData.find(item => item['abbr_name'] === event.target.value)['genome_li'];
        this.setState({ currentValues: newValues, currentGenomeList });
    };
    handleGenomeChange = (event) => {
        const newValues = { ...this.state.currentValues, genome: event.target.value };
        this.setState({ currentValues: newValues });
    };
    handleGeneListChange = (event) => {
        this.setState({ geneInput: event.target.value });
    }

    formatGeneList = () => {
        const geneInput = this.state.geneInput;
        this.setState({ geneInput: this.geneStrToIdList(geneInput).join('\n') });
    }

    geneStrToIdList = (geneInput) => {
        const geneIdList = geneInput.split(/[,\n\t ]/);
        return compact(geneIdList.map((geneId) => trim(geneId, ' \'"')));
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const { currentValues: { species, genome }, geneInput } = this.state;
        const geneList = this.geneStrToIdList(geneInput);
        this.props.handleSubmit(species, genome, geneList);
    }

    render() {
        const { classes, handleSubmit, ...other } = this.props;
        const { renderSelectData, currentValues: { species, genome }, geneInput, currentGenomeList } = this.state;
        const speciesOptions = renderSelectData.map(item => ({
            value: item.abbr_name,
            text: item.name
        }));
        const genomeOptions = currentGenomeList.map(item => ({
            value: item.abbr_name,
            text: item.abbr_name
        }));
        return (
            <Grid container spacing={2} {...other}>
                <Grid item xs={12} sm={6} >
                    <Atoms.SelectControl
                        value={species}
                        handleChange={this.handleSpeciesChange}
                        variant="outlined"
                        placeholder="Species"
                        options={speciesOptions}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Atoms.SelectControl
                        value={genome}
                        placeholder="Genome"
                        variant="outlined"
                        options={genomeOptions}
                        handleChange={this.handleGenomeChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ClickAwayListener onClickAway={this.formatGeneList}>
                        <TextField
                            value={geneInput}
                            onChange={this.handleGeneListChange}
                            inputProps={{
                                className: classes.root
                            }}
                            label="ID List"
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={5}
                            disabled={species === '' || genome === ''}
                        />
                    </ClickAwayListener>
                </Grid>
                <Grid container item justify="flex-end" xs={12} spacing={2}>
                    <Grid item xs={12} sm={4} className={classes.button}>
                        <Button
                            onClick={this.handleSubmit}
                            color="primary"
                            variant="outlined"
                            disabled={species === '' || genome === '' || this.geneStrToIdList(geneInput).length === 0}
                        >Submit</Button>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

GeneListForm.propTypes = {
    handleSubmit: PropTypes.func,
    classes: PropTypes.object
};


export default withStyles(styles)(GeneListForm);