import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import { getPlant2AtInfo } from 'api/fetch_data';

class Plant2At extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        };
        this.header = [
            { key: 'hit_name', content: 'TAIR gene' },
            { key: 'align_len', content: 'Alignment length' },
            { key: 'evalue', content: 'E-value' },
            { key: 'score', content: 'Score' },
            { key: 'hit_desc', content: 'Description' }
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { recordType, genomeAbbr, id } = this.props;
        getPlant2AtInfo(genomeAbbr, id, recordType).then(data => {
            if (!this.__ignoreLastFetch) {
                const dataSource = data.map(item => ({
                    hit_name: <a href={`https://www.arabidopsis.org/servlets/Search?type=general&search_action=detail&method=1&show_obsolete=F&name=${item['hit_name']}&sub_type=gene&SEARCH_EXACT=4&SEARCH_CONTAINS=1`}>{item['hit_name']}</a>,
                    align_len: item['align_len'],
                    evalue: item['evalue'],
                    score: item['score'],
                    hit_desc: item['hit_desc'],
                }));
                this.setState({ dataSource });
            }
        });
    };

    render() {
        const { dataSource } = this.state;

        if (dataSource.length === 0){
            return null;
        }
        return (
            <Atoms.InfoGroup title="BLAST to TAIR database">
                <Atoms.CustomTable 
                    columns={this.header}
                    dataSource={dataSource}/>
            </Atoms.InfoGroup>
        );
    }
}

Plant2At.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};


export default Plant2At;

