import React from 'react';
import { SvgIcon, withStyles } from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        height: '100%'
    }
};

function GenomeSytenyIcon({ scale, classes }) {
    let svgScale = scale;
    if (!scale) {
        svgScale = 24;
    }
    return (
        <SvgIcon viewBox={`0 0 ${svgScale} ${svgScale}`} className={scale ? classes.root : undefined}>
            <g transform={`scale(${svgScale/24})`} >
                <g transform="scale(0.0444)">
                    <g transform="translate(15,-315)">
                        <path d="M 247 333.89C 282.19 332.911 313.757 339.512 339 348.89C 347.575 352.075 360.885 352.346 362 362.89C 363.06 368.105 355.814 375.935 353 380.89C 345.804 393.558 338.418 405.855 331 417.89C 326.569 425.077 326.465 432.597 316 433.89C 311.742 430.54 305.889 430.777 300 428.89C 270.476 419.429 229.22 421.568 202 431.89C 149.638 451.746 119.222 483.766 100 536.89C 93.209 555.658 87.974 588.284 94 612.89C 96.9997 623.889 100 634.891 103 645.89C 122.94 693.571 159.795 727.403 211 743.89C 227.644 749.249 261.179 752.58 281 747.89C 287.423 746.37 310.35 737.982 316 739.89C 323.022 742.26 325.296 752.887 329 758.89C 335.793 769.899 357.463 800.419 358 813.89C 357.196 814.911 357.645 814.164 357 815.89C 314.92 842.441 227.136 843.772 176 824.89C 94.4676 794.784 45.9381 743.765 16 661.89C 2.9501 626.2 -1.2539 563.967 11 522.89C 35.3563 441.244 86.4715 383.19 161 351.89C 177.583 344.926 196.331 341.353 215 336.89C 222.933 334.993 241.209 337.506 247 333.89z"/>
                        <path d="M 391 375.89C 393.532 375.964 394.716 376.01 396 376.89C 410.779 381.81 437.821 409.668 447 421.89C 451.362 427.697 457.771 432.871 462 438.89C 483.527 469.524 496.04 503.97 506 546.89C 510.834 567.721 507.237 595.776 507 617.89C 502.717 620.752 501.154 622.029 493 621.89C 488.644 619.12 482.905 620.4 477 618.89C 459.941 614.527 438.064 613.418 422 606.89C 418.459 598.378 422.997 584.078 420 570.89C 418.333 561.557 416.667 552.222 415 542.89C 400.618 498.839 379.234 477.389 349 449.89C 348.98 438.666 354.811 434.696 359 427.89C 369.745 410.43 377.225 390.272 391 375.89z"/>
                        <path d="M 416 635.89C 441.664 640.556 467.336 645.224 493 649.89C 495.636 653.822 497.054 654.502 497 661.89C 489.599 671.71 487.613 689.984 481 700.89C 464.187 728.619 446.398 755.761 421 774.89C 415.743 778.849 395.208 801.005 386 797.89C 379.773 795.783 369.15 769.715 365 762.89C 361.71 757.48 343.458 732.473 345 725.89C 346.621 718.969 366.49 707.387 372 701.89C 382.448 691.467 392.189 677.689 400 664.89C 406.617 654.047 406.978 644.711 416 635.89z"/>
                    </g>
                </g>
                <path d="M 11 1 
                    C 12 6 9.5 12 23 11
                    L 22 9.5
                    C 13 11 12 4 14 1
                    Z"/>
            </g>
        </SvgIcon>
    );
}

export default withStyles(styles)(GenomeSytenyIcon);