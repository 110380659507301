import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Collapse, withStyles } from '@material-ui/core';

import InboxIcon from '@material-ui/icons/Inbox';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import {
    drawerWidth,
    defaultFont,
    infoColor,
    whiteColor,
    grayColor,
    blackColor,
    hexToRgb
} from 'assets/global.jsx';

const styles = theme => ({
    '@font-face': {
        fontFamily: 'Varela Round',
        fontStyle: 'normal',
        fontWeight: 400,
        src: 'url(/Varela_Round.woff2) format("woff2")',
        unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
    },
    sider: {
        position: 'fixed',
        top: '0',
        bottom: '0',
        left: '0',
        zIndex: '1',
        width: drawerWidth,
        overflowY: 'hidden',
        display: 'flex',
        flexDirection: 'column'
    },
    toolbar: theme.mixins.toolbar,
    background: {
        position: 'absolute',
        zIndex: -1,
        height: '100%',
        width: '100%',
        display: 'block',
        top: '0',
        left: '0',
        backgroundImage: 'url(/cotton_bg.jpg)',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        '&:after': {
            position: 'absolute',
            zIndex: '3',
            width: '100%',
            height: '100%',
            content: '""',
            display: 'block',
            background: blackColor,
            opacity: '.8'
        }
    },
    header: {
        ...defaultFont,
        fontSize: '18px',
        textAlign: 'left',
        fontWeight: '400',
        lineHeight: '30px',
        textDecoration: 'none',
        backgroundColor: 'transparent',
        display: 'flex',
        zIndex: 4,
        // justifyContent: 'center',
        alignItems: 'center',
        '&,&:hover': {
            color: whiteColor
        },
    },
    headerLogo: {
        zIndex: 10,
        height: '36px',
        witdh: '36px',
        marginLeft: '15px'
    },
    headerTitle: {
        height: '64px',
        fontFamily: 'Varela Round',
        lineHeight: '64px',
        fontSize: '1.5rem',
        marginLeft: '20px',
        fontWeight: 'bold'
    },
    divider: {
        margin: '0 4%',
        border: 'none',
        borderTop: `1px solid rgba(${hexToRgb(whiteColor)}, 0.75)`,
        zIndex: 2,
    },
    link: {
        textDecoration: 'none',
        color: 'inherit'
    },
    menuList: {
        overflowY: 'auto',
        overflowX: 'hidden',
        zIndex: 6,
        flexGrow: 1,
        '&::-webkit-scrollbar': {
            width: '5px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: `rgba(${hexToRgb(infoColor[0])}, .45)`,
            borderRadius: '2px',
        },
        '&::-webkit-scrollbar-track': {
            background: 'transparent'
        },
    },
    menuItem: {
        color: whiteColor,
        marginTop: '1px',
        marginLeft: '4%',
        borderRadius: '5px',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(200,200,200,0.2)'
        }
    },
    nested: {
        marginLeft: '8%'
    },
    activeMenuItem: {
        color: `${blackColor} !important`,
        backgroundColor: `${grayColor[10]} !important`,
        boxShadow: `0 12px 20px -10px rgba(${hexToRgb(infoColor[0])}, 0.28), 0 4px 20px 0 rgba(${hexToRgb(blackColor)}, 0.12), 0 7px 8px -5px rgba(${hexToRgb(infoColor[0])}, 0.2)`,
        '&:hover,&:focus': {
            backgroundColor: whiteColor,
            boxShadow:
                '0 12px 20px -10px rgba(' +
                hexToRgb(infoColor[0]) +
                ',.28), 0 4px 20px 0 rgba(' +
                hexToRgb(blackColor) +
                ',.12), 0 7px 8px -5px rgba(' +
                hexToRgb(infoColor[0]) +
                ',.2)'
        }
    },
    listIcon: {
        color: 'inherit'
    }

});

class Sider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openKey: ''
        };
    }

    onOpenChange(clickedKey) {
        const { openKey } = this.state;
        if (clickedKey === openKey) {
            this.setState({ openKey: '' });
        } else {
            this.setState({ openKey: clickedKey });
        }
    }

    returnLinkWrap(link, id, Component) {
        const { classes } = this.props;
        if (link !== undefined && link !== '') {
            // NOT GOOD resolution
            return (<Link key={id} to={link} className={classes.link} onClick={() => this.forceUpdate()}>
                {Component}
            </Link>);
        }
        return Component;
    }

    routeIsActive(routeName) {
        const reg = new RegExp(`${routeName}$`);
        return reg.test(window.location.href);
    }

    render() {
        const { classes, menu } = this.props;
        const { openKey } = this.state;
        return (
            <div className={classes.sider}>
                <div className={classes.background} />
                <div className={classes.toolbar}>
                    <div className={classes.header}>
                        <img className={classes.headerLogo} src="/logo.svg" alt="" />
                        <div className={classes.headerTitle}>
                            MaGenDB
                        </div>
                    </div>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.menuList}>
                    <List>
                        {menu.map((menuItem) => {
                            if (menuItem.children && menuItem.children.length > 0) {
                                return (
                                    <React.Fragment key={menuItem.id}>
                                        <ListItem button key={menuItem.id} onClick={() => this.onOpenChange(menuItem.id)} className={classes.menuItem}>
                                            {menuItem.icon !== null && <ListItemIcon className={classes.listIcon}>{menuItem.icon ? menuItem.icon : <InboxIcon />}</ListItemIcon>}
                                            <ListItemText primary={menuItem.text} />
                                            {openKey === menuItem.id ? <ExpandLess /> : <ExpandMore />}
                                        </ListItem>
                                        <Collapse in={openKey === menuItem.id} timeout="auto" >
                                            {menuItem.children.map((subItem) => {
                                                return this.returnLinkWrap(subItem.link, subItem.id,
                                                    <ListItem 
                                                        button 
                                                        className={clsx(classes.nested, classes.menuItem, {[classes.activeMenuItem]: this.routeIsActive(subItem.link)})} >
                                                        {subItem.icon !== null && <ListItemIcon className={classes.listIcon}>{subItem.icon ? subItem.icon : <InboxIcon />}</ListItemIcon>}
                                                        <ListItemText primary={subItem.text} />
                                                    </ListItem>
                                                );
                                            })}
                                        </Collapse>
                                    </React.Fragment>
                                );
                            }
                            return this.returnLinkWrap(menuItem.link, menuItem.id,
                                <ListItem 
                                    button
                                    key={menuItem.id}
                                    className={clsx(classes.menuItem, {[classes.activeMenuItem]: this.routeIsActive(menuItem.link)})}
                                >
                                    {menuItem.icon !== null && <ListItemIcon className={classes.listIcon}>{menuItem.icon ? menuItem.icon : <InboxIcon />}</ListItemIcon>}
                                    <ListItemText primary={menuItem.text} />
                                </ListItem>
                            );
                        })}
                    </List>
                </div>
            </div>
        );
    }


}

Sider.propTypes = {
    classes: PropTypes.object,
    menu: PropTypes.arrayOf(PropTypes.shape({
        text: PropTypes.string,

    }))
};

export default withStyles(styles)(Sider);
