import React from 'react';
import { Atoms, Layouts } from 'components';
import { Button, Grid, ClickAwayListener, TextField } from '@material-ui/core';
import { getTopGo, getMataInfo } from 'api/fetch_data';
import { compact, trim } from 'lodash';
import download from 'tools/download';


export default class TopGoView extends React.Component {
    constructor(props) {
        super(props);
        this.tabsRef = React.createRef();
        this.state = {
            // data to render select-control options
            selectData: [],
            currentGenomeList: [],
            currentAssembleList: [],

            // form values
            species: '',
            genome: '',
            assemble: '',
            geneInput: '',
            backgroundInput: '',
            topNodes: 5
        };
        this.example1 = {
            species: 'gosArb',
            genome: 'CGP',
            assemble: 'IGIA',
            geneList: [
                'Ga01g00014.F1', 'Ga01g00023.F3', 'Ga01g00056.F1', 'Ga01g00068.P1',
                'Ga01g00211.F1', 'Ga01g00286.F1', 'Ga01g00304.F1', 'Ga01g00524.F1',
                'Ga01g00576.F1', 'Ga01g00590.F1', 'Ga01g00685.F1', 'Ga01g00686.F2',
                'Ga01g00745.F1', 'Ga01g00877.F2', 'Ga01g00917.F3', 'Ga01g01134.F3',
                'Ga01g01200.F1', 'Ga01g01265.F1', 'Ga01g01327.F2', 'Ga01g01338.F3',
                'Ga01g00122.F1', 'Ga01g00151.F1', 'Ga01g00153.F1', 'Ga01g00157.F1'
            ],
            backgroundList: [],
            topNodes: 5,
        };
        this.example2 = {
            species: 'gosHir',
            genome: 'JGI_1.1',
            assemble: 'JGI_1.1',
            geneList: [
                'Gohir.A01G018100.1.v1.1', 'Gohir.A01G018300.1.v1.1', 'Gohir.A01G018400.1.v1.1', 'Gohir.A01G018600.1.v1.1',
                'Gohir.A01G071300.1.v1.1', 'Gohir.A01G072000.1.v1.1', 'Gohir.A01G118200.1.v1.1', 'Gohir.A02G008000.2.v1.1',
                'Gohir.A02G127700.1.v1.1', 'Gohir.A02G168000.1.v1.1', 'Gohir.A03G013700.1.v1.1', 'Gohir.A04G001000.1.v1.1',
                'Gohir.A04G013100.1.v1.1', 'Gohir.A04G013200.1.v1.1', 'Gohir.A04G014700.1.v1.1', 'Gohir.A04G014800.1.v1.1',
                'Gohir.A04G016300.1.v1.1', 'Gohir.A04G016400.1.v1.1', 'Gohir.A04G027800.1.v1.1', 'Gohir.A04G031500.1.v1.1',
                'Gohir.A01G013200.1.v1.1', 'Gohir.A01G013300.3.v1.1', 'Gohir.A01G013700.1.v1.1', 'Gohir.A01G014100.1.v1.1'
            ],
            backgroundList: [
                'Gohir.A01G018100.1.v1.1', 'Gohir.A01G018300.1.v1.1', 'Gohir.A01G018400.1.v1.1', 'Gohir.A01G018600.1.v1.1',
                'Gohir.A01G071300.1.v1.1', 'Gohir.A01G072000.1.v1.1', 'Gohir.A01G118200.1.v1.1', 'Gohir.A02G008000.2.v1.1',
                'Gohir.A02G127700.1.v1.1', 'Gohir.A02G168000.1.v1.1', 'Gohir.A03G013700.1.v1.1', 'Gohir.A04G001000.1.v1.1',
                'Gohir.A04G013100.1.v1.1', 'Gohir.A04G013200.1.v1.1', 'Gohir.A04G014700.1.v1.1', 'Gohir.A04G014800.1.v1.1',
                'Gohir.A04G016300.1.v1.1', 'Gohir.A04G016400.1.v1.1', 'Gohir.A04G027800.1.v1.1', 'Gohir.A04G031500.1.v1.1',
                'Gohir.A01G013200.1.v1.1', 'Gohir.A01G013300.3.v1.1', 'Gohir.A01G013700.1.v1.1', 'Gohir.A01G014100.1.v1.1',
                'Gohir.D13G219100.1.v1.1', 'Gohir.D13G219100.2.v1.1', 'Gohir.D13G219200.1.v1.1', 'Gohir.D13G219200.2.v1.1',
                'Gohir.D13G219300.1.v1.1', 'Gohir.D13G219300.2.v1.1', 'Gohir.D13G219400.1.v1.1', 'Gohir.D13G219600.1.v1.1',
                'Gohir.D13G219800.1.v1.1', 'Gohir.D13G219900.1.v1.1', 'Gohir.D13G220200.1.v1.1', 'Gohir.D13G220300.1.v1.1',
                'Gohir.D13G220300.2.v1.1', 'Gohir.D13G220300.3.v1.1', 'Gohir.D13G220400.1.v1.1', 'Gohir.D13G220400.2.v1.1',
                'Gohir.D13G220600.1.v1.1', 'Gohir.D13G220700.1.v1.1', 'Gohir.D13G220900.1.v1.1', 'Gohir.D13G221000.1.v1.1',
                'Gohir.D13G221100.1.v1.1', 'Gohir.D13G221100.2.v1.1', 'Gohir.D13G221200.1.v1.1', 'Gohir.D13G221200.2.v1.1',
                'Gohir.D13G221400.1.v1.1', 'Gohir.D13G221600.1.v1.1', 'Gohir.D13G221600.2.v1.1', 'Gohir.D13G221700.1.v1.1',
                'Gohir.D13G221800.1.v1.1', 'Gohir.D13G221900.1.v1.1', 'Gohir.D13G222000.1.v1.1', 'Gohir.D13G222100.1.v1.1',
                'Gohir.D13G222200.1.v1.1', 'Gohir.D13G222200.2.v1.1', 'Gohir.D13G222400.1.v1.1', 'Gohir.D13G222400.2.v1.1',
                'Gohir.D13G222600.1.v1.1', 'Gohir.D13G222800.1.v1.1', 'Gohir.D13G222900.1.v1.1', 'Gohir.D13G223000.1.v1.1',
                'Gohir.D13G223100.1.v1.1', 'Gohir.D13G223200.1.v1.1', 'Gohir.D13G223300.1.v1.1', 'Gohir.D13G223400.1.v1.1',
                'Gohir.D13G223500.1.v1.1', 'Gohir.D13G223600.1.v1.1', 'Gohir.D13G223700.1.v1.1', 'Gohir.D13G224000.1.v1.1',
                'Gohir.D13G224100.1.v1.1', 'Gohir.D13G224100.2.v1.1', 'Gohir.D13G224100.3.v1.1', 'Gohir.D13G224100.4.v1.1',
                'Gohir.D13G224100.5.v1.1', 'Gohir.D13G224200.1.v1.1', 'Gohir.D13G224300.1.v1.1', 'Gohir.D13G224400.1.v1.1',
                'Gohir.D13G224500.1.v1.1', 'Gohir.D13G224500.2.v1.1', 'Gohir.D13G224600.1.v1.1', 'Gohir.D13G224800.1.v1.1',
                'Gohir.D13G224900.1.v1.1', 'Gohir.D13G224900.2.v1.1', 'Gohir.D13G224900.3.v1.1', 'Gohir.D13G225000.1.v1.1',
                'Gohir.D13G225100.1.v1.1', 'Gohir.D13G225300.1.v1.1', 'Gohir.D13G225300.2.v1.1', 'Gohir.D13G225600.1.v1.1',
                'Gohir.D13G225700.1.v1.1', 'Gohir.D13G225800.1.v1.1', 'Gohir.D13G225800.2.v1.1', 'Gohir.D13G225800.3.v1.1',
                'Gohir.D13G225800.4.v1.1', 'Gohir.D13G225800.5.v1.1', 'Gohir.D13G225900.1.v1.1', 'Gohir.D13G226000.1.v1.1',
                'Gohir.D13G226200.1.v1.1', 'Gohir.D13G226400.1.v1.1', 'Gohir.D13G226600.1.v1.1', 'Gohir.D13G227000.1.v1.1',
                'Gohir.D13G227300.1.v1.1', 'Gohir.D13G227400.1.v1.1', 'Gohir.D13G227500.1.v1.1', 'Gohir.D13G227700.1.v1.1',
                'Gohir.D13G227800.1.v1.1', 'Gohir.D13G227900.1.v1.1', 'Gohir.D13G228000.1.v1.1', 'Gohir.D13G228100.1.v1.1',
                'Gohir.D13G228100.2.v1.1', 'Gohir.D13G228200.1.v1.1', 'Gohir.D13G228400.1.v1.1', 'Gohir.D13G228400.2.v1.1',
                'Gohir.D13G228500.1.v1.1', 'Gohir.D13G228600.1.v1.1', 'Gohir.D13G228700.1.v1.1', 'Gohir.D13G228800.1.v1.1',
                'Gohir.D13G228900.1.v1.1', 'Gohir.D13G229000.1.v1.1', 'Gohir.D13G229000.2.v1.1', 'Gohir.D13G229100.1.v1.1',
                'Gohir.D13G229200.1.v1.1', 'Gohir.D13G229300.1.v1.1', 'Gohir.D13G229400.1.v1.1', 'Gohir.D13G229400.2.v1.1',
                'Gohir.D13G229400.3.v1.1', 'Gohir.D13G229600.1.v1.1', 'Gohir.D13G229700.1.v1.1', 'Gohir.D13G229700.2.v1.1',
                'Gohir.D13G229800.1.v1.1', 'Gohir.D13G230000.1.v1.1', 'Gohir.D13G230200.1.v1.1', 'Gohir.D13G230200.2.v1.1',
                'Gohir.D13G230400.1.v1.1', 'Gohir.D13G230500.1.v1.1', 'Gohir.D13G230600.1.v1.1', 'Gohir.D13G230700.1.v1.1',
                'Gohir.D13G231000.1.v1.1', 'Gohir.D13G231100.1.v1.1', 'Gohir.D13G231100.2.v1.1', 'Gohir.D13G231100.3.v1.1',
                'Gohir.D13G231200.1.v1.1', 'Gohir.D13G231300.1.v1.1', 'Gohir.D13G231400.1.v1.1', 'Gohir.D13G232000.1.v1.1',
                'Gohir.D13G232000.2.v1.1', 'Gohir.D13G232000.3.v1.1', 'Gohir.D13G232100.1.v1.1', 'Gohir.D13G232300.1.v1.1',
                'Gohir.D13G232400.1.v1.1', 'Gohir.D13G232600.1.v1.1', 'Gohir.D13G232700.1.v1.1', 'Gohir.D13G232700.2.v1.1',
                'Gohir.D13G232800.1.v1.1', 'Gohir.D13G232900.1.v1.1', 'Gohir.D13G233000.1.v1.1', 'Gohir.D13G233100.1.v1.1',
                'Gohir.D13G233200.1.v1.1', 'Gohir.D13G233300.1.v1.1', 'Gohir.D13G233300.2.v1.1', 'Gohir.D13G233400.1.v1.1',
                'Gohir.D13G233500.1.v1.1', 'Gohir.D13G233600.1.v1.1', 'Gohir.D13G233700.1.v1.1', 'Gohir.D13G233700.2.v1.1',
                'Gohir.D13G233700.3.v1.1', 'Gohir.D13G233800.1.v1.1', 'Gohir.D13G233800.2.v1.1', 'Gohir.D13G233900.1.v1.1',
                'Gohir.D13G234000.1.v1.1', 'Gohir.D13G234100.1.v1.1', 'Gohir.D13G234200.1.v1.1', 'Gohir.D13G234300.1.v1.1',
                'Gohir.D13G234400.1.v1.1', 'Gohir.D13G234500.1.v1.1', 'Gohir.D13G234500.2.v1.1', 'Gohir.D13G234600.1.v1.1',
                'Gohir.D13G234800.1.v1.1', 'Gohir.D13G234900.1.v1.1', 'Gohir.D13G235000.1.v1.1', 'Gohir.D13G235000.2.v1.1',
                'Gohir.D13G235100.1.v1.1', 'Gohir.D13G235200.1.v1.1', 'Gohir.D13G235200.2.v1.1', 'Gohir.D13G235300.1.v1.1',
                'Gohir.D13G235300.2.v1.1', 'Gohir.D13G235400.1.v1.1', 'Gohir.D13G235500.1.v1.1', 'Gohir.D13G235600.1.v1.1',
                'Gohir.D13G235600.2.v1.1', 'Gohir.D13G235700.1.v1.1', 'Gohir.D13G235700.2.v1.1', 'Gohir.D13G235700.3.v1.1',
                'Gohir.D13G235700.4.v1.1', 'Gohir.D13G235700.5.v1.1', 'Gohir.D13G235800.1.v1.1', 'Gohir.D13G235900.1.v1.1',
                'Gohir.D13G236000.1.v1.1', 'Gohir.D13G236200.1.v1.1', 'Gohir.D13G236400.1.v1.1', 'Gohir.D13G236500.1.v1.1',
                'Gohir.D13G236600.1.v1.1', 'Gohir.D13G236900.1.v1.1', 'Gohir.D13G237100.1.v1.1', 'Gohir.D13G237500.1.v1.1',
                'Gohir.D13G237600.1.v1.1', 'Gohir.D13G237800.1.v1.1', 'Gohir.D13G237800.2.v1.1', 'Gohir.D13G237900.1.v1.1',
                'Gohir.D13G238000.1.v1.1', 'Gohir.D13G238200.1.v1.1', 'Gohir.D13G238300.1.v1.1', 'Gohir.D13G238400.1.v1.1',
                'Gohir.D13G238500.1.v1.1', 'Gohir.D13G238600.1.v1.1', 'Gohir.D13G238700.1.v1.1', 'Gohir.D13G238800.1.v1.1',
                'Gohir.D13G238800.2.v1.1', 'Gohir.D13G238900.1.v1.1', 'Gohir.D13G239000.1.v1.1', 'Gohir.D13G239100.1.v1.1',
                'Gohir.D13G239200.1.v1.1', 'Gohir.D13G239400.1.v1.1', 'Gohir.D13G239500.1.v1.1', 'Gohir.D13G239500.2.v1.1',
                'Gohir.D13G239600.1.v1.1', 'Gohir.D13G239700.1.v1.1', 'Gohir.D13G239900.1.v1.1', 'Gohir.D13G239900.2.v1.1',
                'Gohir.D13G240000.1.v1.1', 'Gohir.D13G240100.1.v1.1', 'Gohir.D13G240100.2.v1.1', 'Gohir.D13G240200.1.v1.1',
                'Gohir.D13G240300.1.v1.1', 'Gohir.D13G240400.1.v1.1', 'Gohir.D13G240500.1.v1.1', 'Gohir.D13G240700.1.v1.1',
                'Gohir.D13G240800.1.v1.1', 'Gohir.D13G240900.1.v1.1', 'Gohir.D13G241000.1.v1.1', 'Gohir.D13G241100.1.v1.1',
                'Gohir.D13G241200.1.v1.1', 'Gohir.D13G241300.1.v1.1', 'Gohir.D13G241400.1.v1.1', 'Gohir.D13G241500.1.v1.1',
                'Gohir.D13G241700.1.v1.1', 'Gohir.D13G242100.1.v1.1', 'Gohir.D13G242300.1.v1.1', 'Gohir.D13G242500.1.v1.1'
            ],
            topNodes: 5,
        };
    }

    componentDidMount() {
        this.renderSelect();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    renderSelect = async () => {
        const data = await getMataInfo();
        if (data && !this.__ignoreLastFetch) {
            this.setState({ selectData: data });
        }
    }
    handleSpeciesChange = (event) => {
        const species = event.target.value;
        const currentGenomeList = this.state.selectData.find(item => item['abbr_name'] === species)['genome_li'];
        this.setState({ species, genome: '', assemble: '', currentGenomeList, currentAssembleList: [] });
    };
    handleGenomeChange = (event) => {
        const genome = event.target.value;
        const currentAssembleList = this.state.currentGenomeList.find(item => item['abbr_name'] === genome)['assemble_li'];
        this.setState({ genome, assemble: '', currentAssembleList });
    };
    handleAssembleChange = (event) => this.setState({ assemble: event.target.value });
    handleGeneListChange = (event) => this.setState({ geneInput: event.target.value });
    handleBackgroundListChange = (event) => this.setState({ backgroundInput: event.target.value });


    formatGeneList = () => {
        const { geneInput, backgroundInput } = this.state;
        this.setState({
            geneInput: this.geneStrToIdList(geneInput).join('\n'),
            backgroundInput: this.geneStrToIdList(backgroundInput).join('\n'),
        });
    }


    useExample = (example) => (event) => {
        const { species, genome, assemble, geneList, backgroundList, topNodes } = example;
        const currentGenomeList = this.state.selectData.find(item => item['abbr_name'] === species)['genome_li'];
        const currentAssembleList = currentGenomeList.find(item => item['abbr_name'] === genome)['assemble_li'];
        this.tabsRef.current.handleChange(event, 0);
        this.setState({ 
            species, genome, assemble, currentGenomeList, currentAssembleList, topNodes,
            geneInput: geneList.join('\n'),
            backgroundInput: backgroundList.join('\n'),
        });
    }

    geneStrToIdList = (geneInput) => {
        const geneIdList = geneInput.split(/[,\n\t ]/);
        return compact(geneIdList.map((geneId) => trim(geneId, ' \'"')));
    }

    handleSubmit = async () => {
        const { assemble, geneInput, backgroundInput, topNodes } = this.state;
        const geneList = this.geneStrToIdList(geneInput);
        const backgroundList = this.geneStrToIdList(backgroundInput);
        const res = await getTopGo(assemble, geneList, backgroundList, topNodes);
        const blob = await res.blob();
        if (res.status === 200) {
            const filename = 'topGO.zip';
            // const filename = res.headers.get('Content-Disposition').split('=')[1];
            download(blob, filename);
        }
    };

    render() {
        const { selectData, species, genome, assemble, currentGenomeList, currentAssembleList, geneInput, backgroundInput } = this.state;
        const speciesOptions = selectData.map(item => ({
            value: item.abbr_name,
            text: item.name
        }));
        const genomeOptions = currentGenomeList.map(item => ({
            value: item.abbr_name,
            text: item.abbr_name
        }));
        const assembleOptions = currentAssembleList.map(item => ({
            value: item,
            text: item
        }));
        return (
            <Layouts.MainLayoutContent headerText="GO enrichment">
                <Atoms.ExampleCard
                    ref={this.tabsRef}
                    title="GO"
                    formContent={<Grid container spacing={2}>
                        <Grid item xs={12} md={4}>
                            <Atoms.SelectControl
                                value={species}
                                handleChange={this.handleSpeciesChange}
                                placeholder="Species"
                                options={speciesOptions}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Atoms.SelectControl
                                value={genome}
                                placeholder="Genome"
                                options={genomeOptions}
                                handleChange={this.handleGenomeChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Atoms.SelectControl
                                value={assemble}
                                placeholder="Gene model"
                                options={assembleOptions}
                                handleChange={this.handleAssembleChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ClickAwayListener onClickAway={this.formatGeneList}>
                                <TextField
                                    value={geneInput}
                                    onChange={this.handleGeneListChange}
                                    label="ID List"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    disabled={species === '' || genome === '' || assemble === ''}
                                />
                            </ClickAwayListener>
                        </Grid>
                        <Grid item xs={12}>
                            <ClickAwayListener onClickAway={this.formatGeneList}>
                                <TextField
                                    value={backgroundInput}
                                    onChange={this.handleBackgroundListChange}
                                    label="Background List"
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={5}
                                    disabled={species === '' || genome === '' || assemble === ''}
                                />
                            </ClickAwayListener>
                        </Grid>

                        <Grid container item justify="flex-end" xs={12} spacing={2}>
                            <Grid item xs={12} sm={4} style={{ textAlign: 'right' }}>
                                <Button
                                    onClick={this.handleSubmit}
                                    color="primary"
                                    variant="outlined"
                                    disabled={species === '' || genome === '' || assemble === '' || this.geneStrToIdList(geneInput).length === 0}
                                >Submit</Button>
                            </Grid>
                        </Grid>
                    </Grid>}
                    exampleContent={<div>
                        <Button onClick={this.useExample(this.example2)}>Example</Button>
                    </div>}
                />
            </Layouts.MainLayoutContent>
        );
    }
}

