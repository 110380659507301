import React from 'react';
import PropTypes from 'prop-types';
import { Select, MenuItem, FormControl, makeStyles, OutlinedInput, InputLabel } from '@material-ui/core';

const useStyles = makeStyles({
    formControl: {
    }
});

function SelectControl({ value, options, placeholder, handleChange, disabled, variant, ...otherProps }) {
    const classes = useStyles();
    const inputLabel = React.useRef(null);
    const [labelWidth, setLabelWidth] = React.useState(0);
    React.useEffect(() => {
        setLabelWidth(inputLabel.current.offsetWidth);
    }, []);

    return (
        <FormControl disabled={disabled} className={classes.formControl} fullWidth variant={variant}>
            <InputLabel ref={inputLabel} htmlFor={`outlined-${placeholder}`}>
                {placeholder}
            </InputLabel>
            <Select
                value={value}
                onChange={handleChange}
                input={variant === 'outlined' ? <OutlinedInput labelWidth={labelWidth} name={placeholder} id={`outlined-${placeholder}`} /> : undefined}
                {...otherProps}
            >
                <MenuItem value="" disabled>
                    <em>None</em>
                </MenuItem>
                {options.map((option, index) => <MenuItem key={index} value={option.value}>{option.text}</MenuItem>)}
            </Select>
        </FormControl>
    );
}


SelectControl.propTypes = {
    value: PropTypes.any,
    options: PropTypes.arrayOf(PropTypes.object),
    placeholder: PropTypes.string,
    handleChange: PropTypes.func,
    disabled: PropTypes.bool,
    variant: PropTypes.oneOf(['standard', 'outlined', 'filled'])
};

export default SelectControl;