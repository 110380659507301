import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Axis, Coord, Guide } from 'bizcharts';
import { chartColors as colors } from 'assets/global';
import { isEqual } from 'lodash';


function GeneStructureWithCover({ dataSource, bpStart, bpEnd, heightPerDataGroup, width, padding, primaryCoverRange, secondaryCoverRange, withBpLabel }) {
    const data = [];
    dataSource.forEach((geneGroup, index) => {
        geneGroup.blocks.forEach(gene => {
            data.push({
                index: index,
                range: [gene[0], gene[1]],
                group: geneGroup.group,
                name: geneGroup.name,
                strand: geneGroup.strand
            });
        });
    });

    const colorDict = {};
    let iter = 0;
    dataSource.forEach(geneGroup => {
        if (!(geneGroup.group in colorDict)) {
            colorDict[geneGroup.group] = colors[iter];
            iter++;
        }
    });

    const scale = {
        index: {
            type: 'cat',
            alias: 'name',
            ticks: Array.from(new Array(dataSource.length).keys()),
        },
        range: {
            nice: false,
            minLimit: -1,
            maxLimit: bpEnd,
            min: bpStart,
            max: bpEnd
        }
    };


    return (
        <Chart
            height={heightPerDataGroup * (dataSource.length) + padding[0] + padding[2]}
            width={width}
            data={data}
            scale={scale}
            padding={padding}
            onIntervalClick={(ev) => {
                console.log(ev);
            }}
            forceFit
        >
            <Coord transpose />
            <Axis
                name="index"
                tickLine={null}
                label={{
                    textStyle: {
                        fontSize: '12',
                        fill: '#000',
                        fontWeight: 'bold',
                    },
                    position: 'center',
                    formatter: (text, item, index) => {
                        const axisLabel = data.find(set => set.index === index);
                        if (axisLabel) {
                            if (axisLabel.group === 'empty' && axisLabel.name === 'empty') {
                                return '';
                            }
                            return axisLabel.name;
                        }
                    }
                }} />
            <Axis
                name="range"
                label={withBpLabel ? {
                    offset: 20,
                    textStyle: {
                        fontSize: '12',
                        fill: '#000',
                        fontWeight: 'bold',
                    },
                } : null}
            />
            <Geom
                type="interval"
                position="index*range"
                color={['group', colors]}
                /** shape={['strand*group', (strand, group) => {
                    if (group === 'empty') {
                        return 'interval';
                    }
                    if (strand === '-') {
                        return 'geneblockreverse';
                    } else {
                        return 'geneblock';
                    }
                }]}*/
                size={6}
                animate={{
                    appear: {
                        animation: 'fanIn',
                        delay: 5,
                        duration: 10
                    },
                    update: {
                        animation: 'fanIn',
                        delay: 5,
                        duration: 10
                    },
                    enter: {
                        animation: 'fanIn',
                        delay: 5,
                        duration: 10
                    }
                }}
            />

            <Guide>
                {dataSource.map((geneGroup, index) =>
                    (geneGroup.group === 'empty' && geneGroup.name === 'empty') ? null : (
                        <Guide.Line
                            key={index}
                            start={{
                                index: index,
                                range: geneGroup.start
                            }}
                            end={{
                                index: index,
                                range: geneGroup.end
                            }}
                            lineStyle={{
                                stroke: colorDict[geneGroup.group],
                                lineWidth: 1,
                                lineDash: [0, 0, 0]
                            }}
                        />
                    ))}
                <Guide.Region
                    top
                    start={['min', primaryCoverRange[0]]}
                    end={['max', primaryCoverRange[1]]}
                    style={{
                        lineWidth: 0,
                        fill: '#f44336',
                        fillOpacity: 0.5,
                        stroke: '#ccc'
                    }}
                />
                <Guide.Region
                    top
                    start={['min', secondaryCoverRange[0]]}
                    end={['max', secondaryCoverRange[1]]}
                    style={{
                        lineWidth: 0,
                        fill: '#2196f3',
                        fillOpacity: 0.5,
                        stroke: '#ccc'
                    }}
                />
            </Guide>
        </Chart>
    );
}



GeneStructureWithCover.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({
        group: PropTypes.string,
        name: PropTypes.string,
        start: PropTypes.number,
        end: PropTypes.number,
        strand: PropTypes.oneOf(['-', '+']),
        blocks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    })),
    bpStart: PropTypes.number,
    bpEnd: PropTypes.number,
    heightPerDataGroup: PropTypes.number,
    width: PropTypes.number,
    padding: PropTypes.arrayOf(PropTypes.number),
    primaryCoverRange: PropTypes.arrayOf(PropTypes.number),
    secondaryCoverRange: PropTypes.arrayOf(PropTypes.number),
    withBpLabel: PropTypes.bool
};


export default React.memo(GeneStructureWithCover, isEqual);