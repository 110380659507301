import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { withStyles, Paper } from '@material-ui/core';

const styles = theme => ({
    root: {
        maxWidth: window.innerWidth - 300,
        display: 'flex',
        padding: '20px 60px',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        
        '& table': {
            alignSelf: 'center',
            tableLayout: 'fixed',
            width: '80%',
            borderTop: '2px solid #000',
            borderBottom: '2px solid #000',
            borderCollapse: 'collapse',
            '& thead': {
                '& tr':{
                    borderBottom: '1px solid #000'

                }
            }
        }
    }
});

class Markdown extends React.Component {
    state = {
        text: '# Document is Loading...'
    }
    componentDidMount() {
        const { getFile, fileName } = this.props;
        getFile(fileName).then(text => this.setState({text}));
    }
    render() {
        return(
            <Paper className={ this.props.classes.root } >
                <ReactMarkdown source={this.state.text} escapeHtml={false} />
            </Paper>
        );
    }
    
}

Markdown.propTypes = {
    classes: PropTypes.object,
    getFile: PropTypes.func,
    fileName: PropTypes.string
};


export default withStyles(styles)(Markdown);