/* eslint-disable react/no-multi-comp */
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
    MenuList, MenuItem, ListItemIcon, Typography, 
    Button, Link, makeStyles
} from '@material-ui/core';

import { primaryColor, blackColor, hexToRgb } from 'assets/global';


import CompareIcon from 'components/icons/CompareIcon';
import SearchIcon from 'components/icons/SearchIcon';
import JbrowseIcon from 'components/icons/JbrowseIcon';
import DownloadIcon from 'components/icons/DownloadIcon';
import DocumentIcon from 'components/icons/DocumentIcon';
import GenomeInfoIcon from 'components/icons/GenomeInfoIcon';


const menu = [
    {
        key: 'menu-item-1',
        url: '/genome/gosHir',
        text: 'Genomes',
        icon: GenomeInfoIcon
    }, {
        key: 'menu-item-2',
        url: '/search',
        text: 'Search',
        icon: SearchIcon
    }, {
        key: 'menu-item-3',
        url: '/jbrowse?species_abbr=gosArb&genome_abbr=CGP',
        text: 'Genome Browser',
        icon: JbrowseIcon
    }, {
        key: 'menu-item-4',
        url: '/compare',
        text: 'Tools',
        icon: CompareIcon
    }, {
        key: 'menu-item-5',
        url: '/downloads',
        text: 'Downloads',
        icon: DownloadIcon
    }, {
        key: 'menu-item-6',
        url: '/document',
        text: 'User Guide',
        icon: DocumentIcon
    }
];

export function HomeDrawer(){
    const classes = makeStyles({
        link: {
            color: `rgba(${hexToRgb(blackColor)}, 0.8)`,
            '&:hover': {
                color: primaryColor[0]
            }
        },
        icon: {
            color: 'inherit'
        },
        text: {
            fontWeight: 'bold'
        }
    })();
    return(<MenuList>
        {menu.map(item => (
            <Link
                key={item.key}
                className={classes.link}
                underline="none"
                component={RouterLink}
                to={item.url}
            >
                <MenuItem>
                    <ListItemIcon className={classes.icon}><item.icon color="inherit" /></ListItemIcon>
                    <Typography variant="inherit" className={classes.text}>{item.text}</Typography>
                </MenuItem>
            </Link>
        ))}
    </MenuList>);
}

export function HomeNavbarLink() {
    const classes = makeStyles({
        container: {
            height: '100%'
        },
        button: {
            color: 'inherit',
            '&:hover, &:focus': {
                color: primaryColor[0],
            }
        },
        link: {
            color: 'inherit',
        }
    })();
    
    return(<div className={classes.container}>
        {menu.map(item => (
            <Button key={item.key} color="inherit" className={classes.button}>
                <Link
                    className={classes.link}
                    component={RouterLink}
                    underline="none"
                    to={item.url}
                >{item.text}</Link>
            </Button>
        ))}
    </div>);
}