import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {Atoms, Charts} from 'components';
import {getCddDomain} from 'api/fetch_data';

class IvalCddDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            plotData: []
        };
        this.header = [
            {key: 'CddId', content: 'CDD ID'},
            {key: 'Desc', content: 'Description'},
            {key: 'start', content: 'Start'},
            {key: 'end', content: 'End'},
            {key: 'evalue', content: 'E-value'},
            {key: 'score', content: 'Score'},
            {key: 'search', content: 'Search same domain gene'},
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const {recordType, genomeAbbr, id} = this.props;
        getCddDomain(genomeAbbr, id, recordType).then(data => {
            if (!this.__ignoreLastFetch) {
                const dataSource = data.map(item => ({
                    CddId: <a
                        href={`https://www.ncbi.nlm.nih.gov/Structure/cdd/cddsrv.cgi?uid=${item['CddId']}`}>{item['CddId']}</a>,
                    Desc: item['Desc'],
                    start: item['start'],
                    end: item['end'],
                    evalue: item['evalue'],
                    score: item['score'],
                    search: <Link to={{
                        pathname: '/search/cdd_domain',
                        state: {
                            'searchText': item['CddId'].toString(),
                            "species": "All",
                            "genome": "All",
                            "assemble": "All"
                        }
                    }}>Search</Link>
                }));
                const plotData = data.map(item => ({
                    group: 'CDD',
                    start: item['start'],
                    end: item['end'],
                    strand: item['strand'],
                    name: item['CddId'],
                    blocks: [[item['start'], item['end']]],
                }));
                this.setState({dataSource, plotData});
            }
        });
    };

    render() {
        const {dataSource, plotData} = this.state;
        const {recordType, ival} = this.props;

        if (dataSource.length === 0) {
            return null;
        }

        const edix = {
            'Trans': ival.exon_len,
            'Prot': ival.aa_num
        };
        return (
            <Atoms.ExpansionInfoCard
                title="CDD domain"
                mainContent={
                    <Charts.GeneStructure
                        bpStart={0}
                        bpEnd={edix[recordType]}
                        heightPerDataGroup={20}
                        width={1000}
                        padding={[10, 50, 30, 150]}
                        dataSource={plotData}
                    />
                }
                expansionContent={
                    <Atoms.CustomTable
                        columns={this.header}
                        dataSource={dataSource}/>
                }
            />
        );
    }
}

IvalCddDomain.propTypes = {
    ival: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default IvalCddDomain;

