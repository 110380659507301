import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Table, TableBody, TableCell, TableHead, TableRow, Radio, IconButton } from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import RadioTableHeader from './RadioTableHeader';


const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        minWidth: 1020,
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

class RadioTable extends React.Component {
    handleClick = (event, id) => {
        event.preventDefault();
        const item = this.props.dataSource.find(item => item.id === id);
        if (item && item.withRadio) {
            this.props.changeSelectedKey(id);
        }
    }

    handleClear = () => this.props.changeSelectedKey(undefined);

    isSelected = id => this.props.selectedKey === id;

    render() {
        const { classes, dataSource, selectedKey, columns, headerText, onOptionButtonClick } = this.props;
        const item = dataSource.find(item => selectedKey === item.id);
        const itemName = item ? item.alias : undefined;
        return (
            <React.Fragment>
                <RadioTableHeader selectedItemName={itemName} text={headerText} takeOption={onOptionButtonClick}/>
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" >
                                    <IconButton aria-label="Clear" onClick={this.handleClear}>
                                        <ClearIcon />
                                    </IconButton>
                                </TableCell>
                                {columns.map((title, index) => (
                                    <TableCell key={index}>
                                        {title.content}
                                    </TableCell>
                                ), this)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataSource.map((row) => {
                                const isSelected = this.isSelected(row.id);
                                return (
                                    <TableRow
                                        hover
                                        onClick={event => this.handleClick(event, row.id)}
                                        role="checkbox"
                                        aria-checked={isSelected}
                                        tabIndex={-1}
                                        key={row.id}
                                        selected={isSelected}
                                    >
                                        <TableCell padding="checkbox">
                                            {row.withRadio ? <Radio color="primary" checked={isSelected} /> : null}
                                        </TableCell>
                                        {columns.map((title, index) =>
                                            <TableCell key={index}>
                                                {row[title.key]}
                                            </TableCell>)}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            </React.Fragment>
        );
    }
}

RadioTable.propTypes = {
    classes: PropTypes.object.isRequired,
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    columns: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        content: PropTypes.any
    })).isRequired,
    selectedKey: PropTypes.number,
    headerText: PropTypes.string,
    changeSelectedKey: PropTypes.func,
    onOptionButtonClick: PropTypes.func
};

export default withStyles(styles)(RadioTable);