import React from 'react';
import PropTypes from 'prop-types';
import { Paper, GridList, GridListTile, GridListTileBar, IconButton, Typography } from '@material-ui/core';
import SearchForm from './components/SearchForm';
import SimpleSearchForm from './components/SimpleSearchForm';

import { Atoms, Layouts } from 'components';
import { getSearchSuggestInfo, getSearchInfo } from 'api/fetch_data';
// 引入去抖
import AwesomeDebouncePromise from 'awesome-debounce-promise';

import SearchIcon from '@material-ui/icons/Search';
import NearMeIcon from '@material-ui/icons/NearMe';
import InfoIcon from '@material-ui/icons/Info';


import SearchViewExample from 'example/SearchExample';


class SearchView extends React.Component {
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.simpleFormRef = React.createRef();
        this.state = {
            tableLoading: false,
            dataSource: [],
            showExampleDialog: false,
            activeIndex: 0,
            pagination: {
                count: 0,
                rowsPerPage: 30,
                page: 1,
            }
        };
        this.header = [
            { key: 'species', content: 'Species' },
            { key: 'genome', content: 'Genome' },
            { key: 'locus', content: 'Gene locus' },
            { key: 'trans', content: 'Transcript' },
            { key: 'prot', content: 'Protein' },
        ];
        this.searchName = {
            name: 'Name',
            go: 'Gene Ontology',
            ko: 'KEGG Ontology',
            ec: 'Enzyme Commission number',
            interpro_domain: 'InterPro domain',
            pfam_domain: 'Pfam domain',
            cdd_domain: 'Conserved domain',
            tf_family: 'TF family',
            eggNOG_gene_family: 'Gene family',
            pathway: 'KEGG pathway',
            model: 'KEGG model',
            disease: 'KEGG disease',
        };

        this.correspond = {
            locus: 'gene',
            trans: 'transcript',
            prot: 'protein'
        };
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    getSuggestions(searchField, species = 'All', genome = 'All', assemble = 'All', data) {
        return getSearchSuggestInfo(searchField, data, species, genome, assemble);
    }

    applyExample = ({ searchField, species, genome, assemble, searchText }) => (event) => {
        event.preventDefault();
        this.formRef.current.setValues(event, { searchField, species, genome, assemble, searchText });
        this.setState({ activeIndex: 1 });
        this.handleDialogClose();
    };

    renderTableCell(cellIndex, item) {
        if (!item[cellIndex + '_abbr']) {
            return '-';
        }
        return (
            <Atoms.CustomLink
                style={{ fontWeight: item.hit === cellIndex ? 'bold' : 'inherit' }}
                to={`/wiki/${this.correspond[cellIndex]}/${item.species_abbr}/${item.genome_abbr}/${item[cellIndex + '_abbr']}`}
            >
                {item[`${cellIndex}_name`]}
            </Atoms.CustomLink>
        );
    }

    handleSearch = async ({ searchField, searchText, species, genome, assemble }, current, rowsPerPage) => {
        this.setState({ tableLoading: true });
        const res = await getSearchInfo(searchField, searchText, species, genome, assemble, current, rowsPerPage);
        if (res && !this.__ignoreLastFetch) {
            const newPagination = {
                ...this.state.pagination,
                count: res['hit_num'],
                page: res['page'],
                rowsPerPage: res['page_size']
            };
            this.setState({
                dataSource: res['data'].map((item) => ({
                    species: item.species_name,
                    genome: item.genome_abbr,
                    locus: this.renderTableCell('locus', item),
                    trans: this.renderTableCell('trans', item),
                    prot: this.renderTableCell('prot', item),
                })),
                pagination: newPagination,
            });
        }
        this.setState({ tableLoading: false });
    }

    handleDialogOpen = () => this.setState({ showExampleDialog: true });
    handleDialogClose = () => this.setState({ showExampleDialog: false });
    handleTabChange = (event, value) => this.setState({ activeIndex: value });

    onChangePage = (event, newPage) => {
        const { pagination } = this.state;
        // material table返回的page从0开始  +1保持与后端一致
        if (newPage + 1 !== pagination.page) {
            if (this.state.activeIndex === 0) {
                this.simpleFormRef.current.performSearch(null, newPage + 1, pagination.rowsPerPage);
            } else {
                this.formRef.current.performSearch(null, newPage + 1, pagination.rowsPerPage);
            }
        }
    };

    render() {
        const { filterParams } = this.props;
        const { dataSource, pagination, tableLoading, showExampleDialog, activeIndex } = this.state;

        return (
            <Layouts.MainLayoutContent headerText="Search Gene">
                <Atoms.TabsCard
                    title="Search"
                    activeIndex={activeIndex}
                    handleTabChange={this.handleTabChange}
                    headerColor="primary"
                    tabs={[{
                        tabName: 'Simple Search',
                        tabIcon: NearMeIcon,
                        tabContent: (<div>
                            <SimpleSearchForm
                                ref={this.simpleFormRef}
                                handleSearch={this.handleSearch}
                                getSuggestions={AwesomeDebouncePromise(this.getSuggestions, 500)}
                            />
                            <Typography variant="body1">
                                <InfoIcon style={{ verticalAlign: 'bottom', marginRight: '4px' }} />
                                New to our Database?
                                Click <Atoms.CustomLink onClick={this.handleDialogOpen}>Here</Atoms.CustomLink> to view examples.
                            </Typography>
                        </div>),
                    }, {
                        tabName: 'Advanced Search',
                        tabIcon: SearchIcon,
                        tabContent: (<div>
                            <SearchForm
                                ref={this.formRef}
                                handleSearch={this.handleSearch}
                                filterParams={filterParams}
                                getSuggestions={AwesomeDebouncePromise(this.getSuggestions, 500)}
                            />
                            <Typography variant="body1">
                                <InfoIcon style={{ verticalAlign: 'bottom', marginRight: '4px' }} />
                                New to our Database?
                                Click <Atoms.CustomLink onClick={this.handleDialogOpen}>Here</Atoms.CustomLink> to view examples.
                            </Typography>
                        </div>)
                    }]}
                />

                <Paper style={{
                    marginTop: '20px'
                }}>
                    <Atoms.CustomTable
                        loading={tableLoading}
                        columns={this.header}
                        dataSource={dataSource}
                        withPagination
                        pagination={{
                            ...pagination,
                            onChangePage: this.onChangePage
                        }}
                    />
                </Paper>

                <Atoms.CustomDialog
                    open={showExampleDialog}
                    onClose={this.handleDialogClose}
                    title="Search Examlpes"
                >
                    <GridList cols={4} cellHeight={140} spacing={8}>
                        {SearchViewExample.map((item, index) => (
                            <GridListTile 
                                key={index} 
                                style={{ cursor: 'pointer' }}
                                onClick={this.applyExample(item)}
                            >
                                {item.img ? <img src={item.img} alt="example_img" /> : <div style={{ height: 160, width: '100%', backgroundColor: '#75bfbe' }} />}
                                <GridListTileBar
                                    title={item.title}
                                    subtitle={item.description}
                                    actionIcon={<IconButton
                                        style={{ color: 'rgba(255, 255, 255, 0.54)' }}
                                    >
                                        <SearchIcon />
                                    </IconButton>}
                                />
                            </GridListTile>
                        ))}
                    </GridList>
                </Atoms.CustomDialog>
            </Layouts.MainLayoutContent>
        );
    }
}

SearchView.propTypes = {
    match: PropTypes.object,
    filterParams: PropTypes.object
};

export default SearchView;
