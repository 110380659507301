import React from 'react';
import TabsCard from './TabsCard';
import PropTypes from 'prop-types';

import AssignmentIcon from '@material-ui/icons/Assignment';
import StyleIcon from '@material-ui/icons/Style';


class ExampleCard extends React.Component {
    state = {
        value: 0
    };
    handleChange = (event, value) => {
        this.setState({ value });
    }
    render() {
        const { title, formContent, exampleContent } = this.props;
        const { value } = this.state;
        
        return (<TabsCard
            activeIndex={value}
            handleTabChange={this.handleChange}
            title={title}
            headerColor="primary"
            tabs={[{
                tabName: 'Input',
                tabIcon: AssignmentIcon,
                tabContent: (formContent)
            }, {
                tabName: 'Or Try Our Examples',
                tabIcon: StyleIcon,
                tabContent: (exampleContent)
            }]}
        />);
    }
}

ExampleCard.propTypes = {
    title: PropTypes.string,
    formContent: PropTypes.node,
    exampleContent: PropTypes.node
};

export default ExampleCard;

