import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { whiteColor, hexToRgb } from 'assets/global';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#161616',
        padding: '5rem 0',
        [theme.breakpoints.down('sm')]: {
            padding: '2.5rem 0'
        }
    },
    footerText: {
        textAlign: 'center',
        margin: 'auto',
        color: `rgba(${hexToRgb(whiteColor)}, .5)`
    }
}));

export default function Footer() {
    const classes = useStyles();
    return(
        <div className={classes.root}>
            <Typography 
                component="p"
                className={classes.footerText}
            >Copyright &copy; Zhu & Zhou Lab 2019</Typography>
        </div>
    );
}