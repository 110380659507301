import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles, Tabs, Tab } from '@material-ui/core';

import Card from './Card.jsx';
import CardBody from './CardBody.jsx';
import CardHeader from './CardHeader.jsx';

import { whiteColor, hexToRgb } from 'assets/global';

const TabsStyle = {
    cardTitle: {
        float: 'left',
        padding: '10px 10px 10px 0px',
        lineHeight: '24px'
    },
    cardTitleRTL: {
        float: 'right',
        padding: '10px 0px 10px 10px !important'
    },
    displayNone: {
        display: 'none !important'
    },
    tabsRoot: {
        minHeight: 'unset !important',
        overflowX: 'visible',
        '& $tabRootButton': {
            fontSize: '0.875rem'
        }
    },
    tabRootButton: {
        minHeight: 'unset !important',
        minWidth: 'unset !important',
        width: 'unset !important',
        height: 'unset !important',
        maxWidth: 'unset !important',
        maxHeight: 'unset !important',
        padding: '10px 15px',
        borderRadius: '3px',
        lineHeight: '24px',
        border: '0 !important',
        color: whiteColor + ' !important',
        marginLeft: '4px',
        '&:last-child': {
            marginLeft: '0px'
        }
    },
    tabSelected: {
        backgroundColor: 'rgba(' + hexToRgb(whiteColor) + ', 0.2)',
        transition: '0.2s background-color 0.1s'
    },
    tabWrapper: {
        display: 'inline-block',
        minHeight: 'unset !important',
        minWidth: 'unset !important',
        width: 'unset !important',
        height: 'unset !important',
        maxWidth: 'unset !important',
        maxHeight: 'unset !important',
        fontWeight: '500',
        fontSize: '12px',
        marginTop: '1px',
        '& > svg,& > .material-icons': {
            verticalAlign: 'middle',
            margin: '-1px 5px 0 0 !important'
        }
    }
};

function TabsCard({
    activeIndex,
    handleTabChange,
    classes,
    headerColor,
    plainTabs,
    tabs,
    title,
    rtlActive
}) {
    const cardTitle = clsx({
        [classes.cardTitle]: true,
        [classes.cardTitleRTL]: rtlActive
    });
    return (
        <Card plain={plainTabs}>
            <CardHeader color={headerColor} plain={plainTabs}>
                {title !== undefined ? (
                    <div className={cardTitle}>{title}</div>
                ) : null}
                <Tabs
                    value={activeIndex}
                    onChange={handleTabChange}
                    classes={{
                        root: classes.tabsRoot,
                        indicator: classes.displayNone,
                        scrollButtons: classes.displayNone
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {tabs.map((prop, key) => {
                        var icon = {};
                        if (prop.tabIcon) {
                            icon = {
                                icon: <prop.tabIcon />
                            };
                        }
                        return (
                            <Tab
                                classes={{
                                    root: classes.tabRootButton,
                                    selected: classes.tabSelected,
                                    wrapper: classes.tabWrapper
                                }}
                                key={key}
                                label={prop.tabName}
                                {...icon}
                            />
                        );
                    })}
                </Tabs>
            </CardHeader>
            <CardBody>
                {tabs.map((prop, key) => {
                    if (key === activeIndex) {
                        return <div key={key}>{prop.tabContent}</div>;
                    }
                    return <div key={key} style={{ display: 'none' }}>{prop.tabContent}</div>;
                })}
            </CardBody>
        </Card>);
}

TabsCard.propTypes = {
    activeIndex: PropTypes.number,
    handleTabChange: PropTypes.func,
    classes: PropTypes.object.isRequired,
    headerColor: PropTypes.oneOf([
        'warning',
        'success',
        'danger',
        'info',
        'primary',
        'rose'
    ]),
    title: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            tabName: PropTypes.string.isRequired,
            tabIcon: PropTypes.object,
            tabContent: PropTypes.node.isRequired
        })
    ),
    rtlActive: PropTypes.bool,
    plainTabs: PropTypes.bool
};

export default withStyles(TabsStyle)(TabsCard);
