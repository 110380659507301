import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

function DownloadIcon({ fill, style: styleProp, ...otherProps }) {
    return (
        <SvgIcon
            {...otherProps}
            style={fill ? {
                width: '100%', height: '100%', ...styleProp
            } : { ...styleProp }}
        >
            <path d="M19 9H15V3H9V9H5L12 16L19 9ZM5 18V20H19V18H5Z" />
        </SvgIcon>
    );
}

DownloadIcon.propTypes = {
    fill: PropTypes.bool,
    style: PropTypes.object
};

export default DownloadIcon;