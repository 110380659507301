import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import { getGoInfo } from 'api/fetch_data';

class CompareGoTable extends React.Component {
    constructor(props) {
        super(props);
        const { source, target } = props;
        this.state = {
            tableData: [],
            mergeList: [],
        };
        this.header = [
            { key: 'term', content: 'Ontology' },
            { key: 'owner', content: 'Presence in' },
            { key: 'url', content: 'Accession' },
            { key: 'name', content: 'Name' },
        ];
        this.PostData = [
            { genomeAbbr: source.genomeAbbr, recordType: source.compareType, id: source.id },
            { genomeAbbr: target.genomeAbbr, recordType: target.compareType, id: target.id },
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { compareType, source, target } = this.props;
        Promise.all(
            this.PostData.map(data => getGoInfo(data.genomeAbbr, data.id, compareType))
        ).then(dataList => {
            const sourceData = this.buildRawDatafromResponse(dataList[0], source.name);
            const targetData = this.buildRawDatafromResponse(dataList[1], target.name);
            const concatData = sourceData;
            for (const targetGoData of targetData) {
                const index = concatData.findIndex(concatGoData => concatGoData.go === targetGoData.go);
                if (index === -1) {
                    concatData.push(targetGoData);
                } else {
                    const newData = { ...targetGoData, owner: 'Both' };
                    concatData[index] = newData;
                }
            }
            const sortedTableData = concatData.sort(this.sortData);

            // 合并表格第一项（term）
            const mergeData = [];
            let currentTerm = '';
            for (let index = 0; index < sortedTableData.length; index++) {
                if (sortedTableData[index].term !== currentTerm) {
                    currentTerm = sortedTableData[index].term;
                    mergeData.push(index);
                } else {
                    const newItem = { ...sortedTableData[index], term: undefined };
                    sortedTableData[index] = newItem;
                }
            }
            const mergeList = [];
            for (let i = 0; i < mergeData.length; i++) {
                mergeList.push({
                    y: 0,
                    x: mergeData[i],
                    row: i === mergeData.length - 1 ? sortedTableData.length - mergeData[i] : mergeData[i + 1] - mergeData[i]
                });
            }
            if (!this.__ignoreLastFetch)
                this.setState({ tableData: sortedTableData, mergeList: mergeList });
        });
    };

    // 将返回值转换为表格可处理的格式
    buildRawDatafromResponse(data, owner) {
        const keyList = Array.from(Object.keys(data));
        const rawData = [];
        keyList.forEach(key => {
            const goList = data[key];
            goList.forEach(goData => {
                rawData.push({
                    ...goData,
                    term: key,
                    url: <Atoms.CustomLink 
                        href={`http://amigo.geneontology.org/amigo/term/${goData['go']}`}
                        externalTarget="AmiGO 2"
                    >{goData['go']}</Atoms.CustomLink>,
                    owner
                });
            });
        });
        return rawData;
    }

    // 将已经扁平化的数据按要求排序
    sortData(data1, data2) {
        if (data1.term > data2.term) return 1;
        else if (data1.term < data2.term) return -1;
        else {
            if (data1.owner === 'Both' && data2.owner !== 'Both') return -1;
            else if (data2.owner === 'Both' && data1.owner !== 'Both') return 1;
            else {
                if (data1.owner > data2.owner) return 1;
                else if (data2.owner > data1.owner) return -1;
                else {
                    if (data1.go > data2.go) return 1;
                    else if (data2.go > data1.go) return -1;
                    return 0;
                }
            }
        }
    }

    render() {
        const { tableData, mergeList } = this.state;

        if (tableData.length === 0) {
            return null;
        }

        return (
            <Atoms.CustomTable
                columns={this.header}
                dataSource={tableData}
                mergeList={mergeList}
            />
        );
    }
}

CompareGoTable.propTypes = {
    source: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    target: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    compareType: PropTypes.oneOf(['Locus', 'Trans', 'Prot', ''])
};

export default CompareGoTable;

