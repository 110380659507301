import React from 'react';
import { withStyles, TableCell } from '@material-ui/core';
import { infoColor, primaryColor } from 'assets/global';

const styledBy = (property, mapping) => props => mapping[props[property]];

const CustomTableCell = withStyles(theme => ({
    head: {
        backgroundColor: styledBy('colorType', {
            'undefined': '#f5f5f5',
            info: theme.palette.common.white
        }),
        color: styledBy('colorType', {
            'undefined': primaryColor[2],
            info: infoColor[2]
        }),
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 17.5,
        fontWeight: 'bold',
    },
    body: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontSize: 14,
    },
}))(({ classes, colorType, ...other}) => <TableCell classes={classes} {...other} />);


export default CustomTableCell;
