import { asyncFetchPost } from './global';
import React from 'react';
import BlastIcon from 'components/icons/BlastIcon';
import HelpIcon from 'components/icons/HelpIcon';
import CompareIcon from 'components/icons/CompareIcon';
import SearchIcon from 'components/icons/SearchIcon';
import HomeIcon from 'components/icons/HomeIcon';
import JbrowseIcon from 'components/icons/JbrowseIcon';
import DownloadIcon from 'components/icons/DownloadIcon';
import DocumentIcon from 'components/icons/DocumentIcon';
import GenomeInfoIcon from 'components/icons/GenomeInfoIcon';
// import PhyloTreeIcon from 'components/icons/PhyloTreeIcon';
import PrimerDesignIcon from 'components/icons/PrimerDesignIcon';
import GenomeSyntenyIcon from 'components/icons/GenomeSyntenyIcon';
import GeneSyntenyIcon from 'components/icons/GeneSyntenyIcon';
import GeneMapIcon from 'components/icons/GeneMapIcon';
import ToolsIcon from 'components/icons/ToolsIcon';
import BatchFetchIcon from 'components/icons/BatchFetchIcon';
import GeneOntologyIcon from 'components/icons/GeneOntologyIcon';

export default async function getMenu() {
    const jbrowse_info = await asyncFetchPost('wiki/get_jbrowse_info', {}) || [];
    const species_info = await asyncFetchPost('wiki/get_species_info', {}) || [];

    const JbrowseSubMenu = jbrowse_info.map(item => ({
        icon: null,
        id: `Jbrowse_${item.species_abbr}_${item.genome_abbr}`,
        text: `${item.species_abbr} - ${item.genome_abbr}`,
        link: `/jbrowse?species_abbr=${item.species_abbr}&genome_abbr=${item.genome_abbr}`
    }));
    const genomeSubMenu = species_info.map(item => ({
        icon: null,
        id: `Genome_${item.abbr_name}`,
        text: item.name,
        link: `/genome/${item.abbr_name}`
    }));

    return ([
        { id: 'Home', text: 'Home', link: '/', icon: <HomeIcon /> },
        {
            id: 'Genomes',
            text: 'Genomes',
            icon: <GenomeInfoIcon />,
            children: genomeSubMenu
        },
        {
            id: 'JBrowse',
            text: 'Genome browser',
            children: JbrowseSubMenu,
            icon: <JbrowseIcon />
        },
        {
            id: 'Search',
            text: 'Search',
            link: '/search',
            icon: <SearchIcon />
        },
        {
            id: 'Tools',
            text: 'Tools',
            icon: <ToolsIcon />,
            children: [
                { id: 'FetchData', text: 'Batch Fetch Data', link: '/fetchdata', icon: <BatchFetchIcon /> },
                { id: 'Comparative', text: 'Gene Comparator', link: '/compare', icon: <CompareIcon /> },
                { id: 'GeneSyntenyViewer', text: 'Gene Synteny Viewer', link: '/genesyntenyviewer', icon: <GeneSyntenyIcon /> },
                { id: 'Primer', text: 'Primer Design', link: '/primerdesign', icon: <PrimerDesignIcon /> },
                { id: 'Blast', text: 'BLAST', link: '/blast', icon: <BlastIcon /> },
                // { id: 'LiftOver', text: 'LiftOver', link: '/liftover', icon: 'L' },
                { id: 'GoEnrichment', text: 'GO Enrichment', link: '/goenrichment', icon: <GeneOntologyIcon /> },
                // { id: 'FetchMAF', text: 'Fetch MAF', link: '/fetchmaf', icon: 'FM' },
                { id: 'MapViewer', text: 'Gene Map Viewer', link: '/mapviewer', icon: <GeneMapIcon /> },
                { id: 'SyntenyViewer', text: 'GenomeSyn Viewer', link: '/genomesyntenyviewer', icon: <GenomeSyntenyIcon /> },
                // { id: 'PhyloTreeViewer', text: 'PhyloTree Viewer', link: '/phylotree', icon: <PhyloTreeIcon/> },
            ]
        },
        { id: 'Downloads', text: 'Downloads', link: '/downloads', icon: <DownloadIcon /> },
        { id: 'Documentation', text: 'User guide', link: '/document', icon: <DocumentIcon /> },
        {
            id: 'Help',
            text: 'Help',
            icon: <HelpIcon />,
            children: [
                { id: 'construction', text: 'Database construction', link: '/help/construction', icon: null },
                { id: 'external_links', text: 'Related resource', link: '/help/external_links', icon: null },
                { id: 'term_to_use', text: 'Term to use', link: '/help/term_to_use', icon: null },
                { id: 'contact', text: 'Contact us', link: '/help/contact', icon: null },
            ]
        },
    ]);
}

