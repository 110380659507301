import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Axis, Coord } from 'bizcharts';
import { chartColors as colors } from 'assets/global';


function PrimerStructure({ dataSource, bpStart, bpEnd, heightPerDataGroup, width, padding, ToolTipComponent }) {
    const data = [];
    dataSource.forEach(geneGroup => {
        geneGroup.blocks.forEach(gene => {
            data.push({
                range: [gene[0], gene[1]],
                group: geneGroup.group,
                name: geneGroup.name,
            });
        });
    });

    const colorDict = {};
    let iter = 0;
    dataSource.forEach(geneGroup => {
        if (!(geneGroup.group in colorDict)) {
            colorDict[geneGroup.group] = colors[iter];
            iter++;
        }
    });

    const scale = {
        name: {
            type: 'cat',
        },
        range: {
            nice: false,
            minLimit: -1,
            maxLimit: bpEnd,
            min: bpStart,
            max: bpEnd
        }
    };

    return (
        <Chart
            height={heightPerDataGroup * (dataSource.length) + padding[0] + padding[2]}
            width={width}
            data={data}
            scale={scale}
            padding={padding}
            onIntervalClick={(ev) => {
                console.log(ev);
            }}
            forceFit
        >
            <Coord transpose />
            {ToolTipComponent}
            <Axis
                name="name"
                label={{
                    textStyle: {
                        fontSize: '12',
                        fill: '#000',
                        fontWeight: 'bold',
                    },
                    position: 'center',
                }} />
            <Axis
                name="range"
                label={{
                    offset: 20,
                    textStyle: {
                        fontSize: '12',
                        fill: '#000',
                        fontWeight: 'bold',
                    },
                }}
            />
            <Axis name="position" visible={false}/>
            <Geom
                type="interval"
                position="name*range"
                color={['group', colors]}
                size={6}
            />
        </Chart>
    );
}



PrimerStructure.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({
        group: PropTypes.string,
        name: PropTypes.string,
        start: PropTypes.number,
        end: PropTypes.number,
        strand: PropTypes.oneOf(['-', '+']),

        blocks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    })),
    bpStart: PropTypes.number,
    bpEnd: PropTypes.number,
    heightPerDataGroup: PropTypes.number,
    width: PropTypes.number,
    padding: PropTypes.arrayOf(PropTypes.number)
};

export default PrimerStructure;