import React from 'react';
import { SvgIcon, withStyles } from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        height: '100%'
    }
};

function JbrowseIcon({ scale, classes }) {
    let svgScale = scale;
    if (!scale) {
        svgScale = 24;
    }
    return (
        <SvgIcon viewBox={`0 0 ${svgScale} ${svgScale}`} className={scale ? classes.root : undefined}>
            <g transform={`scale(${svgScale/24})`} >
                <g transform="scale(0.38) translate(2,0)">
                    <path d="M27.223,41c.355,0,.976-.047,1.138-.362.343-.667-.316-2.331-1.137-3.775-.823,1.445-1.482,3.111-1.14,3.775C26.247,40.953,26.867,41,27.223,41Z"/>
                    <path d="M39,26H15.5a1.5,1.5,0,0,1,0-3H21a1,1,0,0,0,1-1V2H20V4H17a1,1,0,0,0-1,1V21h-.5A3.492,3.492,0,0,0,14,27.649V29a3,3,0,0,0,2.9,2.99L21.6,57.5a5.488,5.488,0,0,0,10.8,0L37.1,31.99A3,3,0,0,0,40,29V27A1,1,0,0,0,39,26ZM28,58a3.554,3.554,0,0,1-3.455-2.7l-.515-2.06,1.94-.484.514,2.059A1.561,1.561,0,0,0,28,56Zm2.14-16.447A2.957,2.957,0,0,1,27.223,43a2.957,2.957,0,0,1-2.917-1.447c-1.193-2.318,1.543-6.349,2.1-7.134a1.036,1.036,0,0,1,1.627,0C28.6,35.2,31.332,39.235,30.14,41.553Z"/>
                    <polygon points="46 3 31 19 31 22 34 22 54 3 46 3"/>
                </g>
            </g>
        </SvgIcon>
    );
}

export default withStyles(styles)(JbrowseIcon);