import { isEqual, flatten } from 'lodash';

class Cluster {
    constructor(gene, space) {
        this.children = [gene];
        this.position = 0.5 * (gene.start + gene.end);// bottom of the cluster
        this.space = space;
        this.height = space;
    }

    mergeTop(cluster) {
        this.position = (this.position*this.children.length + cluster.position*cluster.children.length)/(this.children.length + cluster.children.length) - this.space * (this.children.length + cluster.children.length - 1);

        this.children = this.children.concat(...cluster.children);
        this.height = (2 * this.children.length - 1) * this.space;
        return this;
    }

    isOverlapTop(cluster) {
        if (!cluster)
            return false;
        const distance = Math.abs(cluster.position - this.position);
        if (distance < this.height)
            return true;
        return false;
    }

    get geneAnnotation() {
        const result = [];
        const sortedGeneList = this.children.sort((gene1, gene2) => gene1.start - gene2.start);
        for (let i = 0; i < sortedGeneList.length; i++) {
            const gene = sortedGeneList[i];
            result.push({
                ...gene,
                position: [
                    0.5 * (gene.start + gene.end),
                    this.position + 2 * i * this.space
                ]
            });
        }
        return result;
    }
}

function recursion(clusterArray) {
    const newClusterArray = [];
    for (let i = 0; i < clusterArray.length; i++) {
        if (clusterArray[i].isOverlapTop(clusterArray[i + 1])) {
            newClusterArray.push(clusterArray[i].mergeTop(clusterArray[i + 1]));
            i++;
        } else {
            newClusterArray.push(clusterArray[i]);
        }
    }
    if (isEqual(newClusterArray, clusterArray)) {
        return newClusterArray;
    } else {
        return recursion(newClusterArray);
    }
}

/**
 * @param {Array<Object>} geneList
 * @param {number} space
 */
export default function LocusMergeToCluster(geneList, space) {
    const clusterArray = geneList.map(gene => new Cluster(gene, space));
    const merge = recursion(clusterArray);
    return flatten(merge.map(cluster => cluster.geneAnnotation));
}