import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Popover, makeStyles } from '@material-ui/core';
import CustomTableCell from './CustomTableCell';

const useStyles = makeStyles(theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
}));

function TableCellWithPopover({ tableText, description, ...otherProps }) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    function handlePopoverOpen(event) {
        setAnchorEl(event.currentTarget);
    }

    function handlePopoverClose() {
        setAnchorEl(null);
    }

    const open = Boolean(anchorEl);
    return (
        <CustomTableCell {...otherProps}>
            <div
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {tableText}
            </div>
            {description !== undefined && <Popover
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper,
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                <Typography>{description}</Typography>
            </Popover>}
        </CustomTableCell>
    );
}

TableCellWithPopover.propTypes = {
    tableText: PropTypes.any,
    description: PropTypes.any
};


export default TableCellWithPopover;