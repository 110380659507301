import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function HomeIcon() {
    return (
        <SvgIcon>
            <path d="
                M 10 19
                v -5
                h 4
                v 5
                c 0 0.55 0.45 1 1 1
                h 3
                c 0.55 0 1 -0.45 1 -1
                v -7
                h 1.7
                c 0.46 0 0.68 -0.57 0.33 -0.87
                L 12.67 3.6
                c-.38-.34-.96-.34-1.34 0
                l-8.36 7.53
                c-.34.3-.13.87.33.87
                H5
                v7
                c0 .55.45 1 1 1
                h3
                c.55 0 1-.45 1-1z
            "/>
        </SvgIcon>
    );
}

export default HomeIcon;