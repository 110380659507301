import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Atoms } from 'components';
import { getGeneOrfInfo } from 'api/fetch_data';

class GeneORFInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
            dataSource: []
        };
        this.header = [
            { key: 'name', content: 'Name' },
            { key: 'alias_name', content: 'Alias' },
            { key: 'position', content: 'Position' },
            { key: 'aa_num', content: 'AA number' },
            { key: 'mw', content: 'MW (kDa)' },
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const {genomeAbbr, id} = this.props;
        getGeneOrfInfo(genomeAbbr, id).then(data => {
            if (!this.__ignoreLastFetch) {
                this.setState({dataSource: data.map(this.prot_info)});
            }
        });
    };

    prot_info = (record) => {
        const { speciesAbbr, genomeAbbr } = this.props;
        const { alias_name, aa_num, chrom, start, end, strand, mw, uniq_prot_id, prot_name } = record;
        return {
            name: <Link
                to={`/wiki/protein/${speciesAbbr}/${genomeAbbr}/${uniq_prot_id}`}>{prot_name}</Link>,
            alias_name: alias_name.length>0 ? <ul> {alias_name.map((names, index) => <li key={index}>{names}</li>)} </ul> : '-',
            position: <div>{`${chrom}: ${start} - ${end} (${strand})`}</div>,
            aa_num: aa_num,
            mw: mw
        };
    };

    render() {
        const {dataSource} = this.state;
        if (dataSource.length === 0) {
            return null;
        }
        return (
            <Atoms.InfoGroup title="ORFs">
                <Atoms.CustomTable columns={this.header} dataSource={dataSource} />
            </Atoms.InfoGroup>
        );
    }
}

GeneORFInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default GeneORFInfo;

