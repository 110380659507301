function getLocalhost() {
    const curWwwPath = window.document.location.href;
    // 获取主机地址之后的目录，如： maldb/demo
    const pathName = window.document.location.pathname;
    const pos = curWwwPath.indexOf(pathName);
    // 获取主机地址，如： http://localhost:8083
    return curWwwPath.substring(0, pos);
}

const site = getLocalhost();


export async function getStaticMarkdownText(fileName) {
    try {
        const response = await fetch(`${site}/doc/${fileName}`, {
            method: 'GET'
        });
        const text = await response.text();
        return text;
    } catch (err) {
        console.log(err);
    }
}
