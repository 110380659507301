import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

function DocumentIcon({ fill, style: styleProp, ...otherProps }) {
    return (
        <SvgIcon
            {...otherProps}
            style={fill ? {
                width: '100%', height: '100%', ...styleProp
            } : { ...styleProp }}
        >
            <g transform="scale(0.06)">
                <path d="
                    M95 65
                    C 91.348 67.606,89.063 68.219,89.063 67.754 
                    C 89.063 66.371,77.499 75.888,73.972 80.174 
                    C 65.303 90.709,65.622 86.095,65.659 200.328 
                    C 65.696 313.841,65.654 313.230,73.971 320.113 
                    C 75.396 321.293,76.563 323.072,76.563 324.068 
                    C 76.563 325.063,78.496 326.729,80.859 327.769 
                    C 83.223 328.809,87.346 331.082,90.022 332.819 
                    C 94.500 335.725,326.983 338.962,324.098 336.078 
                    C 323.746 335.725,324.859 334.455,326.572 333.255 
                    C 331.296 329.946,330.438 324.744,324.502 320.703 
                    L 320 320 210 320 
                    C 90 320 95 320 90 310
                    C 86.859 303.133,87.043 294.744,89.721 288.335 
                    C 94.153 277.726,89.419 278.125,211.009 278.125 
                    C 311.750 278.125,320.978 277.908,325 275
                    L 330 270 330 70 325 65 
                    L 322.455 64.063 210.837 64.124 
                    C 118.229 64.174,98.354 64.561,95 65 
                    M310 170
                    L 308.594 259.375 202.734 259.375 
                    C 81.298 259.375,95.572 258.977,90.625 262.501 
                    L 86.719 265.284 87.500 179.908 
                    C 87.930 132.951,88.894 93.275,89.642 91.739 
                    C 91.202 88.541,99.116 84.012,100.583 85.479 
                    C 101.122 86.018,101.563 85.814,101.563 85
                    C 101.563 84.172,143.383 83.594,205.078 85 
                    L 310 85 310 170 
                    M183.528 100.106 
                    C 183.016 100.935,182.053 101.277,181.388 100.866 
                    C 179.924 99.962,164.724 109.567,161.149 113.655 
                    C 149.144 127.383,146.032 144.743,154.766 149.259 
                    C 162.728 153.376,171.853 146.347,171.869 136.085 
                    C 171.877 131.059,179.061 121.875,182.984 121.875 
                    C 184.710 121.875,185.726 121.234,185.242 120.451 
                    C 183.934 118.334,196.092 116.715,204.184 117.929 
                    C 215.759 119.664,228.826 136.295,224.331 143.569 
                    C 223.929 144.219,223.239 146.744,222.797 149.181 
                    C 222.016 153.486,214.816 162.500,212.157 162.500 
                    C 211.431 162.500,208.386 163.524,205.390 164.776 
                    C 202.394 166.028,199.606 166.907,199.194 166.729 
                    L 190 170
                    L 190 200 
                    a 10 10 0 1 0 20 0 
                    L 210 185 
                    C 245.098 166.114,253.128 141.712,237.776 119.141 
                    C 234.123 113.770,230.507 109.375,229.741 109.375 
                    C 228.975 109.375,227.419 108.488,226.283 107.404 
                    C 224.226 105.439,216.952 101.699,212.500 100.316 
                    C 207.685 98.821,184.427 98.652,183.528 100.106
                    Z
                    M200 235
                    m-15 0
                    a 15 15 0 1 0 30 0
                    a 15 15 0 1 0-30 0
                    Z"
                />
            </g>
        </SvgIcon>
    );
}

DocumentIcon.propTypes = {
    fill: PropTypes.bool,
    style: PropTypes.object
};

export default DocumentIcon;