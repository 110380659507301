import React from 'react';
import PropTypes from 'prop-types';
import { Atoms, Charts } from 'components';
import { getPPIInfo } from 'api/fetch_data';
import { max, fromPairs, mapValues } from 'lodash';

class PPINetworkInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            nodes: [],
            links: [],
            filteredLinks: [],
            nodesWithEdgeCount: {},
            currentValue: '500',
            maxValue: 0,
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = async () => {
        const { recordType, genomeAbbr, id } = this.props;

        const data = await getPPIInfo(genomeAbbr, id, recordType);
        if (data && !this.__ignoreLastFetch) {
            const { nodes, links } = data;
            const maxValue = max(links.map(item => item[2]));
            this.setState({ nodes, links, maxValue, currentValue: maxValue * 0.75 }, () => this.filterLinksByValue());
        }

    };

    handleValueChange = (event) => this.setState({ currentValue: event.target.value });

    filterLinksByValue = () => {
        const { nodes, links, currentValue } = this.state;

        // build an object { [number id]: nodeinfo } which contains all nodes to show
        const displayNodeDict = fromPairs(links
            .filter(item => item[0] === 0 && item[2] > parseInt(currentValue))
            .map(item => item[1])
            .map(id => [id, nodes[id]])
        );
        displayNodeDict[0] = nodes[0];

        const filteredLinks = links
            .filter(item =>
                item[2] > parseInt(currentValue) && (item[0] in displayNodeDict && item[1] in displayNodeDict)
            );

        const nodesWithEdgeCount = mapValues(displayNodeDict, node => ({ ...node, edgeCount: 0 }));
        filteredLinks.forEach(link => {
            nodesWithEdgeCount[link[0]].edgeCount += 1;
            nodesWithEdgeCount[link[1]].edgeCount += 1;
        });

        this.setState({ filteredLinks, nodesWithEdgeCount });
    };

    render() {
        const { nodes, filteredLinks, nodesWithEdgeCount, currentValue, maxValue } = this.state;
        const { speciesAbbr, genomeAbbr } = this.props;
        if (nodes.length === 0) {
            return null;
        }
        return (
            <Atoms.InfoGroup title="PPI network">
                <Atoms.InputControl
                    label={`Threshold of interaction score [200-${maxValue}]`}
                    value={currentValue}
                    handleChange={this.handleValueChange}
                    handleSubmit={this.filterLinksByValue}
                />
                <Charts.NetworkPlot
                    nodes={nodesWithEdgeCount}
                    links={filteredLinks}
                    height="400px"
                    speciesAbbr={speciesAbbr}
                    genomeAbbr={genomeAbbr}
                />
            </Atoms.InfoGroup>
        );
    }
}

PPINetworkInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default PPINetworkInfo;
