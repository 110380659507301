import React from 'react';
import PropTypes from 'prop-types';
import { Atoms, Charts } from 'components';
import { getTmhmmDomain } from 'api/fetch_data';

class IvalTmhmmDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            plotData: []
        };
        this.header = [
            { key: 'domain_name', content: 'Transmembrane helices' },
            { key: 'start', content: 'Start' },
            { key: 'end', content: 'End' },
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { recordType, genomeAbbr, id } = this.props;
        getTmhmmDomain(genomeAbbr, id, recordType).then(data => {
            if (!this.__ignoreLastFetch) {
                const plotData = data.map(item => ({
                    group: item['domain_name'],
                    start: item['start'],
                    end: item['end'],
                    name: item['domain_name'],
                    blocks: [[item['start'], item['end']]],
                }));
                this.setState({ data, plotData });
            }
        });
    };

    render() {
        const { data, plotData } = this.state;
        const { recordType, ival } = this.props;

        if (data.length === 0){
            return null;
        }
        
        const edix = {
            'Trans': ival.exon_len,
            'Prot': ival.aa_num
        };
        return (
            <Atoms.ExpansionInfoCard
                title="Transmembrane helices region"
                mainContent={
                    <Charts.GeneStructure
                        bpStart={0}
                        bpEnd={edix[recordType]}
                        heightPerDataGroup={20}
                        width={1000}
                        padding={[10, 50, 30, 150]}
                        dataSource={plotData}
                    />
                }
                expansionContent={
                    <Atoms.CustomTable 
                        columns={this.header} 
                        dataSource={data} />
                }
            />
        );
    }
}

IvalTmhmmDomain.propTypes = {
    ival: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default IvalTmhmmDomain;

