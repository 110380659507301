import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

function CompareIcon({ fill, style: styleProp, ...otherProps }) {
    return (
        <SvgIcon
            {...otherProps}
            style={fill ? {
                width: '100%', height: '100%', ...styleProp
            } : { ...styleProp }}
        >
            <g transform="scale(0.046875)">
                <g transform="scale(0.85) translate(20,30)">
                    <path d="m512 190.507812-65.367188-65.574218-29.578124 29.488281-59.179688-59.363281 29.578125-29.488282-65.363281-65.570312-103.292969 102.96875c-21.144531 21.074219-33.949219 48.324219-36.753906 77.734375-29.417969 2.714844-56.707031 15.429687-77.847657 36.507813l-104.195312 103.863281 65.367188 65.570312 28.097656-28.011719 59.179687 59.367188-28.097656 28.011719 65.363281 65.566406 104.199219-103.863281c21.742187-21.675782 33.992187-49.324219 36.738281-77.730469 29.421875-2.714844 56.714844-15.429687 77.859375-36.511719zm-283.257812 151.636719-5.714844 5.695313-59.179688-59.363282 5.714844-5.695312c13.515625-13.472656 35.476562-13.441406 48.953125.078125l10.300781 10.332031c13.476563 13.519532 13.441406 35.480469-.074218 48.953125zm55.34375-124.652343c-6.515626-6.535157-10.09375-15.230469-10.078126-24.492188.015626-9.261719 3.621094-17.949219 10.152344-24.460938l3.332032-3.320312 59.179687 59.363281-3.332031 3.320313c-6.519532 6.5-15.195313 10.078125-24.433594 10.078125-.019531 0-.039062 0-.058594 0-9.257812-.015625-17.945312-3.621094-24.460937-10.152344zm83.84375-14.097657-59.179688-59.367187 27.867188-27.78125 59.179687 59.367187zm-225.339844 106.269531 59.179687 59.367188-27.867187 27.78125-59.179688-59.367188zm0 0" />
                </g>
                <polygon points="
                    350,400 
                    260,400 
                    260,460 
                    350,460 
                    350,490 
                    420,430 
                    350,370"/>
                <polygon points="
                    410,360
                    500,360 
                    500,300
                    410,300 
                    410,270
                    340,330 
                    410 390"/>
            </g>
        </SvgIcon>
    );
}

CompareIcon.propTypes = {
    fill: PropTypes.bool,
    style: PropTypes.object
};

export default CompareIcon;