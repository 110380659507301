import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import GenePfamDomain from './GenePfamDomain';
import GeneInterproDomain from './GeneInterproDomain';
import GeneCddDomain from './GeneCddDomain';
import GeneSignalpDomain from './GeneSignalpDomain';
import GeneTmhmmDomain from './GeneTmhmmDomain';

function GeneDomainInfo(props) {
    return (
        <Atoms.InfoGroup title="Functional elements">
            <GeneInterproDomain {...props}  />
            <GeneCddDomain {...props} />
            <GenePfamDomain {...props} />
            <GeneSignalpDomain {...props} />
            <GeneTmhmmDomain {...props} />
        </Atoms.InfoGroup>
    );
}

GeneDomainInfo.propTypes = {
    locus: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default GeneDomainInfo;

