import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
    MuiThemeProvider,
    withStyles, Toolbar, IconButton,
    Container, Typography, AppBar,
    Hidden, Drawer
} from '@material-ui/core';
import { whiteColor, blackColor, hexToRgb } from 'assets/global';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MenuIcon from '@material-ui/icons/Menu';

import { HomeDrawer, HomeNavbarLink } from './HomeMenu';
import WelcomeSection from './WelcomeSection';
import AboutSection from './AboutSection';
import IntroductionSection from './IntroductionSection';
import Footer from './Footer';

const styles = theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'hidden',
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        color: `rgba(${hexToRgb(whiteColor)}, 0.7)`,
        backgroundColor: 'transparent',
        transition: '0.4s background-color,color 0.4s',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: whiteColor,
            color: blackColor
        }
    },
    appBarOnScroll: {
        backgroundColor: `${whiteColor} !important`,
        color: `${blackColor} !important`
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none'
        },
        color: 'inherit',
        marginRight: theme.spacing(2),
    },
    headerLink: {
        color: 'inherit',
        textDecoration: 'none'
    },
    toolbar: theme.mixins.toolbar,
    mainContent: {
        padding: `${theme.spacing(3)} 0`,
    },
    headerText: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    navbarLinks: {
        display: 'none',
        height: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
});

class HeaderContentFooterLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            scrollY: 0,
        };
        this.scrollRef = null;
        this.setScrollRef = element => {
            this.scrollRef = element;
        };
    }

    handleToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    }

    onScrollY = (container) => {
        this.setState({ scrollY: container.scrollTop });
    }

    scrollTo = (position) => {
        if (this.scrollRef) {
            this.scrollRef.scrollTop = position;
        }
    }

    render() {
        const { theme, classes } = this.props;
        const { mobileOpen, scrollY } = this.state;
        return (
            <MuiThemeProvider theme={theme}>
                <PerfectScrollbar
                    onScrollY={this.onScrollY}
                    containerRef={this.setScrollRef}
                >
                    <div className={clsx(classes.root, classes.grow)}>
                        <AppBar className={clsx(classes.appBar, { [classes.appBarOnScroll]: scrollY >= 200 })} position="fixed">
                            <Container maxWidth="lg">
                                <Toolbar id="back-to-top-anchor">
                                    <IconButton onClick={this.handleToggle} className={classes.menuButton}><MenuIcon color="inherit" /></IconButton>
                                    <Typography className={classes.headerText} variant="h6" color="inherit" noWrap>MaGenDB</Typography>
                                    <div className={classes.grow} />
                                    <div className={classes.navbarLinks}>
                                        <HomeNavbarLink />
                                    </div>
                                </Toolbar>
                            </Container>
                            <Hidden smUp implementation="css">
                                <Drawer
                                    variant="temporary"
                                    anchor="top"
                                    open={mobileOpen}
                                    onClose={this.handleToggle}
                                >
                                    <HomeDrawer />
                                </Drawer>
                            </Hidden>
                        </AppBar>
                        <main className={clsx(classes.mainContent, classes.grow)}>
                            <section>
                                <WelcomeSection scrollTo={this.scrollTo} />
                            </section>
                            <section id="start-anchor">
                                <IntroductionSection />
                            </section>
                            <section>
                                <AboutSection />
                            </section>
                        </main>
                        <footer>
                            <Footer />
                        </footer>
                    </div>
                </PerfectScrollbar>
            </MuiThemeProvider>
        );
    }

}

HeaderContentFooterLayout.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.any,
    theme: PropTypes.any,
    window: PropTypes.any
};

export default withStyles(styles)(HeaderContentFooterLayout);