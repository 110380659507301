import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { withStyles, Toolbar, Typography, IconButton } from '@material-ui/core';

import CompareIcon from '@material-ui/icons/Compare';
import FilterListIcon from '@material-ui/icons/FilterList';
import { lighten } from '@material-ui/core/styles/colorManipulator';


const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing(1),
    },
    highlight: {
        color: theme.palette.primary.main,
        backgroundColor: lighten(theme.palette.primary.light, 0.8),
    },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

function TableToolbar({ classes, selectedItemName, text, takeOption }) {
    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: selectedItemName !== undefined,
            })}
        >
            <div className={classes.title}>
                {selectedItemName ? (
                    <Typography color="inherit" variant="subtitle1">
                        {selectedItemName} selected
                    </Typography>
                ) : (<Typography variant="h6" id="tableTitle">{text}</Typography>)}
            </div>
            <div className={classes.spacer} />
            <div className={classes.actions}>
                {selectedItemName ? (<IconButton onClick={takeOption} aria-label="Compare">
                    <CompareIcon />
                </IconButton>) : (<IconButton aria-label="Filter list">
                    <FilterListIcon />
                </IconButton>)}
            </div>
        </Toolbar>
    );
}

TableToolbar.propTypes = {
    classes: PropTypes.object.isRequired,
    selectedItemName: PropTypes.string,
    text: PropTypes.string,
    takeOption: PropTypes.func
};

export default withStyles(toolbarStyles)(TableToolbar);