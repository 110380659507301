import React from 'react';
import { makeStyles, Container, Grid, Paper, Typography } from '@material-ui/core';
import CustomTable from '../../atoms/CustomTable/CustomTable';
import HexIconGroup from './HexIconGroup';
import { primaryColor, blackColor, hexToRgb } from 'assets/global';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#eee',
        paddingTop: '128px',
        paddingBottom: '160px'
    },
    staticsTable: {
        marginBottom: '64px'
    },
    titleContainer: {
        textAlign: 'center',
        marginBottom: '8px',
        [theme.breakpoints.up('md')]: {
            borderLeft: `8px solid ${primaryColor[0]}`,
            paddingLeft: '20px',
            textAlign: 'left !important'
        }
    },
    title: {
        marginBottom: '2.5rem',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '1rem',
        }
    },
    description: {
        fontSize: '1.25rem',
        marginBottom: '2rem',
        color: `rgba(${hexToRgb(blackColor)}, 0.5)`,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    functionContainer: {
        textAlign: 'center',
        marginBottom: '8px',
        [theme.breakpoints.up('md')]: {
            display: 'none'
        }
    }

}));

const header = [
    { key: 'content', content: 'Data' },
    { key: 'number', content: 'Statistics' },
];
const dataSource = [
    { content: 'Subfamily / Genus / Species', number: '5 / 9 / 13' },
    { content: 'Transcripts / Proteins', number: '759,700 / 728,366' },
    { content: 'Types of omics data', number: '9' },
    { content: 'Omics data samples', number: '374' },
    { content: 'GO/KO/EC annotation', number: '494,364 / 254,551 / 85,157' },
    { content: 'Gene / TF families', number: '656,516 / 280,976' },
    { content: 'BLAST annotation (NT/NR/TAIR)', number: '63,313,713 / 307,542,675 / 635,804' },
    { content: 'Types of functional elements', number: '7' },
    { content: 'Functional elements', number: '24,855,369' },
    { content: 'Synteny gene pairs', number: '28,976,317' },
    { content: 'Protein-protein interactions', number: '170,660,615' },
    { content: 'Protein 3D structures', number: '98,831' },
];

export default function AboutSection() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container maxWidth="lg">
                <Grid container alignItems="center" direction="row-reverse" className={classes.staticsTable}>
                    <Grid item xs={12} md={4}>
                        <div className={classes.titleContainer}>
                            <Typography 
                                variant="h4" 
                                className={classes.title}
                            >Summary</Typography>
                            <Typography 
                                variant="body1"
                                className={classes.description}
                            >Summary of MaGenDb data.</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Paper>
                            <CustomTable columns={header} dataSource={dataSource} size="small" forceNoScroll />
                        </Paper>
                    </Grid>
                </Grid>
                <Grid container alignItems="center" direction="row" justify="center">
                    <Grid item xs={12} className={classes.functionContainer}>
                        <Typography variant="h4" className={classes.title}>Functions</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <HexIconGroup/>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
