import React from 'react';
import PropTypes from 'prop-types';
import {Atoms} from 'components';
import {Link} from 'react-router-dom';
import {getEcInfo} from 'api/fetch_data';

class EcInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        };
        this.header = [
            {key: 'ec', content: 'EC'},
            {key: 'name', content: 'Name'},
            {key: 'alias', content: 'Alias'},
            {key: 'rc', content: 'Reaction catalysed'},
            {key: 'search', content: 'Search same ontology gene'}
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const {genomeAbbr, id, recordType} = this.props;
        getEcInfo(genomeAbbr, id, recordType).then(data => {
            if (!this.__ignoreLastFetch) {
                const dataSource = data.map(item => ({
                    ec: <a href={`https://enzyme.expasy.org/EC/${item['ec']}`}>{item['ec']}</a>,
                    name: item['name'],
                    alias: item['alias_name'].length > 0 ?
                        <ul> {item['alias_name'].map((names, index) => <li key={index}>{names}</li>)} </ul> : '-',
                    rc: item['reaction_catalysed'].length > 0 ?
                        <ul> {item['reaction_catalysed'].map((names, index) => <li
                            key={index}>{names}</li>)} </ul> : '-',
                    search: <Link to={{
                        pathname: '/search/ec',
                        state: {'searchText': item['ec'], 'species': 'All', 'genome': 'All', 'assemble': 'All'}
                    }}>Search</Link>
                }));
                this.setState({dataSource});
            }
        });
    };

    render() {
        const {dataSource} = this.state;

        if (dataSource.length === 0) {
            return <div></div>;
        }
        return (
            <Atoms.CustomTable columns={this.header} dataSource={dataSource}/>
        );
    }
}

EcInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default EcInfo;

