import React from 'react';
import { Layouts } from 'components';

function BlastView() {
    return (
        <Layouts.MainLayoutContent headerText="BLAST" contentNoPadding>
            <iframe
                title="blast"
                src={process.env.REACT_APP_BLAST_SERVER_PATH}
                style={{ width: '100%', height: window.innerHeight - 68, frameborder: 0, borderWidth:'0px' }} />
        </Layouts.MainLayoutContent>
    );
}

BlastView.propTypes = {

};

export default BlastView;
