import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Atoms } from 'components';
import { getEggFamily } from 'api/fetch_data';

class GeneFamilyInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { genomeAbbr, recordType, id } = this.props;
        getEggFamily(genomeAbbr, id, recordType).then(data => {
            if (!this.__ignoreLastFetch) {
                const { hit_gene, root, score, evalue } = data;
                const dataSource = [
                    { title: 'Hit gene', description: hit_gene },
                    { title: 'eggNOG root family', description: <a href={`http://eggnogapi.embl.de/nog_data/html/tree/${root}`}>{root}</a> },
                    { title: 'Score', description: score },
                    { title: 'E-value', description: evalue },
                    {
                        title: 'Search', description: <Link to={{
                            pathname: '/search/eggNOG_gene_family',
                            state: { 'searchText': root, "species": "All", "genome": "All", "assemble": "All" }
                        }}>Search same family gene</Link>
                    },
                ];
                this.setState({ dataSource });
            }
        });

    };

    render() {
        const { dataSource } = this.state;
        if (dataSource.length === 0) {
            return null;
        }
        return (
            <Atoms.InfoGroup title="Gene family">
                <Atoms.InfoList dataSource={dataSource} />
            </Atoms.InfoGroup>
        );
    }
}

GeneFamilyInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default GeneFamilyInfo;

