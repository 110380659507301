import React from 'react';
import { SvgIcon } from '@material-ui/core';


function EmptyIcon(props) {
    return (
        <SvgIcon viewBox="0 0 512 512" {...props}>
            <path d="M200.000 49.728 C 193.692 51.807,193.609 52.191,194.320 76.356 L 194.923 96.841 197.071 98.989 C 200.056 101.974,204.620 101.985,207.590 99.015 L 209.711 96.894 209.529 81.846 C 209.317 64.392,208.701 54.889,207.650 52.864 C 207.056 51.720,202.335 49.017,201.392 49.280 C 201.271 49.314,200.645 49.516,200.000 49.728 M292.848 70.333 C 292.138 70.748,285.370 77.593,277.809 85.545 C 264.139 99.922,264.063 100.022,264.063 103.552 C 264.063 118.219,279.264 109.858,300.832 83.328 C 307.061 75.667,300.897 65.635,292.848 70.333 M100.000 72.427 C 96.912 73.555,95.313 76.040,95.313 79.707 L 95.313 83.361 109.180 96.607 C 127.211 113.830,127.556 114.046,132.741 111.365 C 140.289 107.462,138.322 103.237,121.462 87.140 C 106.027 72.404,103.953 70.982,100.000 72.427 M81.834 144.083 C 75.022 146.515,72.301 149.992,54.302 179.273 C 43.713 196.500,32.045 217.370,28.422 225.566 L 25.391 232.422 25.178 280.018 C 24.920 337.446,24.853 337.099,37.613 344.167 L 41.797 346.484 199.219 346.484 L 356.641 346.484 360.328 344.781 C 365.439 342.420,370.651 336.821,372.609 331.589 C 375.134 324.838,375.112 237.603,372.583 230.843 C 368.740 220.573,357.020 199.568,338.899 170.475 C 332.932 160.897,327.887 152.635,327.687 152.116 C 327.157 150.744,319.121 145.078,316.183 144.005 C 312.257 142.571,85.860 142.647,81.834 144.083 M309.718 157.013 C 312.779 158.177,315.342 162.328,334.969 197.912 L 353.141 230.859 299.032 231.250 C 260.121 231.531,244.481 231.895,243.351 232.545 C 239.748 234.618,238.848 236.571,237.814 244.558 C 235.386 263.301,222.980 276.727,205.328 279.714 C 183.235 283.453,161.023 265.383,160.954 243.615 C 160.940 239.144,160.646 238.055,158.891 235.969 C 154.734 231.029,157.511 231.250,99.565 231.250 C 57.587 231.250,46.790 231.049,47.108 230.273 C 49.548 224.312,82.600 165.164,85.024 162.422 C 90.959 155.707,80.051 156.288,200.535 156.268 C 268.450 156.257,308.446 156.530,309.718 157.013 " />
        </SvgIcon>
    );
}

export default EmptyIcon;