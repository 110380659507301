import React from 'react';
import PropTypes from 'prop-types';
import {Atoms} from 'components';
import {Link} from 'react-router-dom';
import {getTfInfo} from 'api/fetch_data';

class TfInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        };
        this.header = [
            {key: 'tf_family', content: 'TF family'},
            {key: 'evalue', content: 'E-value'},
            {key: 'hit_score', content: 'Hit score'},
            {key: 'hit_ratio', content: 'Hit ratio'},
            {key: 'search', content: 'Search same TF family gene'},
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    toFixed = (dight, bits = 3) => {
        return Math.round(dight * Math.pow(10, bits)) / Math.pow(10, bits);
    };

    fetchData = () => {
        const {genomeAbbr, recordType, id} = this.props;
        getTfInfo(genomeAbbr, id, recordType).then(data => {
            if (!this.__ignoreLastFetch) {
                const dataSource = data.map(item => ({
                    tf_family: <a
                        href={`http://planttfdb.cbi.pku.edu.cn/family.php?fam=${item['tf_family']}`}>{item['tf_family']}</a>,
                    evalue: item['evalue'],
                    hit_score: item['hit_score'],
                    hit_ratio: this.toFixed(item['hit_ratio']),
                    search: <Link to={{
                        pathname: '/search/tf_family',
                        state: {'searchText': item['tf_family'], "species": "All", "genome": "All", "assemble": "All"}
                    }}>Search</Link>
                }));
                this.setState({dataSource});
            }
        });
    };

    render() {
        const {dataSource} = this.state;

        if (dataSource.length === 0) {
            return null;
        }
        return (
            <Atoms.InfoGroup title="Transcript factor family">
                <Atoms.CustomTable columns={this.header} dataSource={dataSource}/>
            </Atoms.InfoGroup>
        );
    }
}

TfInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default TfInfo;

