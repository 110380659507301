import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

function GenomeInfoIcon({ fill, style: styleProp, ...otherProps }) {
    return (
        <SvgIcon
            {...otherProps}
            style={
                fill ? {
                    width: '100%', height: '100%', ...styleProp
                } : { ...styleProp }
            }
        >
            <g transform="scale(0.04) translate(40,40)">
                <path d="m311.425781 0c-25.746093 0-46.691406 20.945312-46.691406 46.691406v152.691406c0 5.472657-4.453125 9.925782-9.925781 9.925782s-9.925782-4.453125-9.925782-9.925782v-152.691406c.003907-25.746094-20.941406-46.691406-46.6875-46.691406-25.746093 0-46.695312 20.945312-46.695312 46.691406v152.691406c0 20.152344 5.957031 39.863282 16.949219 56.617188-10.992188 16.753906-16.949219 36.464844-16.949219 56.613281v152.695313c0 25.746094 20.949219 46.6875 46.695312 46.6875 25.746094 0 46.691407-20.941406 46.691407-46.6875v-152.691406c0-5.472657 4.449219-9.925782 9.921875-9.925782s9.925781 4.453125 9.925781 9.925782v152.691406c0 25.746094 20.945313 46.691406 46.691406 46.691406 25.746094 0 46.691407-20.945312 46.691407-46.691406v-152.691406c0-20.152344-5.953126-39.863282-16.949219-56.617188 10.996093-16.753906 16.949219-36.464844 16.949219-56.617188v-152.691406c0-25.746094-20.945313-46.691406-46.691407-46.691406zm0 0" />
                <path d="m83.464844 268.714844c0 22.90625-18.574219 41.480468-41.484375 41.480468-22.910157 0-41.480469-18.574218-41.480469-41.480468 0-22.910156 18.570312-41.484375 41.480469-41.484375 22.910156 0 41.484375 18.574219 41.484375 41.484375zm0 0" />
                <path d="m509.121094 268.714844c0 22.90625-18.574219 41.480468-41.484375 41.480468-22.910157 0-41.480469-18.574218-41.480469-41.480468 0-22.910156 18.570312-41.484375 41.480469-41.484375 22.910156 0 41.484375 18.574219 41.484375 41.484375zm0 0" />
                <path d="m406.589844 135.605469h4.566406v4.5625c0 8.285156 6.714844 15 15 15s15-6.714844 15-15v-4.5625h4.5625c8.285156 0 15-6.71875 15-15 0-8.285157-6.714844-15-15-15h-4.5625v-4.566407c0-8.28125-6.714844-15-15-15s-15 6.71875-15 15v4.566407h-4.566406c-8.28125 0-15 6.714843-15 15 0 8.28125 6.71875 15 15 15zm0 0" />
                <path d="m103.027344 105.605469h-4.5625v-4.566407c0-8.28125-6.71875-15-15-15-8.285156 0-15 6.71875-15 15v4.566407h-4.566406c-8.28125 0-15 6.714843-15 15 0 8.28125 6.71875 15 15 15h4.566406v4.5625c0 8.285156 6.714844 15 15 15 8.28125 0 15-6.714844 15-15v-4.5625h4.5625c8.285156 0 15-6.71875 15-15 0-8.285157-6.714844-15-15-15zm0 0" />
                <path d="m445.71875 376.394531h-4.5625v-4.5625c0-8.285156-6.714844-15-15-15s-15 6.714844-15 15v4.5625h-4.566406c-8.28125 0-15 6.71875-15 15 0 8.285157 6.71875 15 15 15h4.566406v4.566407c0 8.28125 6.714844 15 15 15s15-6.71875 15-15v-4.566407h4.5625c8.285156 0 15-6.714843 15-15 0-8.28125-6.714844-15-15-15zm0 0" />
                <path d="m103.027344 376.394531h-4.5625v-4.5625c0-8.285156-6.71875-15-15-15-8.285156 0-15 6.714844-15 15v4.5625h-4.566406c-8.28125 0-15 6.71875-15 15 0 8.285157 6.71875 15 15 15h4.566406v4.566407c0 8.28125 6.714844 15 15 15 8.28125 0 15-6.71875 15-15v-4.566407h4.5625c8.285156 0 15-6.714843 15-15 0-8.28125-6.714844-15-15-15zm0 0" />
            </g>
        </SvgIcon>
    );
}

GenomeInfoIcon.propTypes = {
    fill: PropTypes.bool,
};

export default GenomeInfoIcon;