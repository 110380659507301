import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

function SearchIcon({ fill, style: styleProp, ...otherProps }) {
    return (
        <SvgIcon
            {...otherProps}
            style={fill ? {
                width: '100%', height: '100%', ...styleProp
            } : { ...styleProp }}
        >
            <g transform="translate(-3,27) scale(0.003, -0.003)">
                <path d="M2130 5703 c0 -2644 -3 -2488 60 -2695 122 -393 441 -708 835 -823
                198 -57 72 -55 2683 -55 l2402 0 0 643 0 642 -430 430 -430 430 0 -643 0 -642
                -1967 2 -1968 3 -58 22 c-115 43 -196 124 -240 240 l-22 58 -3 1968 -2 1967
                642 0 643 0 -430 430 -430 430 -642 0 -643 0 0 -2407z"/>
                <path d="M5120 8103 c0 -5 237 -245 527 -535 l528 -528 -791 -792 c-434 -436
                -810 -821 -834 -855 -167 -233 -240 -443 -241 -693 -1 -186 32 -332 113 -495
                64 -130 207 -326 261 -359 15 -9 178 150 1187 1159 l1170 1170 528 -528 c290
                -290 530 -527 535 -527 4 0 7 673 7 1495 l0 1495 -1495 0 c-822 0 -1495 -3
                -1495 -7z"/>
            </g>
        </SvgIcon>
    );
}

SearchIcon.propTypes = {
    fill: PropTypes.bool,
    style: PropTypes.object
};


export default SearchIcon;