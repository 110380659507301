import React from 'react';
import PropTypes from 'prop-types';
import CustomHeader from './Header';
import { IconButton, makeStyles } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { drawerWidth, grayColor } from 'assets/global';
import { LayoutContext } from './MainLayout';
import MenuIcon from '@material-ui/icons/Menu';
import Footer from './Footer';

const useStyles = makeStyles(theme => ({
    menuButton: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    addOnButton: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    scrollContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1
    },
    content: {
        flexGrow: 1,
        backgroundColor: grayColor[10],
        padding: theme.spacing(3),
    },
    contentNoPadding: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    }
}));

function Content({ headerText, textLoading, headerAddOn, contentNoPadding, children }) {
    const classes = useStyles();
    const { toggle } = React.useContext(LayoutContext);
    return (
        <React.Fragment>
            <CustomHeader text={headerText} textLoading={textLoading}>
                <IconButton
                    color="inherit"
                    aria-label="Open drawer"
                    onClick={toggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <div className={classes.addOnButton}>
                    {headerAddOn}
                </div>
            </CustomHeader>
            <div className={classes.toolbar} id="main-layout-content-margin-top" />
            <PerfectScrollbar className={classes.scrollContainer}>
                <main className={contentNoPadding ? classes.contentNoPadding : classes.content}>
                    {children}
                </main>
                <Footer />
            </PerfectScrollbar>
        </React.Fragment>
    );
}

Content.propTypes = {
    contentNoPadding: PropTypes.bool,
    headerText: PropTypes.string,
    children: PropTypes.any,
    headerAddOn: PropTypes.any,
    textLoading: PropTypes.bool
};

export default Content;
