import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon, withStyles } from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        height: '100%'
    }
};

function BlastIcon({ scale, classes }) {
    let svgScale = scale;
    if (!scale) {
        svgScale = 24;
    }
    return (
        <SvgIcon viewBox={`0 0 ${svgScale} ${svgScale}`} className={scale ? classes.root : undefined}>
            <g transform={`scale(${svgScale / 24})`} >
                <path d="M3 3l18 0 c.5.4.5 1.1 0 1.5l-18 0z"/>
                <path d="M5 6l12 0 c.5.4.5 1.1 0 1.5l-12 0z"/>
                <path d="M5 9l15 0 c.5.4.5 1.1 0 1.5l-15 0z"/>
                <path d="M5 12l15 0 c.5.4.5 1.1 0 1.5l-15 0z"/>
                <path d="M3 15l15 0 c.5.4.5 1.1 0 1.5l-15 0z"/>
                <path d="M7 18l14 0 c.5.4.5 1.1 0 1.5l-14 0z"/>
            </g>
        </SvgIcon>
    );
}

BlastIcon.propTypes = {
    scale: PropTypes.number,
    classes: PropTypes.object.isRequired,
    color: PropTypes.string
};

export default withStyles(styles)(BlastIcon);