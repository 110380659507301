import React from 'react';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { Paper, makeStyles } from '@material-ui/core';
import Suggestion from './Suggestion';
import InputComponent from './InputComponent';

const useStyles = makeStyles(theme => ({
    input: {
        // marginLeft: theme.spacing(4),
        flexGrow: 1,
    },
    container: {
        position: 'relative',
        flexGrow: 1
    },
    suggestionsContainerOpen: {
        position: 'absolute',
        zIndex: 1,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    suggestion: {
        display: 'block',
    },
    suggestionsList: {
        margin: 0,
        padding: 0,
        listStyleType: 'none',
    },
}));

export default function AutoComplete({ getSuggestions, inputValue, handleInputChange, placeholder }) {
    const classes = useStyles();

    const [stateSuggestions, setSuggestions] = React.useState([]);

    const handleSuggestionsFetchRequested = ({ value }) => {
        if(getSuggestions!==undefined)
            getSuggestions(value).then(data => setSuggestions(data.data));
    };

    const handleSuggestionsClearRequested = () => {
        setSuggestions([]);
    };


    return (
        <Autosuggest
            className={classes.input}
            renderInputComponent={InputComponent}
            suggestions={stateSuggestions}
            renderSuggestion={Suggestion}
            onSuggestionsFetchRequested={handleSuggestionsFetchRequested}
            onSuggestionsClearRequested={handleSuggestionsClearRequested}
            getSuggestionValue={suggestion => suggestion}
            inputProps={{
                label: placeholder || 'Search...',
                value: inputValue,
                onChange: handleInputChange,
                classes
            }}
            theme={{
                container: classes.container,
                suggestionsContainerOpen: classes.suggestionsContainerOpen,
                suggestionsList: classes.suggestionsList,
                suggestion: classes.suggestion,
            }}
            renderSuggestionsContainer={options => (
                <Paper {...options.containerProps} square style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    {options.children}
                </Paper>
            )}
        />
    );
}

AutoComplete.propTypes = {
    inputValue: PropTypes.string,
    handleInputChange: PropTypes.func,
    placeholder: PropTypes.string,
    getSuggestions: PropTypes.func
};
