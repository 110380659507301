import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Axis, Geom, Tooltip } from 'bizcharts';

class BarPlot extends React.Component {
    render() {
        const { dataSource, xAxis, yAxis } = this.props;
        const scale = {

        };
        return (
            <Chart height={300} data={dataSource} scale={scale}>
                <Axis name={xAxis} title />
                <Axis name={yAxis} />
                <Tooltip
                    crosshairs={{
                        type: 'y'
                    }} />
                <Geom type='interval' position={`${xAxis.index}*${yAxis.index}`} />
            </Chart>
        );
    }
}

BarPlot.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.object).isRequired,
    xAxis: PropTypes.shape({
        index: PropTypes.string,
        content: PropTypes.string
    }),
    yAxis: PropTypes.shape({
        index: PropTypes.string,
        content: PropTypes.string
    })
};

export default BarPlot;