import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Tooltip, Coord, View, Axis, Shape } from 'bizcharts';
import { max } from 'lodash';

import { serializeBinaryTree, allocatePosition } from 'tools/EvolutionTree';

Shape.registerShape('edge', 'phyloTreePath', {
    draw(cfg, container) {
        const drawPoints = this.parsePoints(cfg.points);
        container.addShape('line', {
            attrs: {
                x1: drawPoints[0].x,
                y1: drawPoints[0].y,
                x2: drawPoints[0].x,
                y2: drawPoints[1].y,
                stroke: cfg.color,
                lineWidth: 2,
            }
        });
        return container.addShape('line', {
            attrs: {
                x1: drawPoints[0].x,
                y1: drawPoints[1].y,
                x2: drawPoints[1].x,
                y2: drawPoints[1].y,
                stroke: cfg.color,
                lineWidth: 2,
            }
        });
    }
});


function TreePlotWithoutDistance({ dataSource, width, heightPerDataGroup, padding }) {
    const treeWithPos = allocatePosition(dataSource);
    const serializedTree = serializeBinaryTree(treeWithPos);

    const maxLevel = max(serializedTree.map(node => node.level));
    const leafPoints = serializedTree
        .filter(node => node.type === 'leaf')
        .map((node) => ({
            position: node.position,
            name: node.hash_name,
            level: maxLevel
        }));
    const pointCount = leafPoints.length;
    const pointData = [{position: 0, level: 0}, ...leafPoints, {position: pointCount+1, level:0}];
    

    const edgeData = [];
    const recursion = (node) => {
        if (node.children && node.children.length === 2) {
            const parent = serializedTree.find(item => item.id === node.id);
            const child1 = serializedTree.find(item => item.id === node.children[0].id);
            const child2 = serializedTree.find(item => item.id === node.children[1].id);
            edgeData.push({
                position: [parent.position, child1.position],
                level: [parent.level, child1.type === 'leaf'? maxLevel: child1.level]
            });
            edgeData.push({
                position: [parent.position, child2.position],
                level: [parent.level, child2.type === 'leaf'? maxLevel: child2.level]
            });
            recursion(node.children[0]);
            recursion(node.children[1]);
        }
    };
    recursion(treeWithPos);

    const scale = {
        level: { min: 0, max: maxLevel },
        position: { min: 0, max: pointCount+1}
    };
    

    return (
        <Chart
            forceFit={width === undefined}
            width={width}
            height={heightPerDataGroup * (pointData.length) + padding[0] + padding[2]}
            padding={padding}
            scale={scale}
        >
            <Coord transpose reflect="y"/>
            <Axis name="level"/>
            <Axis name="position"/>
            <Tooltip showTitle={false} />
            <View data={edgeData} scale={scale}>
                <Geom
                    type="edge"
                    position="position*level"
                    shape="vh"
                    color="grey"
                    opacity={0.5}
                    tooltip={false}
                />
            </View>
            <View data={pointData} scale={scale}>
                <Geom
                    type="point"
                    position="position*level"
                    size={['level', (level) => level===0?0:3]}
                    tooltip={false}
                >
                    {/* <Label
                        content="name"
                        textStyle={{
                            fill: 'grey',
                            fontSize: 14,
                            textBaseline: 'middle',
                            textAlign: 'left'
                        }}
                        offset={5}
                    /> */}
                </Geom>
            </View>
        </Chart>
    );

}

TreePlotWithoutDistance.propTypes = {
    dataSource: PropTypes.shape({
        branchLength: PropTypes.number,
        children: PropTypes.arrayOf(PropTypes.object),
    }),
    heightPerDataGroup: PropTypes.number,
    padding: PropTypes.arrayOf(PropTypes.number),
    width: PropTypes.number,
    height: PropTypes.number
};

export default TreePlotWithoutDistance;