import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Axis, Guide, Tooltip } from 'bizcharts';
import { chartColors as colors } from 'assets/global';
import { max, min, flatMap } from 'lodash';

function ColinearGeneDiagram({ pairList, chromosome, pairedChromosome, withReverse, highLightDataList }) {
    let chromStart = 0, chromEnd = 0, pairChromStart = 0, pairChromEnd = 0;
    if (chromosome && pairedChromosome && pairList.length > 0) {
        const chrom = flatMap(pairList, pair => [pair.this_assemble.start, pair.this_assemble.end]);
        const pairChrom = flatMap(pairList, pair => [pair.paired_assemble.start, pair.paired_assemble.end]);
        chromStart = min(chrom);
        chromEnd = max(chrom);
        pairChromStart = min(pairChrom);
        pairChromEnd = max(pairChrom);
    }
    const maxRange = max([chromEnd - chromStart, pairChromEnd - pairChromStart]);

    const links = [];
    const data = [];

    pairList.forEach(pair => {
        const gene = pair.this_assemble;
        data.push({
            pair_id: pair.pair_id,
            chromosome: chromosome,
            locus_id: gene.locus_id,
            name: gene.name,
            range_1: [gene.start - chromStart, gene.end - chromStart],
            range_2: [],
            center: (gene.end + gene.start) / 2 - chromStart
        });

        const gene2 = pair.paired_assemble;
        if (withReverse) {
            data.push({
                pair_id: pair.pair_id,
                chromosome: pairedChromosome,
                locus_id: gene2.locus_id,
                name: gene2.name,
                range_1: [],
                range_2: [pairChromEnd - gene2.start, pairChromEnd - gene2.end],
                center: pairChromEnd - (gene2.end + gene2.start) / 2
            });
        } else {
            data.push({
                pair_id: pair.pair_id,
                chromosome: pairedChromosome,
                locus_id: gene2.locus_id,
                name: gene2.name,
                range_1: [],
                range_2: [gene2.start - pairChromStart, gene2.end - pairChromStart],
                center: (gene2.end + gene2.start) / 2 - pairChromStart
            });
        }


        links.push({
            pair_id: pair.pair_id,
            center1: (gene.end + gene.start) / 2 - chromStart,
            center2: withReverse ? (pairChromEnd - (gene2.end + gene2.start) / 2) : ((gene2.end + gene2.start) / 2 - pairChromStart)
        });
    });

    const scale = {
        range_1: {
            min: 0,
            max: maxRange,
            formatter: (range) => range + chromStart
        },
        range_2: {
            min: 0,
            max: maxRange,
            formatter: (range) => withReverse ? (pairChromEnd - range) : (range + pairChromStart)
        },
        center: {
            min: 0,
            max: maxRange,
        }
    };

    return (
        <Chart data={data} scale={scale} padding={[20, 60, 40, 60]}>
            <Axis name="chromosome" />
            <Axis name="range_1" position="left" />
            <Axis name="range_2" position="right" />

            <Axis name="center" visible={false} />
            <Tooltip showTitle={false} />
            <Geom
                type="point"
                position="chromosome*center"
                color={['pair_id', (pair_id) => colors[pair_id % 12]]}
                shape="circle"
                tooltip={['name*range_1*range_2', (name, range_1, range_2) => {
                    if (range_1.length === 0) {
                        return {
                            name: name,
                            value: `${range_2[0]}bp - ${range_2[1]}bp`
                        };
                    } else {
                        return {
                            name: name,
                            value: `${range_1[0]}bp - ${range_1[1]}bp`
                        };
                    }
                }]}
                size={['locus_id', (locus_id) => highLightDataList.indexOf(locus_id) === -1 ? 6 : 12]}
            />


            <Geom
                type="interval"
                position="chromosome*range_1"
                color={['pair_id', (pair_id) => colors[pair_id % 12]]}
                size={8}
                tooltip={false}
            />
            <Geom
                type="interval"
                position="chromosome*range_2"
                color={['pair_id', (pair_id) => colors[pair_id % 12]]}
                size={8}
                tooltip={false}
            />


            <Guide>
                {links.map((link, index) => (
                    <Guide.Line
                        key={index}
                        start={{
                            chromosome: chromosome,
                            range_1: link.center1
                        }}
                        end={{
                            chromosome: pairedChromosome,
                            range_2: link.center2
                        }}
                        lineStyle={{
                            stroke: colors[link.pair_id % 12],
                            lineDash: [0, 0, 0],
                            lineWidth: 1,
                        }}
                    />
                ))}
            </Guide>
        </Chart>
    );


}

ColinearGeneDiagram.propTypes = {
    pairList: PropTypes.arrayOf(PropTypes.shape({
        pair_id: PropTypes.number,
        this_assemble: PropTypes.object,
        paired_assemble: PropTypes.object
    })),
    chromosome: PropTypes.string,
    pairedChromosome: PropTypes.string,
    withReverse: PropTypes.bool
};

export default ColinearGeneDiagram;