import React from 'react';
import PropTypes from 'prop-types';
import { Atoms, Layouts, Charts } from 'components';
import { Grid, Button, Typography, withStyles, RadioGroup, FormControlLabel, Radio, Collapse } from '@material-ui/core';
import { getPlazaConsGenome, getMataInfo, getGenomeColinearInfo } from 'api/fetch_data';

import Examples from 'example/GenomeSynExample';

const styles = {
    formText: {
        fontSize: '16px',
        marginLeft: '24px',
        fontWeight: 'bold',
        lineHeight: '56px'
    }
};

const initialState = {
    queryGenomeList: [], targetGenomeList: [],
    // Form
    querySpecies: '', queryGenome: '', targetSpecies: '', targetGenome: '',
    source: 'MaGen', plazaGenome: '',
    // Chart data
    queryGenomeInfo: [], targetGenomeInfo: [], syntenyData: []
};

class GenomeSyntenyViewerView extends React.Component {
    constructor(props) {
        super(props);
        this.tabsRef = React.createRef();
        this.state = {
            // render select control
            magenGenomeData: [],
            plazaGenomeData: [],
            ...initialState
        };
    }


    componentDidMount() {
        this.renderSelect();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    renderSelect = async () => {
        const magenGenomeData = await getMataInfo();
        const plazaGenomeData = await getPlazaConsGenome();
        this.setState({ magenGenomeData, plazaGenomeData });
    }

    // Form events
    handleQuerySpeciesChange = (event) => {
        const species = event.target.value;
        const queryGenomeList = this.state.magenGenomeData.find(item => item['abbr_name'] === species)['genome_li'];
        this.setState({ querySpecies: species, queryGenomeList, queryGenome: '' });
    }
    handleTargetSpeciesChange = (event) => {
        const species = event.target.value;
        const targetGenomeList = this.state.magenGenomeData.find(item => item['abbr_name'] === species)['genome_li'];
        this.setState({ targetSpecies: species, targetGenomeList, targetGenome: '' });
    }
    handleQueryGenomeChange = (event) => {
        if (event.target.value === this.state.targetGenome) {
            this.setState({ queryGenome: event.target.value, targetGenome: '' });
        } else {
            this.setState({ queryGenome: event.target.value });
        }
    }
    handleTargetGenomeChange = (event) => this.setState({ targetGenome: event.target.value });
    handlePlazaGenomeChange = (event) => this.setState({ plazaGenome: event.target.value });
    handleSourceChange = (event) => this.setState({ source: event.target.value });
    handleSubmit = async () => {
        const { queryGenome, targetGenome, source, plazaGenome } = this.state;
        const target = source === 'PLAZA' ? plazaGenome : targetGenome;
        const response = await getGenomeColinearInfo(queryGenome, target, source);
        if (response && !this.__ignoreLastFetch) {
            const { query_genome_size: queryGenomeInfo, target_genome_size: targetGenomeInfo, data: syntenyData } = response;
            this.setState({ queryGenomeInfo, targetGenomeInfo, syntenyData });
        }
    }
    handleClear = () => this.setState({ ...initialState });

    applyExample = ({ querySpecies, queryGenome, targetSpecies, targetGenome, source }) => (event) => {
        const { magenGenomeData } = this.state;
        if (source === 'MaGen') {
            const queryGenomeList = magenGenomeData.find(item => item['abbr_name'] === querySpecies)['genome_li'];
            const targetGenomeList = magenGenomeData.find(item => item['abbr_name'] === targetSpecies)['genome_li'];
            this.setState({ querySpecies, queryGenome, queryGenomeList, targetSpecies, targetGenome, targetGenomeList, source });
        } else if (source === 'PLAZA') {
            const queryGenomeList = magenGenomeData.find(item => item['abbr_name'] === querySpecies)['genome_li'];
            this.setState({ querySpecies, queryGenome, plazaGenome: targetGenome, source, queryGenomeList });
        }
        this.tabsRef.current.handleChange(event, 0);
    };

    render() {
        const { classes } = this.props;
        const { magenGenomeData, plazaGenomeData, source, plazaGenome,
            querySpecies, queryGenome, queryGenomeList,
            targetSpecies, targetGenome, targetGenomeList,
            queryGenomeInfo, targetGenomeInfo, syntenyData
        } = this.state;

        const speciesOptions = magenGenomeData.map(item => ({
            value: item.abbr_name,
            text: item.name
        }));
        const queryGenomeOptions = queryGenomeList.map(item => ({
            value: item.abbr_name,
            text: item.abbr_name
        }));
        const targetGenomeOptions = targetGenomeList.map(item => ({
            value: item.abbr_name,
            text: item.abbr_name
        })).filter(item => item.value !== queryGenome);

        const plazaGenomeOptions = plazaGenomeData.map(item => ({
            value: item.abbr_name,
            text: item.name
        }));

        return (
            <Layouts.MainLayoutContent headerText="Genome Synteny Viewer">
                <Atoms.ExampleCard
                    ref={this.tabsRef}
                    title="Choose Genomes to View:"
                    formContent={<Grid container spacing={4}>
                        <Grid item xs={12} md={2}>
                            <Typography className={classes.formText} variant="body2">Source:</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <RadioGroup value={source} onChange={this.handleSourceChange} row aria-label="target-source">
                                <FormControlLabel
                                    value="PLAZA"
                                    control={<Radio color="primary" />}
                                    label="Plaza DataBase"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="MaGen"
                                    control={<Radio color="primary" />}
                                    label="MaGenDb"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Typography className={classes.formText} variant="body2">Query:</Typography>
                        </Grid>
                        <Grid container item xs={12} md={10} spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Atoms.SelectControl
                                    value={querySpecies}
                                    handleChange={this.handleQuerySpeciesChange}
                                    placeholder="Query Species"
                                    options={speciesOptions}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Atoms.SelectControl
                                    value={queryGenome}
                                    handleChange={this.handleQueryGenomeChange}
                                    placeholder="Query Genome"
                                    options={queryGenomeOptions}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={2}>
                            <Typography className={classes.formText} variant="body2">Target:</Typography>
                        </Grid>
                        <Grid container item xs={12} md={10} spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Collapse in={source === 'MaGen'}>
                                    <Atoms.SelectControl
                                        value={targetSpecies}
                                        handleChange={this.handleTargetSpeciesChange}
                                        placeholder="Target Species"
                                        options={speciesOptions}
                                        variant="outlined"
                                    />
                                </Collapse>
                                <Collapse in={source === 'PLAZA'}>
                                    <Atoms.SelectControl
                                        value={plazaGenome}
                                        handleChange={this.handlePlazaGenomeChange}
                                        placeholder="Target Genome"
                                        options={plazaGenomeOptions}
                                        variant="outlined"
                                    />
                                </Collapse>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Collapse in={source === 'MaGen'}>
                                    <Atoms.SelectControl
                                        value={targetGenome}
                                        handleChange={this.handleTargetGenomeChange}
                                        placeholder="Target Genome"
                                        options={targetGenomeOptions}
                                        variant="outlined"
                                    />
                                </Collapse>
                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={2} justify="flex-end">
                            <Grid item xs={3} style={{ textAlign: 'right' }}>
                                <Button onClick={this.handleClear} color="secondary">Clear</Button>
                                <Button
                                    color="primary"
                                    disabled={queryGenome === '' || (source === 'MaGen' && targetGenome === '') || (source === 'PLAZA' && plazaGenome === '')}
                                    onClick={this.handleSubmit}
                                >Submit</Button>
                            </Grid>
                        </Grid>
                    </Grid>}
                    exampleContent={<div>
                        {Examples.map((item, index) => 
                            <Button onClick={this.applyExample(item)} key={index}>{item.title}</Button>
                        )}
                    </div>}
                />
                <Charts.CircleDiagram
                    queryGenomeInfo={queryGenomeInfo}
                    targetGenomeInfo={targetGenomeInfo}
                    syntenyData={syntenyData}
                />
            </Layouts.MainLayoutContent>
        );
    }
}

GenomeSyntenyViewerView.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(GenomeSyntenyViewerView);
