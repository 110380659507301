import React from 'react';
import { Route } from 'react-router-dom';

class RouteWrapper extends React.Component {
    _onRender = (renderProps) => {
        const {
            component: Component,
            exact,
            computedMatch,
            location,
            withImplicitFilter,
            forceUpdateWithLocation,
            ...restProps
        } = this.props;
        return (
            <Component
                key={forceUpdateWithLocation ? location.pathname+location.search : undefined}
                filterParams={withImplicitFilter ? location.state : undefined}
                {...renderProps}
                {...restProps}
            />
        );
    }

    render() {
        const { path, exact } = this.props;
        return (
            <Route
                path={path}
                exact={exact}
                render={this._onRender}
            />
        );
    }
}

export default RouteWrapper;