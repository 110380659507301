import React from 'react';

import { Atoms, Layouts } from 'components';
import { getStaticMarkdownText } from 'api/staticFile';

function DocView () {
    return (
        <Layouts.MainLayoutContent headerText="Documentation">
            <Atoms.MarkdownPaper
                getFile={getStaticMarkdownText}
                fileName="UserGuide.md"
            />
        </Layouts.MainLayoutContent>
    );
}

export default DocView;
