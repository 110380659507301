import React from 'react';
import PropTypes from 'prop-types';
import { Layouts, Atoms } from 'components';
import {
    TransSummaryInfo, Ontology,
    TfInfo, TransDomainInfo, Annotation, IvalMsInfo,
    PPINetworkInfo, GeneFamilyInfo, ColinearInfo, Prot3D
} from 'pages/wiki/components';

import { getName } from 'api/fetch_data';
import { getCompareListData, storage } from 'api/storage';

class TransWiki extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            transName: '',
            speciesName: '',
            trans:{
                chrom: '',
                start: '',
                end: '',
                strand: '',
                exon_len: '',
            },
            compareListData: {
                geneList: [],
                transcriptionList: [],
                proteinList: []
            }
        };
    }

    setTrans = (trans)=> this.setState({trans: trans});

    componentDidMount() {
        this.fetchData();
        this.setState({ compareListData: getCompareListData() });
    }

    addTransToCompare = () => {
        const { match:{params}} = this.props;
        storage.appendRecord('transcriptionList', {
            name: this.state.transName,
            ...params
        });
        this.setState({ compareListData: getCompareListData() });
    };

    removeTransFromCompare = (category, trans) => {
        storage.removeRecord(category, trans);
        this.setState({ compareListData: getCompareListData() });
    };


    fetchData = () => {
        const { genomeAbbr, id } = this.props.match.params;
        getName(genomeAbbr, id, 'Trans')
            .then(data => {
                this.setState({
                    transName: data.name,
                    speciesName: data.species_name,
                });
            });
    };

    render() {
        const { match:{params}} = this.props;
        const { transName, trans, speciesName, compareListData } = this.state;
        return (
            <Layouts.MainLayoutContent headerText={`${transName} (${speciesName})`} textLoading={transName===''}>
                <TransSummaryInfo setTrans={this.setTrans} {...params} />
                <IvalMsInfo {...params} recordType="Trans" ival={trans}/>
                <Annotation {...params} recordType="Trans"/>
                <GeneFamilyInfo {...params} recordType="Trans"/>
                <TfInfo {...params} recordType="Trans"/>
                <Ontology {...params} recordType="Trans"/>
                <TransDomainInfo trans={trans} {...params} />
                <ColinearInfo {...params} recordType="Trans"/>
                <PPINetworkInfo {...params} recordType="Trans"/>
                <Prot3D {...params} recordType="Trans"/>

                <Atoms.CompareList 
                    dataSource={compareListData} 
                    onClickAdd={this.addTransToCompare}
                    onDelete={this.removeTransFromCompare}
                />
            </Layouts.MainLayoutContent>
        );
    }
}

TransWiki.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object
};

export default TransWiki;
