import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import CustomSlider from './CustomSlider';
import { withStyles, colors } from '@material-ui/core';

const spacingUnit = (value) => `${value * 9}px`;
const styles = theme => ({
    root: {
        display: 'flex',
        maxWidth: spacingUnit(115),
        margin: 'auto',
        width: 'auto',
        border: `2px solid ${colors.blue[600]}`,
        borderRadius: '3px',
        boxShadow: `0 0 5px ${colors.blue[300]} inset`,
    },
    labelBox: {
        display: 'flex',
        flexDirection: 'column',
        '&:first-child': {
            marginTop: '20px',
        },
    },
    label: {
        fontFamily: 'Consolas, Monaco, monospace',
        whiteSpace: 'nowrap',
        marginLeft: spacingUnit(2),
        height: '22px',
        paddingLeft: '15px',
        paddingRight: '20px',
        marginBottom: '5px',
        '-webkitClipPath': 'polygon(0 100%, 8px 50%, 0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%)',
        clipPath: 'polygon(0 100%, 8px 50%, 0 0, calc(100% - 8px) 0, 100% 50%, calc(100% - 8px) 100%)',
        color: '#fff'
    },
    unit: {
        width: spacingUnit(1),
        fontSize: spacingUnit(2),
        flexShrink: 0,
        textTransform: 'uppercase',
        backgroundColor: colors.grey[300]
    },
    decoratorBaseG: {
        backgroundColor: `${colors.orange[500]} !important`,
    },
    decoratorBaseT: {
        backgroundColor: `${colors.red[500]} !important`,
    },
    decoratorBaseA: {
        backgroundColor: `${colors.green[500]} !important`,
    },
    decoratorBaseC: {
        backgroundColor: `${colors.blue[500]} !important`,
    },
    // polar amino acids (G,S,T,Y,C,Q,N) are green
    decoratorAminoAcidG: { backgroundColor: `${colors.teal[600]} !important`, color: '#fff', },
    decoratorAminoAcidS: { backgroundColor: `${colors.teal['A400']} !important`, },
    decoratorAminoAcidT: { backgroundColor: `${colors.green[600]} !important`, color: '#fff', },
    decoratorAminoAcidY: { backgroundColor: `${colors.green['A400']} !important`, },
    decoratorAminoAcidC: { backgroundColor: `${colors.lightGreen[600]} !important`, },
    decoratorAminoAcidQ: { backgroundColor: `${colors.lightGreen['A400']} !important`, },
    decoratorAminoAcidN: { backgroundColor: `${colors.lightGreen['A700']} !important`, },
    // basic (K,R,H) blue
    decoratorAminoAcidK: { backgroundColor: `${colors.blue[600]} !important`, color: '#fff', },
    decoratorAminoAcidR: { backgroundColor: `${colors.blue['A400']} !important`, color: '#fff', },
    decoratorAminoAcidH: { backgroundColor: `${colors.lightBlue[600]} !important`, color: '#fff', },
    // acidic (D,E) red
    decoratorAminoAcidD: { backgroundColor: `${colors.red[600]} !important`, color: '#fff', },
    decoratorAminoAcidE: { backgroundColor: `${colors.pink[600]} !important`, color: '#fff', },
    // hydrophobic (A,V,L,I,P,W,F,M) amino acids are black
    decoratorAminoAcidA: { backgroundColor: `${colors.brown[900]} !important`, color: '#fff', },
    decoratorAminoAcidV: { backgroundColor: `${colors.grey[900]} !important`, color: '#fff', },
    decoratorAminoAcidL: { backgroundColor: `${colors.blueGrey[900]} !important`, color: '#fff', },
    decoratorAminoAcidI: { backgroundColor: '#342c1a !important', color: '#fff', },
    decoratorAminoAcidP: { backgroundColor: '#23311f !important', color: '#fff', },
    decoratorAminoAcidW: { backgroundColor: '#14322e !important', color: '#fff', },
    decoratorAminoAcidF: { backgroundColor: '#2f2f3a !important', color: '#fff', },
    decoratorAminoAcidM: { backgroundColor: '#382d34 !important', color: '#fff', },
    container: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        overflow: 'hidden',
        height: spacingUnit(20),
        fontSize: '16px',
    },
    anchor: {
        width: 0,
        height: 0,
        margin: 0,
        zIndex: 199,
        borderTop: `${spacingUnit(2)} solid ${colors.blue[800]}`,
        borderLeft: `${spacingUnit(2)} solid transparent`,
        borderRight: `${spacingUnit(2)} solid transparent`,
        marginLeft: spacingUnit(2.5),
    },
    sequenceContainer: {
        fontFamily: 'Consolas, Monaco, monospace',
        display: 'flex',
        marginLeft: spacingUnit(4),
        marginBottom: '5px'
    },
    ruler: {
        height: spacingUnit(8),
        marginTop: '5px',
        marginLeft: spacingUnit(3.5),
        fontFamily: 'Consolas, Monaco, monospace',
        display: 'flex'
    },
    'rulerBackground': {
        width: spacingUnit(10),
        textAlign: 'right',
        background: 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAWYAAABsCAYAAABZyhjEAAAABHNCSVQICAgIfAhkiAAAAAFzUkdCAK7OHOkAAAAEZ0FNQQAAsY8L/GEFAAAACXBIWXMAABTHAAAUxwHqjcpQAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAACF0RVh0Q3JlYXRpb24gVGltZQAyMDE5OjA2OjI1IDIzOjAwOjM4o2UUlgAABNhJREFUeF7t2j9sTW8YwPH3to0/dUmUoUPTQQwkRhGRhsViMIiabWIQu1gsRpGYxGYyqHQziYSIiJjVICRNh8ZgIVWC677ymnrudSpvf33yy+eTvHhO3POennvuV3Kjk1Lq9RcAQYyU3wEIQpgBghFmgGCEGSAYYQYIRpgBghFmgGCG/j/mu3fvpunp6TLV8e3bt3TmzJm0srJSjrSzffv29PDhwzLV8/jx43Tjxo11X8+xY8fS9evXy1TP1atX0/Pnz8vUzvj4eLpy5UqamZkpR+o5depUWl1dLVM7+Xrm5+fTli1bypE6FhcX0/nz58vU3okTJ9K1a9fKVM+tW7d+/5zrMTo6mi5evJhmZ2fLkXrOnj2bPn78WKZ2du7cme7cuZMmJyfLkTqWl5fThQsX0qdPn8qRdiYmJtKDBw/KVM/c3Fy6fft2+vHjRznSTm7V5cuXy1RPfh6fPHlSpmY5zI1rYWGhV9uXL196/Yehcb9hq9vtljPUde/evX+6ntOnT5cz1JXP27TfsJWvvx+Icoa6duzY0bjnsJWvJ7/PteXnsWm/v61+BMsZ6rp06VLjfsPW2NhY7+bNm+UMdfXj2rjnsLV79+7e+/fvyxnqyefM527ac9jKP8NGyPc83/umPYet/B5vhPxMNu33Z/kqAyAYYQYIRpgBghFmgGCEGSAYYQYIRpgBghFmgGCEGSAYYQYIRpgBghFmgGCEGSAYYQYIRpgBghFmgGCEGSAYYQYIRpgBghFmgGCEGSAYYQYIRpgBghFmgGCEGSCYTQnz6upq2rNnz7pWr9crr64vn7tpz0Fr165daWxsrLy6rnzefP6mfQetjbw3WdOew9bXr1/LK//fRkdHU7fbbbwHg9bWrVvLq+vLz87ExETjvoNW/ixulPV+zvO1b9TnKsv3vmnfQSu/t/k93gyd/hr4qV5YWEgHDhwoUx0/f/5MT58+LVN7+Q2bmZkpUz0fPnxIr1+/LlN7k5OT1e9N9ubNm7S8vFym9g4dOpT27t1bpnqePXuWvn//Xqb2jh8/nkZG6v67n+/NwYMHy9Te7Oxsun//fpnqeffuXVpcXCxTe/v3709TU1Nlqufly5dpZWWlTO0dPXo0bdu2rUx15Ci/ePGiTO2Nj4+nI0eOlKmepaWl9Pbt2zK1Nz09nfbt21emes6dO5fm5ubKtNZ/Hmb4V9HCDP/qb2H2HTNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQjzADBCDNAMMIMEIwwAwQz0pd/WbMgqqbnddDqdDq/F0Ty57lsembz6szPz/fK313j5MmTqdvtlgk21+fPn9OjR4/K1N7U1FQ6fPhwmWDzvXr1Ki0tLZVprU6vr/wZgAB8ZwEQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AwwgwQjDADBCPMAMEIM0AoKf0CQd3Bk+OsdK0AAAAASUVORK5CYII=") top left no-repeat',
        backgroundSize: 'contain'
    },
    'rulerScale': {
        position: 'relative',
        top: spacingUnit(4),
        right: '-0.3em'
    },

});

class SequenceCompare extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sliderValue: 1
        };
        this.rulerScale = [];
        for (let i = 0; i < props.sourceSequence.sequence.length; i += 10) {
            this.rulerScale.push(i + 10);
        }
    }

    handleChange = (event, value) => {
        this.setState({ sliderValue: value });
    }

    render() {
        const { classes, sourceSequence, targetSequence, type, maxWidth } = this.props;
        const { sliderValue } = this.state;
        return (
            <div className={classes.root} style={{maxWidth: `${maxWidth} !important`}}>
                <div className={classes.labelBox}>
                    <div
                        className={classes.label}
                        style={{
                            backgroundColor: colors.orange[800],
                            boxShadow: `0 0 2px ${colors.orange[900]} inset`,
                        }}
                    >{sourceSequence.description}</div>
                    <div
                        className={classes.label}
                        style={{
                            backgroundColor: colors.green[800],
                            boxShadow: `0 0 2px ${colors.green[900]} inset`,
                        }}
                    >{targetSequence.description}</div>
                </div>
                <div className={classes.container}>
                    <div className={classes.anchor}></div>
                    <div>
                        {[sourceSequence, targetSequence].map((sequenceInfo, containerIndex) => {
                            const sequenceData = sequenceInfo.sequence;
                            return (
                                <div
                                    key={containerIndex}
                                    className={classes.sequenceContainer}
                                    style={{
                                        transform: `translateX(-${spacingUnit(sliderValue - 1)})`
                                    }}
                                >
                                    {sequenceData.split('').map((unit, index) => {
                                        const decorator = 'decorator' + ({dna: 'Base', protein: 'AminoAcid'})[type];
                                        return <div
                                            key={index}
                                            className={
                                                clsx(sourceSequence.sequence[index] === targetSequence.sequence[index] && classes[decorator + unit],
                                                    classes.unit)
                                            }
                                        >{unit}</div>;
                                    })}
                                </div>
                            );
                        })}
                        <div>
                            <div
                                className={classes.ruler}
                                style={{
                                    width: spacingUnit(Math.ceil(sourceSequence.sequence.length / 10) * 10),
                                    transform: `translateX(-${spacingUnit(sliderValue - 1)})`
                                }}
                            >
                                {this.rulerScale.map((item, index) =>
                                    <div key={index} className={classes.rulerBackground}>
                                        <span className={classes.rulerScale}>{item}</span>
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                    {sourceSequence.sequence.length > 50 && <CustomSlider
                        style={{ width: '90%', alignSelf: 'center' }}
                        max={sourceSequence.sequence.length}
                        valueLabelDisplay="auto"
                        aria-label="Sequence slider"
                        value={sliderValue}
                        onChange={this.handleChange}
                        defaultValue={1}
                        min={1}
                    />}
                </div>

            </div>

        );
    }

}


SequenceCompare.propTypes = {
    classes: PropTypes.object,
    type: PropTypes.oneOf(['dna', 'protein']),
    sourceSequence: PropTypes.shape({
        description: PropTypes.string,
        sequence: PropTypes.string
    }),
    targetSequence: PropTypes.shape({
        description: PropTypes.string,
        sequence: PropTypes.string
    }),
};

export default withStyles(styles)(SequenceCompare);
