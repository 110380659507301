import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import IvalPfamDomain from './IvalPfamDomain';
import IvalInterproDomain from './IvalInterproDomain';
import IvalCddDomain from './IvalCddDomain';
import IvalSignalpDomain from './IvalSignalpDomain';
import IvalTmhmmDomain from './IvalTmhmmDomain';
import TransRg4Region from './TransRg4Region';


function TransDomainInfo({trans, ...otherProps}) {
    return (
        <Atoms.InfoGroup title="Functional elements">
            <IvalInterproDomain ival={trans} {...otherProps} recordType="Trans"/>
            <IvalCddDomain ival={trans} {...otherProps} recordType="Trans"/>
            <IvalPfamDomain ival={trans} {...otherProps} recordType="Trans"/>
            <IvalSignalpDomain ival={trans} {...otherProps} recordType="Trans"/>
            <IvalTmhmmDomain ival={trans} {...otherProps} recordType="Trans"/>
            <TransRg4Region ival={trans} {...otherProps}/>
        </Atoms.InfoGroup>
    );
}

TransDomainInfo.propTypes = {
    trans: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default TransDomainInfo;

