import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import { getSummary, getProtSeq } from 'api/fetch_data';

const { InfoGroup, InfoList, ChipDownloadLink, CustomLink } = Atoms;

class ProtSummaryInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = async () => {
        const { speciesAbbr, genomeAbbr, id, setProt } = this.props;

        const data = await getSummary(genomeAbbr, id, 'Prot');
        if (!this.__ignoreLastFetch && data) {
            const {
                species_name, taxon_id,
                prot_name, ms_support, aa_num, mw,
                locus_hash_name, locus_name, trans_hash_name, trans_name,
                chrom, start, end, strand, exon_len, aa_len, tair_symbol
            } = data;
            const dataSource = [
                { title: 'Protein name', description: prot_name },
                { title: 'TAIR gene symbol', description: tair_symbol },
                {
                    title: 'Taxonomy',
                    description: <CustomLink
                        externalTarget="NCBI"
                        href={`https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=${taxon_id}`}
                    >{`${species_name} (${taxon_id})`}</CustomLink>
                },
                {
                    title: 'Position',
                    description: <div>
                        {`${chrom}: ${start} - ${end}`}
                        <CustomLink
                            to={`/jbrowse?species_abbr=${speciesAbbr}&genome_abbr=${genomeAbbr}&chrom=${chrom}&start=${start}&end=${end}`}
                            style={{ marginLeft: '5px' }}
                        >View this protein</CustomLink>
                        <ChipDownloadLink
                            downloadParams={[genomeAbbr, id]}
                            downloadPromise={getProtSeq}
                            fileName="download.fasta"
                            style={{ marginLeft: '10px' }}
                        >Get Sequence</ChipDownloadLink>
                    </div>
                },
                { title: 'Gene locus', description: <CustomLink to={`/wiki/gene/${speciesAbbr}/${genomeAbbr}/${locus_hash_name}`}>{locus_name}</CustomLink> },
                { title: 'Transcript', description: <CustomLink to={`/wiki/transcript/${speciesAbbr}/${genomeAbbr}/${trans_hash_name}`}>{trans_name}</CustomLink> },
                { title: 'MS support', description: ms_support.toString() },
                { title: 'Amino acid number', description: aa_num },
                { title: 'Molecular weight', description: <div>{`${mw} kDA`}</div> },
            ];
            this.setState({ dataSource });
            setProt({ chrom, start, end, strand, exon_len, aa_len });
        }
    };

    render() {
        const { dataSource } = this.state;

        return (
            <InfoGroup title="Summary" defaultExpanded>
                <InfoList dataSource={dataSource} />
            </InfoGroup>
        );
    }
}

ProtSummaryInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    setProt: PropTypes.func,
};

export default ProtSummaryInfo;
