import React from 'react';
import { Atoms } from 'components';

function SeqAlign({ seq1Name, seq2Name,  align }) {
    return (
        <Atoms.InfoGroup title="Sequence alignment">
            <Atoms.InfoList dataSource={[
                { title: 'Source name', description: seq1Name },
                { title: 'Source length', description: align.seq1_len },
                { title: 'Target name', description: seq1Name },
                { title: 'Target length', description: align.seq2_len },
                { title: 'Coverage', description: align.coverage_num },
                { title: 'Identify', description: align.identify_num },
            ]} />
            <Atoms.SequenceCompare
                maxWidth="calc(100% - 1px)"
                type="protein"
                sourceSequence={{
                    description: seq1Name,
                    sequence: align.align_seq1,
                }}
                targetSequence={{
                    description: seq2Name,
                    sequence: align.align_seq2,
                }}
            />
        </Atoms.InfoGroup>
    );
}
export default SeqAlign;