import React from 'react';
import PropTypes from 'prop-types';
import { Atoms, Charts } from 'components';
import { getProtIpured2aScore } from 'api/fetch_data';


class ProtDisorder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
        };
    }
    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { genomeAbbr, id } = this.props;
        getProtIpured2aScore(genomeAbbr, id).then(data => {
            if (!this.__ignoreLastFetch) {
                const dataSource = {
                    Anchor2: {
                        x: data.position,
                        y: data['anchor2_score']
                    },
                    Iupred2: {
                        x: data.position,
                        y: data['iupred2_score']
                    },
                };
                this.setState({ dataSource });
            }
        });

    };

    render() {
        const { dataSource } = this.state;
        if (Object.keys(dataSource).length === 0) return null;
        return (
            <Atoms.InfoGroup title="Disorder score">
                <Charts.LinePlot
                    xAxis=""
                    yAxis="Disorder score"
                    height={450}
                    width={1100}
                    padding={[10, 150, 30, 150]}
                    dataSource={dataSource}
                />
            </Atoms.InfoGroup>
        );
    }
}


ProtDisorder.propTypes = {
    ival: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default ProtDisorder;
