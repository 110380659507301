import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import { withStyles, ListItem, List } from '@material-ui/core';
// core components
import {
    defaultFont,
    container,
    primaryColor,
    grayColor
} from 'assets/global';


const footerStyle = {
    block: {
        color: 'inherit',
        padding: '10px',
        textTransform: 'uppercase',
        borderRadius: '3px',
        textDecoration: 'none',
        position: 'relative',
        display: 'block',
        ...defaultFont,
        fontWeight: '500',
        fontSize: '12px'
    },
    left: {
        float: 'left!important',
        display: 'block'
    },
    right: {
        padding: '10px 0',
        margin: '0',
        fontSize: '14px',
        float: 'right!important'
    },
    footer: {
        bottom: '0',
        borderTop: '1px solid ' + grayColor[5],
        backgroundColor: grayColor[10],
        padding: '10px 0',
        ...defaultFont
    },
    container,
    a: {
        color: primaryColor,
        textDecoration: 'none',
        backgroundColor: 'transparent'
    },
    list: {
        marginBottom: '0',
        padding: '0',
        marginTop: '0'
    },
    inlineBlock: {
        display: 'inline-block',
        padding: '0px',
        width: 'auto'
    }
};

function Footer({ ...props }) {
    const { classes } = props;
    return (
        <footer className={classes.footer}>
            <div className={classes.container}>
                <div className={classes.left}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                            <a href={`${process.env.REACT_APP_HOME_PATH}`} className={classes.block}>Home</a>
                        </ListItem>
                    </List>
                </div>
                <p className={classes.right}>
                    <span>
                        Copyright
                        &copy; 
                        Zhu & Zhou Lab 2019
                    </span>
                </p>
            </div>
        </footer>
    );
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(footerStyle)(Footer);
