import React from 'react';
import PropTypes from 'prop-types';

import { Drawer, List, ListItem, ListItemText, ListItemAvatar, Avatar, ListItemSecondaryAction, IconButton, withStyles } from '@material-ui/core';
import DividerWithText from './DividerWithText';
import FloatButtonGroup from './FloatButtonGroup';

import FolderIcon from '@material-ui/icons/Folder';
import DeleteIcon from '@material-ui/icons/Delete';


const styles = theme => ({
    list: {
        width: 480,
    }
});

class CompareList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        };
    }

    toggleDrawer = (open) => (event) => {
        event.preventDefault();
        this.setState({ open: open });
    }

    delete = (category, item) => (event) => {
        event.preventDefault();
        this.props.onDelete(category, item);
    }

    render() {
        const { classes, dataSource: { geneList, transcriptionList, proteinList }, onClickAdd, onDelete } = this.props;
        const { open } = this.state;
        return (
            <>
                <FloatButtonGroup
                    infoButton={{
                        onClick: this.toggleDrawer(true),
                        text: 'Manage Gene List'
                    }}
                    addButton={{
                        text: 'Add To Gene List',
                        onClick: onClickAdd
                    }}
                />
                <Drawer open={open} onClose={this.toggleDrawer(false)} anchor="right">
                    <div
                        className={classes.list}
                        role="presentation"
                        onKeyDown={this.toggleDrawer(false)}
                    >
                        <DividerWithText text="Gene" />
                        <List>
                            {geneList.map((gene, index) => (
                                <ListItem button key={`gene${index}`}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <FolderIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={gene.name} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="Delete" onClick={this.delete('geneList', gene)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                        <DividerWithText text="Transcript" />
                        <List>
                            {transcriptionList.map((transcription, index) => (
                                <ListItem button key={`trans${index}`}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <FolderIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={transcription.name} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="Delete" onClick={this.delete('transcriptionList',transcription)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                        <DividerWithText text="Protein" />
                        <List>
                            {proteinList.map((protein, index) => (
                                <ListItem button key={`prot${index}`}>
                                    <ListItemAvatar>
                                        <Avatar>
                                            <FolderIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary={protein.name} />
                                    <ListItemSecondaryAction>
                                        <IconButton edge="end" aria-label="Delete" onClick={this.delete('proteinList', protein)}>
                                            <DeleteIcon onClick={onDelete} />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </div>
                </Drawer>
            </>
        );
    }
}

CompareList.propTypes = {
    classes: PropTypes.object,
    dataSource: PropTypes.shape({
        geneList: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            speciesAbbr: PropTypes.string,
            genomeAbbr: PropTypes.string,
            locusId: PropTypes.string
        })),
        transcriptionList: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            speciesAbbr: PropTypes.string,
            genomeAbbr: PropTypes.string,
            transId: PropTypes.string
        })),
        proteinList: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string,
            speciesAbbr: PropTypes.string,
            genomeAbbr: PropTypes.string,
            proteinId: PropTypes.string
        })),
    }),
    onClickAdd: PropTypes.func,
    onDelete: PropTypes.func
};

export default withStyles(styles)(CompareList);