import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, colors } from '@material-ui/core';


const styles = theme => ({
    container: {
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        margin: `${theme.spacing(2)} 0`
    },
    divider: {
        width: '100%',
        verticalAlign: 'middle',
        display: 'inline-block',
        margin: theme.spacing(1)
    },
    text: {
        verticalAlign: 'middle',
        display: 'inline-block',
        whiteSpace: 'nowrap',
        fontSize: '16px',
        fontWeight: 'bold',
        '&::first-letter' : {
            color: colors.green[700],
            fontSize: '125%'
        }
    }
});

function DividerwithText({text, classes}) {
    return(
        <div className={classes.container}>
            <div className={classes.divider}>
                <hr/>
            </div>
            <div className={classes.text}>{text}</div>
            <div className={classes.divider}>
                <hr/>
            </div>
        </div>
    );
}

DividerwithText.propTypes ={
    text: PropTypes.string,
    classes: PropTypes.object
};

export default withStyles(styles)(DividerwithText);