import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Axis, Coord, Guide } from 'bizcharts';
import { chartColors as colors } from 'assets/global';

import { flatten, min, max, countBy, groupBy, omit, values } from 'lodash';


function GeneStructure({ dataSource, sequenceData, sliderValue, heightPerDataGroup, width, padding }) {
    //allocate colors
    const colorDict = {};
    let iter = 0;
    dataSource.forEach(geneGroup => {
        if (!(geneGroup.group in colorDict)) {
            colorDict[geneGroup.group] = colors[iter];
            iter++;
        }
    });

    // calculate dx by seq info
    const midLayerData = [];
    dataSource.forEach((geneGroup) => {
        const seqInfo = sequenceData.find(seq => seq.geneHashName === geneGroup.group);
        const seq = seqInfo ? seqInfo.sequence : '';
        // show 100 bps
        const countDict = countBy(seq.slice(0, sliderValue + 99), item => item === '-');
        const dx = countDict.true ? countDict.true : 0;
        geneGroup.blocks.forEach(gene => {
            midLayerData.push({
                index: geneGroup.index,
                range: [gene[0] + dx, gene[1] + dx],
                group: geneGroup.group,
                name: geneGroup.name,
            });
        });
    });

    // re-scale axis bp-count, from (min, max) to (0, max-min)
    const blocks = flatten(midLayerData.filter(block => block.group !== 'empty').map(block => block.range));
    const bpStart = min(blocks);
    const bpEnd = max(blocks);
    const data = midLayerData.map(block => {
        return {
            ...block,
            range: [block.range[0] - bpStart, block.range[1] - bpStart]
        };
    });

    // draw the whole gene range
    const rangeData = values(omit(groupBy(data, 'group'), 'empty'));

    return (
        <Chart
            height={heightPerDataGroup * (dataSource.length) + padding[0] + padding[2]}
            width={width}
            data={data}
            scale={{
                index: {
                    type: 'cat',
                    alias: 'name',
                    ticks: Array.from(new Array(dataSource.length).keys())
                },
                range: {
                    min: 0,
                    max: bpEnd-bpStart
                }
            }}
            padding={padding}
            onIntervalClick={(ev) => {
                console.log(ev);
            }}
            forceFit
        >
            <Coord transpose reflect="y" />

            <Axis
                name="index"
                line={null}
                label={{
                    textStyle: {
                        fontSize: '12',
                        fill: '#000',
                        fontWeight: 'bold',
                    },
                    position: 'center',
                    formatter: (text, item, index) => {
                        const axisLabel = data.find(set => set.index === index);
                        if (axisLabel) {
                            if (axisLabel.group === 'empty' && axisLabel.name === 'empty') {
                                return '';
                            }
                            return axisLabel.name;
                        }
                    }
                }} />
            <Axis
                name="range"
                label={{
                    offset: 20,
                    textStyle: {
                        fontSize: '12',
                        fill: '#000',
                        fontWeight: 'bold',
                    },
                }}
            />

            <Geom
                type="interval"
                position="index*range"
                color={['group', colors]}
                size={6}
                animate={{
                    appear: {
                        animation: 'fanIn',
                        delay: 5,
                        duration: 10
                    },
                    update: {
                        animation: 'fanIn',
                        delay: 5,
                        duration: 10
                    },
                    enter: {
                        animation: 'fanIn',
                        delay: 5,
                        duration: 10
                    }
                }}
            />
            <Guide>
                {rangeData.map((blockInfoArray) => {
                    const group = blockInfoArray[0].group;
                    const index = blockInfoArray[0].index;
                    const blocks = flatten(blockInfoArray.map(blockInfo => blockInfo.range));
                    const start = min(blocks);
                    const end = max(blocks);
                    return <Guide.Line
                        key={index}
                        start={{
                            index: index,
                            range: start
                        }}
                        end={{
                            index: index,
                            range: end
                        }}
                        lineStyle={{
                            stroke: colorDict[group],
                            lineWidth: 1,
                            lineDash: [0, 0, 0]
                        }}
                    />;
                })}
                <Guide.Region
                    top
                    start={['min', sliderValue - 1 - bpStart]}
                    end={['max', sliderValue + 99 - bpStart]}
                    style={{
                        lineWidth: 0,
                        fill: '#f44336',
                        fillOpacity: 0.5,
                        stroke: '#ccc'
                    }}
                />
            </Guide>
        </Chart>
    );
}


GeneStructure.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({
        group: PropTypes.string,
        name: PropTypes.string,
        blocks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    })),
    heightPerDataGroup: PropTypes.number,
    width: PropTypes.number,
    padding: PropTypes.arrayOf(PropTypes.number)
};

export default GeneStructure;