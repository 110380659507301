import { sum } from 'lodash';

export class GenomeCirclePlot {
    /**
     * Transform chromosome size infomations to polar coordinate system.
     * Range: 0(0 Deg) ~ 1(360 Deg).
     * @constructor
     * @param {Object[]} sourceGenomeInfo - source Chromosomes sizes
     * @param {string} sourceGenomeInfo[].chrom
     * @param {number} sourceGenomeInfo[].size
     * @param {Object[]} targetGenomeInfo - target Chromosomes sizes
     * @param {string} targetGenomeInfo[].chrom
     * @param {number} targetGenomeInfo[].size 
     * @param {Object[]} syntenyData
     * @param {number} paddingRatio - between 0 and 1. Spacing of chromosomes.
    */
    constructor(sourceGenomeInfo, targetGenomeInfo, syntenyData, paddingRatio) {
        this.sourceGenomeInfo = sourceGenomeInfo;
        this.targetGenomeInfo = targetGenomeInfo;
        this.syntenyData = syntenyData;
        this.paddingRatio = paddingRatio;

        this.totalLength = sum([
            ...sourceGenomeInfo.map(chromosome => chromosome.size),
            ...targetGenomeInfo.map(chromosome => chromosome.size)
        ]);
        this.chromCount = sourceGenomeInfo.length + targetGenomeInfo.length;

        this.bp = (1 - paddingRatio) / this.totalLength;
        this.padding = this.paddingRatio / this.chromCount;

        const chromosomes = [];
        let endPos = 0;
        this.sourceGenomeInfo.forEach(chromosome => {
            const start = this.padding + endPos;
            endPos = chromosome.size * this.bp + start;
            chromosomes.push({ start, end: endPos, name: chromosome.chrom, group: 'source', bpCount: chromosome.size });
        });
        this.targetGenomeInfo.forEach(chromosome => {
            const start = this.padding + endPos;
            endPos = chromosome.size * this.bp + start;
            chromosomes.push({ start, end: endPos, name: chromosome.chrom, group: 'target', bpCount: chromosome.size });
        });
        this.chromosomes = chromosomes;
    }

    getChromosomes() {
        return this.chromosomes;
    }

    getSyntenyLinks() {
        const result = [];
        this.syntenyData.forEach(syntenyInfo => {
            const { query_chrom, query_start, query_end, target_chrom, target_start, target_end, score } = syntenyInfo;
            const sourceChrom = this.chromosomes.find(item => item.group === 'source' && item.name === query_chrom);
            const targetChrom = this.chromosomes.find(item => item.group === 'target' && item.name === target_chrom);

            const sourceStart = query_start * this.bp + sourceChrom.start;
            const sourceEnd = query_end * this.bp + sourceChrom.start;
            const targetStart = target_start * this.bp + targetChrom.start;
            const targetEnd = target_end * this.bp + targetChrom.start;
            result.push({
                source: { start: sourceStart, end: sourceEnd, name: sourceChrom.name },
                target: { start: targetStart, end: targetEnd, name: targetChrom.name },
                score
            });
        });
        return result;
    }

}