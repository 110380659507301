import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Atoms, Charts } from 'components';
import { getInterproDomain } from 'api/fetch_data';

class GeneInterproDomain extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            plotData: []
        };
        this.header = [
            { key: 'feature', content: 'Feature' },
            { key: 'domain_name', content: 'Name' },
            { key: 'chrom', content: 'Chrom' },
            { key: 'start', content: 'Start' },
            { key: 'end', content: 'End' },
            { key: 'strand', content: 'Strand' },
            { key: 'search', content: 'Search same domain gene' }
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = async () => {
        const { genomeAbbr, id } = this.props;
        const data = await getInterproDomain(genomeAbbr, id, 'Locus');
        if (!this.__ignoreLastFetch) {
            const dataSource = data.map(item => ({
                feature: item['feature'],
                domain_name: <a href={`https://www.ebi.ac.uk/interpro/signature/${item['domain_name']}`}>{item['domain_name']}</a>,
                chrom: item['chrom'],
                start: item['start'],
                end: item['end'],
                strand: item['strand'],
                search: <Link to={{
                    pathname: '/search/interpro_domain',
                    state: { 'searchText': item['domain_name'], 'species': 'All', 'genome': 'All', 'assemble': 'All' }
                }}>Search</Link>
            }));
            const plotData = data.map(item => ({
                group: item['feature'],
                start: item['start'],
                end: item['end'],
                strand: item['strand'],
                name: item['domain_name'],
                blocks: item['blocks'],
            }));

            this.setState({ dataSource, plotData });
        }
    };

    render() {
        const { locus } = this.props;
        const { dataSource, plotData } = this.state;

        if (dataSource.length === 0) {
            return null;
        }
        return (
            <Atoms.ExpansionInfoCard
                title="InterPro domain"
                mainContent={
                    <Charts.GeneStructure
                        bpStart={locus.start}
                        bpEnd={locus.end}
                        heightPerDataGroup={20}
                        width={1000}
                        padding={[10, 50, 30, 150]}
                        dataSource={plotData}
                    />
                }
                expansionContent={
                    <Atoms.CustomTable columns={this.header} dataSource={dataSource} />
                }
            />
        );
    }
}

GeneInterproDomain.propTypes = {
    locus: PropTypes.object,
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default GeneInterproDomain;
