const site = process.env.REACT_APP_API_PATH;

// get method
async function asyncFetchGet(route, params = '') {
    try {
        let url = '';
        if (params !== '')
            url = `${site}${route}${params}`;
        else
            url = `${site}${route}/`;
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const result = await response.json();
        return result;
    } catch (error) {
        console.log(error);
    }
}
// post method
async function asyncFetchPost(route, body) {
    try {
        const response = await fetch(`${site}${route}/`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const result = await response.json();
        return result;
    } catch (error) {
        console.log(error);
    }
}

async function FetchFile(route, body) {
    try {
        const response = await fetch(`${site}${route}/`, {
            mode: 'cors',
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/octet-stream',
            }
        });
        return response;
    } catch (error) {
        console.log(error);
    }
}

// put method
async function asyncFetchPatch(route, id, body) {
    try {
        const response = await fetch(`${site}${route}/${id}/`, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            }
        });
        const result = await response.json();
        return result;
    } catch (error) {
        console.log(error);
    }
}


export  { site, asyncFetchGet, asyncFetchPatch, asyncFetchPost, FetchFile };