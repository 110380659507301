const example = [{
    img: '/example_background/gene.jpg',
    title: 'Ga01g00214.F3',
    description: 'Search by Locus/Transcript/Protein ID',
    searchField: 'name', searchText: 'Ga01g00214.F3', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/gene.jpg',
    title: 'GhRDL1',
    description: 'Search by Locus/Transcript/Protein name',
    searchField: 'name', searchText: 'GhRDL1', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/gene.jpg',
    title: 'ACX3',
    description: 'Search by TAIR homolog gene symbol',
    searchField: 'name', searchText: 'ACX3', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/go.png',
    title: 'GO:0000032',
    description: 'GO accession ID',
    searchField: 'go', searchText: 'GO:0000032', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/kegg.gif',
    title: 'K11091',
    description: 'KO accession ID',
    searchField: 'ko', searchText: 'K11091', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/kegg.gif',
    title: 'ATPF1B, atpD',
    description: 'KEGG ontology name',
    searchField: 'ko', searchText: 'ATPF1B, atpD', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/ec.png',
    title: '1.17.7.2',
    description: 'Enzyme Commission ID',
    searchField: 'ec', searchText: '1.17.7.1', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/ec.png',
    title: 'RNA-directed RNA polymerase',
    description: 'Enzyme Commission name',
    searchField: 'ec', searchText: 'RNA-directed RNA polymerase. ', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/interpro.png',
    title: 'IPR001736',
    description: 'InterPro ID',
    searchField: 'interpro_domain', searchText: 'IPR001736', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/interpro.png',
    title: 'cd01254',
    description: 'InterPro domain name',
    searchField: 'interpro_domain', searchText: 'cd01254', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/pfam.png',
    title: 'PF00614.22',
    description: 'Pfam ID',
    searchField: 'pfam_domain', searchText: 'PF00614.22', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/pfam.png',
    title: 'PLDc',
    description: 'Pfam domain name',
    searchField: 'pfam_domain', searchText: 'PLDc', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/cdd.png',
    title: '197200',
    description: 'NCBI CDD ID',
    searchField: 'cdd_domain', searchText: '197200', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/tfdb.jpg',
    title: 'C2H2',
    description: 'TF family name',
    searchField: 'tf_family', searchText: 'C2H2', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/eggnog.png',
    title: 'EOY06593',
    description: 'eggNOG root name',
    searchField: 'eggNOG_gene_family', searchText: 'EOY06593', species: 'bomCei', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/eggnog.png',
    title: 'XP_004516060.1',
    description: 'eggNOG hit gene',
    searchField: 'eggNOG_gene_family', searchText: 'XP_004516060.1', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/kegg.gif',
    title: 'ko04024',
    description: 'KEGG pathway ID',
    searchField: 'pathway', searchText: 'ko04024', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/kegg.gif',
    title: 'cAMP signaling pathway',
    description: 'KEGG pathway name',
    searchField: 'pathway', searchText: 'cAMP signaling pathway', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/kegg.gif',
    title: 'M00087',
    description: 'KEGG model ID',
    searchField: 'model', searchText: 'M00087', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/kegg.gif',
    title: 'NADH dehydrogenase (ubiquinone) Fe-S protein/fla',
    description: 'KEGG model name',
    searchField: 'model', searchText: 'NADH dehydrogenase (ubiquinone) Fe-S protein/fla', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/kegg.gif',
    title: 'H00254',
    description: 'KEGG disease ID',
    searchField: 'disease', searchText: 'H00254', species: 'All', genome: 'All', assemble: 'All'
}, {
    img: '/example_background/kegg.gif',
    title: 'Lysosomal acid lipase deficiency',
    description: 'KEGG disease name',
    searchField: 'disease', searchText: 'Lysosomal acid lipase deficiency', species: 'All', genome: 'All', assemble: 'All'
}];

export default example;