import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Axis, Shape, G2, Tooltip } from 'bizcharts';
import { max, uniq, at, groupBy, values } from 'lodash';
import LocusMergeToCluster from 'tools/LocusMergeToCluster';

const { Util } = G2;

function getFillAttrs(cfg) {
    const attrs = Util.mix(
        {
            fill: cfg.color,
            fillOpacity: cfg.opacity
        },
        cfg.style
    );
    return attrs;
}

function getRectPath(points) {
    const path = [];
    for (let i = 0; i < points.length; i++) {
        const point = points[i];
        if (point) {
            const action = i === 0 ? 'M' : 'L';
            path.push([action, point.x, point.y]);
        }
    }
    const first = points[0];
    path.push(['L', first.x, first.y]);
    path.push(['z']);
    return path;
}

Shape.registerShape('interval', 'chromosome', {
    draw(cfg, container) {
        const attrs = getFillAttrs(cfg);
        let path = getRectPath(cfg.points);
        path = this.parsePath(path);
        const radius = (path[2][1] - path[1][1]) / 2;
        const temp = [];

        temp.push(['M', path[0][1] + radius, path[0][2]]);
        temp.push(['A', radius, radius, 90, 0, 1, path[0][1], path[0][2] - radius]);
        temp.push(['L', path[1][1], path[1][2] + radius]);
        temp.push(['A', radius, radius, 90, 0, 1, path[1][1] + radius, path[1][2]]);
        temp.push(['L', path[2][1] - radius, path[2][2]]);
        temp.push(['A', radius, radius, 90, 0, 1, path[2][1], path[2][2] + radius]);
        temp.push(['L', path[3][1], path[3][2] - radius]);
        temp.push(['A', radius, radius, 90, 0, 1, path[3][1] - radius, path[3][2]]);
        temp.push(['Z']);

        return container.addShape('path', {
            attrs: Util.mix(attrs, {
                path: temp
            })
        });
    }
});

Shape.registerShape('point', 'geneLocus', {
    drawShape(cfg, container) {
        const drawPoints = this.parsePoints(cfg.points);
        return container.addShape('line', {
            attrs: {
                x1: drawPoints[0].x - 0.5 * cfg.size,
                y1: drawPoints[0].y,
                x2: drawPoints[0].x + 0.5 * cfg.size,
                y2: drawPoints[0].y,
                stroke: cfg.color,
                lineWidth: 2,
            }
        });
    }
});

Shape.registerShape('interval', 'withAnnotation', {
    getShapePoints({ x, y, size }) {
        return [
            { x, y: y[0] },
            { x: x + 0.15 * size, y: y[0] },
            { x: x + 0.45 * size, y: y[1] },
            { x: x + 0.6 * size, y: y[1] }
        ];
    },
    drawShape(cfg, container) {
        const points = this.parsePoints(cfg.points);
        const geneName = cfg.origin._origin.name;
        container.addShape('line', {
            attrs: {
                x1: points[0].x,
                y1: points[0].y,
                x2: points[1].x,
                y2: points[1].y,
                stroke: '#ccc',
                lineWidth: 1,
            }
        });
        container.addShape('line', {
            attrs: {
                x1: points[1].x,
                y1: points[1].y,
                x2: points[2].x,
                y2: points[2].y,
                stroke: '#ccc',
                lineWidth: 1,
            }
        });
        container.addShape('line', {
            attrs: {
                x1: points[2].x,
                y1: points[2].y,
                x2: points[3].x,
                y2: points[3].y,
                stroke: '#ccc',
                lineWidth: 2,
            }
        });
        const polygon = container.addShape('text', {
            attrs: {
                text: geneName,
                x: points[3].x - 32,
                y: points[3].y,
                fontFamily: 'Roboto',
                fontSize: 12,
                fontWeight: 'bold',
                fill: '#000'
            }
        });
        return polygon;
    }
});


function Chromosome({ chromosomeSize, geneList, height }) {
    const displayChromosome = uniq(geneList.map(gene => gene.chrom));
    const displayChromosomeSize = at(chromosomeSize, displayChromosome);
    const maxLength = max(displayChromosomeSize);

    // the annotation text height, count as bp number in the chart.
    const textHeightAsBp = 14 * maxLength / (height - 90);

    const genePos = values(groupBy(geneList, 'chrom'));
    const dataSource = [];
    genePos.forEach(geneGroup => {
        // geneGroup is all genes in the same chromosome
        const sortedGeneGroup = geneGroup.sort((gene1, gene2) => gene1.start - gene2.start);
        dataSource.push(...LocusMergeToCluster(sortedGeneGroup, textHeightAsBp));
    });


    const data = dataSource.map(gene => ({
        ...gene,
        chromSize: chromosomeSize[gene.chrom]
    }));

    const scale = {
        position: {
            min: 0,
            max: maxLength
        },
        chromSize: {
            min: 0,
            max: maxLength
        }
    };

    return (
        <Chart
            data={data}
            scale={scale}
            height={height}
            padding={[10, 10, 50, 80]}
            forceFit
        >
            <Tooltip />
            <Axis 
                name="chrom"
                line={null}
                tickLine={null}
                label={{
                    textStyle: {
                        fontWeight: 'bold',
                        fontFamily: 'Roboto',
                        fontSize: '14'
                    }
                }}
            />
            <Axis name="chromSize" line={null} />
            <Axis name="position" visible={false} line={null} />

            <Geom
                type="interval"
                position="chrom*chromSize"
                shape="chromosome"
                style={{
                    lineWidth: 1,
                    stroke: '#000',
                    fill: '#fff',
                    shadowColor: '#000',
                    shadowBlur: 2
                }}
                size={16}
            />
            <Geom
                type="interval"
                position="chrom*position"
                shape="withAnnotation"
                color="#000000"
                tooltip={false}
            />
            <Geom
                type="point"
                position="chrom*position"
                tooltip={['start*end*name', (start, end, name) => ({
                    name: 'Range',
                    title: name,
                    value: `From ${start} To ${end}`
                })]}
                shape="geneLocus"
                color="#000"
                size={16}
                active={[true, {
                    highlight: true,
                    style: {
                        lineWidth: 4,
                    }
                }]}
            />
        </Chart>
    );
}

Chromosome.propTypes = {
    chromosomeSize: PropTypes.object,
    geneList: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        chrom: PropTypes.string,
        strand: PropTypes.oneOf(['-', '+']),
        start: PropTypes.number,
        end: PropTypes.number
    })),
    height: PropTypes.number
};

export default Chromosome;