import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

function HelpIcon() {
    return (
        <SvgIcon>
            <g>
                <path d="
                    M 12 2
                    C 6.48 2 2 6.48 2 12
                    s 4.48 10 10 10 10 -4.48 10 -10
                    S17.52 2 12 2z
                    m0 18
                    c -4.41 0 -8 -3.59 -8 -8
                    s 3.59 -8 8 -8 8 3.59 8 8 -3.59 8 -8 8z
                    m -1 -4h2v2h-2z
                    m 1.61 -9.96
                    c -2.06 -0.3 -3.88 0.97 -4.43 2.79 -0.18 0.58 0.26 1.17 0.87 1.17
                    h 0.2
                    c 0.41 0 0.74 -0.29 0.88 -0.67 
                    c 0.32 -0.89 1.27 -1.5 2.3 -1.28
                    c 0.95 0.2 1.65 1.13 1.57 2.1 
                    c -0.1 1.34 -1.62 1.63 -2.45 2.88 
                    c 0 0.01 -0.01 0.01 -0.01 0.02 
                    c -0.01 0.02 -0.02 0.03 -0.03 0.05
                    c -0.09 0.15 -0.18 0.32 -0.25 0.5
                    c -0.01 0.03 -0.03 0.05 -0.04 0.08
                    c -0.01 0.02 -0.01 0.04 -0.02 0.07 
                    c -0.12 0.34 -0.2 0.75 -0.2 1.25
                    h2
                    c0-.42.11-.77.28-1.07.02-.03.03-.06.05-.09.08-.14.18-.27.28-.39.01-.01.02-.03.03-.04.1-.12.21-.23.33-.34.96-.91 2.26-1.65 1.99-3.56-.24-1.74-1.61-3.21-3.35-3.47z
                "/>
            </g>
        </SvgIcon>
    );
}

export default HelpIcon;