import React from 'react';
import PropTypes from 'prop-types';
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Legend,
    Guide
} from 'bizcharts';

export default function HeatMap({ dataSource, valueIndex, xAxis, yAxis, toolTipTemplate, toolTipCallback }) {
    const chartData = dataSource.map(data => ({
        ...data,
        logValue: Math.log10(data[valueIndex] + 0.01)
    }));
    const infoStr = Object.keys(dataSource[0]).join('*');
    const scale = {
        row: {
            type: 'cat',
            values: xAxis.ticks.map(item => item.name),
        },
        col: {
            type: 'cat',
            values: yAxis.ticks.map(item => item.name),
        },
    };
    return (
        <div>
            <Chart
                height={yAxis.ticks.length*18+160}
                data={chartData}
                scale={scale}
                padding={[20, 120, 140, 200]}
                forceFit
            >
                <Axis
                    name="row"
                    grid={{
                        align: 'center',
                        lineStyle: {
                            lineWidth: 1,
                            lineDash: null,
                            stroke: '#f0f0f0',
                        },
                        showFirstLine: true,
                    }}
                    label={{
                        rotate: 30
                    }}
                />
                <Axis
                    name="col"
                    grid={{
                        align: 'center',
                        lineStyle: {
                            lineWidth: 1,
                            lineDash: null,
                            stroke: '#f0f0f0',
                        },
                    }}
                />
                <Legend 
                    name="logValue"
                    position="right-top"
                    slidable={false}
                    itemFormatter={(val) => {
                        return Math.pow(10, val).toString();
                    }}
                />
                <Tooltip
                    showTitle={false}
                    itemTpl={toolTipTemplate}
                />
                <Geom
                    type="polygon"
                    position="row*col"
                    color={['logValue', '#FFFFFF-#BAE7FF-#1890FF-#0050B3']}
                    style={{
                        stroke: '#fff',
                        lineWidth: 1,
                    }}
                    tooltip={[
                        infoStr, 
                        toolTipCallback
                    ]}
                >
                    {/* <Label
                        content={valueIndex}
                        offset={-2}
                        textStyle={{
                            fill: '#fff',
                            fontWeight: 'bold',
                            shadowBlur: 2,
                            shadowColor: 'rgba(0, 0, 0, .75)',
                        }}
                    /> */}
                </Geom>
                <Guide>
                    <Guide.Region
                        start={['0%', '0%']}
                        end={['100%', '100%']}
                        style={{
                            fill: '#9e9e9e',
                            fillOpacity: 0.5
                        }}
                    />
                </Guide>
            </Chart>
        </div>
    );
}

HeatMap.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({
        row: PropTypes.number,
        col: PropTypes.number
    })).isRequired,
    valueIndex: PropTypes.string,
    xAxis: PropTypes.shape({
        title: PropTypes.string,
        ticks: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string
        })),
    }),
    yAxis: PropTypes.shape({
        title: PropTypes.string,
        ticks: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string
        })),
    })
};