import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Grid, TextField, Button, Switch, FormControlLabel, RadioGroup, Radio, Collapse } from '@material-ui/core';
import { Atoms, Charts } from 'components';
import { getDnaPcrPrimer, getRnaPcrPrimer } from 'api/fetch_data';

import PrimerStructure from 'components/charts/GeneStructure/PrimerStructure';

const initialDnaPrimerParams = {
    number_of_return: 5,
    primer_min_size: 18, primer_opt_size: 20, primer_max_size: 22,
    primer_min_gc: 40, primer_opt_gc: 50, primer_max_gc: 60,
    primer_min_tm: 50, primer_opt_tm: 55, primer_max_tm: 60, primer_max_diff_tm: 5,
    min_insert_len: 100, max_insert_len: 300
};

const initialRnaPrimerParams = {
    number_of_return: 5,
    primer_min_size: 18, primer_opt_size: 20, primer_max_size: 22,
    primer_min_gc: 40, primer_opt_gc: 50, primer_max_gc: 60,
    primer_min_tm: 50, primer_opt_tm: 55, primer_max_tm: 60, primer_max_diff_tm: 5,
};


export default class PrimerDesignForm extends React.Component {
    static propTypes = {
        species: PropTypes.string,
        genome: PropTypes.string,
        chromosome: PropTypes.string,
        bpRange: PropTypes.arrayOf(PropTypes.number),
        transList: PropTypes.arrayOf(PropTypes.shape({
            group: PropTypes.string,
            name: PropTypes.string,
            start: PropTypes.number,
            end: PropTypes.number,
            blocks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
        }))
    };

    constructor(props) {
        super(props);
        this.state = {
            // form to design primer
            primerRegionRange: [props.bpRange[0], props.bpRange[0]],
            targetRegionRange: [props.bpRange[0], props.bpRange[0]],
            useTarget: false,
            primerType: 'DNA',

            // DNA primer Design form
            dnaPrimerParams: initialDnaPrimerParams,

            // RNA primer Design form
            rnaPrimerParams: initialRnaPrimerParams,
            rnaPrimerTransTarget: [],

            // design result
            primerList: [],
            primerInfo: [],
        };
        this.primerInfoHeader = [
            { key: 'primer_id', content: 'Primer' },
            { key: 'primer_f_seq', content: 'Primer-F sequence' },
            { key: 'primer_r_seq', content: 'Primer-R sequence' },
            { key: 'primer_f_range', content: 'F-range' },
            { key: 'primer_r_range', content: 'R-range' },
            { key: 'primer_f_tm', content: 'F-TM' },
            { key: 'primer_r_tm', content: 'R-TM' },
            { key: 'primer_f_gc_percent', content: 'F-GC%' },
            { key: 'primer_r_gc_percent', content: 'R-GC%' },
            { key: 'primer_f_penalty', content: 'F-penalty' },
            { key: 'primer_r_penalty', content: 'R-penalty' },
        ];
    }

    handleRegionSlideChange = (eventListener) => (newValue) => this.setState({ [eventListener]: newValue });

    handleUseTargetSwitchChange = (event) => this.setState({ useTarget: event.target.checked });
    handlePrimerTypeRadioChange = (event) => this.setState({ primerType: event.target.value });

    handlePrimerParamsChange = (type, param) => (event) => {
        const { [type + 'PrimerParams']: targetParams } = this.state;
        const newParams = { ...targetParams, [param]: Number(event.target.value) };
        this.setState({ [type + 'PrimerParams']: newParams });
    }

    handleTransTargetChange = (event) => this.setState({ rnaPrimerTransTarget: event.target.value });


    handleDesign = async () => {
        const { species, genome, chromosome } = this.props;
        const { dnaPrimerParams, rnaPrimerParams, primerRegionRange, useTarget, targetRegionRange, primerType, rnaPrimerTransTarget } = this.state;
        let response = [];
        if (primerType === 'DNA') {
            if (useTarget) {
                response = await getDnaPcrPrimer(species, genome, chromosome, ...primerRegionRange, dnaPrimerParams, ...targetRegionRange);
            } else {
                response = await getDnaPcrPrimer(species, genome, chromosome, ...primerRegionRange, dnaPrimerParams, null, null);
            }
        } else if (primerType === 'RNA') {
            if (useTarget) {
                response = await getRnaPcrPrimer(species, genome, rnaPrimerTransTarget, chromosome, ...primerRegionRange, rnaPrimerParams, ...targetRegionRange);
            } else {
                response = await getRnaPcrPrimer(species, genome, rnaPrimerTransTarget, chromosome, ...primerRegionRange, rnaPrimerParams, null, null);
            }
        }
        const primerList = [];
        const primerInfo = [];
        response.forEach((primer, index) => {
            const {
                primer_f_seq, primer_r_seq, primer_f_start, primer_f_end, primer_r_end, primer_r_start,
                chrom, primer_f_blocks, primer_r_blocks, primer_f_tm, primer_r_tm,
                primer_f_gc_percent, primer_r_gc_percent, primer_f_penalty, primer_r_penalty
            } = primer;
            primerList.push({
                group: 'forward',
                blocks: primer_f_blocks,
                start: primer_f_start,
                end: primer_f_end,
                chrom,
                name: `Primer${index + 1}`
            });
            primerList.push({
                group: 'reverse',
                blocks: primer_r_blocks,
                start: primer_r_start,
                end: primer_r_end,
                chrom,
                name: `Primer${index + 1}`
            });
            primerInfo.push({
                'primer_id': `Primer${index + 1}`,
                primer_f_seq, primer_r_seq,
                'primer_f_range': `${primer_f_blocks[0][0]}-${primer_f_blocks[primer_f_blocks.length - 1][1]}`,
                'primer_r_range': `${primer_r_blocks[0][0]}-${primer_r_blocks[primer_r_blocks.length - 1][1]}`,
                primer_f_tm, primer_r_tm,
                primer_f_gc_percent, primer_r_gc_percent,
                primer_f_penalty, primer_r_penalty
            });
        });
        this.setState({ primerList: primerList.reverse(), primerInfo });
    }

    handleClear = () => {
        const bpMin = this.props.bpRange[0];
        this.setState({
            primerRegionRange: [bpMin, bpMin],
            targetRegionRange: [bpMin, bpMin],
            useTarget: false,
            primerType: 'DNA',

            // DNA primer Design form
            dnaPrimerParams: initialDnaPrimerParams,

            // RNA primer Design form
            rnaPrimerParams: initialRnaPrimerParams,
            rnaPrimerTransTarget: [],
            primerList: [],
            primerInfo: [],
        });
    }

    render() {
        const { bpRange, transList } = this.props;
        const {
            primerList, primerInfo, primerRegionRange, targetRegionRange, useTarget, primerType, rnaPrimerParams, rnaPrimerTransTarget,
            dnaPrimerParams,
        } = this.state;

        const transTargetOptions = transList.filter(item => item.name !== 'empty').map(item => ({
            value: item.hash_name,
            text: item.name,
        }));

        return (
            <Atoms.CustomCard
                type="primary"
                title="Translate Region Info"
            >
                <Charts.GeneStructureWithSlideCover
                    bpStart={bpRange[0]}
                    bpEnd={bpRange[1]}
                    heightPerDataGroup={20}
                    padding={[0, 50, primerList.length === 0 ? 30 : 0, 150]}
                    dataSource={transList}
                    primaryCoverRange={primerRegionRange}
                    secondaryCoverRange={targetRegionRange}
                    withBpLabel={primerList.length === 0}

                />
                {primerList.length > 0 ? (<div>
                    <PrimerStructure
                        bpStart={bpRange[0]}
                        bpEnd={bpRange[1]}
                        heightPerDataGroup={20}
                        padding={[0, 50, 30, 150]}
                        dataSource={primerList}
                    />
                    <Atoms.CustomTable
                        columns={this.primerInfoHeader}
                        dataSource={primerInfo}
                        colorType="primary"
                    />
                </div>) : (<div>
                    <div style={{ marginRight: '50px' }}>
                        <div style={{ display: 'flex' }}>
                            <Typography style={{ width: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Primer Region</Typography>
                            <div style={{ flexGrow: 1 }}>
                                <Atoms.RangeSliderWithInput
                                    min={bpRange[0]}
                                    max={bpRange[1]}
                                    range={primerRegionRange}
                                    setRange={this.handleRegionSlideChange('primerRegionRange')}
                                    sliderColor="#f44336"
                                />
                            </div>
                        </div>
                        {useTarget && <div style={{ display: 'flex' }}>
                            <Typography style={{ width: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>Target Region</Typography>
                            <div style={{ flexGrow: 1 }}>
                                <Atoms.RangeSliderWithInput
                                    min={bpRange[0]}
                                    max={bpRange[1]}
                                    range={targetRegionRange}
                                    setRange={this.handleRegionSlideChange('targetRegionRange')}
                                />
                            </div>
                        </div>}
                    </div>

                    <Grid container spacing={2}>
                        <Grid item md={4}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={useTarget}
                                        onChange={this.handleUseTargetSwitchChange}
                                        value="useTarget"
                                        color="primary"
                                    />
                                }
                                label="Specify Target Range" />
                        </Grid>
                        <Grid item md={4}>
                            <RadioGroup row value={primerType} onChange={this.handlePrimerTypeRadioChange}>
                                <FormControlLabel
                                    value="RNA"
                                    control={<Radio color="primary" />}
                                    label="RNA"
                                    labelPlacement="end"
                                />
                                <FormControlLabel
                                    value="DNA"
                                    control={<Radio color="primary" />}
                                    label="DNA"
                                    labelPlacement="end"
                                />
                            </RadioGroup>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Collapse in={primerType==='RNA'}>
                            <Atoms.MultipleSelectControl
                                value={rnaPrimerTransTarget}
                                options={transTargetOptions}
                                handleChange={this.handleTransTargetChange}
                                placeholder="Target Gene"
                            />
                        </Collapse>
                    </Grid>
                    {primerType === 'RNA' && <Grid container spacing={2}>

                        {Object.keys(rnaPrimerParams).map(paramName => {
                            return (
                                <Grid item xs={6} sm={4} md={2} key={paramName}>
                                    <TextField
                                        label={paramName}
                                        inputProps={{
                                            type: 'number'
                                        }}
                                        value={rnaPrimerParams[paramName]}
                                        onChange={this.handlePrimerParamsChange('rna', paramName)}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>}
                    {primerType === 'DNA' && <Grid container spacing={2}>
                        {Object.keys(dnaPrimerParams).map(paramName => {
                            return (
                                <Grid item xs={6} sm={4} md={2} key={paramName}>
                                    <TextField
                                        inputProps={{
                                            type: 'number'
                                        }}
                                        label={paramName}
                                        value={dnaPrimerParams[paramName]}
                                        onChange={this.handlePrimerParamsChange('dna', paramName)}
                                        margin="dense"
                                        variant="outlined"
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>}
                </div>)}

                <Grid container item xs={12} spacing={2} justify="flex-end">
                    <Grid item xs={3} style={{ textAlign: 'right' }}>
                        <Button onClick={this.handleClear} color="secondary">Clear</Button>
                        <Button
                            color="primary"
                            onClick={this.handleDesign}
                        >Design</Button>
                    </Grid>
                </Grid>
            </Atoms.CustomCard>
        );
    }
}