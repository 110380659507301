import React from 'react';
import { SvgIcon, withStyles } from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        height: '100%'
    }
};

function GenomeOntologyIcon({ scale, classes }) {
    let svgScale = scale;
    if (!scale) {
        svgScale = 24;
    }
    return (
        <SvgIcon viewBox={`0 0 ${svgScale} ${svgScale}`} className={scale ? classes.root : undefined}>
            <g transform={`scale(${svgScale / 24})`} >
                <path d="M 3 8
                    a6 6 0 1 0 12 0
                    l-1.5 0
                    a4.5 4.5 0 1 1 -2.25 -3.89711
                    l0.75 -1.29904
                    a6 6 0 0 0 -9 5.19615Z"/>
                <path d="M 15 8 c0 -.25 -.25-.5 -.5-.5l-4.5 0
                    c-.375 0 -.75 .375 -.75 .75
                    c0 .375 .375 .75 .75 .75l4 0Z"/>
                <path d="M 10 15
                    a6 6 0 1 0 6 -6 l0 1.5
                    a4.5 4.5 0 1 1 -4.5 4.5Z"/>
            </g>
        </SvgIcon>
    );
}

export default withStyles(styles)(GenomeOntologyIcon);