import React from 'react';
import { Atoms, Layouts } from 'components';
import { Button } from '@material-ui/core';
import download from 'tools/download';

export default class FetchMAFView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: []
        };
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData();
        formData.append('species_abbr', 'gosArb');
        formData.append('genome_abbr', 'CGP');
        formData.append('file', this.state.fileList[0]);

        const res = await fetch('http://magen.whu.edu.cn/api/tools/fetch_maf/', {
            method: 'POST',
            body: formData
        });
        const blob = await res.blob();
        const filename = 'Result.zip';
        // const filename = res.headers.get('Content-Disposition').split('=')[1];
        download(blob, filename);
    };


    setFileList = (fileList) => this.setState({ fileList });

    render() {
        return (
            <Layouts.MainLayoutContent headerText="Fetch MAF">
                <Atoms.UploadButton
                    fileList={this.state.fileList}
                    setFileList={this.setFileList}
                />
                <Button onClick={this.handleSubmit}>submit</Button>

                <br />
                1. Select species / genome
                <br />
                2. Upload BED file
            </Layouts.MainLayoutContent>
        );
    }
}

