import React from 'react';
import { makeStyles, Grid, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { primaryColor, whiteColor, blackColor, hexToRgb } from 'assets/global';

import CompareIcon from 'components/icons/CompareIcon';
import SearchIcon from 'components/icons/SearchIcon';
import JbrowseIcon from 'components/icons/JbrowseIcon';
import DownloadIcon from 'components/icons/DownloadIcon';
import DocumentIcon from 'components/icons/DocumentIcon';
import GenomeInfoIcon from 'components/icons/GenomeInfoIcon';

const iconGroupTransform = 'perspective(800px) rotateY(20deg) translateX(25px)';
const infoGroupTransform = 'perspective(800px) rotateY(-20deg) translateX(-25px)';
const clipperTransform = 'rotate(-60deg) skewY(30deg)';
const clipperChildTransform = 'translateX(50%) rotate(-60deg) skewY(30deg)';
const containerTransform = 'skewY(-30deg) rotate(60deg)';
const applyTransfrom = (transform) => ({
    '-webkit-transform': transform,
    '-moz-transform': transform,
    '-ms-transform': transform,
    '-o-transform': transform,
    transform: transform
});

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: '120px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        }
    },
    iconGroupContainer: {
        position: 'relative',
        height: 0,
        paddingTop: '58%',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: primaryColor[0],
            clipPath: 'polygon(0 0, 90% 0, 100% 10%, 100% 100%, 10% 100%, 0 90%)',
            '-webkitClipPath': 'polygon(0 0, 90% 0, 100% 10%, 100% 100%, 10% 100%, 0 90%)'
        }
    },
    iconGroup: {
        position: 'absolute',
        backgroundColor: primaryColor[0],
        left: '10%',
        top: '10%',
        height: '80%',
        width: '80%',
        padding: '20px',
        ...applyTransfrom(iconGroupTransform),
        [theme.breakpoints.down('sm')]: {
            ...applyTransfrom('none'),
            padding: 0,
        }
    },
    hexIconClipper: {
        position: 'relative',
        overflow: 'hidden',
        float: 'left',
        width: '27.85714285714286%',
        padding: '0 0 32.16760145166612% 0',
        ...applyTransfrom(clipperTransform),
        visibility: 'hidden',
        '& *': {
            visibility: 'visible',
        },
        '&:nth-child(3n+2)': {
            margin: '0 1%',
        },
        '&:nth-child(6n+4), &:nth-child(6n+5), &:nth-child(6n+6)': {
            marginTop: '-6.9285714285%',
            marginBottom: '-6.9285714285%',
            ...applyTransfrom(clipperChildTransform)
        },
        'nth-child(6n+4)': {
            marginLeft: '0.5%'
        }
    },
    hexIconContainer: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: `rgba(${hexToRgb(whiteColor)}, 0.65)`,
        color: primaryColor[0],
        ...applyTransfrom(containerTransform),
        '&:hover, &:focus': {
            backgroundColor: `rgba(${hexToRgb(whiteColor)}, 0.85)`,
        }
    },
    iconLink: {
        color: 'inherit',
        textDecoration: 'none'
    },
    infoGroup: {
        backgroundColor: blackColor,
        height: '200px',
        padding: '80px 50px',
        ...applyTransfrom(infoGroupTransform),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    infoTitle: {
        color: whiteColor,
        marginBottom: '1.5rem'
    },
    infoDescription: {
        color: `rgba(${hexToRgb(whiteColor)}, 0.65)`,
        marginBottom: '1.5rem'
    },
    infoDecorator: {
        width: '128px',
        height: '8px',
        backgroundColor: primaryColor[0]
    }
}));

const iconArray = [
    { key: 'genome', icon: GenomeInfoIcon, link: '/genome/gosHir' },
    { key: 'search', icon: SearchIcon, link: '/search' },
    { key: 'jbrowse', icon: JbrowseIcon, link: '/jbrowse?species_abbr=gosArb&genome_abbr=CGP' },
    { key: 'compare', icon: CompareIcon, link: '/compare' },
    { key: 'download', icon: DownloadIcon, link: '/downloads' },
    { key: 'document', icon: DocumentIcon, link: '/document' },
];
const infoMap = {
    default: {
        title: 'Overview',
        description: 'Many data and tools can be accessed in MaGenDB.'
    },
    compare: {
        title: 'Tools',
        description: 'Functional and comparative genomics tools in MaGenDB',
    },
    search: {
        title: 'Search',
        description: 'Smart search system to quickly find the data of interest'
    },
    jbrowse: {
        title: 'Genome Browser',
        description: 'A genome browser to visualise omics data and functional elements'
    },
    download: {
        title: 'Downloads',
        description: 'Download data in MaGenDB'
    },
    document: {
        title: 'User Guides',
        description: 'User guides to help users get started quickly'
    },
    genome: {
        title: 'Genomes',
        description: 'Genomes in MaGenDB'
    }
};

export default function HexIconGroup() {
    const classes = useStyles();
    const [activeLink, setActiveLink] = React.useState('default');

    const handleHover = (value) => () => {
        setActiveLink(value);
    };

    return (
        <div className={classes.root}>
            <Grid container>
                <Grid item xs={12} md={7}>
                    <div className={classes.iconGroupContainer}>
                        <div className={classes.iconGroup}>
                            {iconArray.map(item => (
                                <div
                                    key={item.key}
                                    className={classes.hexIconClipper}
                                    onMouseOver={handleHover(item.key)}
                                    onMouseOut={handleHover('default')}
                                >
                                    <div className={classes.hexIconContainer}>
                                        <Link to={item.link} className={classes.iconLink}>
                                            <item.icon fill/>
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                </Grid>
                <Grid item xs={12} md={5}>
                    <div className={classes.infoGroup}>
                        <Typography
                            variant="h4"
                            className={classes.infoTitle}
                        >{infoMap[activeLink].title}</Typography>
                        <Typography
                            variant="body1"
                            className={classes.infoDescription}
                        >{infoMap[activeLink].description}</Typography>
                        <div className={classes.infoDecorator}></div>
                    </div>
                </Grid>
            </Grid>
        </div>


    );
}
