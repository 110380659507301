import React from 'react';
import HeatMap from './HeatMap';
import PropTypes from 'prop-types';

function GeneTissueHeatMap(props) {
    const xTicks = props.xAxis.ticks;
    const yTicks = props.yAxis.ticks;
    const toolTipTemplate = `<li data-index={index}>
            {locusName} - {sample}<br/>
            <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
            genome: {genome}<br/>
            <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
            Expression Level: {FPKM}<br/>
            <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
            SD: {SD}<br/>
            <span style="background-color:{color};width:8px;height:8px;border-radius:50%;display:inline-block;margin-right:8px;"></span>
            N: {N}
        </li>`;
    const toolTipRenderCallback = (row, col, FPKM, SD, N) => {
        return {
            locusName: yTicks[row].name,
            sample: xTicks[col].name,
            genome: yTicks[row].genome,
            FPKM, SD, N
        };
    };
    return <HeatMap
        toolTipTemplate={toolTipTemplate}
        toolTipCallback={toolTipRenderCallback}
        {...props}
    />;
}

GeneTissueHeatMap.propTypes = {
    dataSource: PropTypes.arrayOf(PropTypes.shape({
        row: PropTypes.number,
        col: PropTypes.number
    })).isRequired,
    valueIndex: PropTypes.string,
    xAxis: PropTypes.shape({
        title: PropTypes.string,
        ticks: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string
        })),
    }),
    yAxis: PropTypes.shape({
        title: PropTypes.string,
        ticks: PropTypes.arrayOf(PropTypes.shape({
            name: PropTypes.string
        })),
    })
};


export default GeneTissueHeatMap;