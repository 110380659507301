import React from 'react';
import { Button, FormControlLabel, TextField, RadioGroup, Radio, Grid, IconButton, Collapse } from '@material-ui/core';
import { Layouts, Atoms } from 'components';
import { getMultiAlign, getDomainCoorInfo } from 'api/fetch_data';
import { getCompareListData } from 'api/storage';
import { CompareResults } from '../components';

import InsertCommentIcon from '@material-ui/icons/InsertComment';
// import CompareDomain from '../component/CompareDomain';

const initialState = {
    useSequence: false,
    compareType: '',
    source: '',
    target: '',
    sequence: ''
};

class CompareView extends React.Component {
    constructor(props) {
        super(props);
        this.tabsRef = React.createRef();
        this.state = {
            compareListData: {
                geneList: [],
                transcriptionList: [],
                proteinList: []
            },
            ...initialState,
            align: {},
            sourceCoor: {},
            targetCoor: {},
        };
        this.selectMapping = {
            Locus: 'geneList',
            Trans: 'transcriptionList',
            Prot: 'proteinList'
        };
    }

    handleCompareTypeChange = (event) =>
        this.setState({ compareType: event.target.value, source: '', target: '' });

    handleSourceChange = (event) => this.setState({ source: event.target.value, target: '' });

    handleTargetChange = (event) => this.setState({ target: event.target.value });

    handleUseSequenceChange = () => this.setState({ useSequence: !this.state.useSequence });

    handleSeqInputChange = (event) => this.setState({ sequence: event.target.value });

    componentDidMount() {
        this.setState({ compareListData: getCompareListData() });
    }

    updateAlignData = async () => {
        const { compareType, source, target, sequence, useSequence } = this.state;
        const alignVariableParams = useSequence ? ['', '', sequence] : [target.id, target.genomeAbbr, ''];
        const align = await getMultiAlign(compareType, source.id, source.genomeAbbr, ...alignVariableParams);
        const sourceCoor = await getDomainCoorInfo(source.genomeAbbr, source.id, compareType);

        const targetCoor = useSequence ? {} : await getDomainCoorInfo(target.genomeAbbr, target.id, compareType);

        if (!this.__ignoreLastFetch) {
            this.setState({ align, sourceCoor, targetCoor });
        }
    };

    applyExample = (record, compareType) => (event) => {
        event.preventDefault();
        const newCompareListData = this.state.compareListData;
        const newRecordList = newCompareListData[this.selectMapping[compareType]];

        const sourceIndex = newRecordList.findIndex(
            item => item.id === record[0].id && item.speciesAbbr === record[0].speciesAbbr && item.genomeAbbr === newRecordList[0].genomeAbbr
        );
        if (sourceIndex === -1) newRecordList.push(record[0]);
        else record[0] = newRecordList[sourceIndex];

        const targetIndex = newRecordList.findIndex(
            item => item.id === record[1].id && item.speciesAbbr === record[1].speciesAbbr && item.genomeAbbr === record[1].genomeAbbr
        );
        if (targetIndex === -1) newRecordList.push(record[1]);
        else record[1] = newRecordList[targetIndex];

        newCompareListData[this.selectMapping[compareType]] = newRecordList;
        this.setState({ compareListData: newCompareListData, source: record[0], target: record[1], compareType, sequence: '', useSequence: false });
        this.tabsRef.current.handleChange(event, 0);
    }

    applySeqExample = (sequence, source, compareType) => (event) => {
        event.preventDefault();
        const newCompareListData = this.state.compareListData;
        const newRecordList = newCompareListData[this.selectMapping[compareType]];

        const sourceIndex = newRecordList.findIndex(
            item => item.id === source.id && item.speciesAbbr === source.speciesAbbr && item.genomeAbbr === source.genomeAbbr
        );
        if (sourceIndex === -1) newRecordList.push(source);
        else source = newRecordList[sourceIndex];

        newCompareListData[this.selectMapping[compareType]] = newRecordList;
        this.setState({ sequence, source, compareType, useSequence: true, target: '' });
        this.tabsRef.current.handleChange(event, 0);
    }


    render() {
        const { compareType, source, target, compareListData, align, sequence, useSequence, sourceCoor, targetCoor } = this.state;
        const sourceOptions = compareListData[this.selectMapping[compareType]] || [];
        let targetOptions = sourceOptions;
        if (source !== '') {
            targetOptions = sourceOptions.filter(item =>
                item.id !== source.id || item.speciesAbbr !== source.speciesAbbr || item.genomeAbbr !== source.genomeAbbr);
        }

        return (
            <Layouts.MainLayoutContent headerText="Gene comparator">
                <Atoms.ExampleCard
                    title="Select records or provide a sequence:"
                    ref={this.tabsRef}
                    formContent={
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <RadioGroup row value={useSequence} onChange={this.handleUseSequenceChange}>
                                    <FormControlLabel
                                        value={false}
                                        control={<Radio color="primary" />}
                                        label="Choose From Your List"
                                        labelPlacement="end"
                                    />
                                    <FormControlLabel
                                        value={true}
                                        control={<Radio color="primary" />}
                                        label="Paste Your Own Sequence"
                                        labelPlacement="end"
                                    />
                                </RadioGroup>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Atoms.SelectControl
                                    value={compareType}
                                    handleChange={this.handleCompareTypeChange}
                                    options={[
                                        { value: 'Locus', text: 'Compare Gene' },
                                        { value: 'Trans', text: 'Compare Transcript' },
                                        { value: 'Prot', text: 'Compare Protein' }
                                    ]}
                                    variant="outlined"
                                    placeholder="Choose Mode"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Atoms.SelectControl
                                    value={source}
                                    handleChange={this.handleSourceChange}
                                    placeholder="Choose Compare Item"
                                    options={sourceOptions.map(item =>
                                        ({ value: item, text: item.name })
                                    )}
                                    variant="outlined"
                                />
                            </Grid>
                            <Grid item xs={useSequence ? 0 : 12} md={4}>
                                <Collapse in={!useSequence}>
                                    <Atoms.SelectControl
                                        disabled={source === '' || useSequence}
                                        value={target}
                                        handleChange={this.handleTargetChange}
                                        placeholder="Choose Compare Item"
                                        options={targetOptions.map(item =>
                                            ({ value: item, text: item.name })
                                        )}
                                        variant="outlined"
                                    />
                                </Collapse>
                            </Grid>
                            <Grid item xs={12}>
                                <Collapse in={useSequence}>
                                    <TextField
                                        id="sequence"
                                        label="Sequence"
                                        fullWidth
                                        margin="normal"
                                        variant="outlined"
                                        value={sequence}
                                        onChange={this.handleSeqInputChange}
                                        multiline
                                        rows={4}
                                    />
                                </Collapse>
                            </Grid>
                            <Grid container item justify="flex-end" xs={12}>
                                <Grid item xs={12} sm={6} md={3} style={{ textAlign: 'right' }}>
                                    <Button
                                        onClick={this.updateAlignData}
                                        disabled={source === '' || ((target === '' || useSequence) && (!useSequence || sequence === '')) || compareType === ''}
                                        color="primary"
                                    >Submit</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    exampleContent={
                        <div>
                            <IconButton
                                onClick={this.applyExample(
                                    [
                                        { 'name': 'Ga05g02643.F1', 'speciesAbbr': 'gosArb', 'genomeAbbr': 'CGP', 'id': 'Chr05_3260451487322147248' },
                                        { 'name': 'evm.model.Ga05G2709', 'speciesAbbr': 'gosArb', 'genomeAbbr': 'CGP', 'id': 'Chr05_2593599946228492417' }
                                    ], 'Prot'
                                )}
                                size="small">
                                <InsertCommentIcon />Example 1
                            </IconButton>
                            <IconButton
                                onClick={this.applySeqExample(
                                    'CATTAAAAGAACATGTTATTCACGACCCCGAAAATGTCTTGACAACTAACTGGTCAGCTTCTACCCATGTTTGCAATTGGTTTGGTGTCAGTTTTTGGCGGTGAATTACCTGTCCAATTATCCAATTTGCGACGGTTGAAGCATATAAACTTTCGTAACAACAACTTTAGTGGAGAAATCCCATCATGGTTGGGATCATTACCTGAACTTCAAAGGTTGTTTCTGTATCATAACAACTTCAAGGGTGCTATTCCATTTTCTTTAGGTAATTTGTCAAAGCTAGAGATGTTGTACTTGTTAGATAACCAGCTTTCAGGTTCAATACCTTTATCCATCTTCAATATATCTTCGTTGCAGAAAATAAATCTAAGAAACAATTATCTCTTTGGTTCCATACCCTCTGGTTCGCACGATTTTCTTTCCCTAGCACTCATAGATTTCACCTTCAATAATTTCACTGGCCATATTCCAAGTGATATGTTTGATCATCTTCCGAATTTGAAAAATCTGGGCTTGAGTTATAACATGCTTTCCGGTAAAATTCCAGCTAGTTTATGCAAGTGCAAAGAGTTACAAATTTTATCGTTATCATATAACCAATTTGGAGGAAACCTTCCGATAGAAATTGGGAATTTGAGTATGCTTCAAATGCTCCTTATTGGTGACAATAACTTTGAAGGTAAGGTATACCAATTTTTTGAAGCTACCAAAGTGTTTATACAATTTGAGAATAAATTAATAAAATAAAAAGGAGATAATTTTTTAAAAAAATCCATGCTATTCGGACCTTTATTCCTGTAGTCATGGAGTGTGCAATATGATACCTGATAGGATGGGGAATATCTTGTTAGTGCTGCTTTATTCAATTTCTTGCAAACTTTTTTCTGAGTGGGAGAATTCTGAATTTGATGTGTGAATTTTATCATTGCAGGTGAAATTCCAGAACAAGTTGGGAATCTAACTCTTCTTATGATGCTTGATGCTGCCTACAATAACTTAACAGGTGAATGAGTAAAAGATAGTTTACATTCAAATTATTATTAGTTAATTTGTAACTT',
                                    { 'name': 'evm.model.Ga01G0494', 'speciesAbbr': 'gosArb', 'genomeAbbr': 'CGP', 'id': 'gosArb_Chr01_5614616_5616030_fwd' },
                                    'Locus'
                                )}
                                size="small">
                                <InsertCommentIcon />Example 2
                            </IconButton>
                        </div>
                    }
                />
                <CompareResults
                    align={align}
                    sequence={sequence}
                    source={source}
                    target={target}
                    compareType={compareType}
                    sourceCoor={sourceCoor}
                    targetCoor={targetCoor}
                />
            </Layouts.MainLayoutContent>
        );
    }
}


CompareView.propTypes = {
};


export default CompareView;
