const storage = {
    init: function () {
        if (
            (!localStorage.getItem('geneList')) ||
            (!localStorage.getItem('transcriptionList')) ||
            (!localStorage.getItem('proteinList'))
        ) {
            this.set('geneList', []);
            this.set('transcriptionList', []);
            this.set('proteinList', []);
        }

    },
    set: function (key, value) {
        localStorage.setItem(key, JSON.stringify(value));
    },
    get: function (key) {
        return JSON.parse(localStorage.getItem(key));
    },
    delete: function (key) {
        localStorage.removeItem(key);
    },
    appendRecord: function (key, value) {
        const valuelist = this.get(key);
        if (valuelist instanceof Array) {
            if (valuelist.find(record =>
                record.name === value.name &&
                record.speciesAbbr === value.speciesAbbr &&
                record.genomeAbbr === value.genomeAbbr &&
                record.id === value.id
            ) === undefined) {
                valuelist.push(value);
                this.set(key, valuelist);
            }
        }
    },
    removeRecord: function (key, value) {
        const valuelist = this.get(key);
        if (valuelist instanceof Array) {
            const index = valuelist.findIndex(record =>
                record.name === value.name &&
                record.speciesAbbr === value.speciesAbbr &&
                record.genomeAbbr === value.genomeAbbr &&
                record.id === value.id
            );
            if (index !== -1) {
                valuelist.splice(index, 1);
                this.set(key, valuelist);
            }
        }
    },
};


function getCompareListData() {
    return {
        geneList: storage.get('geneList') || [],
        transcriptionList: storage.get('transcriptionList') || [],
        proteinList: storage.get('proteinList') || []
    };
}

export { storage, getCompareListData };