import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import { primaryColor } from 'assets/global';
import ExternalLinkIcon from 'components/icons/ExternalLinkIcon';

const useStyles = makeStyles({
    link: {
        position: 'relative',
        fontWeight: '600',
        display: 'inline-block',
        color: primaryColor[0],
        textDecoration: 'none',
        transition: '.25s',
        '&::after': {
            position: 'absolute',
            bottom: 4,
            left: 0,
            content: '""',
            width: 0,
            height: '1px',
            backgroundColor: primaryColor[0],
            transition: '.25s'
        },
        '&:hover::after,&:focus::after': {
            width: '100%'
        },
        '&:visited': {
            color: `${primaryColor[3]} !important`
        },
        '&:hover,&:focus': {
            transform: 'scale(1.05)',
            '& span.ext-link-addon':{
                display: 'inline-block'
            }
        },
        '& span.ext-link-addon': {
            color: 'rgba(0,0,0,0.75)',
            marginLeft: '2px',
            transition: '.25s',
            display: 'none',
        }
    },
    
});

export default function CustomLink({ children, href, onClick, externalTarget, ...otherProps }) {
    const classes = useStyles();
    if (onClick) {
        return (
            <span onClick={onClick} className={classes.link}>
                {children}
            </span>
        );
    }
    if (href) {
        return (
            <a className={classes.link} href={href}>
                {children}
                <span className="ext-link-addon">
                    <ExternalLinkIcon fontSize="inherit"/>
                    {externalTarget}
                </span>
            </a>
        );
    }
    return (
        <Link
            className={classes.link}
            {...otherProps}
        >{children}</Link>
    );
}

CustomLink.propTypes = {
    onClick: PropTypes.func,
    href: PropTypes.string,
    externalTarget: PropTypes.string,
    children: PropTypes.any
};