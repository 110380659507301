import React from 'react';
import echarts from 'echarts';
import PropTypes from 'prop-types';
import { AppContext } from 'App';

import { chartColors } from 'assets/global';

class NetWorkPlot extends React.Component {
    constructor(props, context) {
        super(props, context);
        const id = ('_' + Math.random()).replace('.', '_');
        this.state = {
            pieId: 'pie' + id
        };
    }

    componentDidMount() {
        const { genomeAbbr, speciesAbbr, history } = this.props;
        this.myChart = echarts.getInstanceByDom(document.getElementById(this.state.pieId));
        if (this.myChart === undefined) {
            this.myChart = echarts.init(document.getElementById(this.state.pieId));
        }
        this.myChart.on('click', 'series.graph', params => {
            if (params.dataType === 'node') {
                if (history.location.pathname) {
                    const pathList = history.location.pathname.split('/').slice(0, 4);
                    pathList.push(speciesAbbr);
                    pathList.push(genomeAbbr);
                    pathList.push(params.data.id);
                    history.push(pathList.join('/'));
                    history.go();
                }
                //history.push(`/magendb/wiki/gene/${speciesAbbr}/${genomeAbbr}/${params.data.id}`);
                //history.go();
            }
        });
    }

    render() {
        const { nodes, links, height } = this.props;
        const data = [];
        const categories = [{ name: 'center' }, { name: 'other' }];
        for (const key in nodes) {
            const node = nodes[key];
            if (node.edgeCount !== 0) {
                key === '0' ? data.push({ category: 'center', ...node, symbolSize: 20 }) : data.push({ category: 'other', ...node, symbolSize: 10 });
            }
        }

        // draw chart
        const echartsOption = {
            tooltip: {
                formatter: (params) => {
                    if (params.dataType === 'node') {
                        return `<div style="color: ${params.color};">${params.data.name}<div>`;
                    } else {
                        return `<div><p>${params.data.sourceName} >> ${params.data.targetName}</p><p>Score:  ${params.data.value}</p></div>`;
                    }
                }
            },
            color: chartColors,
            series: [{
                type: 'graph',
                layout: 'force',
                animation: false,
                label: 'none',
                roam: true,
                focusNodeAdjacency: true,
                draggable: true,
                data: data,
                categories: categories,
                edges: links.map(link => {
                    return { source: nodes[link[0]].id, target: nodes[link[1]].id, value: link[2], sourceName: nodes[link[0]].name, targetName: nodes[link[1]].name };
                }),
                force: {
                    repulsion: 200
                },

                itemStyle: {
                    normal: {
                        borderColor: '#fff',
                        borderWidth: 1,
                        shadowBlur: 10,
                        shadowColor: 'rgba(0, 0, 0, 0.3)'
                    }
                },
                lineStyle: {
                    color: 'source',
                    curveness: 0
                },
                emphasis: {
                    lineStyle: {
                        width: 10
                    }
                }
            }]
        };
        if (this.myChart) {
            this.myChart.setOption(echartsOption);
        }

        return (
            <div id={this.state.pieId} style={{ height: height }}></div>
        );
    }
}


NetWorkPlot.propTypes = {
    nodes: PropTypes.object,
    links: PropTypes.array,
    height: PropTypes.string,
    speciesAbbr: PropTypes.string,
    genomeAbbr: PropTypes.string
};


const PlotHOC = props => (
    <AppContext.Consumer>
        {({ history }) => <NetWorkPlot {...props} history={history} />}
    </AppContext.Consumer>
);
export default PlotHOC;