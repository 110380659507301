import React from 'react';
import { Typography } from '@material-ui/core';
import EmptyIcon from 'components/icons/EmptyIcon';
import { grayColor } from 'assets/global';

export default function Empty() {
    return (
        <div style={{ color: grayColor[6] }}>
            <EmptyIcon style={{ fontSize: '5rem'}} />
            <Typography variant="h5">No Data Found!</Typography>
        </div>
    );
}