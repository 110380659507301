import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Atoms } from 'components';
import { getGoInfo } from 'api/fetch_data';

class GoInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableData: [],
            mergeList: []
        };
        this.header = [
            { key: 'term', content: 'Ontology' },
            { key: 'go', content: 'Accession' },
            { key: 'name', content: 'Name' },
            { key: 'search', content: 'Search same ontology gene' }
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { genomeAbbr, recordType, id } = this.props;
        getGoInfo(genomeAbbr, id, recordType).then(data => {
            if (!this.__ignoreLastFetch && data) {
                const dataSource = [];
                const mergeList = [];
                let begin_indx = 0;
                for (const term in data) {
                    const data_li = data[term];
                    const row_len = data_li.length;
                    mergeList.push(
                        { y: 0, x: begin_indx, row: row_len }
                    );
                    begin_indx = begin_indx + row_len;
                    data_li.forEach((item, index) => {
                        let newData = {};
                        if (index === 0) {
                            newData = {
                                go: <a href={`http://amigo.geneontology.org/amigo/term/${item['go']}`}>{item['go']}</a>,
                                name: item['name'],
                                term: term,
                                search: <Link to={{
                                    pathname: '/search/go',
                                    state: {'searchText': item['go'], 'species': 'All', 'genome': 'All', 'assemble': 'All'}
                                }}>Search</Link>
                            };
                        } else {
                            newData = {
                                go: <a href={`http://amigo.geneontology.org/amigo/term/${item['go']}`}>{item['go']}</a>,
                                name: item['name'],
                                search: <Link to={{
                                    pathname: '/search/go',
                                    state: {'searchText': item['go'], 'species': 'All', 'genome': 'All', 'assemble': 'All'}
                                }}>Search</Link>
                            };
                        }
                        dataSource.push(newData);
                    });
                }
                this.setState({ tableData: dataSource, mergeList });
            }
        });

    };

    render() {
        const { tableData, mergeList } = this.state;

        if (tableData.length === 0) {
            return <div></div>;
        }
        return (
            <Atoms.CustomTable columns={this.header} dataSource={tableData} mergeList={mergeList} />  
        );    
    }
}

GoInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string
};

export default GoInfo;

