import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Axis, Geom, Tooltip, View, Shape } from 'bizcharts';

import { chartColors as colors } from 'assets/global';

Shape.registerShape('interval', 'stdevbar', {
    getPoints(cfg){
        const x = cfg.x;
        const y = cfg.y[0];
        const stdev = cfg.y[1];
        const y0 = cfg.y0;
        const width = cfg.size;
        return [
            { x: x - width / 2, y: y0 },
            { x: x - width / 2, y: y },
            { x: x + width / 2, y: y},
            { x: x + width / 2, y: y0 },
            { x: x, y: y - stdev },
            { x: x, y: y + stdev },
            { x: x + width/6, y: y - stdev },
            { x: x - width/6, y: y + stdev }

        ];
    },
    draw(cfg, container) {
        const points = this.parsePoints(cfg.points);
        container.addShape('polygon', {
            attrs: {
                points: [
                    [points[0].x, points[0].y],
                    [points[1].x, points[1].y],
                    [points[2].x, points[2].y],
                    [points[3].x, points[3].y]
                ],
                fill: cfg.color
            }
        });
        container.addShape('line', {
            attrs: {
                x1: points[4].x,
                x2: points[5].x,
                y1: points[4].y,
                y2: points[5].y,
                lineWidth: 1,
                stroke: '#000',
            }
        });
        container.addShape('line', {
            attrs: {
                x1: points[6].x,
                x2: points[7].x,
                y1: points[6].y,
                y2: points[6].y,
                lineWidth: 2,
                stroke: '#000',
            }
        });
        const polygon = container.addShape('line', {
            attrs: {
                x1: points[6].x,
                x2: points[7].x,
                y1: points[7].y,
                y2: points[7].y,
                lineWidth: 2,
                stroke: '#000',
            }
        });
        
        return polygon;
    }
});

Shape.registerShape('interval', 'myrect', {
    getPoints(cfg){
        const x = cfg.x;
        const y = cfg.y[0];
        const y0 = cfg.y0;
        const width = cfg.size;
        return [
            { x: x - width / 2, y: y0 },
            { x: x - width / 2, y: y },
            { x: x + width / 2, y: y},
            { x: x + width / 2, y: y0 },
        ];
    },
    draw(cfg, container) {
        const points = this.parsePoints(cfg.points);
        const polygon = container.addShape('polygon', {
            attrs: {
                points: [
                    [points[0].x, points[0].y],
                    [points[1].x, points[1].y],
                    [points[2].x, points[2].y],
                    [points[3].x, points[3].y]
                ],
                fill: cfg.color
            }
        });
        return polygon;
    }
});


const sortData = (data1, data2) => {
    var a = data1.sample.toLowerCase();
    var b = data2.sample.toLowerCase();
    if(a < b) return -1;
    if(a > b) return 1;
    return 0;
};

function StDevStatisticsDiagram({ rawData, summary, widthPerSample, height, padding }) {
    const pointData = [], barData = [];
    const pointIndexList = Object.keys(rawData);
    pointIndexList.forEach(pointIndex =>
        rawData[pointIndex].forEach(point => {
            pointData.push({
                sample: pointIndex,
                srr: point['SRR_ID'],
                value: point['expr']
            });
        })
    );
    summary.forEach(sample => 
        barData.push({
            sample: sample['sample'],
            value: [ sample['mu'], sample['sd'] ],
            withStDev: sample['sd'] !== 'NA',
            count: sample['file_num']
        })
    );

    const maxRange = Math.max.apply(null, pointData.map(item => item.value))*1.05;

    const scale = {
        value: {
            min: 0,
            max: maxRange>0?maxRange:1
        },
        sample: {
            sync: true
        }
    };

    return (
        <Chart data={pointData.sort(sortData)} scale={scale} width={widthPerSample*barData.length+ padding[0] + padding[2]} height={height} padding={padding}>
            <Axis name="sample" />
            <Axis name="value" position="right" visible={false}/>
            <Tooltip
                crosshairs={{
                    type: 'cross',
                }}
            />
            <Geom
                type="point"
                position="sample*value"
                shape="cross"
                color="#000"
                tooltip={['srr*value', (srr, value) => ({
                    name: srr,
                    value: value
                })]}
            />
            <View data={barData.sort(sortData)} scale={scale}>
                <Axis name="value" />
                <Geom
                    type="interval"
                    position="sample*value"
                    color={['sample', colors]}
                    shape={['withStDev', (withStDev) => {
                        if (withStDev) {
                            return 'stdevbar';
                        } else 
                            return 'myrect';
                    }]}
                    tooltip={['withStDev*value', (withStDev, value) => ({
                        name: '',
                        value: `Aver: ${value[0]},  St.dev: ${withStDev?value[1]:'None'}`
                    })]}
                />
                
            </View>
        </Chart>
    );
}

StDevStatisticsDiagram.propTypes = {
    rawData: PropTypes.object,
    summary: PropTypes.array,
    widthPerSample: PropTypes.number,
    height: PropTypes.number,
    padding: PropTypes.arrayOf(PropTypes.number)
};

export default StDevStatisticsDiagram;