import React from 'react';
import PropTypes from 'prop-types';
import { Atoms, Charts } from 'components';
import { getGeneExpr } from 'api/fetch_data';

class GeneExpressionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sampleData: {},
            dataSource: []
        };
        this.header = [
            { key: 'sample', content: 'Sample' },
            { key: 'file_num', content: '#Replicates' },
            { key: 'mu', content: 'Average FPKM' },
            { key: 'sd', content: 'FPKM Std.' },
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { genomeAbbr, id } = this.props;
        getGeneExpr(genomeAbbr, id).then(res => {
            if (!this.__ignoreLastFetch) {
                const dataSource =  res['summary'].map(item => ({ ...item }));
                this.setState({ sampleData: res.data, dataSource });
            }
        });

    };

    render() {
        const { sampleData, dataSource } = this.state;
        if (dataSource.length === 0) {
            return null;
        }
        return (
            <Atoms.InfoGroup title="Gene expression">
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Charts.StDevStatisticsDiagram
                        rawData={sampleData}
                        summary={dataSource}
                        height={300}
                        widthPerSample={40}
                        padding={[10, 50, 80, 50]}
                    />
                </div>
                <Atoms.CustomTable columns={this.header} dataSource={dataSource} />
            </Atoms.InfoGroup>
        );
    }
}

GeneExpressionInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
};

export default GeneExpressionInfo;

