export default function download(blob, fileName) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) //For IE
    {
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    }
    else if (navigator.userAgent.toLowerCase().indexOf('firefox') > -1) //For FireFox
    {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        document.body.appendChild(link);
        //not working
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }
    else {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.download = fileName;
        link.click();
        link.remove();
        window.URL.revokeObjectURL(url);
    }
}
