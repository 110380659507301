import React from 'react';
import PropTypes from 'prop-types';
import { Table, Container, TableHead, TableBody, TableRow, withStyles, colors } from '@material-ui/core';
import CustomLink from '../CustomLink';
import TableCellWithPopover from './TableCellWithPopover';
import CustomTableCell from './CustomTableCell';

import { infoColor, whiteColor, hexToRgb } from 'assets/global';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';

const styles = ({
    container: {
        padding: '0 !important',
        background: '#fafafa',
        boxShadow: '0 0 5px rgba(0, 0, 0, 0.45)',
        maxHeight: window.innerHeight - 160,
        '&::-webkit-scrollbar': {
            width: '10px',
            height: '10px'
        },
        '&::-webkit-scrollbar-thumb': {
            background: infoColor[0],
            borderRadius: '2px',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': `inset 0 0 6px rgba(${hexToRgb(whiteColor)}, 0.25)`,
            background: 'transparent'
        },
        overflow: 'auto'
    },
    tableCell: {
        borderRight: `1px solid ${colors.grey[200]}`,
        textAlign: 'center',
        padding: '14px 28px 14px 28px'
    },
    tableFirstCol: {
        position: 'sticky',
        left: 0,
        top: 'auto',
        zIndex: 1
    }

});

function VerticalCustomTable({ classes, dataSource, rows, spanList }) {
    const tableHeaderRows = rows.filter(row => row.sticky);
    const tableBodyRows = rows.filter(row => !row.sticky);

    return rows.length === 0 ? null : (
        <Container fixed className={classes.container}>
            <Table>
                <TableHead>
                    {tableHeaderRows.map((row, rowIndex) => {
                        return <TableRow key={rowIndex}>
                            <TableCellWithPopover
                                style={{ position: 'sticky', top: 68 * rowIndex, left: 0, height: 68, zIndex: 2 }}
                                variant="head"
                                component="th"
                                tableText={row['Content']}
                                description={row['Description']}
                            />
                            {(dataSource[row['name']]).map((cell, colIndex) => {
                                const span = spanList[row['span_level']];
                                const displayMode = cell['Display'];
                                const displayMapping = {
                                    'Normal': <CustomLink href={cell['Link']}>{cell['Content']}</CustomLink>,
                                    'Hide': '-',
                                    'Icon': <a href={cell['Link']} style={{ color: 'inherit' }}><VerticalAlignBottomIcon /></a>
                                };
                                return <CustomTableCell
                                    className={classes.tableCell}
                                    style={{ position: 'sticky', top: 68 * rowIndex, height: 68, background: '#fafafa' }}
                                    variant="body"
                                    component="th"
                                    colSpan={span ? (span[colIndex])['len'] : 1}
                                    key={colIndex}>
                                    {displayMapping[displayMode]}
                                </CustomTableCell>;
                            })}
                        </TableRow>;
                    })}
                </TableHead>
                <TableBody>
                    {tableBodyRows.map((row, rowIndex) => {
                        return <TableRow key={rowIndex}>
                            <TableCellWithPopover
                                className={classes.tableFirstCol}
                                variant="head"
                                component="td"
                                tableText={row['Content']}
                                description={row['Description']}
                            />
                            {(dataSource[row['name']]).map((cell, colIndex) => {
                                const span = spanList[row['span_level']];
                                const displayMode = cell['Display'];
                                const displayMapping = {
                                    'Normal': <CustomLink href={cell['Link']}>{cell['Content']}</CustomLink>,
                                    'Hide': '-',
                                    'Icon': <a href={cell['Link']} style={{ color: 'inherit' }}><VerticalAlignBottomIcon /></a>
                                };
                                return <CustomTableCell
                                    className={classes.tableCell}
                                    variant="body"
                                    component="td"
                                    colSpan={span ? (span[colIndex])['len'] : 1}
                                    key={colIndex}>
                                    {displayMapping[displayMode]}
                                </CustomTableCell>;
                            })}
                        </TableRow>;
                    })}
                </TableBody>
            </Table>
        </Container>
    );
}

VerticalCustomTable.propTypes = {
    classes: PropTypes.object.isRequired,
    rows: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        content: PropTypes.any
    })).isRequired,
    dataSource: PropTypes.object.isRequired,
    spanList: PropTypes.object,
};

export default withStyles(styles)(VerticalCustomTable);