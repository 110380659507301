import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Typography, Toolbar, withStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

import { drawerWidth, grayColor, hexToRgb, blackColor } from 'assets/global';

const styles = theme => ({
    appBar: {
        boxShadow: 'none',
        backgroundColor: grayColor[10],
        display: 'flex',
        paddingLeft: '10px',
        color: `rgba(${hexToRgb(blackColor)}, 0.65)`,
        marginLeft: drawerWidth,
        [theme.breakpoints.up('sm')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    text: {
        fontWeight: 'bold',
        flexGrow: 1
    }
});

function CustomHeader({ classes, text, textLoading, children }) {
    return (
        <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
                {textLoading ? <Skeleton variant="rect" height={32} width={360}/> : <Typography variant="h6" color="inherit" noWrap className={classes.text}>
                    {text}
                </Typography>}
                {children}
            </Toolbar>
        </AppBar>
    );
}

CustomHeader.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.any,
    text: PropTypes.string.isRequired,
    textLoading: PropTypes.bool
};

export default withStyles(styles)(CustomHeader);