import React from 'react';
import { Charts, Layouts } from 'components';

export default class PhyloTreeViewer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tree: {}
        };
    }

    async componentDidMount() {
        /* const response = await fetch(`${process.env.REACT_APP_HOME_PATH}phyloTree.json`, {
            method: 'GET'
        }); */
        const response = await fetch('/phyloTree.json', {
            method: 'GET'
        });
        const text = await response.text();
        const tree = JSON.parse(text);
        this.setState({ tree });
    }

    render() {
        const { tree } = this.state;
        return (
            <Layouts.MainLayoutContent headerText="PhyloTree Viewer">
                <Charts.TreePlot
                    dataSource={tree}
                    height={900}
                />
            </Layouts.MainLayoutContent>
        );
    }
}

PhyloTreeViewer.propTypes = {

};
