import React from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@material-ui/core';
import { whiteColor } from 'assets/global';

export default function ChipLink({ href, children }) {
    return (
        <Chip
            style={{ color: whiteColor }}
            size="small"
            label={children}
            href={href}
            clickable
            color="primary"
        />
    );
}

ChipLink.propTypes = {
    href: PropTypes.string,
    children: PropTypes.any
};