import React from 'react';
import PropTypes from 'prop-types';
import { SvgIcon } from '@material-ui/core';

function JbrowseIcon({ fill, style: styleProp, ...otherProps }) {
    return (
        <SvgIcon
            {...otherProps}
            style={fill ? {
                width: '100%', height: '100%', ...styleProp
            } : { ...styleProp }}
        >
            <g transform="scale(0.024)" >
                <path d="
                    M226 784
                    h548
                    c4.4 0 8-3.6 8-8
                    v-500
                    c0-7.2-8.7-10.7-13.7-5.7
                    l-160 280
                    l-125-124
                    a8.03 8.03 0 0 0-11.3 0
                    l-150 190
                    a7.87 7.87 0 0 0-2.3 -5.6
                    v-390
                    c0-4.4-3.6-8-8-8
                    h-90
                    c4.4 0 -8 3.6-8 8
                    V776
                    c0 4.4 3.6 8 8 8z">
                </path> 
            </g>
        </SvgIcon>
    );
}

JbrowseIcon.propTypes = {
    fill: PropTypes.bool,
    style: PropTypes.object
};

export default JbrowseIcon;