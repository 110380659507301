import React from 'react';
import { SvgIcon, withStyles } from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        height: '100%'
    }
};

function GenomeSytenyIcon({ scale, classes }) {
    let svgScale = scale;
    if (!scale) {
        svgScale = 24;
    }
    return (
        <SvgIcon viewBox={`0 0 ${svgScale} ${svgScale}`} className={scale ? classes.root : undefined}>
            <g transform={`scale(${svgScale / 24})`} >
                <path d="M 4 5l16 0 0-1-16 0Z" />
                <path d="M 6 12l16 0 0-1-16 0Z" />
                <path d="M 4 19l13 0 0-1-13 0Z" />

                <path d="M 4 6l3.7 0c.25 0 .5-.25.5-.5l0-2c0-.25-.25-.5-.5-.5l-3.7 0c-.25 0-.5.25-.5.5l0 2c0 .25.25.5.5.5Z" />
                <path d="M 10 6l9.7 0c.25 0 .5-.25.5-.5l0-2c0-.25-.25-.5-.5-.5l-9.7 0c-.25 0-.5.25-.5.5l0 2c0 .25.25.5.5.5Z" />

                <path d="M 6 13l1.7 0c.25 0 .5-.25.5-.5l0-2c0-.25-.25-.5-.5-.5l-1.7 0c-.25 0-.5.25-.5.5l0 2c0 .25.25.5.5.5Z" />
                <path d="M 10 13l11.7 0c.25 0 .5-.25.5-.5l0-2c0-.25-.25-.5-.5-.5l-11.7 0c-.25 0-.5.25-.5.5l0 2c0 .25.25.5.5.5Z" />

                <path d="M 3 20l2.7 0c.25 0 .5-.25.5-.5l0-2c0-.25-.25-.5-.5-.5l-2.7 0c-.25 0-.5.25-.5.5l0 2c0 .25.25.5.5.5Z" />
                <path d="M 10 20l7.7 0c.25 0 .5-.25.5-.5l0-2c0-.25-.25-.5-.5-.5l-7.7 0c-.25 0-.5.25-.5.5l0 2c0 .25.25.5.5.5Z" />
            </g>
        </SvgIcon>
    );
}

export default withStyles(styles)(GenomeSytenyIcon);