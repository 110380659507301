import React from 'react';
import PropTypes from 'prop-types';
import { Layouts, Atoms } from 'components';
import {
    ProtSummaryInfo, Ontology,
    ProtDomainInfo ,TfInfo, Annotation, IvalMsInfo,
    PPINetworkInfo, GeneFamilyInfo, ColinearInfo, Prot3D
} from 'pages/wiki/components';

import { getName } from 'api/fetch_data';
import { getCompareListData, storage } from 'api/storage';

class ProtWiki extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            protName: '',
            speciesName: '',
            prot:{
                chrom: '',
                start: '',
                end: '',
                strand: '',
                exon_len: '',
                aa_len: '',
            },
            compareListData: {
                geneList: [],
                transcriptionList: [],
                proteinList: []
            }
        };
    }

    setProt = (prot)=> this.setState({prot: prot});

    componentDidMount() {
        this.fetchData();
    }

    addProtToCompare = () => {
        const { match:{ params }} = this.props;
        storage.appendRecord('proteinList', {
            name: this.state.protName,
            ...params
        });
        this.setState({ compareListData: getCompareListData() });
    };

    removeProtFromCompare = (category, prot) => {
        storage.removeRecord(category, prot);
        this.setState({ compareListData: getCompareListData() });
    };

    fetchData = () => {
        const { genomeAbbr, id } = this.props.match.params; 
        getName(genomeAbbr, id, 'Prot')
            .then(data => {
                this.setState({
                    protName: data.name,
                    speciesName: data.species_name,
                });
            });
    };

    render() {
        const { match: {params} } = this.props;
        const { protName, speciesName, prot, compareListData } = this.state;
        return (
            <Layouts.MainLayoutContent headerText={`${protName} (${speciesName})`} textLoading={protName===''}>
                <ProtSummaryInfo setProt={this.setProt} {...params} />
                <IvalMsInfo {...params} recordType="Prot" ival={prot}/>
                <Annotation {...params} recordType="Prot"/>
                <GeneFamilyInfo {...params} recordType="Prot"/>
                <TfInfo {...params} recordType="Prot"/>
                <Ontology {...params} recordType="Prot"/>
                <ProtDomainInfo prot={prot} {...params}/>
                <ColinearInfo {...params} recordType="Prot"/>
                <PPINetworkInfo {...params} recordType="Prot"/>
                <Prot3D {...params} recordType="Prot"/>
                <Atoms.CompareList 
                    dataSource={compareListData} 
                    onClickAdd={this.addProtToCompare}
                    onDelete={this.removeProtFromCompare}
                />
            </Layouts.MainLayoutContent>
        );
    }
}

ProtWiki.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object
};

export default ProtWiki;
