import React from 'react';
import { SvgIcon, withStyles } from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        height: '100%'
    }
};

function BatchFetchIcon({ scale, classes }) {
    let svgScale = scale;
    if (!scale) {
        svgScale = 24;
    }
    return (
        <SvgIcon viewBox={`0 0 ${svgScale} ${svgScale}`} className={scale ? classes.root : undefined}>
            <g transform={`scale(${svgScale/24})`} >
                <g transform="scale(0.04) translate(50,50)">
                    <path d="M234.667,341.333c8.286,0,16.967-0.185,25.853-0.514c3.953-0.146,7.43-2.569,9.147-6.133
                        c22.406-46.509,70.016-78.686,125-78.686c20.563,0,40.563,4.552,59.417,13.531c3.313,1.552,7.167,1.333,10.271-0.615
                        c3.104-1.958,4.979-5.365,4.979-9.021l0.001-57.177c0-8.346-8.952-13.277-16.122-9.007
                        c-66.251,39.452-199.001,40.954-218.546,40.954c-19.544,0-152.293-1.503-218.544-40.954C8.952,189.441,0,194.372,0,202.719
                        v63.948C0,321.448,140.333,341.333,234.667,341.333z"/>
                    <path d="M234.667,213.333c94.333,0,234.667-19.885,234.667-74.667V64c0-43.969-121.646-64-234.667-64S0,20.031,0,64v74.667
                        C0,193.448,140.333,213.333,234.667,213.333z M234.667,21.333c124.229,0,192,28.188,192,42.667s-67.771,42.667-192,42.667
                        s-192-28.188-192-42.667S110.438,21.333,234.667,21.333z"/>
                    <path d="M256,394.667c0-6.751,0.538-13.37,1.517-19.848c0.992-6.563-4.148-12.427-10.783-12.29
                        c-5.521,0.115-9.658,0.138-12.068,0.138c-19.568,0-152.625-1.505-218.792-41.103C8.858,317.366,0,322.542,0,330.719v63.948
                        c0,49.021,118.063,74.667,234.667,74.667c8.583,0,17.021-0.167,25.354-0.438c3.604-0.104,6.896-2.021,8.771-5.083
                        c1.875-3.073,2.083-6.875,0.542-10.125C260.479,434.927,256,415.073,256,394.667z"/>
                    <path d="M394.667,277.333c-64.688,0-117.333,52.635-117.333,117.333S329.979,512,394.667,512S512,459.365,512,394.667
                        S459.354,277.333,394.667,277.333z M444.875,423.542l-42.667,42.667c-2.083,2.083-4.813,3.125-7.542,3.125
                        s-5.458-1.042-7.542-3.125l-42.667-42.667c-4.167-4.167-4.167-10.917,0-15.083c4.167-4.167,10.917-4.167,15.083,0L384,432.917
                        v-102.25c0-5.896,4.771-10.667,10.667-10.667s10.667,4.771,10.667,10.667v102.25l24.458-24.458
                        c4.167-4.167,10.917-4.167,15.083,0C449.042,412.625,449.042,419.375,444.875,423.542z"/>
                </g>
            </g>
        </SvgIcon>
    );
}

export default withStyles(styles)(BatchFetchIcon);