import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Charts } from 'components';
import {
    getInterproDomain, getCddDomain, getPfamDomain,
    getSignalpDomain, getTmhmmDomain, getTransRg4Region
} from 'api/fetch_data';

import CompareDomainTable from './CompareDomainTable';

class CompareDomain extends React.Component {
    constructor(props) {
        super(props);
        const { source, target, sequence } = props;
        const seq2name = sequence ? 'Sequence' : target['name'];
        this.state = {
            seq1name: source['name'],
            seq2name: seq2name,
            sourceFormatData: [],
            targetFormatData: []
        };

        this.MetaInfo = {
            Interpro: {
                fetchFunc: getInterproDomain,
                name: 'InterPro domain',
                group: 'feature',
                record: 'domain_name',
            },
            CDD: {
                fetchFunc: getCddDomain,
                name: 'CDD domain',
                group: undefined,
                record: source.compareType === 'Locus' ? 'cdd__ncbi_cdd_id' : 'CddId'
            },
            Pfam: {
                fetchFunc: getPfamDomain,
                name: 'Pfam domain',
                group: 'PfamType',
                record: 'PfamName'
            },
            SignalP: {
                fetchFunc: getSignalpDomain,
                name: 'SignalP',
                group: undefined,
                record: 'domain_name'
            },
            TMHMM: {
                fetchFunc: getTmhmmDomain,
                name: 'Transmembrane helices region',
                group: undefined,
                record: 'domain_name'
            },
            RG4: {
                fetchFunc: getTransRg4Region,
                name: 'RG4',
                group: undefined,
                record: undefined
            }

        };
    }

    componentDidMount() {
        if ((this.props.type === 'RG4') && (this.props.compareType !== 'Trans')) {
            return undefined;
        }
        if (this.props.sequence) {
            this.fetchSeqData();
        } else {
            this.fetchPairData();
        }
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchPairData = async () => {
        const { compareType, type, source, target } = this.props;
        const metaInfo = this.MetaInfo[type];

        const sourceData = await (metaInfo.fetchFunc)(source.genomeAbbr, source.id, compareType);
        const sourceFormatData = this.formatDomainData(sourceData);

        const targetData = await (metaInfo.fetchFunc)(target.genomeAbbr, target.id, compareType);
        const targetFormatData = this.formatDomainData(targetData);


        this.setState({ sourceFormatData, targetFormatData });
    };

    fetchSeqData = async () => {
        const { compareType, type, source } = this.props;
        const metaInfo = this.MetaInfo[type];
        const sourceData = await (metaInfo.fetchFunc)(source.genomeAbbr, source.id, compareType);
        const sourceFormatData = this.formatDomainData(sourceData);

        this.setState({ sourceFormatData });
    };

    formatDomainData = (domainData) => {
        const metaInfo = this.MetaInfo[this.props.type];
        return domainData.map(
            item => ({
                group: item[metaInfo.group],
                name: item[metaInfo.record],
                start: item.start,
                end: item.end,
                strand: item.strand ? item.strand : '+',
                blocks: item.blocks ? item.blocks : [[item.start, item.end]]
            })
        );
    };

    renderDomainInfo = (CoorInfo, alignSeq, geneName) => {
        return {
            start: CoorInfo.start,
            end: CoorInfo.end,
            strand: CoorInfo.strand ? CoorInfo.strand : '+',
            gene_name: geneName,
            alignSeq
        };
    };

    render() {
        const { align, sequence, sourceCoor, targetCoor, seq1Name, seq2Name } = this.props;
        const { sourceFormatData, targetFormatData } = this.state;

        const sourceInfo = this.renderDomainInfo(sourceCoor, align.align_seq1, seq1Name);
        const targetInfo = this.renderDomainInfo(targetCoor, align.align_seq2, seq2Name);

        if ((sourceFormatData.length === 0) && (targetFormatData.length === 0)) {
            return null;
        }
        return (
            <div>
                <Typography variant="h6" style={{ padding: 16 }}>{this.MetaInfo[this.props.type].name}</Typography>
                <Charts.CompareGeneStructure
                    sourceData={sourceFormatData}
                    targetData={!sequence ? targetFormatData : []}
                    sourceInfo={sourceInfo}
                    targetInfo={targetInfo}
                    heightPerDataGroup={20}
                    width={1000}
                    padding={[10, 50, 30, 150]}
                />
                <CompareDomainTable
                    sourceName={seq1Name}
                    targetName={seq2Name}
                    sourceData={sourceFormatData}
                    targetData={targetFormatData}
                />
            </div>
        );
    }
}

CompareDomain.propTypes = {
    align: PropTypes.any,
    sequence: PropTypes.any,
    source: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    target: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    sourceCoor: PropTypes.any,
    targetCoor: PropTypes.any,
    compareType: PropTypes.oneOf(['Locus', 'Trans', 'Prot', '']),
    type: PropTypes.oneOf(['Interpro', 'CDD', 'Pfam', 'SignalP', 'TMHMM', 'RG4', 'Disorder']),
    seq1Name: PropTypes.string,
    seq2Name: PropTypes.string
};

export default CompareDomain;

