import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import { getSummary, getTransSeq } from 'api/fetch_data';

const { InfoGroup, InfoList, CustomLink, ChipDownloadLink } = Atoms;

class TransSummaryInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = async () => {
        const { id, genomeAbbr, speciesAbbr } = this.props;
        const data = await getSummary(genomeAbbr, id, 'Trans');
        if (!this.__ignoreLastFetch && data) {
            const {
                species_name, taxon_id,
                trans_name, trans_type, ms_support,
                locus_hash_name, locus_name, prot_hash_name, prot_name,
                chrom, start, end, strand, exon_len, tair_symbol
            } = data;
            const dataSource = [
                { title: 'Transcript name', description: trans_name },
                { title: 'TAIR gene symbol', description: tair_symbol },
                { 
                    title: 'Taxonomy', 
                    description: 
                    <CustomLink
                        externalTarget="NCBI"
                        href={`https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=${taxon_id}`}
                    >{`${species_name} (${taxon_id})`}</CustomLink> },
                {
                    title: 'Position', description: <div>
                        {`${chrom}: ${start} - ${end}`}
                        <CustomLink 
                            to={`/jbrowse?species_abbr=${speciesAbbr}&genome_abbr=${genomeAbbr}&chrom=${chrom}&start=${start}&end=${end}`}
                            style={{ marginLeft: '5px' }}
                        >View this transcript</CustomLink>
                        <ChipDownloadLink
                            downloadParams={[genomeAbbr, id]}
                            downloadPromise={getTransSeq}
                            fileName="download.fasta"
                            style={{ marginLeft: '10px' }}
                        >Get Sequence</ChipDownloadLink>
                    </div>
                },
                { title: 'Gene locus', description: <CustomLink to={`/wiki/gene/${this.props.speciesAbbr}/${this.props.genomeAbbr}/${locus_hash_name}`}>{locus_name}</CustomLink> },
                { title: 'Protein', description: <CustomLink to={`/wiki/protein/${this.props.speciesAbbr}/${this.props.genomeAbbr}/${prot_hash_name}`}>{prot_name}</CustomLink> },
                { title: 'Type', description: trans_type },
                { title: 'MS support', description: ms_support.toString() },
            ];
            this.setState({ dataSource });
            this.props.setTrans({ chrom, start, end, strand, exon_len });
        }
    };

    render() {
        const { dataSource } = this.state;
        return (
            <InfoGroup title="Summary" defaultExpanded>
                <InfoList dataSource={dataSource} />
            </InfoGroup>
        );
    }
}

TransSummaryInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    speciesAbbr: PropTypes.string,
    id: PropTypes.string,
    setTrans: PropTypes.func,
};

export default TransSummaryInfo;

