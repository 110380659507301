import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import GeneStructure from './GeneStructureWithCover';

class CompareGeneStructure extends React.Component {
    // 从fasta数据获取gap的位置
    seqToGaps(seq) {
        const gapList = [];
        let gap = [];
        for (let i = 0; i < seq.length; i++) {
            if (seq.charAt(i) === '-' && seq.charAt(i - 1) !== '-') {
                gap[0] = i;
            }
            if (seq.charAt(i) === '-' && seq.charAt(i + 1) !== '-') {
                gap[1] = i;
                gapList.push(gap);
                gap = [];
            }
        }
        return gapList;
    }

    // 转换domain区间至align后的相对位置
    transformDomainRange(gaps, start, domainRange) {
        const newDomain = [domainRange[0] - start, domainRange[1] - start];
        gaps.forEach(gap => {
            if (newDomain[0] >= gap.position) {
                const newPosition = newDomain[0] + gap.gapLength;
                newDomain[0] = newPosition;
            }
            if (newDomain[1] >= gap.position) {
                const newPosition = newDomain[1] + gap.gapLength;
                newDomain[1] = newPosition;
            }
        });
        return newDomain;
    }

    transformGapListToAbsolute(gapList) {
        const absoluteGaps = [];
        let totalLength = 0;
        for (let i = 0; i < gapList.length; i++) {
            absoluteGaps.push({
                position: gapList[i][0] - totalLength,
                gapLength: gapList[i][1] - gapList[i][0] + 1
            });
            totalLength += (gapList[i][1] - gapList[i][0] + 1);
        }
        return absoluteGaps;
    }

    // 从绝对bp定位获取画图的相对位置
    transformPositionToRelative(gaps, start, blockList) {
        const relativeBlockList = [];
        blockList.forEach(block => {
            const relativeBlock = [block[0] - start, block[1] - start];
            gaps.forEach(gap => {
                if (relativeBlock[0] >= gap.position) {
                    const newPosition = relativeBlock[0] + gap.gapLength;
                    relativeBlock[0] = newPosition;
                }
                if (relativeBlock[1] >= gap.position) {
                    const newPosition = relativeBlock[1] + gap.gapLength;
                    relativeBlock[1] = newPosition;
                }
            });
            relativeBlockList.push(relativeBlock);
        });
        return relativeBlockList;
    }

    // 负链坐标转换
    processData(dataList, sequenceEnd) {
        const newData = [];
        dataList.forEach(data => {
            const newBlocks = data.blocks.map(block => ([
                sequenceEnd - block[0],
                sequenceEnd - block[1]
            ]));
            newData.push({
                group: data.group,
                name: data.name,
                start: sequenceEnd - data.start,
                end: sequenceEnd - data.end,
                strand: '-',
                blocks: newBlocks
            });
        });
        return newData;
    }

    render() {
        // const { sourcePlotData, targetPlotData, relativeEnd, sourceGaps, targetGaps } = this.state;
        let { sourceData, targetData } = this.props;
        const { sourceInfo, targetInfo, heightPerDataGroup, width, padding } = this.props;
        const relativeEnd = sourceInfo.alignSeq.length;
        if(sourceInfo.strand === '-') {
            sourceData = this.processData(sourceData, sourceInfo.end);
            const newStart = sourceInfo.end - sourceInfo.start;
            const newEnd = 0;
            sourceInfo.start = newEnd;
            sourceInfo.end = newStart;
        }
        if(targetInfo.strand === '-') {
            targetData = this.processData(targetData, targetInfo.end);
            const newStart = sourceInfo.end - sourceInfo.start;
            const newEnd = 0;
            targetInfo.start = newEnd;
            targetInfo.end = newStart;
        }



        const sourceGaps = this.seqToGaps(sourceInfo.alignSeq);
        const targetGaps = this.seqToGaps(targetInfo.alignSeq);

        const sourceAbsoluteGaps = this.transformGapListToAbsolute(sourceGaps);
        const targetAbsoluteGaps = this.transformGapListToAbsolute(targetGaps);

        const sourcePlotData = sourceData.map(domain => {
            const sourceRange = this.transformDomainRange(sourceAbsoluteGaps, sourceInfo.start, [domain.start, domain.end]);
            return {
                group: domain.group,
                name: domain.name,
                start: sourceRange[0],
                end: sourceRange[1],
                strand: domain.strand,
                blocks: this.transformPositionToRelative(sourceAbsoluteGaps, sourceInfo.start, domain.blocks)
            };
        });

        const targetPlotData = targetData.map(domain => {
            const targetRange = this.transformDomainRange(targetAbsoluteGaps, targetInfo.start, [domain.start, domain.end]);
            return {
                group: domain.group,
                name: domain.name,
                start: targetRange[0],
                end: targetRange[1],
                strand: domain.strand,
                blocks: this.transformPositionToRelative(targetAbsoluteGaps, targetInfo.start, domain.blocks)
            };
        });

        const emptyData = { group: 'empty', name: 'empty', start: 0, end: 0, blocks: [[0, 0]] };
        sourcePlotData.push(emptyData);
        sourcePlotData.unshift(emptyData);
        targetPlotData.push(emptyData);
        targetPlotData.unshift(emptyData);

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h6" style={{ paddingLeft: padding[3] }}>{sourceInfo.gene_name}</Typography>
                <div>
                    <GeneStructure
                        bpStart={0}
                        bpEnd={relativeEnd}
                        heightPerDataGroup={heightPerDataGroup}
                        width={width}
                        padding={padding}
                        dataSource={sourcePlotData}
                        coverRegion={sourceGaps}
                    />
                </div>
                <Typography variant="h6" style={{ paddingLeft: padding[3] }}>{targetInfo.gene_name}</Typography>
                <div>
                    <GeneStructure
                        bpStart={0}
                        bpEnd={relativeEnd}
                        heightPerDataGroup={heightPerDataGroup}
                        width={width}
                        padding={padding}
                        dataSource={targetPlotData}
                        coverRegion={targetGaps}
                        withBpLabel
                    />
                </div>
            </div>
        );
    }
}


CompareGeneStructure.propTypes = {
    sourceData: PropTypes.arrayOf(PropTypes.shape({
        group: PropTypes.string,
        name: PropTypes.string,
        start: PropTypes.number,
        end: PropTypes.number,
        strand: PropTypes.oneOf(['-', '+']),
        blocks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    })),
    targetData: PropTypes.arrayOf(PropTypes.shape({
        group: PropTypes.string,
        name: PropTypes.string,
        start: PropTypes.number,
        end: PropTypes.number,
        strand: PropTypes.oneOf(['-', '+']),
        blocks: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
    })),
    sourceInfo: PropTypes.shape({
        gene_name: PropTypes.string,
        chromosome: PropTypes.string,
        start: PropTypes.number,
        end: PropTypes.number,
        strand: PropTypes.oneOf(['-', '+']),
        alignSeq: PropTypes.string
    }),
    targetInfo: PropTypes.shape({
        gene_name: PropTypes.string,
        chromosome: PropTypes.string,
        start: PropTypes.number,
        end: PropTypes.number,
        strand: PropTypes.oneOf(['-', '+']),
        alignSeq: PropTypes.string
    }),
    heightPerDataGroup: PropTypes.number,
    width: PropTypes.number,
    padding: PropTypes.arrayOf(PropTypes.number)
};

export default CompareGeneStructure;