import React from 'react';
import { makeStyles, Typography, Container, Link } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {
        background: '-webkit-gradient(linear, left top, left bottom, from(#161616), color-stop(75%, rgba(22, 22, 22, 0.9)), to(rgba(22, 22, 22, 0.6)))',
        fallbacks: {
            background: 'linear-gradient(to bottom, #161616 0%, rgba(22, 22, 22, 0.9) 75%, rgba(22, 22, 22, 0.8) 100%)'
        },
        paddingTop: '160px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    introductionContainer: {
        textAlign: 'center !important',
        color: 'rgba(255,255,255,.65)'
    },
    title: {
        color: 'rgba(255,255,255,.95)',
        marginBottom: '2.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2.5rem'
        }
    },
    mainText: {
        textIndent: '2rem',
        marginBottom: '1.5rem',
        textAlign: 'justify'
    },
    addition: {
        background: 'url("/cotton-gene.png")',
        marginLeft: 'auto',
        marginRight: 0,
        width: '60%',
        height: 'auto',
        minHeight: '25rem',
        backgroundPosition: 'right top',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '60%',
        [theme.breakpoints.down('md')]: {
            width: '50%',
            marginRight: 'auto',
            backgroundPosition: 'center top',
            backgroundSize: '75%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '70%',
            backgroundSize: '70%',
            minHeight: '20rem'
        },
        [theme.breakpoints.down('xs')]: {
            backgroundSize: '100%'
        }
    }
}));

export default function IntroductionSection() {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container maxWidth="lg" className={classes.introductionContainer}>
                <Typography variant="h2" className={classes.title}>Introduction</Typography>
                <Typography variant="body1" className={classes.mainText}>
                        Malvaceae is a family of flowering plants containing many economically important
                    plant species including cotton, cacao, and durian. Recently, the genomes of several
                    Malvaceae species have been decoded, and many omics data were generated for
                    individual species. However, no integrative database of multiple species, enabling
                    users to jointly compare and analyse relevant data, is available for Malvaceae. Thus,
                    we developed a user-friendly database named 
                    <Link component={RouterLink} to="/"> MaGenDB </Link>
                    as a functional genomics hub for the plant community. We collected all available
                    genomes of Malvaceae, and comprehensively annotated genes from different
                    perspectives including functional RNA/protein element, gene ontology, KEGG
                    orthology, and gene family. We processed more than 300 sets of diverse omics data
                    with standard pipelines and integrated them into a customised genome browser, and
                    designed multiple dynamic charts to present gene/RNA/protein-level knowledge such
                    as dynamic expression profiles and functional elements. We also implemented a
                    smart search system for efficiently mining genes. In addition, we constructed a
                    functional comparison system to help comparative analysis between genes on
                    multiple features in one species or across closely related species. This database and
                    associated tools will allow users to quickly retrieve large-scale functional information
                    for biological discovery.
                </Typography>
                <div className={classes.addition}/>
            </Container>
        </div>
    );
}