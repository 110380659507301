import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Typography, Button } from '@material-ui/core';
import { whiteColor, hexToRgb } from 'assets/global';


const useStyles = makeStyles(theme => ({
    '@font-face': {
        fontFamily: 'Varela Round',
        fontStyle: 'normal',
        fontWeight: 400,
        src: 'url(/Varela_Round.woff2) format("woff2")',
        unicodeRange: 'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD'
    },
    root: {
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        background: '-webkit-gradient(linear, left top, left bottom, from(rgba(22, 22, 22, 0.3)), color-stop(75%, rgba(22, 22, 22, 0.7)), to(#161616)), url("/magendb-background.png")',
        fallbacks: {
            background: 'linear-gradient(to bottom, rgba(22, 22, 22, 0.3) 0%, rgba(22, 22, 22, 0.7) 75%, #161616 100%), url("/cotton_bg.jpg")',
            height: 'auto',
        },
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'scroll',
        backgroundSize: 'cover',
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: '65%',
        },
        [theme.breakpoints.down('xs')]: {
            backgroundPosition: '75%',
        },
    },
    logo: {
        width: '400px',
        height: '400px',
        [theme.breakpoints.down('md')]: {
            width: '350px',
            height: '350px'
        },
        [theme.breakpoints.down('sm')]: {
            width: '300px',
            height: '300px',
        },
        [theme.breakpoints.down('xs')]: {
            display: 'none'
        },
        // for mobile rotate:
        '@media (max-height: 500px)': {
            width: '120px !important',
            height: '120px !important'
        }
    },
    titleContainer: {
        fontFamily: '"Varela Round", "Helvetica Neue",Helvetica,Arial,"Microsoft Yahei","Hiragino Sans GB","Heiti SC","WenQuanYi Micro Hei",sans-serif',
        textAlign: 'center !important',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    title: {
        fontFamily: 'Varela Round',
        background: `-webkit-linear-gradient(rgba(${hexToRgb(whiteColor)}, .9), rgba(${hexToRgb(whiteColor)}, 0))`,
        backgroundClip: 'text',
        marginBottom: '1rem',
        '-webkit-text-fill-color': 'transparent',
        '-webkit-background-clip': 'text',
        fontSize: '5rem',
        lineHeight: '5rem',
        letterSpacing: '0.5rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '4rem',
            lineHeight: '4rem',
            letterSpacing: '0.4rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5rem',
            lineHeight: '2.5rem',
            letterSpacing: '0.25rem'
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.5rem',
            lineHeight: '1.5rem',
            letterSpacing: '0.15rem'
        }
    },
    description: {
        color: `rgba(${hexToRgb(whiteColor)}, 0.5)`,
        marginBottom: '3rem',
        fontSize: '2rem',
        lineHeight: '2rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '1.5rem',
            lineHeight: '1.5rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            lineHeight: '1rem',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: '0.9rem',
            lineHeight: '0.9rem',
        }
    },
    startButton: {
        color: whiteColor,
    },
}));


export default function WelcomeSection({ scrollTo }) {
    const classes = useStyles();
    const handleClickStart = event => {
        const anchor = (event.target.ownerDocument || document).querySelector('#start-anchor');
        if (anchor) {
            const anchorTop = anchor.offsetTop;
            scrollTo(anchorTop);
        }
    };

    return (
        <div className={classes.root}>
            <img src="/logo-transparent.svg" alt="transparent-logo" className={classes.logo} />
            <div className={classes.titleContainer}>
                <Typography variant="h1" className={classes.title} noWrap>Welcome to MaGenDB!</Typography>
                <Typography variant="h5" className={classes.description}>A user-friendly omics database of Malvaceae species.</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.startButton}
                    onClick={handleClickStart}
                >Get Started &#x00BB;</Button>
            </div>
        </div>
    );
}

WelcomeSection.propTypes = {
    scrollTo: PropTypes.func
};