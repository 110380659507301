import React from 'react';
import { SvgIcon, withStyles } from '@material-ui/core';

const styles = {
    root: {
        width: '100%',
        height: '100%'
    }
};

function GeneMapIcon({ scale, classes }) {
    let svgScale = scale;
    if (!scale) {
        svgScale = 24;
    }
    return (
        <SvgIcon viewBox={`0 0 ${svgScale} ${svgScale}`} className={scale ? classes.root : undefined}>
            <g transform={`scale(${svgScale / 24})`} >
                <g transform="scale(0.04)">
                    <path d="m140 537.851562c-.445312 21.125 15.054688 39.210938 36 41.996094 11.257813 1.132813 22.46875-2.550781 30.851563-10.152344 8.382813-7.589843 13.164063-18.382812 13.148437-29.695312v-30h-80zm0 0" />
                    <path d="m140 360h80v190h-80zm0 0" />
                    <path d="m0 360h80v190h-80zm0 0" />
                    <path d="m36 579.808594c11.246094 1.132812 22.453126-2.550782 30.835938-10.136719s13.164062-18.363281 13.164062-29.671875v-30h-80v27.851562c-.4257815 21.109376 15.0703125 39.171876 35.9999995 41.957032zm0 0" />
                    <path d="m44 20.191406c-11.25-1.132812-22.453124 2.550782-30.835937 10.136719-8.386719 7.585937-13.16796875 18.363281-13.1640625 29.671875h79.8007815c-.53125-20.265625-15.707032-37.140625-35.800782-39.808594zm0 0" />
                    <path d="m140 60h80v80h-80zm0 0" />
                    <path d="m184 20.191406c-11.25-1.132812-22.453124 2.550782-30.835937 10.136719-8.386719 7.585937-13.167969 18.363281-13.164063 29.671875h79.800782c-.53125-20.265625-15.707032-37.140625-35.800782-39.808594zm0 0" />
                    <path d="m0 60h80v80h-80zm0 0" />
                    <path d="m80 310c0-16.566406 13.429688-30 30-30 16.566407 0 30 13.433594 30 30v50h80v-10c-.085937-42.738281-30.144531-79.550781-72-88.1875-4.660156-.953125-8-5.050781-8-9.800781v-4.003907c0-4.75 3.339844-8.847656 8-9.800781 41.863282-8.636719 71.917969-45.464843 72-88.207031v-30h-80v80c0 16.566406-13.433593 30-30 30-16.570312 0-30-13.433594-30-30v-80h-80v30c.0898437 42.738281 30.1406255 79.550781 71.9960935 88.1875 4.660156.953125 8.003906 5.050781 8.003906 9.800781v4.003907c0 4.75-3.34375 8.847656-8.003906 9.800781-41.859375 8.636719-71.9179685 45.464843-71.9960935 88.207031v10h80zm0 0" />
                    <path d="m0 420h80v70h-80zm0 0" />
                    <path d="m320 590c0 5.523438 4.476563 10 10 10h100v-20h-90v-560h90v-20h-100c-5.523437 0-10 4.476562-10 10v370h-60v20h60zm0 0" />
                    <path d="m560 70h-160v-10h-20v17.140625c.074219 39.363281 20.976563 75.75 54.9375 95.648437l25.210938 14.710938-21.148438 12.320312c-36.46875 20.132813-59.082031 58.523438-59 100.179688-.152343 44.375 23.675782 85.371094 62.308594 107.210938l16.691406 9-21 11.375c-35.761718 19.347656-58.035156 56.753906-58 97.414062v15h20v-10h160v10h20v-15c.019532-40.648438-22.246093-78.03125-58.003906-97.367188l-20.996094-11.382812 16.691407-9c38.636719-21.84375 62.464843-62.859375 62.308593-107.25.078126-41.65625-22.535156-80.046875-59-100.179688l-21.152343-12.320312 25.210937-14.710938c33.964844-19.898437 54.863282-56.285156 54.941406-95.648437v-17.140625h-20zm-12 410c5.296876 9.292969 8.90625 19.449219 10.648438 30h-157.261719c1.734375-10.546875 5.328125-20.707031 10.609375-30zm-35.480468-34.789062c7.324218 3.988281 14.0625 8.964843 20.03125 14.789062h-105.105469c5.984375-5.824219 12.734375-10.804688 20.074219-14.789062l32.480468-17.589844zm-4.3125-55.59375-28.207032 15.261718-28.171874-15.261718c-13.027344-7.214844-24.207032-17.351563-32.65625-29.617188h121.660156c-8.457032 12.261719-19.636719 22.402344-32.664063 29.617188zm43.789062-49.617188h-143.996094c-3.902343-9.585938-6.367187-19.691406-7.320312-30h158.636719c-.945313 10.308594-3.414063 20.414062-7.320313 30zm-.996094-80c4.484376 9.449219 7.316407 19.59375 8.378907 30h-158.730469c1.066406-10.40625 3.898438-20.550781 8.378906-30zm-40.070312-42.898438c10.320312 6.003907 19.523438 13.753907 27.199219 22.898438h-116.257813c7.691406-9.148438 16.902344-16.894531 27.234375-22.898438l30.894531-18.019531zm4.089844-61.585937-35.019532 20.402344-35-20.402344c-7.210937-4.234375-13.792968-9.460937-19.558593-15.515625h109.121093c-5.773437 6.054688-12.355468 11.28125-19.5625 15.515625zm33.980468-35.515625h-138c-5.066406-9.335938-8.449218-19.492188-10-30h158c-1.554687 10.507812-4.9375 20.664062-10 30zm0 0" />
                </g>
            </g>
        </SvgIcon>
    );
}

export default withStyles(styles)(GeneMapIcon);