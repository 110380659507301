import React from 'react';
import { Chart, Geom, Tooltip, Coord, View, Label, Axis } from 'bizcharts';
import PropTypes from 'prop-types';

import Empty from 'components/atoms/Empty';
import { GenomeCirclePlot } from 'tools/CirclePlot';
import { chartColors as colors } from 'assets/global';

import { flatten, uniqBy } from 'lodash';

class CircleDiagram extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filtered: false,
            selectedChrom: {}
        };
        this.scale = {
            x: { min: 0, max: 1, sync: true },
            y: { sync: true }
        };
    }

    setFilter(name, group) {
        const { filtered, selectedChrom } = this.state;
        if (!filtered)
            this.setState({ filtered: true, selectedChrom: { name, group } });
        else if (name === selectedChrom.name && group === selectedChrom.group)
            this.setState({ filtered: false, selectedChrom: {} });
    }

    render() {
        const { queryGenomeInfo, targetGenomeInfo, syntenyData } = this.props;
        const { filtered, selectedChrom } = this.state;
        if (queryGenomeInfo.length === 0 || targetGenomeInfo.length === 0) {
            return null;
        }

        const CirclePlot = new GenomeCirclePlot(queryGenomeInfo, targetGenomeInfo, syntenyData, 0.2);
        const polygonData = CirclePlot.getChromosomes().map(range => ({
            x: [range.start, range.end, range.end, range.start],
            y: [0, 0, -0.02, -0.02],
            name: range.name,
            group: range.group,
            bpCount: range.bpCount
        }));

        const edgeData = CirclePlot.getSyntenyLinks().map((link, index) => ({
            index,
            x: [link.source.start, link.source.end, link.target.start, link.target.end],
            y: [0, 0, 0, 0],
            source: link.source.name,
            target: link.target.name,
            score: link.score
        }));

        let displayPolygonData = [], displayEdgeData = [], displayChrom = [], filteredSynteny = [];
        let drawBinaryPlot = false;

        if (filtered) {
            if (selectedChrom.group === 'source') {
                filteredSynteny = syntenyData.filter(record => record.query_chrom === selectedChrom.name);
            } else {
                filteredSynteny = syntenyData.filter(record => record.target_chrom === selectedChrom.name);
            }
            displayChrom = uniqBy(flatten(filteredSynteny.map(record => [
                { group: 'source', name: record.query_chrom },
                { group: 'target', name: record.target_chrom }
            ])), item => item.group + item.name);
            if (displayChrom.length === 0) {
                this.setFilter(selectedChrom.name, selectedChrom.group);
            } else if (displayChrom.length === 2) {
                drawBinaryPlot = true;
            } else if (displayChrom.length > 2) {
                const sourceNameList = displayChrom.filter(chrom => chrom.group === 'source').map(chrom => chrom.name);
                const targetNameList = displayChrom.filter(chrom => chrom.group === 'target').map(chrom => chrom.name);
                const filteredQuery = queryGenomeInfo.filter(record => sourceNameList.indexOf(record.chrom) !== -1);
                const filteredTarget = targetGenomeInfo.filter(record => targetNameList.indexOf(record.chrom) !== -1);
                const filteredCirclePlot = new GenomeCirclePlot(filteredQuery, filteredTarget, filteredSynteny, 0.2);
                displayPolygonData = filteredCirclePlot.getChromosomes().map(range => ({
                    x: [range.start, range.end, range.end, range.start],
                    y: [0, 0, -0.02, -0.02],
                    name: range.name,
                    group: range.group,
                    bpCount: range.bpCount
                }));
                displayEdgeData = filteredCirclePlot.getSyntenyLinks().map((link, index) => ({
                    index,
                    x: [link.source.start, link.source.end, link.target.start, link.target.end],
                    y: [0, 0, 0, 0],
                    source: link.source.name,
                    target: link.target.name,
                    score: link.score
                }));
            }
        } else {
            displayPolygonData = polygonData;
            displayEdgeData = edgeData;
        }

        if (drawBinaryPlot) {
            const lineData = [];
            syntenyData.forEach((record, index) => {
                const { query_chrom, query_start, query_end, target_chrom, target_start, target_end, score } = record;
                if (selectedChrom.group === 'source' && query_chrom === selectedChrom.name) {
                    lineData.push({
                        index, score, source: query_chrom, target: target_chrom,
                        x: [query_start, query_end],
                        y: [target_start, target_end]
                    });
                } else if (selectedChrom.group === 'target' && target_chrom === selectedChrom.name) {
                    lineData.push({
                        index, score, source: query_chrom, target: target_chrom,
                        x: [query_start, query_end],
                        y: [target_start, target_end]
                    });
                }
            });
            const sourceChromName = displayChrom.find(chrom => chrom.group === 'source').name;
            const targetChromName = displayChrom.find(chrom => chrom.group === 'target').name;
            const sourceChrom = queryGenomeInfo.find(chrom => chrom.chrom === sourceChromName);
            const targetChrom = targetGenomeInfo.find(chrom => chrom.chrom === targetChromName);
            const chartScale = {
                x: {
                    min: 0,
                    max: sourceChrom.size,
                    nice: false,
                    alias: sourceChrom.chrom
                },
                y: {
                    min: 0,
                    max: targetChrom.size,
                    nice: false,
                    alias: targetChrom.chrom
                }
            };
            return (
                <Chart
                    data={lineData}
                    height={1000}
                    forceFit
                    scale={chartScale}
                    padding="auto"
                    onPlotClick={(ev) => {
                        console.log(selectedChrom);
                        this.setFilter(selectedChrom.name, selectedChrom.group);
                    }}
                >
                    <Tooltip />
                    <Coord />
                    <Axis
                        name="x"
                        grid={{
                            align: 'center',
                            type: 'line',
                            lineStyle: { stroke: '#d9d9d9', lineWidth: 1, lineDash: [4, 4] }
                        }}
                        title={{
                            textStyle: { textAlign: 'center', fontSize: 18, fontWeight: 'bold', fill: '#999' }
                        }}
                    />
                    <Axis
                        name="y"
                        grid={{
                            align: 'center',
                            type: 'line',
                            lineStyle: { stroke: '#d9d9d9', lineWidth: 1, lineDash: [4, 4] }
                        }}
                        title={{
                            textStyle: { textAlign: 'center', fontSize: 18, fontWeight: 'bold', fill: '#999' }
                        }}
                    />
                    <Geom
                        type="edge"
                        position="x*y"
                        color={['index', colors]}
                        opacity={0.5}
                        style={{ lineWidth: 5 }}
                        tooltip={['x*y*score*source*target', (x, y, score, source, target) => ({
                            title: `${source}:${x[0]}-${x[1]}bp > ${target}:${y[0]}-${y[1]}bp`,
                            name: 'Score',
                            value: score
                        })]}
                    >
                    </Geom>
                </Chart>
            );
        }

        return (
            <Chart
                height={1000}
                forceFit
                padding={[100, 100, 100, 100]}
                scale={this.scale}
                onPolygonClick={(ev) => {
                    const { name, group } = ev.data._origin;
                    this.setFilter(name, group);
                }}
            >
                <Tooltip />
                <View data={displayEdgeData} scale={this.scale}>
                    <Coord type="polar" reflect="y" />
                    <Geom
                        type="edge"
                        position="x*y"
                        shape="arc"
                        color={['index', colors]}
                        opacity={0.5}
                        tooltip={['source*target*score', (source, target, score) => ({
                            title: `${source} > ${target}`,
                            name: 'Score',
                            value: score
                        })]}
                    >
                    </Geom>
                </View>
                <View data={displayPolygonData} scale={this.scale}>
                    <Coord type="polar" reflect="y" />
                    <Geom
                        type="polygon"
                        position="x*y"
                        color={['group', colors]}
                        tooltip={['group*name*bpCount', (group, name, bpCount) => ({
                            title: group,
                            name: name,
                            value: `${bpCount}bp`
                        })]}>
                        <Label
                            content="name"
                            labelEmit={true}
                            textStyle={{ fill: '#000' }}
                        />
                    </Geom>
                </View>
            </Chart>
        );
    }
}

CircleDiagram.propTypes = {
    queryGenomeInfo: PropTypes.array
};

export default CircleDiagram;