import React from 'react';
import { Paper, Divider, IconButton, FormControl, withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import SearchIcon from '@material-ui/icons/Search';


const styles = theme => ({
    root: {
        padding: theme.spacing(1),
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: theme.spacing(4),
        flex: 1,
    },
    divider: {
        width: 1,
        height: 28,
        margin: 4,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
});

function SearchForm({ classes, children, handleSearch, inputComponent: Component, inputProps, inputValue, handleInputChange, disabled }) {
    return (
        <Paper className={classes.root}>
            <Component
                {...inputProps}
                className={classes.input}
            />
            <IconButton 
                className={classes.iconButton} 
                aria-label="Search"
                onClick={handleSearch}
                disabled={disabled}
            >
                <SearchIcon />
            </IconButton>
            {React.Children.map(children, (child, index) => {
                return (
                    <>
                        <Divider className={classes.divider} />
                        <FormControl key={index} variant="outlined" className={classes.formControl}>
                            {child}
                        </FormControl>
                    </>
                );
            })}
        </Paper>
    );

}

SearchForm.propTypes = {
    classes: PropTypes.object,
    children: PropTypes.any,
    handleSearch: PropTypes.func,
    inputValue: PropTypes.string,
    handleInputChange: PropTypes.func,
    disabled: PropTypes.bool,
};


export default withStyles(styles)(SearchForm);