import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, Typography, colors } from '@material-ui/core';
import { Link } from 'react-router-dom';


const styles = theme => ({
    root: {
        width: '160px',
        height: '240px',
        borderRadius: '5px',
        boxShadow: '0 0 5px #000000 inset',
        backgroundColor: colors.grey[800],
        padding: '1px'
    },
    link: {
        color: colors.grey[800],
        textDecoration: 'none'
    },
    svgContainer: {
        backgroundColor: colors.grey[100],
        zIndex: 0,
        width: '100px',
        height: '100px',
        margin: '25px 30px',
        borderRadius: '50px',
        boxShadow: '0 0 10px #000000',
        '&:hover': {
            boxShadow: '0 0 10px #fff',
        },
        '&:active': {
            backgroundColor: colors.grey[400]
        }
    },
    divider: {
        height: '6px',
        margin: '1px',
        borderRadius: '2px',
    },
    description: {
        margin: '20px 10px',
        textAlign: 'center',
        fontSize: '14px',
        color: '#fff',

    }
});

function LinkCard({ classes, icon: Icon, color, description, link }) {
    return (
        <div className={classes.root}>
            <Link to={link} className={classes.link}>
                <div className={classes.svgContainer}>
                    <Icon scale={100} />
                </div>
            </Link>
            <div
                style={{
                    backgroundColor: color[500],
                    boxShadow: `0 0 3px ${color[300]}`
                }}
                className={classes.divider} />
            <Typography
                className={classes.description}
                style={{
                    textShadow: `0 0 0.2em ${color[50]}`,
                }}
            >
                {description}
            </Typography>
        </div>
    );
}

LinkCard.propTypes = {
    classes: PropTypes.object,
    icon: PropTypes.node.isRequired,
    link: PropTypes.string,
    color: PropTypes.object,
    description: PropTypes.string
};

export default withStyles(styles)(LinkCard);