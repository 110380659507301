import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Atoms, Charts } from 'components';
import { getColinerInfo, getColinerGroupInfo, getGeneExprList } from 'api/fetch_data';
import { flatten, uniq, invert } from 'lodash';

class MagenColinearInfo extends React.Component {
    state = {
        data: [],

        // draw heatmap
        expressionLevel: [],
        geneInfo: [],
        sampleInfo: [],

        // draw sankey
        selectedKey: undefined,
        colinerData: [],
        this_chrom: '',
        paired_chrom: '',
        with_rev: false,
    };

    columns = [
        { key: 'paired_species', content: 'Synteny species' },
        { key: 'paired_genome', content: 'Synteny genome' },
        { key: 'paired_chrom', content: 'Synteny chrom' },
        { key: 'paired_locus', content: 'Synteny gene' },
        { key: 'score', content: 'Score' },
    ];


    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    changeSelectedKeys = (id) => {
        this.setState({
            selectedKey: id,
            this_chrom: this.state.data[id].this_chrom,
            paired_chrom: this.state.data[id].paired_chrom,
            with_rev: this.state.data[id].with_rev,
        }, () => this.drawSankey());
    };

    fetchData = async () => {
        const { genomeAbbr, recordType, id } = this.props;
        const colinerData = await getColinerInfo(genomeAbbr, id, recordType);
        const locusIdList = colinerData.map(item => [item.paired_genome_abbr, item.paired_locus_id]);

        const expressionList = await getGeneExprList(locusIdList);

        const sampleArray = uniq(flatten(expressionList.map(expressionInfo => expressionInfo.summary)).map(record => record.sample)).sort();
        const sampleInvertDict = invert(sampleArray);
        const sampleInfo = sampleArray.map(sampleName => ({ name: sampleName }));

        const geneInfo = [];
        const expressionLevel = [];
        expressionList.forEach((expressionInfo, geneIndex) => {
            const { locus_name: name, genome_abbr: genome } = expressionInfo.gene_info;
            geneInfo.push({ name, genome });
            expressionInfo.summary.forEach(record => {
                const { sample, mu, sd, file_num } = record;

                expressionLevel.push({
                    col: geneIndex,
                    row: Number(sampleInvertDict[sample]),
                    'FPKM': mu,
                    'SD': sd,
                    'N': file_num
                });
            });
        });

        if (!this.__ignoreLastFetch) {
            this.setState({
                geneInfo, sampleInfo, expressionLevel,
                data: colinerData,
            });
        }
    };


    drawSankey = async() => {
        const { this_assemble_abbr, paired_assemble_abbr, coliner_group_id, first_pos } = this.state.data[this.state.selectedKey];
        const data = await getColinerGroupInfo(this_assemble_abbr, paired_assemble_abbr, coliner_group_id, first_pos);
        this.setState({ colinerData: data });
    };

    is_with_rev = (colinear_data_li) => {
        const first_data = colinear_data_li[0];
        const last_data = colinear_data_li[colinear_data_li.length - 1];
        const first_this_sidx = first_data['this_assemble']['start'];
        const first_paired_sidx = first_data['paired_assemble']['start'];
        const last_this_sidx = last_data['this_assemble']['start'];
        const last_paired_sidx = last_data['paired_assemble']['start'];
        return (first_this_sidx > last_this_sidx) ^ (first_paired_sidx > last_paired_sidx);
    };

    render() {
        const { id } = this.props;
        const { data, expressionLevel, geneInfo, sampleInfo, colinerData, selectedKey, this_chrom, paired_chrom, with_rev } = this.state;
        const dataSource = data.map((item, index) => {
            const {
                paired_species_abbr, paired_species_name, paired_genome_abbr,
                score, paired_locus_id, paired_locus_name, this_chrom, paired_chrom
            } = item;
            return {
                id: index,
                alias: paired_locus_name,
                withRadio: true,
                paired_species: paired_species_name,
                paired_genome: paired_genome_abbr,
                paired_locus: <Link
                    to={`/wiki/gene/${paired_species_abbr}/${paired_genome_abbr}/${paired_locus_id}`}>{paired_locus_name}</Link>,
                score, this_chrom, paired_chrom, with_rev
            };
        });
        if (dataSource.length === 0) {
            return <div>No co-linear gene found.</div>;
        }
        return (
            <div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    {colinerData.length > 0 && <div>
                        <h3>Gene synteny cluster</h3>
                        <Charts.ColinearGeneDiagram
                            highLightDataList={[id, data[selectedKey].paired_locus_id]}
                            pairList={colinerData}
                            chromosome={this_chrom}
                            pairedChromosome={paired_chrom}
                            withReverse={this.is_with_rev(colinerData)}
                        />
                    </div>}
                </div>
                <div>
                    {geneInfo.length > 0 && <div>
                        <h3>Gene expression matrix for synteny genes</h3>
                        <Charts.GeneTissueHeatMap
                            valueIndex="FPKM"
                            dataSource={expressionLevel}
                            yAxis={{
                                title: 'Gene',
                                ticks: geneInfo
                            }}
                            xAxis={{
                                title: 'tissue',
                                ticks: sampleInfo
                            }}
                        />
                    </div>}
                </div>
                <Atoms.RadioTable
                    selectedKey={selectedKey}
                    changeSelectedKey={this.changeSelectedKeys}
                    headerText="Select a gene"
                    columns={this.columns}
                    dataSource={dataSource}
                    onOptionButtonClick={this.drawSankey}
                />
            </div>
        );
    }
}


MagenColinearInfo.propTypes = {
    genomeAbbr: PropTypes.string,
    id: PropTypes.string,
    recordType: PropTypes.string,
};

export default MagenColinearInfo;

