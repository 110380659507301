import React from 'react';
import PropTypes from 'prop-types';
import { Layouts, Atoms } from 'components';
import { getSpeciesDesc, getGenomeDesc } from 'api/fetch_data';


class Genome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            speciesName: '',
            speciesData: [],
            speciesDesc: {},
            genomeData: [],
        };
        this.genomeHeader = [
            { key: 'abbr_name', content: 'Name' },
            { key: 'reference', content: 'Source' },
            { key: 'publish_time', content: 'Pub. Date' },
            { key: 'contig_N50', content: 'Contig N50' },
            { key: 'scaffold_N50', content: 'Scaffold N50' },
        ];
    }


    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        const { speciesAbbr } = this.props.match.params;
        const speciesInfo = await getSpeciesDesc(speciesAbbr);
        const genomeInfo = await getGenomeDesc(speciesAbbr);
        const speciesData = [
            { title: 'Species', description: speciesInfo.name },
            { title: 'Genus', description: speciesInfo.genus },
            { title: 'Subfamily', description: speciesInfo.subfamily },
            { title: 'Family', description: speciesInfo.family },
            {
                title: 'Taxonomy ID',
                description: <Atoms.CustomLink
                    href={`https://www.ncbi.nlm.nih.gov/Taxonomy/Browser/wwwtax.cgi?id=${speciesInfo.taxon_id}`}
                >{speciesInfo.taxon_id}</Atoms.CustomLink>
            },
            { title: 'Scientific Name', description: speciesInfo.scientific_name },
            { title: 'Common Name', description: speciesInfo.common_name },
        ];
        const speciesDesc = { 'Desc': speciesInfo.desc, 'Source': speciesInfo.desc_source };

        const genomeData = genomeInfo.map(item => ({
            abbr_name: item.abbr_name,
            reference: <a href={item.ref_url}>{item.reference}</a>,
            publish_time: item.publish_time,
            contig_N50: item.contig_N50,
            scaffold_N50: item.scaffold_N50
        }));
        this.setState({ speciesData, speciesDesc, genomeData, speciesName: speciesInfo.name });
    };

    render() {
        const { speciesData, speciesDesc, genomeData, speciesName } = this.state;
        return (
            <Layouts.MainLayoutContent headerText={speciesName}>
                <Atoms.InfoGroup title="Overview" defaultExpanded>
                    <Atoms.InfoList dataSource={speciesData} />
                </Atoms.InfoGroup>
                <Atoms.InfoGroup title="Description" defaultExpanded>
                    <div style={{ whiteSpace: 'pre-wrap' }}>{speciesDesc.Desc}</div>
                </Atoms.InfoGroup>
                <Atoms.InfoGroup title="Genomes" defaultExpanded>
                    <Atoms.CustomTable columns={this.genomeHeader} dataSource={genomeData} />
                </Atoms.InfoGroup>
            </Layouts.MainLayoutContent>
        );
    }
}

Genome.propTypes = {
    match: PropTypes.object
};

export default Genome;
