import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { MenuItem } from '@material-ui/core';

function Suggestion(suggestion, { query, isHighlighted }) {
    const matches = match(suggestion, query);
    const parts = parse(suggestion, matches);

    return (
        <MenuItem selected={isHighlighted} component="div">
            <div>
                {parts.map((part, index) => (
                    <span key={index} style={{ fontWeight: part.highlight ? 'bolder' : 'normal' }}>
                        {part.text}
                    </span>
                ))}
            </div>
        </MenuItem>
    );
}

export default Suggestion;