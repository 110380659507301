import React from 'react';
import PropTypes from 'prop-types';
import { Atoms } from 'components';
import { getEcInfo } from 'api/fetch_data';

class CompareEcTable extends React.Component {
    constructor(props) {
        super(props);
        const { source, target } = props;
        this.state = {
            tableData: [],
        };
        this.header = [
            { key: 'url', content: 'EC' },
            { key: 'owner', content: 'Presence in' },
            { key: 'name', content: 'Name' },
            { key: 'alias', content: 'Alias' },
            { key: 'rc', content: 'Reaction catalysed' },
        ];
        this.PostData = [
            { genomeAbbr: source.genomeAbbr, recordType: source.compareType, id: source.id },
            { genomeAbbr: target.genomeAbbr, recordType: target.compareType, id: target.id },
        ];
    }

    componentDidMount() {
        this.fetchData();
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = () => {
        const { compareType, source, target } = this.props;
        Promise.all(
            this.PostData.map(data => getEcInfo(data.genomeAbbr, data.id, compareType))
        ).then(dataList => {
            const sourceData = this.buildRawDatafromResponse(dataList[0], source.name);
            const targetData = this.buildRawDatafromResponse(dataList[1], target.name);
            const concatData = sourceData;
            for (const targetEcData of targetData) {
                const index = concatData.findIndex(concatEcData => concatEcData.ec === targetEcData.ec);
                if (index === -1) {
                    concatData.push(targetEcData);
                } else {
                    const newData = { ...targetEcData, owner: 'Both' };
                    concatData[index] = newData;
                }
            }
            const sortedTableData = concatData.sort(this.sortData);
            if(!this.__ignoreLastFetch)
                this.setState({ tableData: sortedTableData });
        });
    };

    // 将返回值转换为表格可处理的格式
    buildRawDatafromResponse(data, owner) {
        const rawData = [];
        data.forEach(ecData => {
            const { ec, name, alias_name: alias, reaction_catalysed: rc } = ecData;
            rawData.push({ 
                ec, 
                name, 
                alias: <ul>{alias.map((item, index) => <li key={index}>{item}</li>)}</ul>, 
                rc: <ul>{rc.map((item, index) => <li key={index}>{item}</li>)}</ul>,
                url: <Atoms.CustomLink 
                    href={`https://enzyme.expasy.org/EC/${ec}`}
                    externalTarget="ExPASy"
                >{ec}</Atoms.CustomLink>,
                owner 
            });
        });
        return rawData;
    }
    // 将已经扁平化的数据按要求排序
    sortData(data1, data2) {
        if (data1.owner === 'Both' && data2.owner !== 'Both') return -1;
        else if (data2.owner === 'Both' && data1.owner !== 'Both') return 1;
        else {
            if (data1.owner > data2.owner) return 1;
            else if (data2.owner > data1.owner) return -1;
            else {
                if (data1.ec > data2.ec) return 1;
                else if (data2.ec > data1.ec) return -1;
                return 0;
            }
        }
    }

    render() {
        const { tableData } = this.state;

        if (tableData.length === 0) {
            return null;
        }

        return (
            <Atoms.CustomTable
                columns={this.header}
                dataSource={tableData}
            />
        );
    }
}

CompareEcTable.propTypes = {
    source: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    target: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.string
    ]),
    compareType: PropTypes.oneOf(['Locus', 'Trans', 'Prot', ''])
};

export default CompareEcTable;

