/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions, Button, Slide, AppBar, IconButton, Typography, Toolbar } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function CustomDialog({ open, title, onClose, children }) {
    return (
        <Dialog
            fullScreen
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
        >
            <AppBar style={{ position: 'relative' }}>
                <Toolbar style={{ color: 'rgba(255, 255, 255, 0.87)'}}>
                    <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography id="alert-dialog-slide-title" variant="h6" color="inherit">{title}</Typography>
                </Toolbar>
            </AppBar>
            
            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
}

CustomDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string,
    onClose: PropTypes.func,
    children: PropTypes.any
};

export default CustomDialog;