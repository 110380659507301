import React from 'react';
import { Atoms, Layouts } from 'components';
import { getStaticMarkdownText } from 'api/staticFile';

class DownloadView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: {},
            rowList: [],
            spanList: {}
        };
    }

    componentDidMount() {
        getStaticMarkdownText('download_data.json').then(
            text => JSON.parse(text)
        ).then(
            res => {
                this.setState({
                    dataSource: res['data'],
                    rowList: res['row_name'],
                    spanList: res['span_indx']
                });
            }
        );
    }

    render() {
        const { dataSource, rowList, spanList } = this.state;
        return (
            <Layouts.MainLayoutContent headerText="Download">
                <Atoms.VerticalTable
                    dataSource={dataSource}
                    rows={rowList}
                    spanList={spanList}
                />
            </Layouts.MainLayoutContent>
        );
    }
}

export default DownloadView;
