import React from 'react';
import PropTypes from 'prop-types';
import { TextField, InputAdornment, withStyles, IconButton } from '@material-ui/core';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const styles = theme => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
});

function InputControl ({classes, value, label, handleChange, handleSubmit}) {
    return(
        <TextField
            label={label}
            className={classes.textField}
            value={value}
            onChange={handleChange}
            margin="normal"
            variant="outlined"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton
                            edge="end"
                            onClick={handleSubmit}
                        >
                            <ArrowRightAltIcon />
                        </IconButton>
                    </InputAdornment>
                )
            }}
        />
    );
}

InputControl.propTypes = {
    classes: PropTypes.object,
    value: PropTypes.string,
    label: PropTypes.string,
    handleChange: PropTypes.func,
    handleSubmit: PropTypes.func
};

export default withStyles(styles)(InputControl);
