import React from 'react';
import { Atoms } from 'components';
import { Grid, Button } from '@material-ui/core';
import { getMataInfo } from 'api/fetch_data';
import PropTypes from 'prop-types';
import { size } from 'lodash';


class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: {
                searchField: '',
                species: '',
                genome: '',
                assemble: ''
            },
            data: [],
            currentGenomeList: [],
            currentAssembleList: [],
            searchText: ''
        };

        this.searchFieldOptions = [
            { value: 'name', text: 'Name' },
            { value: 'go', text: 'Gene Ontology' },
            { value: 'ko', text: 'KEGG Orthology' },
            { value: 'ec', text: 'EC number' },
            { value: 'pathway', text: 'KEGG pathway' },
            { value: 'model', text: 'KEGG model' },
            { value: 'disease', text: 'KEGG disease' },
            { value: 'interpro_domain', text: 'InterPro domain' },
            { value: 'pfam_domain', text: 'Pfam domain' },
            { value: 'cdd_domain', text: 'Conserved domain' },
            { value: 'tf_family', text: 'TF family' },
            { value: 'eggNOG_gene_family', text: 'Gene family' },
        ];

    }

    componentDidMount() {
        // 通过ref暴露子组件实例供翻页组件获取
        this.fetchData();
    }

    getSuggestions = (text) => {
        const { searchField, species, genome, assemble } = this.state.values;
        return this.props.getSuggestions(searchField, species, genome, assemble, text);
    }

    handleInputChange = (event, { newValue }) => {
        this.setState({ searchText: newValue });
    };

    performSearch = (event, page, pageSize) => {
        const { values: { searchField, species, genome, assemble }, searchText } = this.state;
        if (searchText !== '' && searchField !== '' && species !== '' && genome !== '' && assemble !== '') {
            this.props.handleSearch({ searchField, searchText, species, genome, assemble }, page, pageSize);
        }
    }

    componentWillUnmount() {
        this.__ignoreLastFetch = true;
    }

    fetchData = async () => {
        // 获取菜单。如果有初始值，在获取完成后赋值。
        const res = await getMataInfo(true);
        if (!this.__ignoreLastFetch && res) {
            this.setState({ data: res }, () => {
                const { filterParams } = this.props;
                if (size(filterParams)) {
                    this.setValues(filterParams);
                }
            });
        }
    };

    setValues = (event, { searchField, species, genome, assemble, searchText }) => {
        const selectedSpecies = this.state.data.find(item => item.abbr_name === species);
        const currentGenomeList = selectedSpecies ? selectedSpecies.genome_li : [];

        const selectedGenome = currentGenomeList.find(item => item.abbr_name === genome);
        const currentAssembleList = selectedGenome ? selectedGenome.assemble_li : [];
        this.setState({
            values: {
                searchField,
                species,
                genome,
                assemble,
            },
            searchText,
            currentGenomeList,
            currentAssembleList
        });
    }

    valueIsEmpty = () => {
        const { values: { searchField, species, genome, assemble }, searchText } = this.state;
        return (searchField && species && genome && assemble && searchText) === '';
    }

    handleFieldChange = (event) => {
        const newValues = { ...this.state.values, searchField: event.target.value };
        this.setState({ values: newValues });
    }

    handleSpeciesChange = (event) => {
        const newValues = { ...this.state.values, species: event.target.value, genome: '', assemble: '' };
        const currentGenomeList = this.state.data.find(item => item['abbr_name'] === event.target.value)['genome_li'];
        this.setState({ values: newValues, currentGenomeList, currentAssembleList: [] });
    };

    handleGenomeChange = (event) => {
        const newValues = { ...this.state.values, genome: event.target.value, assemble: '' };
        const currentAssembleList = this.state.currentGenomeList.find(item => item['abbr_name'] === event.target.value)['assemble_li'];
        this.setState({ values: newValues, currentAssembleList });
    };

    handleAssembleChange = (event) => {
        const newValues = { ...this.state.values, assemble: event.target.value };
        this.setState({ values: newValues });
    };

    render() {
        const { data, values: { species, genome, assemble, searchField }, currentGenomeList, currentAssembleList, searchText } = this.state;
        const speciesOptions = data.map(item => ({
            value: item.abbr_name,
            text: item.name
        }));
        const genomeOptions = currentGenomeList.map(item => ({
            value: item.abbr_name,
            text: item.abbr_name
        }));
        const assembleOptions = currentAssembleList.map(item => ({
            value: item,
            text: item
        }));
        return (<Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <Atoms.SelectControl
                    value={species}
                    handleChange={this.handleSpeciesChange}
                    placeholder="Species"
                    options={speciesOptions}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Atoms.SelectControl
                    value={genome}
                    placeholder="Genome"
                    options={genomeOptions}
                    handleChange={this.handleGenomeChange}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Atoms.SelectControl
                    value={assemble}
                    placeholder="Gene model"
                    options={assembleOptions}
                    handleChange={this.handleAssembleChange}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <Atoms.SelectControl
                    value={searchField}
                    handleChange={this.handleFieldChange}
                    placeholder="Search By..."
                    options={this.searchFieldOptions}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <Atoms.AutoComplete
                    getSuggestions={this.getSuggestions}
                    inputValue={searchText}
                    handleInputChange={this.handleInputChange}
                />
            </Grid>
            <Grid container item justify="flex-end" xs={12} spacing={2}>
                <Button
                    size="large"
                    style={{ marginTop: '8px' }}
                    onClick={this.performSearch}
                    color="primary"
                    disabled={searchText === '' || species === '' || genome === '' || assemble === ''}
                >Search</Button>
            </Grid>
        </Grid>);
    }
}


SearchForm.propTypes = {
    handleSearch: PropTypes.func,
    filterParams: PropTypes.object,
    getSuggestions: PropTypes.func
};

export default SearchForm;